<div class="flex flex-col gap-6"
  *transloco="let t; prefix: 'shared.request-walkthrough-modal.request-walkthrough-success'">
  <h2 class="h2 text-black">{{t('header')}}</h2>
  <p>{{t('infoText.walkthroughConfirmed')}}</p>
  <div class="divider-line1"></div>
  <div class="flex flex-col gap-6 justify-between items-center">
    <p>{{t('infoText.walkthroughDetails', { brokerName: property?.brokerName, streetNumber:
      property?.address?.streetNumber, streetName: property?.address?.streetName, prefecture:
      property?.address?.prefecture, formatedDate: formattedDate})}}</p>
    <img class="h-full w-full" src="../../../../../assets/request-callback/walkthrough-congrats.svg" alt="">
  </div>

  <button role="button" (click)="onSuccess()"
    class="btn-primary w-full flex flex-row items-center justify-center mt-6">{{t('buttons.great')}}!</button>
</div>