<ng-container *transloco="let t; prefix: 'loan-wizard'">
    <div class="container pt-[4.5rem] pb-[96px]">
        <h2 class="font-bold text-black text-[2.5rem]">Thank you!<br>Your loan application is now submitted for
            processing
        </h2>
        <div class="flex flex-row gap-12">
            <div class="flex flex-col gap-8 min-h-[570px]">
                <div class="p-6 rounded-[24px] bg-gray-100 min-h-[213px] flex flex-col gap-4 mb-8">
                    <div class="pb-4 border-b border-gray-500">
                        <div class="text-black font-semibold text-xl">Your application ID</div>
                        <div class="text-black font-semibold text-[2rem]">{{formData.applicationId}}</div>
                    </div>
                    <div class="text-gray-900">
                        Track your loan applications progress in <a class="text-secondary underline">My requests</a>. We
                        have also sent you an email confirmation.
                    </div>
                </div>

                <div class="mb-12">
                    <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('yourLoan')}}</h3>
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="text-left text-gray-700 font-normal pb-3 border-b border-gray-300">
                                    {{t('loanType')}}</th>
                                <th class="text-left text-black font-semibold pb-3 border-b border-gray-300">
                                    <ng-container [ngSwitch]="formData.loan.categoryId">
                                        <ng-container
                                            *ngSwitchCase="propertyMortgageCategoryId">{{t('answerLoanTypeMortgage')}}</ng-container>
                                        <ng-container
                                            *ngSwitchCase="propertyRenovationCategoryId">{{t('answerLoanTypeRenovation')}}</ng-container>
                                    </ng-container>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('propertyValue')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">€
                                    {{formData.loan.realEstateValue | number}}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('loanAmount')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">€
                                    {{formData.loan.amount | number}}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('loanDuration')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{formData.loan.duration}} {{t('years')}}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('fixedInterestDuration')}}
                                </td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{formData.loan.fixedInterestDuration}}
                                    {{t('years')}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mb-12">
                    <h3 class="font-bold text-black text-[1.75rem] mb-6">{{'shared.property' |
                        transloco}}</h3>
                    <table class="w-full">
                        <tbody>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">URL</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{formData.property.url}}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">
                                    {{'shared.checkout.step-buyer-info.labels.address'
                                    | transloco
                                    }}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{formData.property.address}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mb-12">
                    <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('yourDetails')}}</h3>
                    <table class="w-full">
                        <tbody>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('yearBon')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{formData.details.yearBorn}}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('professionalStatusTitle')}}
                                </td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    <ng-container [ngSwitch]="formData.details.professionalStatus">
                                        <ng-container *ngSwitchCase="'employed'">{{t('employed')}}</ng-container>
                                        <ng-container
                                            *ngSwitchCase="'selfEmployed'">{{t('selfEmployed')}}</ng-container>
                                        <ng-container *ngSwitchCase="'retired'">{{t('retired')}}</ng-container>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('marriageStatusTitle')}}
                                </td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    <ng-container [ngSwitch]="formData.details.marriageStatus">
                                        <ng-container *ngSwitchCase="'single'">{{t('answerSingle')}}</ng-container>
                                        <ng-container *ngSwitchCase="'married'">{{t('answerMarried')}}</ng-container>
                                        <ng-container
                                            *ngSwitchCase="'cohabitation'">{{t('answerCohabitation')}}</ng-container>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('coborrower')}}?</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">{{
                                    formData.details.haveCoborrower ?
                                    ('shared.answers.yes' | transloco) :
                                    ('shared.answers.no' | transloco) }}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('grossSalaryPerYear')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">€ {{
                                    formData.details.grossSalaryPerYear | number }}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('householdDebt')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">€ {{
                                    formData.details.householdDebt | number }}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('nationality')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{formData.details.nationality}}</td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('wherePayTaxTitle')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    <ng-container [ngSwitch]="formData.details.wherePayTax">
                                        <ng-container *ngSwitchCase="'inGreece'">{{t('inGreece')}}</ng-container>
                                        <ng-container *ngSwitchCase="'inEu'">{{t('inEu')}}</ng-container>
                                        <ng-container *ngSwitchCase="'outsideEu'">{{t('outsideEu')}}</ng-container>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('taxPerYear')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">€ {{
                                    formData.details.taxPerYear | number}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mb-12">
                    <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('yourGuarantor')}}</h3>
                    <table class="w-full">
                        <tbody>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{t('haveGuarantorTitle')}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{
                                    formData.guarantor.haveGuarantor ?
                                    ('shared.answers.yes' | transloco) :
                                    ('shared.answers.no' | transloco) }}
                                </td>
                            </tr>
                            <ng-container *ngIf="formData.guarantor.haveGuarantor">
                                <tr>
                                    <td class="text-gray-700 py-3 border-b border-gray-300">{{t('children')}}</td>
                                    <td class="text-black font-semibold py-3 border-b border-gray-300">
                                        {{formData.guarantor.childrenGuarantor}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-gray-700 py-3 border-b border-gray-300">{{t('grossSalaryPerYear')}}
                                    </td>
                                    <td class="text-black font-semibold py-3 border-b border-gray-300">
                                        € {{
                                        formData.guarantor.householdIncomeGuarantor |
                                        number
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-gray-700 py-3 border-b border-gray-300">{{t('householdDebt')}}</td>
                                    <td class="text-black font-semibold py-3 border-b border-gray-300">€ {{
                                        formData.guarantor.householdDebtGuarantor | number
                                        }}

                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-gray-700 py-3 border-b border-gray-300">{{t('yearBornGuarantor')}}
                                    </td>
                                    <td class="text-black font-semibold py-3 border-b border-gray-300">
                                        {{formData.guarantor.yearBornGuarantor}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-gray-700 py-3 border-b border-gray-300">{{t('marriageStatusTitle')}}
                                    </td>
                                    <td class="text-black font-semibold py-3 border-b border-gray-300">
                                        <ng-container [ngSwitch]="formData.guarantor.marriageStatusGuarantor">
                                            <ng-container *ngSwitchCase="'single'">{{t('answerSingle')}}</ng-container>
                                            <ng-container
                                                *ngSwitchCase="'married'">{{t('answerMarried')}}</ng-container>
                                            <ng-container
                                                *ngSwitchCase="'cohabitation'">{{t('answerCohabitation')}}</ng-container>
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-gray-700 py-3 border-b border-gray-300">{{t('taxPerYear')}}</td>
                                    <td class="text-black font-semibold py-3 border-b border-gray-300">
                                        € {{ formData.guarantor.taxPerYearGuarantor | number }}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>

                <div class="mb-12">
                    <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('preferredBranch')}}</h3>
                    <table class="w-full">
                        <tbody>
                            <tr>
                                <td class="text-gray-700 py-3 border-b border-gray-300">{{'shared.location' |
                                    transloco}}</td>
                                <td class="text-black font-semibold py-3 border-b border-gray-300">
                                    {{formData.branch.branchInfo}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="flex flex-col gap-12 max-w-[384px]">
                <div><app-doughnut-chart [showEstimatedFees]="true"></app-doughnut-chart></div>

                <div class="bg-white rounded-[1rem] py-8 flex flex-col items-center px-4">
                    <img class="mb-[0.875rem]" src="assets/static/image_9.webp">
                    <div class="font-bold text-black text-[1.75rem] mb-[1.125rem]">
                        Finance renovation or remodelling of your new property
                    </div>
                    <ul class="list-disc">
                        <li>Option to get your loan in advance</li>
                        <li>Borrow up to €20.000</li>
                        <li>Reduced interest rate</li>
                    </ul>
                    <button class="btn-primary mt-8 min-w-[313px]">Apply for a renovation loan</button>
                    <a href="#" class="font-semibold text-secondary underline py-3">See more services</a>
                </div>
            </div>
        </div>
        <div class="mb-12">
            <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('nextSteps')}}</h3>
            <div class="flex flex-row gap-8">
                <div>
                    <div class="font-semibold text-black mb-2">{{t('beReadyToProvideAdditionalDoc')}}</div>
                    <div class="text-gray-900">{{t('mayBeAskedMore')}}
                    </div>
                </div>
                <div>
                    <div class="font-semibold text-black mb-2">{{t('planYourMove')}}</div>
                    <div class="text-gray-900">{{t('planYourMoveParagraph')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>