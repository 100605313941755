<ng-container *transloco="let t; prefix: 'loan-wizard'">
    <ng-container *ngIf="resultData.isEligible">
        <div class="container pt-[4.5rem] pb-[96px]">
            <div class="flex flex-row gap-12">
                <div class="flex flex-col gap-8 min-h-[570px]">
                    <div class="flex flex-row items-end">
                        <div class="text-black font-bold text-[2.5rem]">
                            {{t('youAreEligibleForLoan')}}
                        </div>
                        <img src="assets/static/image_17.webp">
                    </div>

                    <div class="flex flex-row items-start gap-2 py-[.625rem] px-4 bg-gray-100 rounded">
                        <i class="icon-info-circle text-lg text-gray-900"></i>
                        <div>
                            {{t('notePreliminaryAssessment')}}
                        </div>
                    </div>

                    <div class="mb-12">
                        <div class="overflow-hidden" [ngClass]="{ 'max-h-[245px]': this.tableIsCollapsed }">
                            <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('yourLoan')}}</h3>
                            <table class="w-full mb-[4.5rem]">
                                <tbody>
                                    <tr>
                                        <td class="text-left text-gray-700 font-normal pb-3 border-b border-gray-300">
                                            {{t('loanType')}}
                                        </td>
                                        <td class="text-left text-black font-semibold pb-3 border-b border-gray-300">
                                            <ng-container [ngSwitch]="formData.loan.categoryId">
                                                <ng-container
                                                    *ngSwitchCase="propertyMortgageCategoryId">{{t('answerLoanTypeMortgage')}}</ng-container>
                                                <ng-container
                                                    *ngSwitchCase="propertyRenovationCategoryId">{{t('answerLoanTypeRenovation')}}</ng-container>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('propertyValue')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">€
                                            {{formData.loan.realEstateValue | number}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('loanAmount')}}</td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            € {{formData.loan.amount | number}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('loanDuration')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            {{formData.loan.duration}} {{t('years')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">
                                            {{t('fixedInterestDuration')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            {{formData.loan.interestRate}} {{t('years')}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('yourHousehold')}}
                            </h3>
                            <table class="w-full mb-[4.5rem]">
                                <tbody>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('yearBornTitle')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            {{formData.household.yearBorn}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">
                                            {{t('marriageStatusTitle')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            <ng-container [ngSwitch]="formData.household.marriageStatus">
                                                <ng-container
                                                    *ngSwitchCase="'single'">{{t('answerSingle')}}</ng-container>
                                                <ng-container
                                                    *ngSwitchCase="'married'">{{t('answerMarried')}}</ng-container>
                                                <ng-container
                                                    *ngSwitchCase="'cohabitation'">{{t('answerCohabitation')}}</ng-container>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('children')}}</td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            {{formData.household.children}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">
                                            {{t('householdIncomePerYear')}}</td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            € {{formData.household.householdIncomePerYear | number}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('taxPerYear')}}</td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            € {{formData.household.taxPerYear | number}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('householdDebt')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            € {{formData.household.householdDebt | number}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">{{t('isNbgCustomer')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            {{formData.household.isNbgCustomer ? ('shared.answers.yes' | transloco) :
                                            ('shared.answers.no' | transloco)}}</td>
                                    </tr>

                                </tbody>
                            </table>
                            <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('yourGuarantor')}}
                            </h3>
                            <table class="w-full mb-[4.5rem]">
                                <tbody>
                                    <tr>
                                        <td class="text-gray-700 py-3 border-b border-gray-300">
                                            {{t('haveGuarantorTitle')}}
                                        </td>
                                        <td class="text-black font-semibold py-3 border-b border-gray-300">
                                            {{formData.guarantor.wantGuarantor ? ('shared.answers.yes' | transloco) :
                                            ('shared.answers.no' | transloco) }}</td>
                                    </tr>
                                    <ng-container *ngIf="formData.guarantor.wantGuarantor">
                                        <tr>
                                            <td class="text-gray-700 py-3 border-b border-gray-300">
                                                {{t('yearBornTitle')}}</td>
                                            <td class="text-black font-semibold py-3 border-b border-gray-300">
                                                {{formData.guarantor.yearBornGuarantor}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-gray-700 py-3 border-b border-gray-300">
                                                {{t('children')}}</td>
                                            <td class="text-black font-semibold py-3 border-b border-gray-300">
                                                {{formData.guarantor.childrenGuarantor}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-gray-700 py-3 border-b border-gray-300">
                                                {{t('marriageStatusTitle')}}</td>
                                            <td class="text-black font-semibold py-3 border-b border-gray-300">
                                                <ng-container [ngSwitch]="formData.guarantor.marriageStatusGuarantor">
                                                    <ng-container
                                                        *ngSwitchCase="'single'">{{t('answerSingle')}}</ng-container>
                                                    <ng-container
                                                        *ngSwitchCase="'married'">{{t('answerMarried')}}</ng-container>
                                                    <ng-container
                                                        *ngSwitchCase="'cohabitation'">{{t('answerCohabitation')}}</ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-gray-700 py-3 border-b border-gray-300">
                                                {{t('householdIncomePerYear')}}</td>
                                            <td class="text-black font-semibold py-3 border-b border-gray-300">
                                                € {{formData.guarantor.householdIncomeGuarantor | number}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-gray-700 py-3 border-b border-gray-300">
                                                {{t('taxPerYear')}}</td>
                                            <td class="text-black font-semibold py-3 border-b border-gray-300">
                                                € {{formData.guarantor.taxPerYearGuarantor | number}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-gray-700 py-3 border-b border-gray-300">
                                                {{t('householdDebt')}}</td>
                                            <td class="text-black font-semibold py-3 border-b border-gray-300">
                                                € {{formData.guarantor.householdDebtGuarantor | number}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <button (click)="toggleCollapse($event)"
                            class="text-secondary font-semibold flex flex-row items-center gap-2 mt-3"><i
                                [ngClass]="{ 'icon-chevron-up': this.tableIsCollapsed,'icon-chevron-down': !this.tableIsCollapsed, }"></i>{{t('allDetailsEntered')}}</button>
                    </div>
                </div>
                <div class="flex flex-col gap-12 max-w-[384px]">
                    <app-doughnut-chart [showEstimatedFees]="true"></app-doughnut-chart>
                </div>
            </div>
            <div class="mb-12">
                <h3 class="font-bold text-black text-[1.75rem] mb-6">{{t('nextSteps')}}</h3>
                <div class="flex flex-row gap-8">
                    <div>
                        <div class="font-semibold text-black mb-2">{{t('beReadyToProvideAdditionalDoc')}}</div>
                        <div class="text-gray-900">{{t('mayBeAskedMore')}}</div>
                    </div>
                    <div>
                        <div class="font-semibold text-black mb-2">{{t('planYourMove')}}</div>
                        <div class="text-gray-900">{{t('planYourMoveParagraph')}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-navigation-buttons [HideSkip]=" true" [HidePrevious]="false" (PreviousButtonClicked)="onPrevClick()"
            (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="onNextClick()"
            [CustomPreviousTitle]="t('goBackToPropertyButton')"
            [CustomNextTitle]="t('applyForLoan')"></app-navigation-buttons>
    </ng-container>
    <ng-container *ngIf="!resultData.isEligible">
        <div class="container pt-[4.5rem] pb-[96px]">
            <div class="flex flex-row items-center gap-1 mb-12">
                <h2 class="text-black font-bold text-[2.5rem]">{{t('sorryNotEligible')}}</h2>
                <img src="assets/static/image_5.webp" alt="Image">
            </div>

            <div>
                <h3 class="text-black font-semibold text-[1.75rem] mb-6">{{t('howWeAccessEligibility')}}</h3>
                <div class="flex flex-row gap-6">
                    <div>
                        <div class="text-black font-semibold">{{t('yourIncomeAndOutgoings')}}
                        </div>
                        <div>
                            Lorem ipsum dolor sit amet consectetur. Mi pellentesque hendrerit tristique dignissim
                            mattis.
                            Quisque non duis placerat in. <br><br>

                            In vestibulum elementum sit facilisi. Habitant nisi vitae volutpat sit. In pellentesque eu
                            ipsum
                            vel suspendisse nibh eget gravida.
                        </div>
                    </div>
                    <div>
                        <div class="text-black font-semibold">{{t('ifCanGuaranteeYourLoan')}}</div>
                        <div>
                            Lorem ipsum dolor sit amet consectetur. Mi pellentesque hendrerit tristique dignissim
                            mattis.
                            Quisque non duis placerat in. <br><br>

                            In vestibulum elementum sit facilisi. Habitant nisi vitae volutpat sit.
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h3 class="text-black font-semibold text-[1.75rem] mb-6">{{t('getInTouchLearnMore')}}</h3>
                <div class="flex flex-row gap-6">
                    <div>
                        <div class="text-black font-semibold">{{t('callYourAdvisor')}}</div>
                        <div>
                            Lorem ipsum dolor sit amet consectetur. Mi pellentesque hendrerit tristique dignissim
                            mattis.
                            Quisque non duis placerat in. <br><br>

                            In vestibulum elementum sit facilisi. Habitant nisi vitae volutpat sit. In pellentesque eu
                            ipsum
                            vel suspendisse nibh eget gravida.
                        </div>
                    </div>
                    <div>
                        <div class="text-black font-semibold">{{t('speakNbgFinancialExperts')}}</div>
                        <div>
                            Lorem ipsum dolor sit amet consectetur. Mi pellentesque hendrerit tristique dignissim
                            mattis.
                            Quisque non duis placerat in. <br><br>

                            In vestibulum elementum sit facilisi. Habitant nisi vitae volutpat sit.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-navigation-buttons [HideSkip]=" true" [HidePrevious]="false" (PreviousButtonClicked)="onPrevClick()"
            (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="onNextClick()"
            [CustomPreviousTitle]="t('goBackToPropertyButton')"
            [CustomNextTitle]="t('seeFinanceOptionsButton')"></app-navigation-buttons>
    </ng-container>
</ng-container>