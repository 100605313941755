import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BuyerInfoModel, CardInfoModel } from '../models';
import { TranslocoDirective } from '@jsverse/transloco';
import { GridListCardsComponent } from "../../../../features/find-property/grid-list-cards/grid-list-cards.component";
import { PortalApiService, PropertySummary } from 'src/app/services/portal-api.service';
import { Observable } from 'rxjs';
import { PropertyCardItemComponent } from "../../property-card-item/property-card-item.component";
import { OrderResponse } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-step-review',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, GridListCardsComponent, PropertyCardItemComponent],
  templateUrl: './step-review.component.html',
  styleUrl: './step-review.component.css'
})
export class StepReviewComponent implements OnInit {
  @Input() cardData: CardInfoModel | undefined;
  @Input() orderData: OrderResponse | undefined;
  @Input() isFromCompleteStep: boolean = false;
  protected propertySummary$: Observable<PropertySummary> | undefined;
  private propertyId?: string;
  constructor(
    private _portalApiService: PortalApiService
  ) {
  }

  ngOnInit(): void {

    this.propertyId = this.orderData?.orderItems?.at(0)?.propertyId ?? '';
    this.propertySummary$ = this._portalApiService.getPropertyById(this.propertyId).pipe();
    if (!this.isFromCompleteStep)
      this.configureTreeDs();
  }

  private configureTreeDs(): void {

    ThreeDS.configure({
      merchantId: this.cardData?.merchant,
      sessionId: this.cardData?.sessionId,
      containerId: "3DSUI",
      callback: () => {
        if (ThreeDS.isConfigured()) {
          this.initiateThreeDsAuthentication();
        } else {
          alert('error on 3Ds configuration')
        }
      },
      configuration: {
        wsVersion: Number(this.cardData?.version)
      }
    });
  }

  private initiateThreeDsAuthentication(): void {
    const orderNumber = this.orderData?.number ?? '';
    const transactionId = this.cardData?.transactionId ?? '';
    const optionalParams = {
      sourceOfFunds: {
        type: "CARD"
      },
    };

    ThreeDS.initiateAuthentication(orderNumber, transactionId, (data) => {
      if (data && data.error) {
        var error = data.error;

        //Something bad happened, the error value will match what is returned by the Authentication API
        console.error("error.code : ", error.code);
        console.error("error.msg : ", error.msg);
        console.error("error.result : ", error.result);
        console.error("error.status : ", error.status);
      } else {
        console.log("After Initiate 3DS ", data);

        //data.response will contain information like gatewayRecommendation, authentication version, and so on.
        console.log("REST API raw response ", data.restApiResponse);
        console.log("Correlation Id", data.correlationId);
        console.log("Gateway Recommendation", data.gatewayRecommendation);
        console.log("HTML Redirect Code", data.htmlRedirectCode);
        console.log("Authentication Version", data.authenticationVersion);

        switch (data.gatewayRecommendation) {
          case "PROCEED":
            if (data.restApiResponse.transaction.authenticationStatus != "AUTHENTICATION_AVAILABLE") {
              alert('data.restApiResponse.transaction.authenticationStatus != "AUTHENTICATION_AVAILABLE"')

            }
            //data.restApiResponse.transaction.authenticationStatus == "AUTHENTICATION_AVAILABLE"
            //ThreeDS.authenticatePayer(orderNumber, transactionId);//merchant's method
            //TODO: use paybody

            break;
          case "RESUBMIT_WITH_ALTERNATIVE_PAYMENT_DETAILS":
            //ThreeDS.tryOtherPayment();//Card does not support 3DS and transaction filtering rules require 3DS on this transaction: Ask the payer to select a different payment method.
            break;
        }
      }
    }, optionalParams);
  }
}
