import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PortalApiService, PropertySummaryResultSet, PropertyType } from 'src/app/services/portal-api.service';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, {
  Navigation,
  Thumbs,
  Scrollbar,
  EffectFade,
  Pagination,
  Autoplay,
  Keyboard,
  A11y,
  SwiperOptions,
  Lazy,
  Grid,
  Controller,
  Swiper,
} from 'swiper';

import { SwiperComponent } from 'swiper/angular';
import { PropertyCardItemComponent } from '../property-card-item/property-card-item.component';
import { ReactiveComponentBase } from 'src/app/core/components/ReactiveComponentBase';
import { Observable, of, switchMap } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';
SwiperCore.use([
  Pagination,
  Navigation,
  Thumbs,
  Scrollbar,
  Autoplay,
  Keyboard,
  A11y,
  EffectFade,
  Lazy,
  Grid,
  Controller,
]);
@Component({
  selector: 'app-related-property-swiper',
  templateUrl: './property-swiper.component.html',
  standalone: true,
  imports: [CommonModule, SwiperModule, PropertyCardItemComponent, TranslocoDirective],
  styleUrls: ['./property-swiper.component.scss'],
})
export class RelatedPropertySwiperComponent extends ReactiveComponentBase<PropertySummaryResultSet> implements OnInit {

  @Input() title: string = '';

  //Related by property id
  @Input() propertyId: string | undefined;
  //Related filters
  @Input() area: number | undefined;
  @Input() type: PropertyType | undefined;
  @Input() location: string | undefined;
  @Input() newWindow = false;

  @ViewChild('propertySwiper') swiper?: SwiperComponent;

  public config: SwiperOptions = {
    slidesPerView: 2,
    spaceBetween: 20,
    effect: 'coverflow',
    uniqueNavElements: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1536: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    }
  };

  constructor(private _portalApi: PortalApiService) {
    super()
  }

  override getModel(): Observable<PropertySummaryResultSet> {
    let searchType: [PropertyType] | undefined;
    if (this.type && this.type != PropertyType.Unknown) {
      searchType = [this.type];
    }
    let minSqMeters = this.area;
    let maxSqMeters = this.area;
    return this._portalApi.getPropertyList(false,
      false,
      1,
      20,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      searchType,
      minSqMeters,
      maxSqMeters,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.location,
      undefined,).pipe(
        switchMap((res) => { return of(new PropertySummaryResultSet(res.properties)) })
      );
  }


  onSwiper(swiper: any) { }
  onSlideChange() { }
  swipePrev() {
    this.swiper?.swiperRef.slidePrev();
  }
  swipeNext() {
    this.swiper?.swiperRef.slideNext();
  }
}
