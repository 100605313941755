<div class="rounded-small shadow-[0_4px_18px_0_rgba(0,0,0,0.17)] p-8">
  <div class="flex flex-col rfs:gap-7">
    <div class="flex justify-between">
      <div class="flex flex-col gap-2">
        <h2 class="text-black font-medium md:h2 md:leading-10 text-xl">Δημιούργησε λογαριασμό ή συνδέσου για να μας
          στείλεις το μήνυμά σου</h2>
      </div>
      <div>
        <i class="icon-close hover:cursor-pointer" (click)="close()"></i>
      </div>
    </div>
    <div class="divider-line1 border-b-tone-05"></div>
    <div class="flex flex-col gap-3">

      <p class="text-gray-900">Για να μας στείλεις μήνυμα θα χρειαστεί να δημιουργήσεις λογαριασμό ή να συνδεθείς.</p>
    </div>
    <img class="object-contain w-full h-[190px] md:h-[245px]" src="../../../../assets/happy_family_sitting_in_sofa.png"
      alt="erikceder document being stamped">

    <div class="flex pb-4 pt-2 flex-col gap-3">
      <a class="btn-primary text-center w-full" href="/register">Δημιουργία δωρεάν λογαριασμού</a>
      <div class="text-gray-900 flex justify-center">Έχεις ήδη λογαριασμό Uniko;
        <a class="text-primary font-medium ml-1 underline" href="/login">Login here</a></div>
    </div>
  </div>
</div>
