<div class="flex flex-col flex-1 h-full" *transloco="let t; prefix: 'property-upload-wizard.welcome-page'">
    <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
        <div class="max-w-[1008px] w-full">
        </div>
        <div class="flex items-center justify-center">
            <h2 class="h2 max-w-[940px] rfs:text-[60px] rfs:leading-[64px] text-left font-semibold text-black">
                {{t('headers.main')}}</h2>
        </div>
        <div class="flex flex-col md:flex-row">
            <div class="flex flex-col gap-6 max-w-[487px]">
                <div class="flex flex-col gap-2">
                    <h4 class="h4 text-black text-xl font-semibold">{{t('headers.stepOne')}}</h4>
                    <div class="flex gap-4">
                        <div class="flex justify-center">
                            <div class="h-8 w-8 bg-secondary rounded-full flex justify-center items-center">
                                <i class="icon-pencil text-[12px] text-white"></i>
                            </div>
                        </div>
                        <p>{{t('infoText.stepOne')}}</p>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <h4 class="h4 text-black text-xl font-semibold">{{t('headers.stepTwo')}}</h4>
                    <div class="flex gap-4">
                        <div class="flex justify-center">
                            <div class="h-8 w-8 bg-secondary rounded-full flex justify-center items-center">
                                <i class="icon-user-edit text-[12px] text-white"></i>
                            </div>
                        </div>
                        <p>{{t('infoText.stepTwo')}}</p>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <h4 class="h4 text-black text-xl font-semibold">{{t('headers.stepThree')}}</h4>
                    <div class="flex gap-4">
                        <div class="flex justify-center">
                            <div class="h-8 w-8 bg-secondary rounded-full flex justify-center items-center">
                                <i class="icon-rocket text-[12px] text-white"></i>
                            </div>
                        </div>
                        <p>{{t('infoText.stepThree')}}</p>
                    </div>
                </div>
            </div>
            <div class="object-top object-contain flex-1">
                <img class="h-[466px] w-full" src="assets/property-wizard/house-karikatur.webp"
                    alt="laptop in front of house karikatur">
            </div>
        </div>
    </div>
    <div class="flex items-end pt-8">
        <div class="w-full border-t border-gray-300">
            <div
                class="bg-gray-100 py-6 px-3 lg:px-[72px] flex flex-col md:flex-row items-center justify-between gap-2">
                <div>
                    <button class="btn-text" [routerLink]="['/home']">{{t('buttons.exit')}}</button>
                </div>
                <div class="flex flex-col md:flex-row gap-4 justify-between" [routerLink]="['/property-upload-wizard']">
                    <button class="btn-primary min-w-[288px] flex items-center justify-center">{{t('buttons.createListing')}}<i class="icon-arrow-right text-[20px]"></i></button>
                </div>
                <div class="flex">
                    <button class="hidden btn-text flex items-center justify-center gap-2"><i
                            class="icon-arrow-right text-[20px]"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>