import { Component, Input, input } from '@angular/core';
import { IndicationSidePanelInputModel } from './IndicationSidePanelInputModel';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-indication-side-panel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './indication-side-panel.component.html',
  styleUrl: './indication-side-panel.component.css'
})
export class IndicationSidePanelComponent {
  @Input() inputData: Array<IndicationSidePanelInputModel> = [];

  activeAnchor: number = 0;

  /**
 * Scrolls to section with attribute scroll-anchor=<scroll-to> 
 */
  scrollToTarget(event: MouseEvent, index: number) {
    this.activeAnchor = index;
    let scrollTo = (event?.currentTarget as HTMLElement).getAttribute("scroll-to") ?? "";
    let scrollAnchor = document.querySelector(`[scroll-anchor="${scrollTo}"]`) as Element;
    const offset = -80;
    const y = scrollAnchor?.getBoundingClientRect().top + window.scrollY + offset;
    if (y) {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
