import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroSectionComponent } from './hero-section/hero-section.component';
import { ParagraphSectionComponent } from './paragraph-section/paragraph-section.component';
import { FeaturesSectionComponent } from './features-section/features-section.component';
import { FloorPlanSectionComponent } from './floor-plan-section/floor-plan-section.component';
import { DetailSectionComponent } from './detail-section/detail-section.component';
import { ReactiveComponentBase } from 'src/app/core/components/ReactiveComponentBase';
import {
  MediaType,
  Photo,
  PhotoResultSet,
  PortalApiService,
  PropertyDetails,
  PropertySummaryResultSet,
} from 'src/app/services/portal-api.service';
import { Observable, map, mergeMap, of, takeUntil, tap } from 'rxjs';
import { PropertyListingService } from 'src/app/services/property.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MapAreaSectionComponent } from './map-area-section/map-area-section.component';
import { IndicationSidePanelInputModel } from 'src/app/shared/components/indication-side-panel/IndicationSidePanelInputModel';
import { IndicationSidePanelComponent } from '../../shared/components/indication-side-panel/indication-side-panel.component';
import { ToasterContainerComponent } from 'src/app/shared/components/toast/toast-container.component';
import { DocumentsSectionComponent } from './documents-section/documents-section.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { FinancingSectionComponent } from './financing-section/financing-section.component';
import { SimilarPropertiesComponent } from 'src/app/shared/components/similar-properties/similar-properties.component';
import { PropertyInsightsComponentComponent } from '../../shared/components/property-insights-component/property-insights-component.component';
import { AsideContentComponent } from './aside-content/aside-content.component';
import { PropertyDescriptionComponent } from './property-description/property-description.component';
import { ReviewComponent } from './review/review.component';
import { PropertyServicesComponent } from './property-services/property-services.component';
import { CostsComponent } from './costs/costs.component';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-property-details',
  standalone: true,
  templateUrl: './property-details.component.html',
  styleUrl: './property-details.component.css',
  imports: [
    CommonModule,
    HeroSectionComponent,
    ParagraphSectionComponent,
    DetailSectionComponent,
    FeaturesSectionComponent,
    FloorPlanSectionComponent,
    MapAreaSectionComponent,
    IndicationSidePanelComponent,
    ToasterContainerComponent,
    DocumentsSectionComponent,
    FinancingSectionComponent,
    SimilarPropertiesComponent,
    PropertyInsightsComponentComponent,
    AsideContentComponent,
    PropertyDescriptionComponent,
    ReviewComponent,
    PropertyServicesComponent,
    CostsComponent,
    TopNavigationComponent,
  ],
})
export class PropertyDetailsComponent extends ReactiveComponentBase<PropertyDetails> {
  public propertyId: string = '';
  protected propertyLat: number = 0;
  protected propertyLon: number = 0;
  protected isLoggedIn: boolean = false;
  protected isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn();
  protected sidePanelData: Array<IndicationSidePanelInputModel> = [];
  public similarProperties: PropertySummaryResultSet | undefined;
  public photos: PhotoResultSet | undefined;
  public floorPlans: Photo[] | undefined;

  constructor(
    private modelService: PropertyListingService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private portalApi: PortalApiService,
    private translocoService: TranslocoService
  ) {
    super();
    this.activatedRoute.params
      .pipe(
        tap((t: Params) => {
          this.propertyId = t['propertyId'];
        }),
        mergeMap(() => {
          return this.isLoggedIn$;
        }),
        mergeMap((isLoggedIn: boolean) => {
          if (isLoggedIn) return portalApi.addRecentlyViewed(this.propertyId);
          else return of(undefined);
        }),
      )
      .subscribe();

    this.populateSidePanelData();

    this.portalApi
      .getRelatedProperties(this.propertyId, 1, 7, undefined, undefined)
      .pipe(
        tap((res) => {
          this.similarProperties = res;
        }),
      )
      .subscribe();

    this.portalApi.getPhotos(this.propertyId ?? '').subscribe({
      next: (res) => { 
        this.photos = res;
        this.floorPlans = this.photos?.items?.filter(x => x.type === MediaType.PropertyElevation); 
      }
    })
  }

  protected populateSidePanelData() {

    this.translocoService.selectTranslateObject('property-details.top-navigation').pipe(
      tap((t: any) => {
        this.sidePanelData = [
          {
            label: t['description'],
            scrollTo: 'paragraph',
          },
          {
            label: t['details'],
            scrollTo: 'detail',
          },
          {
            label: t['features'],
            scrollTo: 'features',
          },
          {
            label: t['map-area'],
            scrollTo: 'mapArea',
          },
          {
            label: t['floor-plan'],
            scrollTo: 'floorPlan',
          },
          {
            label: t['property-insights'],
            scrollTo: 'insights',
          },
          {
            label: t['services'],
            scrollTo: 'services'
          },
          {
            label: t['fees'],
            scrollTo: 'fees',
          },
          {
            label: t['financing'],
            scrollTo: 'financing',
          },
        ]
      })
    ).subscribe();

  }
  override getModel(): Observable<PropertyDetails> {
    let result = this.modelService.getPropertyById(this.propertyId);

    return result;
  }
}
