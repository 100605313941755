import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-features-visual-checkbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './features-visual-checkbox.component.html',
  styleUrl: './features-visual-checkbox.component.css'
})
export class FeaturesVisualCheckboxComponent {
  @Input() visCheckClass: string = "";
  @Input() icon: string = "";
  @Input() iconClass: string = "";
  @Input() spanClass: string = "";
  @Input() mainText: string = "";


}
