<ng-container *ngIf="isVissible">
  <div
    class="absolute rounded top-0 bottom-0 right-0 left-0 bg-[#03624433] backdrop-blur-md flex items-center justify-center"
  >
    <div
      class="max-w-[360px] w-full flex flex-col items-center gap-4 py-4 px-8 bg-white rounded-lg"
    >
      <div
        class="text-secondary-900 w-12 h-12 rounded-full border border-secondary-900 flex items-center justify-center text-xl"
      >
        <i class="icon-lock"></i>
      </div>
      <div>
        <a [routerLink]="['#']" class="text-primary-700 font-semibold underline"
          >Δημιούργησε δωρεάν λογαριασμό ή συνδέσου</a
        >
        για να δεις την αξιολόγηση του ακινήτου
      </div>
    </div>
  </div>
</ng-container>
