import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Address, AuctionRoundFilter, BasicInfo, BedroomFilter, CreateListingResponse, FloorFilter, HeatingControl, HeatingType, PortalApiService, PropertyDetails, PropertyListFilter, PropertySummaryResultSet, PropertyType, Search, View } from "./portal-api.service";


@Injectable({
    providedIn: 'root'
})
export class PropertyListingService {
    constructor(private _portalApi: PortalApiService) { }

    public getProperties(acceptLanguage: string, page?: number | undefined, size?: number | undefined, sort?: string | undefined, search?: string | undefined, filters?: PropertyListFilter, includeDetails: boolean = false
    ): Observable<{ criteriaAdjusted: boolean, properties: PropertySummaryResultSet }> {
        let propertySummary = this._portalApi.getPropertyList(includeDetails, filters?.isSold ?? false, page, size, sort, "", undefined, filters?.minPrice, filters?.maxPrice, filters?.type, filters?.minSqMeters, filters?.maxSqMeters, filters?.floor, filters?.hasParking, filters?.parkingSpace, filters?.bedrooms, filters?.bathrooms, filters?.minConstructionYear, filters?.maxConstructionYear, filters?.preApproved, filters?.inAuction, filters?.auctionRound, filters?.hasElevator, filters?.hasAlarm, filters?.isAiry, filters?.sieves, filters?.hasSolarWaterHeating, filters?.hasBoiler, filters?.suitableForProfessionalUse, filters?.suitableForTouristUse, filters?.hasNightElectricCurrent, filters?.hasSafetyDoor, filters?.isBright, filters?.accessibilityForDisabled, filters?.hasPlayroom, filters?.isPenthouse, filters?.hasGarden, filters?.hasPool, filters?.hasStorageRoom, filters?.petsAreAllowed, filters?.hasTents, filters?.isFloorApartment, filters?.internalStaircase, filters?.view, filters?.numberOfRooms, filters?.numberOfBathRooms, undefined, undefined, undefined, filters?.heatingType, undefined,filters?.area, undefined, filters?.isREO, filters?.tag)
            .pipe(
                map(res => ({ criteriaAdjusted: res.criteriaAdjusted ?? false, properties: new PropertySummaryResultSet(res.properties) }))
            )
        return propertySummary;

    }

    public getPropertyById(propertyId: string): Observable<PropertyDetails> {
        let propertyDetails = this._portalApi.getPropertyById(propertyId);
        return propertyDetails;
    }

    public createPropertyBasicInfo(propertyData: propertyData): Observable<CreateListingResponse> {
        let coordinates: string | undefined
        if (propertyData.PropertyAddress?.Lat && propertyData.PropertyAddress?.Lon) {
            coordinates = `${propertyData.PropertyAddress?.Lat},${propertyData.PropertyAddress?.Lon}`;
        }
        let body = new BasicInfo({
            type: propertyData.PropertyType,
            askingPrice: propertyData.MaxPrice,
            lowestPrice: propertyData.MinPrice,
            area: propertyData.PropertyArea,
            address: new Address({
                postalCode: propertyData.PropertyAddress?.PostalCode,
                administrativeAreas: propertyData.PropertyAddress?.AdministrativeAreas,
                country: propertyData.PropertyAddress?.Country,
                geoPoint: coordinates,
                prefecture: propertyData.PropertyAddress?.Prefecture,
                streetNumber: propertyData.PropertyAddress?.StreetNumber,
                streetName: propertyData.PropertyAddress?.StreetName,
            })
        })
        return this._portalApi.postListing(body);
    }

    public getSearchFiltersFromUrl(params: ParamMap): PropertyListFilter {

        const parsePropertyType = (value: string): PropertyType | undefined => {
            const propertyTypeMap: { [key: string]: PropertyType } = {
                "unknown": PropertyType.Unknown,
                "apartment": PropertyType.Apartment,
                "studio": PropertyType.Studio,
                "apartmentcomplex": PropertyType.ApartmentComplex,
                "loft": PropertyType.Loft,
                "residentialbuilding": PropertyType.ResidentialBuilding,
                "maisonette": PropertyType.Maisonette,
                "detachedhouse": PropertyType.DetachedHouse,
                "villa": PropertyType.Villa,
                "duplex": PropertyType.Duplex,
                "farmhouse": PropertyType.FarmHouse,
                "bungallow": PropertyType.Bungallow,
                "semidetached": PropertyType.SemiDetached,
                "residencewithcommercialuse": PropertyType.ResidenceWithCommercialUse,
                "office": PropertyType.Office,
                "commercialofficebuilding": PropertyType.CommercialOfficeBuilding,
                "shop": PropertyType.Shop,
                "space": PropertyType.Space,
                "business": PropertyType.Business,
                "showroom": PropertyType.Showroom,
                "commercialbuilding": PropertyType.CommercialBuilding,
                "specialpurpose": PropertyType.SpecialPurpose,
                "industrialspace": PropertyType.IndustrialSpace,
                "hall": PropertyType.Hall,
                "lightindustrialspace": PropertyType.LightIndustrialSpace,
                "warehouse": PropertyType.Warehouse,
                "land": PropertyType.Land,
                "landplot": PropertyType.LandPlot,
                "parcelorlandfield": PropertyType.ParcelOrLandField,
                "hotel": PropertyType.Hotel,
                "other": PropertyType.Other,
                "parking": PropertyType.Parking,
                "storage": PropertyType.Storage,
                "island": PropertyType.Island,
                "prefabricated": PropertyType.Prefabricated,
                "detachable": PropertyType.Detachable,
                "air": PropertyType.Air,
            };
            return propertyTypeMap[value.toLowerCase()];
        };
        const parseAuctionRound = (value: string): AuctionRoundFilter | undefined => {

            const auctionRoundFilterMap: { [key: string]: AuctionRoundFilter } = {
                "first": AuctionRoundFilter.First,
                "second": AuctionRoundFilter.Second,
                "third": AuctionRoundFilter.Third,
                "fourth": AuctionRoundFilter.Fourth,
            };
            return auctionRoundFilterMap[value.toLowerCase()]
        }
        const parseRoomFilter = (value: string): BedroomFilter | undefined => {

            const bedroomFilterMap: { [key: string]: BedroomFilter } = {
                "one": BedroomFilter.One,
                "two": BedroomFilter.Two,
                "three": BedroomFilter.Three,
                "four": BedroomFilter.Four,
                "fiveplus": BedroomFilter.FivePlus,
            };
            return bedroomFilterMap[value.toLowerCase()]

        }
        const parseHeatingType = (value: string): HeatingType | undefined => {
            const heatingTypeMap: { [key: string]: HeatingType } = {
                "heatingoil": HeatingType.HeatingOil,
                "naturalgas": HeatingType.NaturalGas,
                "electricairconditioning": HeatingType.ElectricAirConditioning,
                "electricheatpump": HeatingType.ElectricHeatPump,
                "electricradiators": HeatingType.ElectricRadiators,
                "electricheatpumpwaterheater": HeatingType.ElectricHeatPumpWaterHeater,
                "fireplacesimple": HeatingType.FireplaceSimple,
                "energyefficientfireplace": HeatingType.EnergyEfficientFireplace,
                "pelletstove": HeatingType.PelletStove,
                "heatpump": HeatingType.HeatPump,
                "geothermal": HeatingType.Geothermal,
                "otherheating": HeatingType.OtherHeating,
                "other": HeatingType.Other,
            };
            return heatingTypeMap[value.toLowerCase()]

        }

        const parseHeatingControl = (value: string): HeatingControl | undefined => {
            const heatingControlMap: { [key: string]: HeatingControl } = {
                "autonomous": HeatingControl.Autonomous,
                "central": HeatingControl.Central
            };
            return heatingControlMap[value.toLowerCase()]
        }

        const parseParkingSpaceFilter = (value: string): BedroomFilter | undefined => {
            console.log(value.toLowerCase());
            const parkingSpaceFilterMap: { [key: string]: BedroomFilter } = {
                "one": BedroomFilter.One,
                "two": BedroomFilter.Two,
                "three": BedroomFilter.Three,
                "four": BedroomFilter.Four,
                "fiveplus": BedroomFilter.FivePlus
            };

            return parkingSpaceFilterMap[value.toLowerCase()]
        }

        const parseFloorNumber = (value: string): FloorFilter | undefined => {
            const floorFilterMap: { [key: string]: FloorFilter } = {
                "ground": FloorFilter.Ground,
                "first": FloorFilter.First,
                "second": FloorFilter.Second,
                "third": FloorFilter.Third,
                "fourthplus": FloorFilter.FourthPlus,
                "topfloor": FloorFilter.TopFloor,
            };
            return floorFilterMap[value.toLowerCase()]

        }

        const parseBoolean = (value: string | null): boolean | undefined => {
            return value === 'true' ? true : value === 'false' ? false : undefined;
        };

        // Helper function to parse number values
        const parseNumber = (value: string | null): number | undefined => {
            return value !== null ? Number(value) : undefined;
        };

        // Helper function to parse arrays
        const parseArray = (value: string | null): string[] | undefined => {
            return value ? value.split(',') : undefined;
        };

        let types: PropertyType[] | undefined = []
        parseArray(params.get('type'))?.forEach(x => {
            let val = parsePropertyType(x);
            if (val) {
                types?.push(val);
            }
        });

        let floors: FloorFilter[] | undefined = [];
        parseArray(params.get('floor'))?.forEach(x => {
            let val = parseFloorNumber(x);
            if (val) {
                floors?.push(val);
            }
        });

        let bedrooms: BedroomFilter[] | undefined = [];
        parseArray(params.get('bedrooms'))?.forEach(x => {
            let val = parseRoomFilter(x);
            if (val) {
                bedrooms?.push(val);
            }
        });

        let bathrooms: BedroomFilter[] | undefined = [];
        parseArray(params.get('bathrooms'))?.forEach(x => {
            let val = parseRoomFilter(x);
            if (val) {
                bathrooms?.push(val);
            }
        });

        let auctionRounds: AuctionRoundFilter[] | undefined = [];
        parseArray(params.get('auctionRound'))?.forEach(x => {
            let val = parseAuctionRound(x);
            if (val) {
                auctionRounds?.push(val);
            }
        });

        let heatingTypes: HeatingType[] | undefined = [];
        parseArray(params.get('heatingType'))?.forEach(x => {
            let val = parseHeatingType(x);
            if (val) {
                heatingTypes?.push(val);
            }
        });

        let heatingControls: HeatingControl[] | undefined = [];
        parseArray(params.get('heatingControl'))?.forEach(x => {
            let val = parseHeatingControl(x);
            if (val) {
                heatingControls?.push(val);
            }
        })

        let parkingSpace: BedroomFilter[] | undefined = [];
        parseArray(params.get('parkingSpace'))?.forEach(x => {
            let val = parseParkingSpaceFilter(x);
            if (val) {
                parkingSpace?.push(val);
            }
        })

        return new PropertyListFilter({
            searchTerm: params.get('searchTerm') || undefined,
            minPrice: parseNumber(params.get('minPrice')),
            maxPrice: parseNumber(params.get('maxPrice')),
            type: types.length > 0 ? types : undefined,
            minSqMeters: parseNumber(params.get('minSqMeters')),
            maxSqMeters: parseNumber(params.get('maxSqMeters')),
            floor: floors.length > 0 ? floors : undefined,
            hasParking: parseBoolean(params.get('hasParking')),
            bedrooms: bedrooms.length > 0 ? bedrooms : undefined,
            bathrooms: bathrooms.length > 0 ? bathrooms : undefined,
            parkingSpace: parkingSpace.length > 0 ? parkingSpace : undefined,
            minConstructionYear: parseNumber(params.get('minConstructionYear')),
            maxConstructionYear: parseNumber(params.get('maxConstructionYear')),
            preApproved: parseBoolean(params.get('preApproved')),
            inAuction: parseBoolean(params.get('inAuction')),
            auctionRound: auctionRounds.length > 0 ? auctionRounds : undefined,
            hasElevator: parseBoolean(params.get('hasElevator')),
            hasAlarm: parseBoolean(params.get('hasAlarm')),
            isAiry: parseBoolean(params.get('isAiry')),
            sieves: parseBoolean(params.get('sieves')),
            hasSolarWaterHeating: parseBoolean(params.get('hasSolarWaterHeating')),
            hasBoiler: parseBoolean(params.get('hasBoiler')),
            suitableForProfessionalUse: parseBoolean(params.get('suitableForProfessionalUse')),
            suitableForTouristUse: parseBoolean(params.get('suitableForTouristUse')),
            hasNightElectricCurrent: parseBoolean(params.get('hasNightElectricCurrent')),
            hasSafetyDoor: parseBoolean(params.get('hasSafetyDoor')),
            isBright: parseBoolean(params.get('isBright')),
            accessibilityForDisabled: parseBoolean(params.get('accessibilityForDisabled')),
            hasPlayroom: parseBoolean(params.get('hasPlayroom')),
            isPenthouse: parseBoolean(params.get('isPenthouse')),
            hasGarden: parseBoolean(params.get('hasGarden')),
            hasPool: parseBoolean(params.get('hasPool')),
            hasStorageRoom: parseBoolean(params.get('hasStorageRoom')),
            petsAreAllowed: parseBoolean(params.get('petsAreAllowed')),
            hasTents: parseBoolean(params.get('hasTents')),
            isFloorApartment: parseBoolean(params.get('isFloorApartment')),
            internalStaircase: parseBoolean(params.get('internalStaircase')),
            view: params.get('view') || undefined,
            numberOfRooms: params.get('numberOfRooms') || undefined,
            numberOfBathRooms: params.get('numberOfBathRooms') || undefined,
            heatingType: heatingTypes.length > 0 ? heatingTypes : undefined,
            // heatingControls: heatingControls.length > 0 ? heatingControls : undefined,
            area: params.get('area') || undefined,
            includeDetails: parseBoolean(params.get('includeDetails')),
            clauses: params.get('clauses') || undefined,
            isREO: parseBoolean(params.get('isREO')),
            isSold: parseBoolean(params.get('isSold')),
            tag: parseArray(params.get("tag")) || undefined
            // search: params.get('search') || undefined
        });
    }

    public saveSearch(filters: PropertyListFilter | undefined, name: string | undefined, placeName?: string | undefined): Observable<void> {
        let body = new Search({
            name: name ?? "",
            placeName: placeName,
            propertyType: filters?.type?.join(','),
            query: filters,
        })

        return this._portalApi.saveSearch(body);
    }
}

export class PlaceBoundsViewModel {
    SwLon: number | undefined
    SwLat: number | undefined
    NeLon: number | undefined
    NeLat: number | undefined
}

export class PlaceViewModel {
    PlaceId?: string | undefined = undefined
    FormatedAddress?: any
    Country?: any
    Prefecture?: any
    AdministrativeAreas?: any
    StreetName?: any
    StreetNumber?: any
    PostalCode?: any
    Lon?: any | undefined
    Lat?: any | undefined
    Bounds: PlaceBoundsViewModel = new PlaceBoundsViewModel();
}
export interface propertyData {
    PropertyType?: PropertyType,
    PropertyArea?: number,
    PropertyAddress?: PlaceViewModel,
    MinPrice?: number,
    MaxPrice?: number
}
export class PropertyFilters {
    minPrice?: number | undefined;
    maxPrice?: number | undefined;
    minSqMeters?: number | undefined;
    maxSqMeters?: number | undefined;
    minYear?: number | undefined;
    maxYear?: number | undefined;
    propertyType?: string | undefined;
    isSold?: boolean | undefined;
    hasParking?: boolean | undefined;
    hasStorage?: boolean | undefined;
    hasGarden?: boolean | undefined;
    view?: View[];
    floor?: string | undefined;
    roomNumber?: string | undefined;
    bathroomNumber?: string | undefined;
    auctionRound?: string | undefined;
    preApproved?: boolean | undefined;
    isReo?: boolean | undefined;
    area?: string | undefined
}
