import { CommonModule } from '@angular/common';
import { ProgressStepperComponent } from 'src/app/shared/components/progress-stepper/progress-stepper.component';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { OnboardingData, TogglePropertyTypeButton, ToggleSupportingServicesTypeButton } from './models';
import { GoogleMapsAutocompleteComponent } from 'src/app/shared/components/google-maps-autocomplete/google-maps-autocomplete.component';
import { KindOfSupportPreference, LookingForPropertyOfType, ProfilingAnswers } from 'src/app/services/portal-api.service';
import { Router, RouterModule } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { ModalService } from '@indice/ng-components';
import { OnboardingExitModalComponent } from '../onboarding-exit-modal/onboarding-exit-modal.component';
import { NavigationButtonsComponent } from 'src/app/shared/components/navigation-buttons/navigation-buttons.component';
import { PropertyTypeButtonsComponent } from 'src/app/shared/components/property-type-buttons/property-type-buttons.component';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { tap } from 'rxjs';
import { SellerFinishPageComponent } from './finish-page/finish-page.component';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-onboarding-seller',
  standalone: true,
  imports: [CommonModule, ProgressStepperComponent, RouterModule, NavigationButtonsComponent, PropertyTypeButtonsComponent, TranslocoDirective, SellerFinishPageComponent],
  templateUrl: './onboarding-seller.component.html',
  styleUrl: './onboarding-seller.component.css'
})
export class OnboardingSellerComponent implements OnInit {
  Step: string = "Step1"
  StepKeys: string[] = [
    "your-property",
    "your-needs"
  ]
  Steps: string[] = []
  SelectedPropertyType: LookingForPropertyOfType | undefined;
  SelectedSupportServices: KindOfSupportPreference[] = [];

  SupportServices: ToggleSupportingServicesTypeButton[] = [
    {
      "id": KindOfSupportPreference.LegalSupport,
      "title": "Legal support",
      "isToggled": false
    },
    {
      "id": KindOfSupportPreference.TechnicalDueDiligenceSupport,
      "title": "Technical due diligence support",
      "isToggled": false
    },
    {
      "id": KindOfSupportPreference.DontNeedSupport,
      "title": "I don't need support",
      "isToggled": false
    },
    {
      "id": KindOfSupportPreference.Unspecified,
      "title": "I don't know",
      "isToggled": false
    }
  ]
  OnboardingData: OnboardingData = new OnboardingData;

  constructor(private _router: Router, private _onboardingService: OnboardingService, private modalService: ModalService, private translateService: TranslocoService, private authService: AuthService) { }
  ngOnInit(): void {
    this.translateService.selectTranslateObject('onboarding.onboarding-seller').pipe(
      tap(t => {
        this.SupportServices.forEach(sp => sp.title = t[sp.id])
        this.StepKeys.forEach(step => {
          this.Steps.push(t[step]);
        })
      })
    ).subscribe();
  }

  buttonSelected(buttonIds: LookingForPropertyOfType[]) {
    if (buttonIds.length > 0) {
      this.SelectedPropertyType = buttonIds[0];
    }
  }
  toggleStep3Select(buttonId: KindOfSupportPreference | undefined) {
    var button = this.SupportServices.find(x => x.id == buttonId);
    if (!button || !button.id)
      return;
    button.isToggled = !button.isToggled;
    if (buttonId == KindOfSupportPreference.DontNeedSupport || buttonId == KindOfSupportPreference.Unspecified) {
      this.SelectedSupportServices = [button.id];
      this.SupportServices.forEach(ss => {
        if (ss.id != buttonId) {
          ss.isToggled = false
        }
      });
    }
    else {
      this.SupportServices.forEach(ss => {
        if (ss.id == KindOfSupportPreference.DontNeedSupport || ss.id == KindOfSupportPreference.Unspecified) {
          ss.isToggled = false;
        }
      })
      let index = this.SelectedSupportServices.indexOf(button.id);
      if (index > -1) {
        this.SelectedSupportServices.splice(index, 1);
      }
      else {
        this.SelectedSupportServices.push(button.id);
      }

      this.SelectedSupportServices = this.SelectedSupportServices.filter(ss => ss != KindOfSupportPreference.Unspecified && ss != KindOfSupportPreference.DontNeedSupport)
    }
  }
  Goto(step: string) {
    this.Step = step;
  }
  SaveStep1() {
    this.OnboardingData.propertyType = this.SelectedPropertyType;
    this.Goto("Step2")
    console.log(this.OnboardingData);
  }
  SaveStep2() {
    this.OnboardingData.services = this.SelectedSupportServices;
    this.Goto("Step3")
    console.log(this.OnboardingData);
  }
  SaveAnswers(navigation: string) {
    var profilingAnswers: ProfilingAnswers = new ProfilingAnswers({
      sellerProtertyType: this.OnboardingData.propertyType,
      sellerKindOfSupportPreference: this.OnboardingData.services
    });
    this._onboardingService.updateAccountOwner(undefined, profilingAnswers).subscribe();
    this.authService.signinRedirect(navigation);
  }
  openModalComponent() {
    const exitModal = this.modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
}
