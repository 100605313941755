<ng-container *ngIf="model$ | async; let model">
  <app-hero-section [model]="model" [photos]="photos"></app-hero-section>
  <div class="container pt-10">
    <div class="xl:flex flex-row rfs:gap-[4.5rem]">
      <div class="xl:max-w-[calc(100%_-_283px_-_4.5rem)] relative">
        <app-top-navigation [inputData]="sidePanelData"></app-top-navigation>
        <app-property-description
          scroll-anchor="paragraph"
          [model]="model"
        ></app-property-description>
        <app-detail-section
          scroll-anchor="detail"
          [model]="model"
        ></app-detail-section>
        <app-features-section
          scroll-anchor="features"
          [model]="model"
        ></app-features-section>
        <app-map-area-section
          scroll-anchor="mapArea"
          [lat]="model?.address?.geoPoint?.split(',')?.at(0)"
          [lon]="model?.address?.geoPoint?.split(',')?.at(1)"
          [propertyID]="propertyId"
        ></app-map-area-section>
        @if (floorPlans && floorPlans.length > 0) {
          <app-floor-plan-section
          scroll-anchor="floorPlan"
          [address]="model.address?.streetName"
          [plotArea]="model?.plotArea"
          [numOfRooms]="model.numberOfRooms"
          [propertyId]="propertyId"
          [floorPlans]="floorPlans"
        ></app-floor-plan-section>
        }
        <section scroll-anchor="insights">
          <app-property-insights-component
            [PropertyId]="model.id"
          ></app-property-insights-component>
        </section>
        <app-property-services scroll-anchor="services"></app-property-services>
        <app-costs
          scroll-anchor="fees"
          [propertyValue]="model.price || 0"
        ></app-costs>
        <app-financing-section
          [propertyValue]="model.price"
          [isLoggedIn]="isLoggedIn$ | async"
          scroll-anchor="financing"
        ></app-financing-section>
        <div
          class="px-20 pb-20"
          *ngIf="similarProperties && similarProperties.count !== 0"
        >
          <div class="divider-line2 border-b-teal pt-10"></div>
          <app-similar-properties
            [similarProperties]="similarProperties"
          ></app-similar-properties>
        </div>
      </div>
      <app-aside-content [property]="model"></app-aside-content>
    </div>
  </div>
</ng-container>
<lib-toaster-container></lib-toaster-container>
