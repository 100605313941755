<div class="flex-1 pb-24">
    <div class="container mb-[72px]"></div>
    <div class="container">
        <div class="bg-white rounded-[1rem] p-8 flex flex-col gap-8 min-h-[570px]">
            <h5 class="text-black font-semibold text-2xl">Choose the property you are interested in</h5>
            <div>
                <fieldset class="flex flex-col gap-2 group/input">
                    <label
                        class="font-semibold flex items-center justify-between text-gray-950 group-disabled:text-gray-300">Neon
                        property ID<i class="icon-info-circle text-gray-700 text-xl"></i></label>
                    <input [formControl]="searchProperty" type="text" placeholder="Paste a link to the property here"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                </fieldset>
            </div>

            <app-property-card-item [newWindow]="true" *ngIf="propertySummary" [withBorder]="true"
                [propertySummary]="this.propertySummary"></app-property-card-item>

            <div class="flex flex-row items-center gap-4">
                <span class="text-gray-700 text-xl">Or choose from below</span>
                <span class="h-px flex-1 bg-gray-300"></span>
            </div>
            <div class="w-full p-1 border-full border border-gray-500 bg-gray-50 flex flex-row rounded-full">
                <button (click)="showFavouritesUi = true" class="tab-pill" [ngClass]="{'active': showFavouritesUi}"><i
                        class="icon-star"></i>Your favourites</button>
                <button (click)="showFavouritesUi = false" class="tab-pill" [ngClass]="{'active': !showFavouritesUi}"><i
                        class="
                icon-clock"></i>Recently viewed</button>
            </div>
            <ng-container *ngIf="showFavouritesUi">
                <ng-container *ngFor="let propertyFavouriteSummary of propertyFavourites">

                    <app-property-card-item [newWindow]="true" [isClickable]="false" [withBorder]="true"
                        (click)="chooseThisProperty(propertyFavouriteSummary.id)"
                        [propertySummary]="propertyFavouriteSummary"></app-property-card-item>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!showFavouritesUi">
                <ng-container *ngFor="let propertyRecentlyViewedSummary of propertyRecentlyViewed">
                    <app-property-card-item [newWindow]="true" [isClickable]="false" [withBorder]="true"
                        (click)="chooseThisProperty(propertyRecentlyViewedSummary.id)"
                        [propertySummary]="propertyRecentlyViewedSummary"></app-property-card-item>
                </ng-container>
            </ng-container>
        </div>
    </div>

</div>
<app-navigation-buttons [HideSkip]="true" [HideExit]="true" (NextButtonClicked)="proceedToCheckout()"
    (PreviousButtonClicked)="navigationBack()"></app-navigation-buttons>