import { mergeMap as _observableMergeMap, BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { PMT, CUMPRINC } from '@formulajs/formulajs';
import { EstimatedFees, EstimatedFeeService } from './estimated-fee.service';

export const NBG_PORTAL_API_BASE_URL = new InjectionToken<string>('NBG_PORTAL_API_BASE_URL');

@Injectable({
    providedIn: 'root'
})

export class LoanService {
    private http: HttpClient;
    private baseUrl: string;

    private loanCalculatorValues: LoanCalculatorValues = new LoanCalculatorValues();
    private payments: IComputedValues = {
        interestRate: 0,
        paymentAmountFinal: 0,
        paymentAmountInitial: 0,
        paymentAmountInterest: 0
    };
    private variableRate = 0;
    private fixedRate = 0;
    private hasOnlyInterestPeriod = false;
    private onlyInterestPeriod = 1;

    private loanValues = new BehaviorSubject(new LoanValues(this.loanCalculatorValues, this.payments, this.variableRate, this.fixedRate));
    public currentLoanValues = this.loanValues.asObservable();

    private estimatedFees = new BehaviorSubject<EstimatedFees | null>(null);
    public currentEstimatedFees = this.estimatedFees.asObservable();
    
    private selectedLoanCategory = new BehaviorSubject(LoanCategories[0].id);
    public currentSelectedCategory = this.selectedLoanCategory.asObservable();


    constructor(@Inject(HttpClient) http: HttpClient, private _estimatedFeeService: EstimatedFeeService, @Optional() @Inject(NBG_PORTAL_API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ?? "";
    }

    public getData(categoryId: string, productId: string = ''): Observable<IGetDataResponse> {
        let url_ = this.baseUrl + "/nbgapi/mortgageCalculator/getData";
        url_ = url_.replace(/[?&]$/, "");

        let request: IGetDataRequest = {
            categoryId: categoryId,
            productId: productId
        }
        return this.http.post<IGetDataResponse>(url_, request).pipe(_observableMergeMap((response: any) => {
            if (response) {
                return of(response);
            } else {
                return of(null);
            }
        }));
    }

    public checkEligibility(request: ICheckEligibilityRequest): Observable<ICheckEligibilityResponse> {
        let url_ = this.baseUrl + "/nbgapi/mortgageCalculator/checkEligibility";
        url_ = url_.replace(/[?&]$/, "");

        return this.http.post<ICheckEligibilityResponse>(url_, request).pipe(_observableMergeMap((response: any) => {
            if (response) {
                return of(response)
            } else {
                return of(null);
            }
        }));
    }

    public calculateLoanAmounts(amount: number, duration: number, fixedDuration: number, rate: number, fixedRate: number, hasOnlyInterestPeriod: boolean, onlyInterestPeriod: number): IComputedValues {
        let value: IComputedValues = {
            interestRate: rate,
            paymentAmountFinal: 0,
            paymentAmountInitial: 0,
            paymentAmountInterest: 0
        }
        if (fixedDuration > duration) {
            duration = fixedDuration;
        }
        if (hasOnlyInterestPeriod) {
            onlyInterestPeriod = onlyInterestPeriod * 12; //in months
            value.paymentAmountInterest = ((fixedDuration > 0) ? fixedRate : rate) * amount / 100 / 12;
        } else {
            onlyInterestPeriod = 0;
        }
        if (fixedDuration > 0) {
            value.paymentAmountInitial = -PMT(fixedRate / 100 / 12, (duration * 12) - onlyInterestPeriod, amount, undefined, undefined);
        }
        else {
            value.paymentAmountInitial = -PMT(rate / 100 / 12, ((duration * 12) - onlyInterestPeriod), amount, undefined, undefined);
        }
        if (fixedDuration < duration && fixedDuration > 0) {
            let fixedRateCumulativePrincipal = CUMPRINC(fixedRate / 100 / 12, ((duration * 12) - onlyInterestPeriod), amount, 1, (fixedDuration * 12), 0);
            let balance = amount + typeof (fixedRateCumulativePrincipal) === typeof (Error) ? 0 : fixedRateCumulativePrincipal;
            let periodicPaymentAmount = -PMT(rate / 100 / 12, (((duration - fixedDuration) * 12) - onlyInterestPeriod), amount, undefined, undefined);
            let periodicPaymentBalance = -PMT(rate / 100 / 12, (((duration - fixedDuration) * 12) - onlyInterestPeriod), balance, undefined, undefined);
            value.paymentAmountFinal = (typeof (periodicPaymentAmount) === typeof (Error) ? 0 : periodicPaymentAmount) + (typeof (periodicPaymentBalance) === typeof (Error) ? 0 : periodicPaymentBalance);
            //value.paymentAmountFinal = -PMT(rate / 100 / 12, (((duration - fixedDuration) * 12) - onlyInterestPeriod), balance, undefined, undefined);
        }

        return value;
    }

    public changeLoanValues(newLoanValues: LoanValues) {
        this.variableRate = newLoanValues.variableRate;
        this.fixedRate = newLoanValues.fixedRate;
        this.payments = newLoanValues.payments;
        this.loanCalculatorValues = newLoanValues.loanCalculatorValues;
        
        this.loanValues.next(newLoanValues);
    }

    public resetLoanValues(fixedPropertyValue: number | undefined) {
        this.variableRate = 0;
        this.fixedRate = 0;
        this.payments = {
            interestRate: 0,
            paymentAmountFinal: 0,
            paymentAmountInitial: 0,
            paymentAmountInterest: 0
        };
        this.loanCalculatorValues = new LoanCalculatorValues();
        
        if (fixedPropertyValue) {
            this.loanCalculatorValues.propertyValue = fixedPropertyValue;
        }
        this.changeLoanValues(new LoanValues(this.loanCalculatorValues, this.payments, this.variableRate, this.fixedRate));
    }

    public calculateEstimatedFees() {
        let fees = this._estimatedFeeService.calculateEstimatedFees(this.loanCalculatorValues.propertyValue);
        this.estimatedFees.next(fees);
    }

    public changeSelectedCategory(categoryId: string) {
        let id = LoanCategories.find((x) => x.id === categoryId)?.id;
        if (id) {
            this.selectedLoanCategory.next(id);
        }
    }

    public changePropertyValue(newPropertyValue: number) {
        this.loanCalculatorValues.propertyValue = newPropertyValue;

        this.changeLoanValues(new LoanValues(this.loanCalculatorValues, this.payments, this.variableRate, this.fixedRate));
        this.calculateEstimatedFees();
    }
}

export enum LoanCategory {
    Mortgage = "Mortgage",
    Renovation = "Renovation"
}

export var LoanCategories = [
    { id: "9EEC3373-4411-44C1-82F9-8B12A3C30566", category: LoanCategory.Mortgage },
    { id: "300951CE-9CC4-4904-8921-540AD2B6CC20", category: LoanCategory.Renovation }
]

export interface ICalculateRateRequest {
    realEstateValue: number;
    amount: number;
    duration: number;
    fixedInterestRate: number;
    insurance: boolean;
    categoryId: string;
}

export interface ICalculateRateResponse {
    paymentAmountInitial: number;
    paymentAmountFinal: number;
    interestRate: number;
}

export interface ICheckEligibilityRequest {
    loanInfo: ILoanInfo;
    personalInfo: IPersonalInfo;
    financialInfo: IFinancialInfo;
    guarantors: IGuarantors;
}

export interface ICheckEligibilityResponse {
    loanAmount: number;
    isEligible: boolean;
}

export interface IFinancialInfo {
    familyIncome: number;
    totalTaxValue: number;
    totalDebt: number;
    underageKidsNumber: number;
}

export interface IGetDataRequest {
    categoryId?: string;
    productId?: string;
}

export interface IGetDataResponse {
    property: IProperty;
    products: IProduct[];
}

export interface IGuarantors {
    useGuarantors: boolean;
    numberOfGuarantors?: number;
    numberOfGuarantorsUnder60?: number;
    totalIncome: number;
    totalTaxValue: number;
    totalDebt: number;
    guarantor: IGuarantor[];
}

export interface IGuarantor {
    birthYearOfGuarantor?: number;
    income?: number;
    taxValue?: number;
    debt: number;
    underageKidsNumber?: number;
    maritalStatus: string;
}

export interface ILoanInfo {
    categoryId: string;
    realEstateValue: number;
    amount: number;
    duration: number;
    interestRate: string;
    fixedInterestRate?: number;
    security: number;
    energyUpgrade: boolean;
}

export interface IPersonalInfo {
    isCustomer: boolean;
    birthYear: number;
    maritalStatus: string;
    underageKidsNumber: number;
}

export interface IProduct {
    productId: string;
    productName: string;
    categoryId: string;
    categoryName: string;
    security: string[];
    amount: IValueRange;
    duration: IValueRange;
    gracePeriod: IValueRange;
    interestRateType: string;
    fixedInterestRate: IValueRange;
    variableInterestRate: IValueRange;
    fixedRates: IRate[];
    variableRates: IRate[];
    energyUpgrade: boolean;
}

export interface IProductRate {
    productId: string;
    fixedRates: IRate[];
    variableRates: IRate[];
}

export interface IProperty {
    propertyValueMin: number;
    propertyValueMax: number;
}

export interface IRate {
    interestRate?: number;
    loanDuration?: number;
    fixedRateDurationMax?: number;
}

export interface IRatesRequest {
    categoryId?: string;
    productId?: string;
}

export interface IRatesResponse {
    productRates: IProductRate[];
}

export interface IValueRange {
    valueDefault?: number;
    valueMin?: number;
    valueMax?: number;
}

export interface IComputedValues extends ICalculateRateResponse {
    paymentAmountInterest: number;
}

export class LoanCalculatorValues {
    propertyValue: number;
    loanAmount: number;
    loanDuration: number;
    loanFixedDuration: number;

    constructor(
        propertyValue = 140000,
        loanAmount = 100000,
        loanDuration = 40,
        loanFixedDuration = 10
    ) {
        this.propertyValue = propertyValue;
        this.loanAmount = loanAmount;
        this.loanDuration = loanDuration;
        this.loanFixedDuration = loanFixedDuration;
    }
}

export class LoanValues {
    loanCalculatorValues: LoanCalculatorValues;
    payments: IComputedValues;
    variableRate: number;
    fixedRate: number;

    constructor(
        loanCalculatorValues: LoanCalculatorValues,
        payments: IComputedValues,
        variableRate: number,
        fixedRate: number
    ) {
        this.loanCalculatorValues = loanCalculatorValues;
        this.payments = payments;
        this.variableRate = variableRate;
        this.fixedRate = fixedRate;
    }
}