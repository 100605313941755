import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ContentFormGroup, PropertyListingTab } from '../property-listing.component';
import { Advisor, BasicInfo } from 'src/app/services/portal-api.service';
import { Subject } from 'rxjs';
import { AdvisorCallbackComponent } from 'src/app/shared/components/advisor-callback/advisor-callback.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [ReactiveFormsModule, AdvisorCallbackComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './content.component.html',
  styleUrl: './content.component.css'
})
export class ContentComponent implements OnInit{
  @Input() contentFormGroup: FormGroup<ContentFormGroup> = new FormGroup(new ContentFormGroup());
  @Input() advisor: Advisor | undefined;
  @Input() propertyBasicInfo: BasicInfo | undefined;

  @Output() onNextTab: Subject<PropertyListingTab> = new Subject();

  public propertyListingTab = PropertyListingTab;

  ngOnInit(): void {
    let prefilledTitleEl = !this.contentFormGroup.controls.titleEl.value ? 
      this.propertyBasicInfo?.area !== undefined && this.propertyBasicInfo?.type !== undefined && this.propertyBasicInfo.address?.prefecture !== undefined ?
      `Ένα ${this.propertyBasicInfo.type.toString()} ${this.propertyBasicInfo.area} τετραγωνικών μέτρων - ${this.propertyBasicInfo.address.prefecture}` : null : this.contentFormGroup.controls.titleEl.value;

    let prefilledTitleEn = !this.contentFormGroup.controls.titleEl.value ? 
      this.propertyBasicInfo?.area !== undefined && this.propertyBasicInfo?.type !== undefined && this.propertyBasicInfo.address?.prefecture !== undefined ?
      `A ${this.propertyBasicInfo.area}m² ${this.propertyBasicInfo.type.toString()} in ${this.propertyBasicInfo.address.prefecture}` : null : this.contentFormGroup.controls.titleEn.value;

    this.contentFormGroup.disable();
    this.contentFormGroup.patchValue({
      titleEl: prefilledTitleEl,
      titleEn: prefilledTitleEn
    }, { onlySelf: true, emitEvent: false});
    this.contentFormGroup.enable();
  }

  public navigateToTab(tab: PropertyListingTab) {
    this.onNextTab.next(tab);
  }

}
