<div class="flex-1" [formGroup]="basicInfoFormGroup" *transloco="let t; prefix: 'property-listing.basic-information'">
    <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
            <h2 class="h2 text-black">{{'property-listing.tabs.basicInformation' | transloco}}</h2>
            <ng-container [ngTemplateOutlet]="!editPrices ? prices : editPrices">
            </ng-container>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.propertyType')}}</h3>
            <div class="flex flex-col md:flex-row gap-4">

                <label for="villaCheckbox" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="villaCheckbox" class="opacity-0 absolute h-0 w-0"
                        [checked]="basicInfoFormGroup.controls.type.value == propertyType.Villa"
                        (click)="basicInfoFormGroup.controls.type.setValue(propertyType.Villa)">
                    <i class="icon-villas text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyType.' + propertyType.Villa |transloco}}</span>
                </label>


                <label for="apartmentCheckbox" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="apartmentCheckbox" class="opacity-0 absolute h-0 w-0"
                        [checked]="basicInfoFormGroup.controls.type.value == propertyType.Apartment"
                        (click)="basicInfoFormGroup.controls.type.setValue(propertyType.Apartment)">
                    <i class="icon-apartment text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyType.' + propertyType.Apartment |transloco}}</span>
                </label>


                <label for="maisonetteCheckbox" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="maisonetteCheckbox" class="opacity-0 absolute h-0 w-0"
                        [checked]="basicInfoFormGroup.controls.type.value == propertyType.Maisonette"
                        (click)="basicInfoFormGroup.controls.type.setValue(propertyType.Maisonette)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyType.' + propertyType.Maisonette |transloco}}</span>
                </label>


                <label for="holidayHomeCheckbox" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="holidayHomeCheckbox" class="opacity-0 absolute h-0 w-0" [checked]="false"
                        disabled="true">
                    <i class="icon-country-house text-[40px]"></i>
                    <span class="">Holiday home</span>
                </label>


                <label for="professionalCheckbox" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="professionalCheckbox" class="opacity-0 absolute h-0 w-0"
                        [checked]="false" disabled="true">
                    <i class="icon-investment text-[40px]"></i>
                    <span class="">Professional</span>
                </label>

            </div>
            <form id="property-wizard">
                <fieldset class="flex flex-col gap-3 group/input">
                    <label class="font-semibold flex gap-2 items-center text-gray-950 group-disabled:text-gray-300">
                        {{t('labels.area')}}
                        <i class="icon-info-circle text-xl text-gray-900"></i>
                    </label>
                    <div class="flex flex-col lg:flex-row gap-4 items-center max-w-[430px]">
                        <div
                            class="flex-1 justify-between flex max-w-[150px] bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                            <input type="number" min="20" max="800" placeholder="122" class="outline-none max-w-[50px]"
                                formControlName="area"
                                onchange="if(this.value < 20) { this.value = 20;} else if (this.value > 800 ) { this.value = 800; }">
                            <span class="text-gray-900 font-semibold">m²</span>
                        </div>
                        <div class="flex-1">
                            <label for="Floor space"></label>
                            <input #areaRangeInput type="range" id="Floor space" min="20" max="800" step="1"
                                [value]="basicInfoFormGroup.controls.area.value"
                                (input)="basicInfoFormGroup.controls.area.setValue(areaRangeInput.value)"
                                formControlName="area">
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.address')}}</h3>
            <div class="flex flex-col xl:flex-row gap-8">
                <div class="max-w-[460px] w-full">
                    <app-google-maps-search-autocomplete #addressSearch [placeHolder]="t('placeholders.address')"
                        [types]="['address']"
                        (onItemSelected)="onPlaceSelected($event)"></app-google-maps-search-autocomplete>
                    <form id="property-wizard-location" [formGroup]="basicInfoFormGroup.controls.address">
                        <div class="flex flex-col gap-4">
                            <fieldset class="flex flex-col gap-2 group/input">
                                <label
                                    class="font-semibold flex items-center justify-between text-gray-950 group-disabled:text-gray-300">{{t('labels.streetName')}}</label>
                                <input [placeholder]="t('labels.streetName')"
                                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                                    [readonly]="true" formControlName="streetName">
                            </fieldset>
                            <div class="flex flex-col md:flex-row justify-between gap-4">
                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center justify-between text-gray-950 group-disabled:text-gray-300">{{t('labels.streetNumber')}}</label>
                                    <input [placeholder]="t('labels.streetNumber')"
                                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                                        [readOnly]="true" formControlName="streetNumber">
                                </fieldset>
                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center justify-between text-gray-950 group-disabled:text-gray-300">{{t('labels.zipCode')}}</label>
                                    <input [placeholder]="t('labels.zipCode')"
                                        class="md:max-w-[184px] bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                                        [readOnly]="true" formControlName="postalCode">
                                </fieldset>
                            </div>
                            <fieldset class="flex flex-col gap-2 group/input">
                                <label
                                    class="font-semibold flex items-center text-gray-950 group-disabled:text-gray-300 gap-2">{{t('labels.municipality')}}
                                    <i class="icon-info-circle text-xl text-gray-900"></i>
                                </label>
                                <input [placeholder]="t('labels.municipality')"
                                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                                    [readOnly]="true" formControlName="prefecture">
                            </fieldset>
                        </div>
                    </form>
                </div>
                <div *ngIf="basicInfoFormGroup.controls.address.get('geoPoint')?.value"
                    class="w-full h-full border border-gray-300 rounded-[1.5rem]" #gMapContainer>
                    <google-map [mapId]="'f5552e3916e0e5d7'" [options]="mapOptions" #propertyMap
                        [width]="gMapContainer.clientWidth">
                        <map-marker *ngFor="let marker of markers$ | async" [position]="marker.position"
                            [icon]="marker.icon" class="text-white">
                        </map-marker>
                    </google-map>
                </div>
            </div>
        </div>

        <app-advisor-callback [advisor]="advisor"></app-advisor-callback>

        <div class="border-t-2 pt-7 flex justify-end">
            <button class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color"
                (click)="onNext()">
                {{'property-listing.tabs.titleAndDescription' | transloco}}
                <i class="icon-arrow-right text-lg"></i>
            </button>
        </div>
    </div>
</div>

<ng-template #prices>
    <div class="bg-gray-100 rounded-lg flex flex-col gap-5 p-6"
        *transloco="let t; prefix: 'property-listing.basic-information'">
        <div class="font-semibold text-gray-900 flex justify-between" (click)="showPrices = true">
            <span>{{t('infoText.askingPriceBetween')}}</span>
            <button class="text-secondary-900 font-semibold flex items-end gap-1 leading-none"><i
                    class="icon-edit text-lg"></i>{{t('buttons.edit')}}</button>
        </div>
        <div class="text-gray-700"><span
                class="text-black rfs:text-[2rem] leading-none font-semibold">€{{basicInfoFormGroup.controls.lowestPrice.value}}
                –
                €{{basicInfoFormGroup.controls.askingPrice.value}}</span> {{ basicInfoFormGroup.controls.area.value ?
            'minimum ' + ((basicInfoFormGroup.controls.lowestPrice.value / basicInfoFormGroup.controls.area.value) |
            number : '1.0-2') + (' ' + t('infoText.perSqM')) : '' }}</div>
        <div class="text-gray-700">{{t('infoText.priceWithinRecentClosingRange')}}</div>
    </div>
</ng-template>

<ng-template #editPrices [formGroup]="basicInfoFormGroup">
    <ng-container *transloco="let t; prefix: 'property-listing.basic-information'">
        <h3 class="h3 text-black">{{t('subheaders.price')}}</h3>
        <div class="flex flex-col gap-6 items-center justify-center">
            <!-- <div class="max-w-[594px] w-full">
            <label for="Asking price"></label>
            <div class="double-range relative">
                <input class="double-range-min" type="range" id="Asking price" min="0" max="900000" value="10000">
                <input class="double-range-max" type="range" id="Asking price" min="0" max="900000" value="700000">
            </div>
        </div> -->
        <ngx-slider [(value)]="minPrice" [(highValue)]="maxPrice" [options]="sliderOptions"
                                (userChange)="onPriceSliderChange($event)"></ngx-slider>
            <fieldset class="flex gap-8">
                <div class="flex gap-3 items-center group/input">
                    <p class="text-black">{{t('labels.lowestPrice')}}:</p>
                    <input #lowestPriceInput type="text" placeholder="€540,000" min="20000" max="2000000"
                        (change)="onPriceAdjust()"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                        formControlName="lowestPrice">
                </div>
                <div class="flex gap-3 items-center group/input">
                    <p class="text-black">{{t('labels.askingPrice')}}:</p>
                    <input #askingPriceInput type="text" placeholder="€650,000" min="20000" max="2000000"
                        (change)="onPriceAdjust()"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                        formControlName="askingPrice">
                </div>
            </fieldset>
        </div>
    </ng-container>
</ng-template>