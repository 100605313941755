<ng-template #modalTemplate>
    <div *transloco="let t; prefix: 'shared.autocomplete.search-property'" class="flex flex-col">
        <div
            class="advance-filter-wrapper flex flex-col justify-between w-full md:!max-w-[808px] z-50 p-4 bg-gray-50 h-[calc(100vh-84px)] fixed right-0 top-[84px]">
            <div>
                <div
                    class="flex md:flex-row flex-row-reverse items-center gap-5 justify-between md:justify-normal mb-5">
                    <button role="button"
                        class="cursor-pointer md:rounded-full w-14 h-14 text-primary-800 md:bg-gray-100"
                        (click)="closeMobileModal()">
                        <i class="icon-close "></i>
                    </button>
                    <h4 class="text-black text-xl">{{t('header')}}</h4>
                </div>

                <fieldset class="flex-1 flex flex-col gap-2 group/input">
                    <div class="flex items-center text-gray-500 rounded-full bg-white  py-1 px-1  transition-colors duration-300 outline-none"
                        [ngClass]="isHomepage ? '' : 'h-[54px] border border-gray-300 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500' ">
                        <span *ngIf="!isHomepage" class="flex justify-center items-center mr-2 ml-3">
                            <i *ngIf="showSearchIcon" class="icon-search text-gray-700 text-xl"></i>
                        </span>
                        <div class="flex flex-row flex-1 flex-wrap items-center">
                            <input (click)="showDropDown()" (clickOutside)="hideDropdown()"
                                class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300 mr-2"
                                [ngClass]="{'text-black':isHomepage}"
                                [placeholder]="searchPlaceholder ? searchPlaceholder : 'Search area or address'"
                                (keyup)="onInputKeyUp($event)" #searchInput>
                        </div>
                    </div>
                </fieldset>
                <div>
                    <section class="w-full py-4 px-4">
                        <button type="button" *ngFor="let item of items; let i = index"
                            [ngClass]="{'text-black' : isHomepage}"
                            class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-[2px] px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20
                             [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-start min-w-[280px] w-full"
                            (click)="onListItemSelected(item)">
                            <ng-container [ngTemplateOutlet]="defaultItemTemplate"
                                [ngTemplateOutletContext]="{ $implicit: item }">
                            </ng-container>
                        </button>
                    </section>

                    <section class="w-full px-4 py-4"
                        [ngClass]="historySearches && historySearches.length > 0 ? 'visible' : 'hidden'">
                        <div class="flex items-center my-4 gap-2">
                            <i class="group-disabled/menu-item:text-gray-700 icon-clock text-base px-1"></i>
                            <span class="font-normal text-base"
                                [ngClass]="isHomepage ? 'font-semibold group-disabled/menu-item:text-gray-50' : 'text-gray-700'">{{t('text.recentSearches')}}</span>
                        </div>
                        <button *ngFor="let search of historySearches" (click)="savedSearchSelected(search)"
                            [ngClass]="isHomepage ? 'py-1' : 'py-3' "
                            class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                            <div class="flex items-center text-base text-left font-normal">
                                <span
                                    class="bg-transparent group-disabled/menu-item:text-gray-50">{{search.name}}</span>
                            </div>
                        </button>
                    </section>
                    <div *ngIf="!isHomepage" class="flex-grow border-t border-gray-200 w-full mt-2 mb-2"></div>
                    <section *ngIf="popularAreas && popularAreas.length > 0" class="px-4 py-4">
                        <div class="flex items-center my-4 gap-2">
                            <i class="group-disabled/menu-item:text-gray-700 icon-fire-alt text-base px-1"></i>
                            <span class="font-normal text-base"
                                [ngClass]="isHomepage ? 'font-semibold group-disabled/menu-item:text-gray-50' : 'text-gray-700'">{{t('text.popularAreas')}}</span>
                        </div>
                        <button *ngFor="let search of popularAreas" (click)="savedSearchSelected(search)"
                            class="py-1 group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                            <div class="flex items-center text-base text-left font-normal">
                                <span
                                    class="bg-transparent group-disabled/menu-item:text-gray-50">{{search.name}}</span>
                            </div>
                        </button>
                    </section>
                </div>
            </div>

            <button
                class="btn-primary self-stretch w-full h-12 py-4 rounded-full justify-center items-center gap-2 inline-flex"
                (click)="search()">{{t('header')}}</button>
        </div>

    </div>
</ng-template>

<div *transloco="let t; prefix: 'shared.autocomplete.search-property'" class="flex flex-col"
    [ngClass]="isHomepage ? '' : 'gap-4'">
    <div class="relative">
        <fieldset class="flex-1 flex flex-col gap-2 group/input">
            <div class="flex items-center text-gray-500 rounded-full bg-white  py-1 px-1  transition-colors duration-300 outline-none"
                [ngClass]="isHomepage ? '' : 'h-[54px] border border-gray-300 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500' ">
                <span *ngIf="!isHomepage" class="flex justify-center items-center mr-2 ml-3">
                    <i *ngIf="showSearchIcon" class="icon-search text-gray-700 text-xl"></i>
                </span>
                <div class="flex flex-row flex-1 flex-wrap items-center">
                    <input (click)="showDropDown()" (clickOutside)="hideDropdown()"
                        [attr.disabled]="(disableInput | async) ? 'true' : undefined "
                        class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300 mr-2"
                        [ngClass]="{'text-black':isHomepage}"
                        [placeholder]="searchPlaceholder ? searchPlaceholder : 'Search area or address'"
                        (keyup)="onInputKeyUp($event)" #searchInput>
                </div>
            </div>
        </fieldset>
        <div *ngIf="showResults && (items || savedSearches || historySearches)" class="z-[60]" [ngClass]="isHomepage 
            ? 'bg-white text-base md:translate-y-8 md:-left-10 md:w-[350px] md:absolute md:top-[100%] md:rounded-xl md:px-4 md:py-4 md:shadow-2xl' 
            : 'bg-gray-50 text-xl translate-y-4 w-full absolute top-[100%] left-0 rounded-xl shadow-2xl'">

            <section class="w-full py-4 px-4">
                <button type="button" *ngFor="let item of items; let i = index" [ngClass]="{'text-black' : isHomepage}"
                    class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-[2px] px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20
                     [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-start min-w-[280px] w-full"
                    (click)="onListItemSelected(item)">
                    <ng-container [ngTemplateOutlet]="defaultItemTemplate"
                        [ngTemplateOutletContext]="{ $implicit: item }">
                    </ng-container>
                </button>
            </section>
            <!-- SEARCH NEARBY TBD -->
            <!-- <section *ngIf="!items">
              <button class="m-4" (click)="SearchNearby()"
                  class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-3 px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                  <div class="flex items-center text-xl">
                      <i class="group-disabled/menu-item:text-gray-50 icon-location mr-4"></i>
                      <span class="bg-transparent group-disabled/menu-item:text-gray-50">Properties near me</span>
                  </div>
              </button>
          </section> -->
            <section *ngIf="historySearches && historySearches.length > 0" class="w-full px-4 py-4">
                <div class="flex items-center my-4 gap-2">
                    <i class="group-disabled/menu-item:text-gray-700 icon-clock text-base px-1"></i>
                    <span class="font-normal text-base"
                        [ngClass]="isHomepage ? 'font-semibold group-disabled/menu-item:text-gray-50' : 'text-gray-700'">{{t('text.recentSearches')}}</span>
                </div>
                <button *ngFor="let search of historySearches" (click)="savedSearchSelected(search)"
                    [ngClass]="isHomepage ? 'py-1' : 'py-3' "
                    class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                    <div class="flex items-center text-base text-left font-normal">
                        <span class="bg-transparent group-disabled/menu-item:text-gray-50">{{search.name}}</span>
                    </div>
                </button>
            </section>
            <div *ngIf="!isHomepage" class="flex-grow border-t border-gray-200 w-full mt-2 mb-2"></div>
            <section *ngIf="popularAreas && popularAreas.length > 0" class="px-4 py-4">
                <div class="flex items-center my-4 gap-2">
                    <i class="group-disabled/menu-item:text-gray-700 icon-fire-alt text-base px-1"></i>
                    <span class="font-normal text-base"
                        [ngClass]="isHomepage ? 'font-semibold group-disabled/menu-item:text-gray-50' : 'text-gray-700'">{{t('text.popularAreas')}}</span>
                </div>
                <button *ngFor="let search of popularAreas" (click)="savedSearchSelected(search)"
                    class="py-1 group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                    <div class="flex items-center text-base text-left font-normal">
                        <span class="bg-transparent group-disabled/menu-item:text-gray-50">{{search.name}}</span>
                    </div>
                </button>
            </section>
        </div>
    </div>
    <div *ngIf="!isHomepage" class="flex flex-col justify-between lg:flex-row flex-wrap w-full md:justify-start gap-4">
        <div *ngFor="let selectedItem of selectedItems"
            class="flex justify-between gap-2 items-center text-center rounded-lg text-base text-black font-normal border border-gray-300 px-4 py-2 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
            <span>{{selectedItem.FormatedAddress}}</span>
            <div class="w-4 h-4 relative">
                <i class="text-black hover:text-black icon-close mx-1" (click)="onItemRemove(selectedItem)">
                </i>
            </div>
        </div>
    </div>
</div>

<ng-template #defaultNoResultsTemplate let-searchTerm>
    <div class="flex items-center" *transloco="let t; prefix: 'shared.autocomplete.search-property'">
        <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{t('text.noResultsFoundFor')}}
            <i>{{ searchTerm }}</i>
        </span>
    </div>
</ng-template>
<ng-template #defaultItemTemplate let-item>
    <div class="flex items-center justify-start text-left">
        <i *ngIf="!isHomepage" class="group-disabled/menu-item:text-tone-06 icon-location mr-4"></i>
        <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{
            item.description
            }}</span>
    </div>
</ng-template>