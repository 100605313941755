import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ModalOptions, ModalService, ToastType } from '@indice/ng-components';
import { MakeOfferModalComponent } from './make-offer-modal/make-offer-modal.component';
import { RequestWalkthroughModalComponent } from 'src/app/shared/components/request-walkthrough-modal/request-walkthrough-modal.coponent';
import { PropertyDetails } from 'src/app/services/portal-api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestCallbackModalComponent } from 'src/app/shared/components/request-callback-modal/request-callback-modal.component';
import { ToasterService } from 'src/app/services/toaster.service';
import { User } from 'oidc-client-ts';

@Component({
  selector: 'app-aside-content',
  standalone: true,
  templateUrl: './aside-content.component.html',
  imports: [CommonModule, TranslocoDirective],
})
export class AsideContentComponent {
  @Input() property: PropertyDetails | undefined;
  public isOpen: boolean = false;
  private currentUser: User | null;

  constructor(
    private _authService: AuthService,
    private _modalService: ModalService,
    @Inject(ToasterService) private _toastService: ToasterService
  ) {
    this.currentUser = this._authService.getCurrentUser();
  }

  public toggleAside() {
    this.isOpen = !this.isOpen;
  }

  protected onAskTourClick(): void {
    const modal = this._modalService.show(RequestWalkthroughModalComponent, {
      keyboard: true,
      class: this.currentUser ? '' : 'modal-xl',
      initialState: {
        property: this.property,
        isLoggedIn: this.currentUser !== null,
      },
    });
    modal.onHidden?.subscribe((r: any) => {
      if (r.result.success != undefined) {
        if (r.result.success == true) {
          this._toastService.show(
            ToastType.Success,
            'Walkthrough request sent',
            undefined,
            10000
          );
        } else {
          this._toastService.show(
            ToastType.Error,
            'Walkthrough request error',
            undefined,
            10000
          );
        }
      }
    });
  }

  protected onMakeOfferClick(): void {
    const modal = this._modalService.show(MakeOfferModalComponent, {
      keyboard: true,
      initialState: {
        askingPrice: this.property?.price,
        propertyId: this.property?.id,
        isLoggedIn: this.currentUser !== null,
      },
    });
    modal.onHidden?.subscribe((r: any) => {
      if (r.result.success != undefined) {
        if (r.result.success == true) {
          this._toastService.show(
            ToastType.Success,
            'Offer request sent',
            undefined,
            10000
          );
        } else {
          this._toastService.show(
            ToastType.Error,
            'Offer request error',
            undefined,
            10000
          );
        }
      }
    });
  }
  public onCallbackClick() {
    const modal = this._modalService.show(RequestCallbackModalComponent, {
      keyboard: true,
      class: this.currentUser ? '' : 'modal-xl',
      initialState: {
        property: this.property,
        isLoggedIn: this.currentUser !== null,
      },
    });
    modal.onHidden?.subscribe((r: any) => {
      if (r.result.success != undefined) {
        if (r.result.success == true) {
          this._toastService.show(
            ToastType.Success,
            'Callback request sent',
            `Your advisor ${
              this.property?.brokerName ?? ''
            } will call you back on ${
              this.currentUser?.profile?.phone_number
            } within 1 business day.`,
            10000
          );
        } else {
          this._toastService.show(
            ToastType.Error,
            'Callback request error',
            undefined,
            10000
          );
        }
      }
    });
  }
}
