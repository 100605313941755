import { inject, Injectable } from "@angular/core";
import { TranslocoLoader } from "@jsverse/transloco";
import { PortalApiService } from "./services/portal-api.service";
import { catchError, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private portalApi = inject(PortalApiService);

    getTranslation(lang: string) {
        return this.portalApi.anonymous(lang).pipe(
            catchError(() => {
                return of({});
            })
        );
    }
}
