import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, input } from '@angular/core';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'app-sort-dropdown',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './sort-dropdown.component.html',
  styleUrl: './sort-dropdown.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortDropdownComponent implements OnChanges {
  @Input() dropdownItems: FilterDropdownItem[] = [];
  @Input() title: string | undefined;
  @Input() selectedKey: string | undefined;
  @Input() allValuesLabel: string | undefined;
  @Input() btnStyleText: boolean = true;
  @Output() selectedItem: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  #defaultSelectedKey!: string;

  selectedLabel: string | undefined;
  showResults: boolean = false;
  // MultipleSelectedString: string = "Multiple"

  constructor(private cd: ChangeDetectorRef) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedKey']) {
      this.selectedKey = changes['selectedKey'].currentValue;
      this.initializeDropdownSelection();
    }
  }

  ngOnInit() {
    this.selectedKey ||= this.#defaultSelectedKey;
    this.initializeDropdownSelection();
  }

  private initializeDropdownSelection(): void {
    if (this.selectedKey) {
      this.selectedLabel = this.dropdownItems.find(item => item.key === this.selectedKey)?.label;
      this.dropdownItems.forEach(item => item.checked = item.key === this.selectedKey);
    } else {
      this.selectedLabel = this.allValuesLabel;
    }
    this.cd.markForCheck();  // Trigger change detection for the view
  }

  ItemSelected(item: FilterDropdownItem): void {
    this.selectedKey = item.key;
    this.dropdownItems.forEach(x => x.checked = x === item);
    this.resolveSelection();
    this.selectedItem.emit(this.selectedKey);
    this.toggleDropdown();
  }
  
  hasSelectedItems(): boolean {
    return this.dropdownItems.some(item => item.checked);
  }
  
  ClearFilter(): void {
    this.selectedKey = undefined;
    this.updateDropdownItemsSelection();
    this.resolveSelection();
    this.selectedItem.emit(undefined);
    this.closeDropdown();
  }
  
  toggleDropdown(): void {
    this.showResults = !this.showResults;
  }
  
  closeDropdown(): void {
    this.showResults = false;
  }
  
  private updateDropdownItemsSelection(selectedItem?: FilterDropdownItem): void {
    this.dropdownItems.forEach(item => item.checked = item === selectedItem ? !item.checked : false);
  }
  
  private resolveSelection(): void {
    this.resolveViewLabel();
  }
  
  private resolveViewLabel(): void {
    this.selectedLabel = this.dropdownItems.find(item => item.key === this.selectedKey && item.checked)?.label ?? this.allValuesLabel;
  }

}
export interface FilterDropdownItem {
  key: string,
  label: string,
  icon?: string,
  checked?: boolean
}
