<ng-container *transloco="let t; prefix: 'shared.insights'">
  <div *ngIf="model$ | async as model; else nodata" class="pt-6 pb-12">
    <h2
      class="text-[1.75rem] font-semibold text-black flex flex-row items-center gap-4 mb-8"
    >
      {{ t("evaluation") }}
    </h2>
    <div
      class="sm:grid relative block grid-cols-2 md:grid-cols-4 md:border border-gray-300 rounded-medium items-center"
    >
      <app-doughnut-chart
        #hottness
        [stringValue]="model.hotnessScore ?? ''"
        [title]="t('hotness-score')"
        [type]="1"
      ></app-doughnut-chart>
      <app-doughnut-chart
        #liquidity
        [stringValue]="model.liquidityScore ?? ''"
        [title]="t('liquidity-score')"
        [type]="0"
      ></app-doughnut-chart>
      <div
        class="md:px-8 col-span-2 md:border-l border-gray-300 h-full flex flex-col justify-center"
      >
        <div>
          <div
            class="flex md:flex-row flex-col md:items-center md:mt-0 mt-6 md:py-6 md:border-b border-gray-300"
          >
            <div class="flex flex-col flex-1 md:mb-0 mb-6">
              <p class="text-gray-700">{{ t("opinion-of-rent") }}</p>
              <div class="flex-1">
                <h4 class="h4 text-xl text-black">
                  €{{ model.minRentPrice | number }} - €
                  {{ model.maxRentPrice | number }}
                </h4>
              </div>
            </div>
            <div class="flex flex-col flex-1">
              <p class="text-gray-700">{{ t("rental-yield") }}</p>
              <div class="flex-1">
                <h4 class="h4 text-xl text-black">
                  {{ model.minRentYieldPercentage }}% -
                  {{ model.maxRentYieldPercentage }}%
                </h4>
              </div>
            </div>
          </div>
          <div class="py-6">
            <div class="flex flex-col flex-1">
              <p class="text-gray-700">{{ t("average-municipality-price") }}</p>
              <div class="flex-1">
                <h4 class="h4 text-xl text-black">€1.200</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-locked-content-overlay
        [isVissible]="!isLoggedIn"
      ></app-locked-content-overlay>
    </div>
    <div class="pt-4">
      <a href="#" class="btn-text flex gap-2 items-center py-[6px] hidden"
        ><i class="icon-arrow-alt-to-bottom text-xl"></i
        >{{ t("download-marketability-report") }}</a
      >
    </div>
  </div>
  <ng-template #nodata class="hidden"></ng-template>
</ng-container>
