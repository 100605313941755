<aside class="sticky top-24 max-w-[332px] hidden lg:block">
  <nav>
    <ng-container *ngFor="let item of inputData; let index = index">
      <div [attr.scroll-to]="item.scrollTo" (click)="scrollToTarget($event, index)" [ngClass]="
          activeAnchor === index
            ? 'py-4 px-4 mb-1 lg:px-12 bg-transparent border border-transparent hover:border-primary-500 hover:cursor-pointer hover:text-primary-500 rounded-[2rem] text-black border border-black font-semibold  transition-all active:text-black active:border active:border-black active:font-semibold'
            : 'py-4 px-4 mb-1 lg:px-12 bg-transparent border border-transparent hover:border-primary-500 hover:cursor-pointer hover:text-primary-500 rounded-[2rem] transition-all active:text-black active:border active:border-black active:font-semibold'
        ">
        {{ item.label }}
      </div>
    </ng-container>
  </nav>
</aside>