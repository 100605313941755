import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
    constructor(
        private _authService: AuthService,
        private _logger: LoggerService
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const copiedRequest = request.clone({
            headers: request.headers.set('Accept-Language', this._authService.getUserLocaleOrDefault())
        });
        return next.handle(copiedRequest).pipe(
            tap((httpEvent: HttpEvent<any>) => {
                if (httpEvent instanceof HttpResponse) { }
            }),
            catchError((error: any) => {
                this._logger.log(error);
                throw error;
            })
        );
    }
}
