<div class="my-4" >
  <div class="relative">
        <div
        class="relative flex items-center text-gray-500 rounded-full bg-white border border-gray-300 py-2 px-1 group-hover/input:bg-white
        group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500
        transition-colors duration-300 outline-none max-w-[450px]">
        <span class="flex justify-center items-center mr-2 ml-3">
          <i class="icon-search text-gray-700 text-xl"></i>
        </span>
        <input type="text" #searchInput placeholder="Search area or address"
          class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300 mr-2" (click)="showDropDown()" (clickOutside)="hideDropdown()" (keyup)="onInputKeyUp($event)" />
      </div>
      <div *ngIf="showResults && items"
                  class="absolute top-[65%] translate-y-4 left-0 w-full rounded-xl px-4 py-4 shadow-2xl bg-gray-50 text-xl z-50">
                  <button type="button" *ngFor="let item of items; let i = index"
                      class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-[2px] px-1
                       [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03
                       flex items-center justify-start min-w-[280px] w-full"
                      (click)="selectPlaceById(item,true)">
                      <ng-container [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                          [ngTemplateOutletContext]="{ $implicit: item }">
                      </ng-container>
                  </button>
      </div>
</div>
</div>

<ng-template #defaultNoResultsTemplate let-searchTerm>
  <div class="flex items-center" *transloco="let t; prefix: 'shared.autocomplete.search-property'">
      <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{t('text.noResultsFoundFor')}}
          <i>{{ searchTerm }}</i>
      </span>
  </div>
</ng-template>
<ng-template #defaultItemTemplate let-item>
  <div class="flex items-center justify-start text-left">
      <i class="group-disabled/menu-item:text-tone-06 icon-location mr-4"></i>
      <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{
          item.description
          }}</span>
  </div>
</ng-template>
