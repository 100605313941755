import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberMask',
  standalone: true
})
export class NumberMaskPipe implements PipeTransform {
  transform(value: number | string | null | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Convert value to a number if it's a string
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    // Format the number with thousands separators
    return this.formatNumberWithDots(numberValue);
  }

  private formatNumberWithDots(value: number): string {
    // Convert number to string and split into integer and decimal parts
    const [integerPart, decimalPart] = value.toString().split('.');

    // Format the integer part with dots as thousands separators
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Return formatted value with decimal part if exists
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  }
}
