<div class="flex-1" *transloco="let t; prefix: 'shared.loan-calculator'">
  <div class="w-full max-w-[467px]">
    <div class="">
      <div
        class="p-1 border-full border border-gray-500 bg-gray-50 flex flex-row rounded-full mb-4"
      >
        <button
          class="tab-pill flex-1 justify-center"
          type="button"
          [ngClass]="{ active: selectedLoanCategory === loanCategories[0].id }"
          (click)="onLoanCategorySelect(loanCategories[0].id)"
        >
          <i class="icon-apartment"></i>{{ t("categories.homePurchase") }}
        </button>
        <button
          class="tab-pill flex-1 justify-center"
          type="button"
          [ngClass]="{ active: selectedLoanCategory === loanCategories[1].id }"
          (click)="onLoanCategorySelect(loanCategories[1].id)"
        >
          <i class="icon-renovation"></i>{{ t("categories.renovation") }}
        </button>
      </div>
    </div>
    <div class="mb-3">
      <div class="flex md:flex-row flex-col gap-4 mt-2">
        <div class="text-black flex font-semibold items-center gap-2 flex-1">
          {{
            selectedLoanCategory === loanCategories[0].id
              ? t("labels.propertyValue")
              : t("labels.estimatedRenovationCost")
          }}
        </div>
        <ngx-slider
          *ngIf="!propertyValue"
          [(value)]="loanValues!.loanCalculatorValues.propertyValue"
          [options]="propertyValueOptions"
          (userChange)="onRangeValueChange($event, fields.propertyValue)"
        ></ngx-slider>

        <div
          class="max-w-[215px] flex flex-row gap-2 items-center bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
        >
          <span class="text-gray-500 font-semibold"></span>
          <input
            placeholder="€140,000"
            [disabled]="true"
            [value]="
              loanValues!.loanCalculatorValues.propertyValue
                | currency: 'EUR' : 'symbol' : '1.0-0'
            "
            class="text-gray-500 bg-transparent outline-none flex-1"
          />
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="text-black flex font-semibold items-center gap-2">
        {{ t("labels.loanAmount") }}
      </div>
      <div class="flex md:flex-row flex-col gap-4 mt-2">
        <ngx-slider
          [(value)]="loanValues!.loanCalculatorValues.loanAmount"
          [options]="loanAmountOptions"
          (userChange)="onRangeValueChange($event, fields.loanAmount)"
        ></ngx-slider>
        <div
          class="max-w-[215px] flex flex-row gap-2 items-center bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
        >
          <span class="text-gray-500 font-semibold"></span>
          <input
            placeholder="280,000"
            [disabled]="true"
            [value]="
              loanValues!.loanCalculatorValues.loanAmount
                | currency: 'EUR' : 'symbol' : '1.0-0'
            "
            class="text-gray-500 bg-transparent outline-none flex-1"
          />
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="text-black flex font-semibold items-center gap-2">
        {{ t("labels.loanDuration") }}
      </div>
      <div class="flex md:flex-row flex-col gap-4 mt-2">
        <ngx-slider
          [(value)]="loanValues!.loanCalculatorValues.loanDuration"
          [options]="loanDurationOptions"
          (userChange)="onRangeValueChange($event, fields.loanDuration)"
        ></ngx-slider>
        <div
          class="min-w-[215px] flex flex-row gap-2 items-center bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
        >
          <input
            placeholder="40 years"
            [disabled]="true"
            [value]="loanValues!.loanCalculatorValues.loanDuration"
            class="text-gray-500 bg-transparent outline-none max-w-[20px]"
          />
          <span class="text-gray-500 font-semibold flex-1">{{
            loanValues?.loanCalculatorValues?.loanDuration === 1
              ? ("shared.loan-calculator.doughnut-chart.text.year" | transloco)
              : ("shared.loan-calculator.doughnut-chart.text.years" | transloco)
          }}</span>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="text-black flex font-semibold items-center gap-2">
        {{ t("labels.fixedInterestRateDuration") }}
      </div>
      <div class="flex md:flex-row flex-col gap-4 mt-2">
        <ngx-slider
          [(value)]="loanValues!.loanCalculatorValues.loanFixedDuration"
          [options]="loanFixedDurationOptions"
          (userChange)="
            onRangeValueChange($event, fields.fixedInterestRateDuration)
          "
        ></ngx-slider>
        <div
          class="min-w-[215px] flex flex-row gap-2 items-center bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
        >
          <input
            placeholder="10 years"
            [disabled]="true"
            [value]="loanValues!.loanCalculatorValues.loanFixedDuration"
            class="text-gray-500 bg-transparent outline-none max-w-[20px]"
          />
          <span class="text-gray-500 font-semibold flex-1">{{
            loanValues?.loanCalculatorValues?.loanFixedDuration === 1
              ? ("shared.loan-calculator.doughnut-chart.text.year" | transloco)
              : ("shared.loan-calculator.doughnut-chart.text.years" | transloco)
          }}</span>
        </div>
      </div>
    </div>
    <span class="text-gray-700 text-xs">{{
      t("infoText.illustrativePurposesOnly")
    }}</span>
  </div>
</div>
