import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { EuroFormatInputDirective } from 'src/app/shared/directives/euro-format.directive';
import { NumericOnlyDirective } from 'src/app/shared/directives/number-only.directive';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';
import { NumberMaskPipe } from 'src/app/shared/pipes/number-mask.pipe';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-range-dropdown',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, NumericOnlyDirective, TranslocoDirective, EuroFormatInputDirective, NumberMaskPipe],
  templateUrl: './range-dropdown.component.html',
  styleUrl: './range-dropdown.component.css'
})
export class RangeDropdownComponent {
  @Input() minDropdownItems: RangeDropdownItem[] = [];
  @Input() maxDropdownItems: RangeDropdownItem[] = [];
  @Input() title: string | undefined;
  @Input() preselectedRange: RangeDropdownItem | undefined;
  @Input() allValuesLabel: string | undefined;
  @Input() prefix: string | undefined;
  @Input() prefixIcon: string | undefined;
  @Input() postfix: string | undefined;
  @Input() postfixIcon: string | undefined;
  @Input() hasBorder: boolean | undefined = true;
  @Input() hasPlaceholder: boolean | undefined = true;
  @Input() isHomepage: boolean | undefined = false;
  @Input() iconClasses: string | undefined = '';
  @Output() selectedRangeEvent: EventEmitter<RangeDropdownItem | undefined> = new EventEmitter<RangeDropdownItem | undefined>();

  selectedLabel: string | undefined;
  showResults: boolean = false;
  MultipleSelectedString: string = "Multiple"
  selectedRange: RangeDropdownItem | undefined;
  minValue: number | null = null;
  maxValue: number | null = null;

  ngOnInit() {
    this.setDefaultValue();
  }
  ngOnChanges() {
    this.setDefaultValue();
  }

  setDefaultValue() {
    if (this.preselectedRange) {
      this.itemSelected(this.preselectedRange);
      this.selectedRange = this.preselectedRange;
    }
    else {
      this.selectedLabel = this.allValuesLabel;
    }
  }
  itemSelected(item: RangeDropdownItem) {
    this.selectedRange = {...this.selectedRange, ...item};
    this.resolveViewLabel()
    this.selectedRangeEvent.emit(this.selectedRange);
    this.minValue = item.from ?? null;
    this.maxValue = item.to ?? null;
    this.swapIfNeeded();
    this.closeDropdown();
  }

  ClearFilter() {
    this.selectedRange = undefined;
    this.selectedLabel = this.allValuesLabel
    this.minValue = null;
    this.maxValue = null;
    this.selectedRangeEvent.emit(undefined);
    this.closeDropdown();
  }

  toggleDropdown() {
    this.showResults = !this.showResults;
  }

  closeDropdown() {
    this.showResults = false;
  }

  resolveViewLabel() {
    if (this.selectedRange?.from || this.selectedRange?.to) {
      // Ensure the selected range is valid
      this.ensureValidRange(this.selectedRange);
    
      // Set the selected label based on the selected range
      this.selectedLabel = this.selectedRange.label;
    } else {
      // Fall back to the default label if no range is selected
      this.selectedLabel = this.allValuesLabel;
    }
  }

  // Function to ensure 'from' is less than or equal to 'to' for a valid range
  ensureValidRange(range: { from?: number; to?: number }): void {
    if (range.from != null && range.to != null && range.from > range.to) {
      [range.from, range.to] = [range.to, range.from];
    }
  }

  handleValueChange(value: string, isMin: boolean) {
    let parsedValue = value !== '' ? parseInt(value.replace(/\./g, '').trim()) : null;
  
    if (isMin) {
      this.minValue = parsedValue;
    } else {
      this.maxValue = parsedValue;
    }
  
    // Ensure minValue is not greater than maxValue by swapping if needed
    this.swapIfNeeded();
  
    // Update selectedRange
    this.selectedRange = {
      from: this.minValue ?? undefined,
      to: this.maxValue ?? undefined,
      label: `${this.minValue ?? ''} - ${this.maxValue ?? ''}`
    };
  
    // Emit the selectedRange event and call resolveViewLabel once
    if (this.selectedRange) {
      this.selectedRangeEvent.emit(this.selectedRange);
      this.resolveViewLabel();
    }
  }

  // Swap minValue and maxValue if minValue is greater than maxValue
  swapIfNeeded() {
    if (this.minValue !== null && this.maxValue !== null && this.minValue > this.maxValue) {
      [this.minValue, this.maxValue] = [this.maxValue, this.minValue];
    }
  }

  // Methods for minValueChanged and maxValueChanged using the reusable function
  minValueChanged(event: Event) {
    console.log(`MinValueChanged: `, event)
    const val = (event.target as HTMLTextAreaElement).value;
    this.handleValueChange(val, true);
  }

  maxValueChanged(event: Event) {
    console.log(`MaxValueChanged: `, event)
    const val = (event.target as HTMLTextAreaElement).value;
    this.handleValueChange(val, false);
  }

  // minValueChanged(event: Event) {
  //   let val = (event.target as HTMLTextAreaElement).value
  //   this.minValue = val !== '' ? parseInt(val.replace(/\./g, '').trim()) : null;

  //   if (this.maxValue && this.minValue && this.minValue > this.maxValue) {
  //     this.minValue = this.maxValue;
  //   }
  //   this.selectedRange = {
  //     from: this.minValue ?? undefined,
  //     to: this.maxValue ?? undefined,
  //     label: `${this.minValue ?? ''} - ${this.maxValue ?? ''}`
  //   }

  //   if (this.selectedRange) {
  //     this.selectedRangeEvent.emit(this.selectedRange);
  //     this.resolveViewLabel();  // Ensure it’s only called once
  //   }
  // }
  // maxValueChanged(event: Event) {
  //   let val = (event.target as HTMLTextAreaElement).value
  //   this.maxValue = val !== '' ? parseInt(val.replace(/\./g, '').trim()) : null;

  //   if (this.minValue && this.maxValue && this.minValue > this.maxValue) {
  //     this.maxValue = this.minValue;
  //   }
  //   this.selectedRange = {
  //     from: this.minValue ?? undefined,
  //     to: this.maxValue ?? undefined,
  //     label: `${this.minValue ?? ''} - ${this.maxValue ?? ''}`
  //   }

  //   if (this.selectedRange) {
  //     this.selectedRangeEvent.emit(this.selectedRange);
  //     this.resolveViewLabel();  // Ensure it’s only called once
  //   }
  // }

  private formatRangeLabel(range: string): string {
    return range.split('-').map(value => `${value.trim()}€`).join(' - ');
}


}
export interface RangeDropdownItem {
  from?: number,
  to?: number,
  label: string
}
