import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { Modal, ModalOptions, ModalService } from '@indice/ng-components';
import { TranslocoDirective } from '@jsverse/transloco';
import { mergeMap, of, Subject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { FilterDropdownComponent, FilterDropdownItem } from 'src/app/features/find-property/filter-dropdown/filter-dropdown.component';
import { PropertyListFilter, PropertyType } from 'src/app/services/portal-api.service';
import { PropertyListingService } from 'src/app/services/property.service';
import { SearchHistoryService } from 'src/app/services/search-history.service';
import { ToasterService, ToastType } from 'src/app/services/toaster.service';
import { SearchPropertyComponent } from 'src/app/shared/components/autocomplete/search-property/search-property.component';
import { SaveSearchAnonymousModalComponent } from '../save-search-modal/save-search-anonymous-modal/save-search-anonymous-modal.component';
import { SaveSearchModalComponent } from '../save-search-modal/save-search-modal.component';
import { AdvancedFiltersDrawerComponent } from './advanced-filters-drawer/advanced-filters-drawer.component';
import { GridListCardsComponent } from './grid-list-cards/grid-list-cards.component';
import { PopularAreasComponent } from './popular-areas/popular-areas.component';
import { PropertyTagFiltersComponent } from './property-tag-filters/property-tag-filters.component';
import { RangeDropdownComponent, RangeDropdownItem } from './range-dropdown/range-dropdown.component';
import { SponsoredPropertiesComponent } from './sponsored-properties/sponsored-properties.component';

@Component({
  selector: 'app-find-property',
  standalone: true,
  imports: [CommonModule,
    GridListCardsComponent,
    SponsoredPropertiesComponent,
    PopularAreasComponent,
    PropertyTagFiltersComponent,
    SearchPropertyComponent,
    FilterDropdownComponent,
    RangeDropdownComponent,
    AdvancedFiltersDrawerComponent,
    TranslocoDirective, 
    RouterLink
  ],
  templateUrl: './find-property.component.html',
  styleUrl: './find-property.component.css',
  changeDetection: ChangeDetectionStrategy.Default
})
export class FindPropertyComponent {
  @ViewChild('triggerButton') triggerButton!: ElementRef;
  toggleAdvancedFilters$: Subject<void> = new Subject();
  advancedFilterOn: boolean = false;
  searchFilter: PropertyListFilter | undefined
  propertyTypes: FilterDropdownItem[] = []
  showPopularAreas: boolean = true;
  selectedType: PropertyType[] | undefined
  selectedPriceRange: RangeDropdownItem | undefined
  selectedSizeRange: RangeDropdownItem | undefined
  isloggedIn: boolean = false;

  minPriceRanges: RangeDropdownItem[] = [
    { from: 10000, label: "10.000€" },
    { from: 20000, label: "20.000€" },
    { from: 30000, label: "30.000€" },
    { from: 40000, label: "40.000€" },
    { from: 50000, label: "50.000€" },
  ]

  maxPriceRanges: RangeDropdownItem[] = [
    { to: 100000, label: "100.000€" },
    { to: 200000, label: "200.000€" },
    { to: 500000, label: "500.000€" },
    { to: 1000000, label: "1.000.000€" },
    { to: 2000000, label: "2.000.000€" },
  ]

  minSizeRanges: RangeDropdownItem[] = [
    { from: 10, label: "10 τ.μ." },
    { from: 20, label: "20 τ.μ." },
    { from: 30, label: "30 τ.μ." },
    { from: 40, label: "40 τ.μ." },
    { from: 50, label: "50 τ.μ." }
  ]

  maxSizeRanges: RangeDropdownItem[] = [
    { to: 150, label: "150 τ.μ." },
    { to: 200, label: "200 τ.μ." },
    { to: 500, label: "500 τ.μ." },
    { to: 800, label: "800 τ.μ." },
    { to: 1000, label: "1000 τ.μ." }
  ]

  searchFiltersCount: number = 0;

  private readonly emptySearchFilters = {
      from: undefined,
      to: undefined,
      label: ''
  }

  constructor(
    private destroyRef: DestroyRef,
    private renderer: Renderer2,
    private _geocodeService: GeocodeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private propertyListingService: PropertyListingService,
    private searchHistoryService: SearchHistoryService,
    private authService: AuthService,
    private _modalService: ModalService,
    @Inject(ToasterService) private _toastService: ToasterService,
  ) {
    this.authService.isLoggedIn().subscribe(x => this.isloggedIn = x);
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      
      const paramsObj = Object.fromEntries(params.keys.map(key => [key, params.get(key)]));
      this.searchFiltersCount = Object.keys(paramsObj).length;

      this.searchFilter = this.propertyListingService.getSearchFiltersFromUrl(params);
      this.hasUrlParams(params)

      //Type filter
      if (this.searchFilter.type && this.searchFilter.type.length > 0) {
        this.selectedType = this.searchFilter.type
      } else {
        this.selectedType = [];
      }

      //price filter
      if (this.searchFilter.minPrice !== undefined || this.searchFilter.maxPrice !== undefined) {
        let label = `${this.searchFilter.minPrice?.toString() ?? ''} - ${this.searchFilter.maxPrice?.toString() ?? ''}`;
        this.selectedPriceRange = {
          from: this.searchFilter.minPrice,
          to: this.searchFilter.maxPrice,
          label
        }
      } else {
        this.selectedPriceRange = this.emptySearchFilters
      }

      //size filter
      if (this.searchFilter.minSqMeters || this.searchFilter.maxSqMeters) {
        let label = `${this.searchFilter.minSqMeters?.toString() ?? ''} - ${this.searchFilter.maxSqMeters?.toString() ?? ''}`;
        this.selectedSizeRange = {
          from: this.searchFilter.minSqMeters,
          to: this.searchFilter.maxSqMeters,
          label
        }
      } else {
        this.selectedSizeRange = this.emptySearchFilters;
      }

    })

    const allowedPropertyTypes: PropertyType[] = [
      PropertyType.Apartment,
      PropertyType.Detachable,
      PropertyType.Maisonette,
      PropertyType.CountryHouse
    ]

    this.propertyTypes = Object.values(PropertyType)
      .filter(type => allowedPropertyTypes.includes(type as PropertyType))
      .map(type => ({
        label: this.formatKey(type),
        key: type,
        icon: this.propertyTypeIcons(type)
      }));
  }

  private hasUrlParams(params: ParamMap) {
    const excludeKey = 'searchTerm';
    const keys = params.keys.filter(key => key !== excludeKey);
    const noFilter = keys.reduce((acc, key) => {
      const value = params.get(key);
      return acc && (value === null || value === '');
    }, true);
    this.showPopularAreas = noFilter;
    if (!noFilter && this.isloggedIn) {
      this.searchHistoryService.saveHistory(params);
    }
  }

  private formatKey(key: string): string {
    const formattedKey = key
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase();

    // Capitalize the first letter of the entire phrase
    return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
  }
  public searchWithLocation(placesToSearch: any[]): PropertyListFilter {
    let filter: PropertyListFilter = new PropertyListFilter();
    let encodedMessages: string[] = [];
    let placesTerms: string[] = [];
    placesToSearch.forEach((place) => {
      let encodedMessage = this._geocodeService.protoEncodeGeoSearch(place);
      encodedMessages.push(btoa(String.fromCharCode(...encodedMessage)));
      placesTerms.push(`${place.PlaceId}~${place.FormatedAddress}`);
    });
    if (encodedMessages.length > 0) {
      let encodedAreaQueryParam = encodedMessages.join('!');
      filter = new PropertyListFilter({
        area: encodedAreaQueryParam,
        searchTerm: placesTerms.join("!")
      })

      return filter;
    }

    return filter;

  }
  public selectSearchItem(placesToSelect: any[]) {
    let placesTerms: string[] = [];
    if (!placesToSelect || placesToSelect.length == 0) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { searchTerm: undefined, area: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }
    placesToSelect.forEach((place) => {
      placesTerms.push(`${place.PlaceId}~${place.FormatedAddress}`);
    });
    placesTerms.sort();
    let filter = new PropertyListFilter({
      searchTerm: placesTerms.join("!")
    })

    let areaAndSearchTermFilters = this.searchWithLocation(placesToSelect);

    if (areaAndSearchTermFilters) {
      filter.area = areaAndSearchTermFilters.area;
    }

    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  searchWithTypeFilter(propertyType: any | undefined) {
    if (!propertyType || propertyType.length == 0) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { type: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      type: propertyType.join(","),
    })
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  searchWithPriceFilter(range: RangeDropdownItem | undefined) {
    if (!range) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { minPrice: undefined, maxPrice: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      minPrice: range.from,
      maxPrice: range.to
    })

    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  searchWithSizeFilter(range: RangeDropdownItem | undefined) {
    if (!range) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { minSqMeters: undefined, maxSqMeters: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      minSqMeters: range.from,
      maxSqMeters: range.to
    })

    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  advancedSearchHidden(val: boolean) {
    this.advancedFilterOn = false;
    if (val) {
      document.documentElement.classList.toggle("overflow-hidden");
    }
  }
  toggleAdvancedFilers() {
    this.toggleAdvancedFilters$.next();
    this.advancedFilterOn = !this.advancedFilterOn
    document.documentElement.classList.toggle("overflow-hidden");
  }
  searchWithAdvancedFilters(filter: PropertyListFilter) {
    this.searchFiltersCount = this.countSelectedFilters(filter);
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...this.convertArrayValues(filter) },
        queryParamsHandling: 'merge'
      }
    );
  }
  removeQueryParams(): void {
    // Use navigation to clear query parameters
    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: {},
      queryParamsHandling: '',
    })
  }
  public SaveSearch() {
    let saveSearchModal: Modal<SaveSearchModalComponent | SaveSearchAnonymousModalComponent>;

    const options: ModalOptions<SaveSearchModalComponent | SaveSearchAnonymousModalComponent> = {
      keyboard: true,
      ...(this.isloggedIn ? { initialState: { SearchFilters: this.searchFilter } as Partial<SaveSearchModalComponent> } : {})
    };

    saveSearchModal = this._modalService.show(this.isloggedIn ? SaveSearchModalComponent : SaveSearchAnonymousModalComponent, options)

    // Listen for the modal's onHidden event
    saveSearchModal.onHidden?.pipe(
      takeUntilDestroyed(this.destroyRef),
      mergeMap((result: any) => {
        
        if (result && result.result.success) {

          this._toastService.show(
            ToastType.Success,
            'Search saved!',
            'You can find it in your "saved searches" or in the search bar!',
            3500
          );

          // Programmatically trigger a click event
          this.triggerClick();
        } else {
          console.log('No success in modal result, skipping toast');
        }
        return of(null); // Return observable
      })
    ).subscribe();
  }

  private propertyTypeIcons(propertyType: PropertyType | undefined): string | undefined {
    const defaultIcon = "icon-Apartment"

    switch (propertyType) {
      case PropertyType.Apartment:
        return "icon-Apartment";
      case PropertyType.Maisonette:
        return "icon-maisonette"
      case PropertyType.Business:
        return "icon-briefcase"
      case PropertyType.Villa:
        return "icon-villas"
      default:
        return defaultIcon
    }
  }

  private countSelectedFilters(filterObj: { [key: string]: any }): number {
    let count = 0;

    for (const key in filterObj) {
        const value = filterObj[key];

        // Count arrays if they have elements
        if (Array.isArray(value) && value.length > 0) {
            count++;
        }
        // Count boolean values if they are true
        else if (typeof value === "boolean" && value === true) {
            count++;
        }
        // Count numbers (assuming any specified number is considered a selected filter)
        else if (typeof value === "number") {
            count++;
        }
    }

    return count;
  }

  private convertArrayValues(obj: { [key: string]: any }): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (Array.isArray(value)) {
          result[key] = (value as any[]).join(',');

          this.searchFiltersCount += result[key].length;

        } else if (typeof result[key] === 'boolean' && result[key] === true) {
          this.searchFiltersCount += 1;
        } else {
          result[key] = value;
        }
      }
    }

    return result;
  }

  private triggerClick() {
    // Dispatch a click event on the hidden button to simulate a user click
    this.renderer.selectRootElement(this.triggerButton.nativeElement).click();
  }
}

