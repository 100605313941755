import { Injectable } from "@angular/core";
import * as _ from "lodash"

@Injectable({
    providedIn: 'root'
})
export class AddressComponent {
    public findPostalCode(addressComponents: AddressComponentModel[]): string | null {
        var res = _.first(addressComponents.filter(x => (x.types).includes("postal_code")));
        if (res != null) {
            return (res.long_name);
        }
        return null;
    }
    public findCountry(addressComponents: AddressComponentModel[]): string | null {
        var res = _.first(addressComponents.filter(x => (x.types).includes("country")));
        if (res != null) {
            return (res.long_name);
        }
        return null;
    }
    public findLocality(addressComponents: AddressComponentModel[]): string | null {
        var res = _.first(addressComponents.filter(x => (x.types).includes("locality")));
        if (res != null) {
            return (res.long_name);
        }
        return null;
    }
    public findAdministativeAreas(addressComponents: AddressComponentModel[]): string | null {
        var res = addressComponents.filter(x => {
            let res = false;
            x.types.forEach(type => {
                if (type.includes("administrative_area") || type.includes("neighborhood")) {
                    res = true;
                }
            })
            return res;
        });
        if (res != null) {
            return (res.map(x => x.long_name).join(','));
        }
        return null;
    }
    public findRoute(addressComponents: AddressComponentModel[]): string | null {
        var res = _.first(addressComponents.filter(x => (x.types).includes("route")));
        if (res != null) {
            return (res.long_name);
        }
        return null;
    }
    public findStreetNumber(addressComponents: AddressComponentModel[]): string | null {
        var res = _.first(addressComponents.filter(x => (x.types).includes("street_number")));
        if (res != null) {
            return (res.long_name);
        }
        return null;
    }
}
export class AddressComponentModel {
    long_name: string = "";
    short_name: string = "";
    types: string[] = [];
}