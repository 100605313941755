<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white rounded-small p-6">
        <div class="flex justify-between">
            <button (click)="closeModal()" class="p-2"><i class="icon-close text-xl"></i></button>
        </div>
        <div class="flex flex-1 justify-center">
            <div class="h-[566px] w-[850px]" #matterport allowFullScreen>
            </div>
        </div>
    </div>
</div>