import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyDetails } from 'src/app/services/portal-api.service';
import { IWalkthroughDate } from '../request-walkthrough-select-date/request-walkthrough-select-date.component';
import { DatePipe } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-request-walkthrough-success',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './request-walkthrough-success.component.html'
})
export class RequestWalkthroughSuccessComponent implements OnInit {
  @Input()
  property: PropertyDetails | undefined;
  @Input()
  walkthroughDate: IWalkthroughDate | undefined;
  @Output()
  succeeded: EventEmitter<void> = new EventEmitter<void>();

  public formattedDate: string = '';

  constructor() {

  }
  ngOnInit(): void {
    this.formattedDate = this._formatDate();
  }

  public onSuccess() {
    this.succeeded.emit()
  }

  private _formatDate(): string {
    const datePipe = new DatePipe('en-US');
    const date = this.walkthroughDate?.date;
    const dayOfWeek = datePipe.transform(date, 'EEEE');
    const month = datePipe.transform(date, 'MMMM');
    const dayOfMonth = datePipe.transform(date, 'd');
    const suffix = this._getDaySuffix(+dayOfMonth!);

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} at ${this.walkthroughDate?.fromTime}`;
  }

  private _getDaySuffix(day: number): string {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
