<div class="modal-container p-1" [ngClass]="{ 'hidden' : selfHidden }" *transloco="let t; prefix: 'shared.checkout.exit-modal'">
    <div class="bg-modals-light rounded-medium p-8">
        <div class="flex flex-col gap-2">
            <div class="flex justify-end">
                <button (click)="closeModal()" class="p-2"><i class="icon-close text-xl"></i></button>
            </div>
            <div class="flex flex-col gap-4">
                <h2 class="h2">{{t('header')}}</h2>
                <h4 class="h4 subheading">{{t('subheader')}}</h4>
            </div>
        </div>
        <div class="divider-line1 border-b-tone-05 rfs:pt-8"></div>
        <div class="flex flex-col rfs:gap-10 rfs:pt-6 px-4">
            <img class="h-[258px] w-full object-center object-contain " src="assets/on-boarding/bg-6.webp"
                alt="house karikatur with broker">
            <div class="flex flex-col gap-4">
                <button class="btn-primary" (click)="closeModal()">{{t('buttons.cancel')}}</button>
                <!-- [routerLink]="['/home']"  -->
                <button class="btn-text pb-5" (click)="closeModal()">{{t('buttons.exit')}}</button>
            </div>
        </div>
    </div>
</div>