import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class EstimatedFeeService {
    constructor() {}

    public calculateEstimatedFees(propertyValue: number) : EstimatedFees {
        let estimatedFees = new EstimatedFees();

        estimatedFees.notaryExpenses = this.calculateNotaryExpenses(propertyValue);
        estimatedFees.landRegistryFees = this.calculateLandRegistryFees(propertyValue);
        estimatedFees.propertyTransferTax = this.calculatePropertyTransferTax(propertyValue);
        estimatedFees.engineerFee = this.calculateEngineerFee(propertyValue);
        estimatedFees.lawyerFee = this.calculateLawyerFee(propertyValue);
        estimatedFees.realEstateAgencyFee = this.calculateRealEstateAgencyFee(propertyValue);

        return estimatedFees;

    }

    private calculateNotaryExpenses(propertyValue: number): EstimatedFee {
        let percentage = 0;
        if (propertyValue <= 120000) {
            percentage = 0.80;
        } else if (propertyValue <= 380000) {
            percentage = 0.70;
        } else if (propertyValue <= 2000000) {
            percentage = 0.65;
        } else if (propertyValue <= 5000000) {
            percentage = 0.55;
        } else if (propertyValue <= 8000000) {
            percentage = 0.50;
        } else if (propertyValue <= 10000000) {
            percentage = 0.40;
        } else if (propertyValue <= 12000000) {
            percentage = 0.30;
        } else if (propertyValue <= 20000000) {
            percentage = 0.25;
        } else {
            percentage = 0.10;
        }
        let vat = 24 / 100;
        let notaryExpense = (propertyValue * percentage / 100);
        let totalfee = notaryExpense + (notaryExpense * vat);
        
        return new EstimatedFee(totalfee, percentage);
    }

    private calculateLandRegistryFees(propertyValue: number): EstimatedFee {
        let percentage = 0.475;
        let vat = 24 / 100;
        let landRegistryFee = (propertyValue * percentage / 100);
        let totalFee = landRegistryFee + (landRegistryFee * vat);

        return new EstimatedFee(totalFee, percentage);
    }

    private calculatePropertyTransferTax(propertyValue: number): EstimatedFee {
        let percentage = 3;
        let tax = (propertyValue * percentage / 100);
        let municipalityFee = (tax * percentage / 100);
        let totalFee = tax + municipalityFee;

        return new EstimatedFee(totalFee, percentage);
    }

    private calculateEngineerFee(propertyValue: number): EstimatedFee {
        let totalFee = 100;
        let percentage = (totalFee / propertyValue) * 100;

        return new EstimatedFee(totalFee, percentage);
    }

    private calculateLawyerFee(propertyValue: number): EstimatedFee {
        let percentage = 0;
        if (propertyValue <= 44000) {
            percentage = 1;
        } else if (propertyValue <= 1400000) {
            percentage = 0.50;
        } else if (propertyValue <= 3000000) {
            percentage = 0.4;
        } else if (propertyValue <= 6000000) {
            percentage = 0.3;
        } else if (propertyValue <= 15000000) {
            percentage = 0.2;
        } else if (propertyValue <= 30000000) {
            percentage = 0.1;
        } else if (propertyValue <= 60000000) {
            percentage = 0.05;
        } else {
            percentage = 0.01;
        }
        let vat = 24 / 100;
        let lawyerFee = (propertyValue * percentage / 100);
        let totalFee = lawyerFee + (lawyerFee * vat);
        
        return new EstimatedFee(totalFee, percentage);
    }

    private calculateRealEstateAgencyFee(propertyValue: number): EstimatedFee {
        let percentage = 2;
        let vat = 24 / 100;
        let realEstateAgencyFee = (propertyValue * percentage / 100);
        let totalFee = realEstateAgencyFee + (realEstateAgencyFee * vat);

        return new EstimatedFee(totalFee, percentage)
    }
    
}

export class EstimatedFees {
    notaryExpenses?: EstimatedFee;
    landRegistryFees?: EstimatedFee;
    propertyTransferTax?: EstimatedFee;
    engineerFee?: EstimatedFee;
    lawyerFee?: EstimatedFee;
    realEstateAgencyFee?: EstimatedFee;

    constructor(
        notaryExpenses?: EstimatedFee,
        landRegistryFees?: EstimatedFee,
        propertyTransferTax?: EstimatedFee,
        engineerFee?: EstimatedFee,
        lawyerFee?: EstimatedFee,
        realEstateAgencyFee?: EstimatedFee,
    ) {
        this.notaryExpenses = notaryExpenses;
        this.landRegistryFees = landRegistryFees;
        this.propertyTransferTax = propertyTransferTax;
        this.engineerFee = engineerFee;
        this.lawyerFee = lawyerFee;
        this.realEstateAgencyFee = realEstateAgencyFee;
    }
}

class EstimatedFee {
    fee: number;
    percentage: number;

    constructor(
        fee: number,
        percentage: number
    ) {
        this.fee = fee;
        this.percentage = percentage;
    }
}