<section
  id="description"
  *transloco="let t; prefix: 'property-details.property-descriotion-section'"
>
  @if (model) {
  <div class="flex flex-row items-center justify-between mb-2">
    <h2 class="h2 text-black">{{ model.title }}</h2>
    <div class="flex flex-row items-center gap-4">
      <span
        *ngIf="model.previousPrice"
        class="text-semibold text-2xl line-through text-gray-700"
        >{{ model.previousPrice | currency: "EUR" : 'symbol' : '1.0-0' }}</span
      >
      <span class="h2 text-black">{{ model.price | currency: "EUR" : 'symbol' : '1.0-0' }}</span>
    </div>
  </div>
  <div class="flex flex-row items-center mb-6">
    <span class="text-gray-900 pr-4 border-r border-300">
      {{ model.address ? model.address.prefecture : "" }}
    </span>
    <span class="text-gray-900 px-4 border-r border-300">
      ID: {{ model.code }}
    </span>
    <span *ngIf="model.auctionRound" class="text-[#3B61C1] font-semibold px-4">
      <i class="icon-auction"></i> {{ model.auctionRound
      }}{{ t("auction-round") }}
    </span>
  </div>
  <div class="flex flex-row items-center justify-between mb-12">
    <div class="flex flex-row items-center">
      <span class="text-gray-900 pr-4 border-r border-300">
        <i class="icon-needs-renovation"></i>
        {{ model.renovationYear ?? model.constructionYear }}
      </span>
      <span class="text-gray-900 px-4 border-r border-300">
        <i class="icon-stairs"></i>
        {{ model.floor }}{{ t("floor") }}
      </span>
      <span class="text-gray-900 px-4 border-r border-300">
        <i class="icon-bed"></i>
        {{ model.numberOfRooms }} {{ t("rooms") }}
      </span>
      <span class="text-gray-900 pl-4">
        <i class="icon-bathroom"></i>
        {{ model.numberOfBathrooms }} {{ t("bathrooms") }}
      </span>
    </div>
    <div class="flex items-center gap-3">
      <button (click)="toggleFavorite($event)" class="btn-icon-secondary-sm">
        <i
          [ngClass]="
            model.isFavorite
              ? 'icon-heart-filled text-primary'
              : 'icon-heart-line'
          "
        ></i>
      </button>
      <button type="button" class="btn-icon-secondary-sm">
        <i class="icon-share-alt"></i>
      </button>
    </div>
  </div>
  <div
    *ngIf="model.preApproved === 'Approved'"
    class="bg-[#037079CC] text-white p-4 rounded-[8px] mb-12"
  >
    <button
      (click)="toggleAccordionApproved()"
      type="button"
      class="flex flex-row items-center justify-between w-full"
      [ngClass]="{ 'mb-4': accordionApproved }"
    >
      <div class="font-semibold">{{ t("nbg-approved") }}</div>
      <div class="flex flex-row items-center gap-8">
        <img src="assets/static/nbg-white.png" alt="NBG Logo" />
        <div
          class="rotate-0 transition-all"
          [ngClass]="{ 'rotate-180': accordionApproved }"
        >
          <i class="icon-chevron-up"></i>
        </div>
      </div>
    </button>
    <div
      class="text-white overflow-hidden"
      [ngClass]="{ 'h-0': !accordionApproved }"
    >
      {{ t("nbg-approved-description") }}
    </div>
  </div>

  <div class="font-semibold text-black text-2xl mb-6">
    {{ model.title }}
  </div>
  <div
    class="relative overflow-hidden mb-6"
    [ngClass]="{ 'h-[161px]': !descriptionOpen }"
  >
    {{ model.description }}
    @if (!descriptionOpen) {
    <div role="button" (click)="toggleDescription()" class="blur-cascade"></div>
    }
  </div>
  <button type="button" (click)="toggleDescription()" class="btn-text">
    {{ descriptionOpen ? t("show-less") : t("show-more") }}
  </button>
  }
</section>
