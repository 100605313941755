import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-save-search-anonymous-modal',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslocoDirective],
  templateUrl: './save-search-anonymous-modal.component.html',
  styleUrl: './save-search-anonymous-modal.component.css'
})
export class SaveSearchAnonymousModalComponent {
  currentPath: string
  constructor(private router: Router) {
    this.currentPath = this.router.url;
  }
}
