<ng-container *transloco="let t; prefix: 'shared.checkout.checkout-result'">
    <main *ngIf="(order$ | async)" class="flex-1 flex flex-col mt-[5px]">
        <section class="flex-1 flex justify-center items-center container pb-[108px] pt-[72px]">
            <div class="flex flex-col gap-12 flex-1 max-w-[1024px]">
                <div class="text-start">
                    <h1 class="h1 font-sm text-black leading-snug" [innerHTML]="t('header')"></h1>
                </div>
                <div class="bg-gray-100 p-6 flex flex-col gap-6 rounded-lg">
                    <div class="flex justify-between items-center">
                        <div class="text-black font-semibold leading-tight">
                            <span class="rfs:text-xl">{{t('labels.orderId')}}</span>
                            <span class="block rfs:text-[2rem]">{{ order?.number }}</span>
                        </div>
                        <div class="flex rounded-full h-8 w-8 bg-primary-500 justify-center items-center">
                            <i class="icon-check text-white text"></i>
                        </div>
                    </div>
                    <hr>
                    <p class="max-w-[380px]" [innerHTML]="t('infoText')"></p>
                </div>
                <app-step-review [cardData]="cardData" [orderData]="order"
                    [isFromCompleteStep]="true"></app-step-review>
            </div>
        </section>

    </main>
    <app-navigation-buttons [HideSkip]="true" [HidePrevious]="true" (NextButtonClicked)="onNextClick()"
        [CustomNextTitle]="t('titles.myRequests')"
        [HideNextArrow]="true"></app-navigation-buttons>
</ng-container>