import { TranslocoDirective } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuctionRoundFilter, BedroomFilter, FloorFilter, HeatingControl, HeatingType, PropertyListFilter, PropertyType } from 'src/app/services/portal-api.service';
import { PropertyListingService } from 'src/app/services/property.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { RangeDropdownComponent, RangeDropdownItem } from '../range-dropdown/range-dropdown.component';
import { FilterDropdownComponent, FilterDropdownItem } from '../filter-dropdown/filter-dropdown.component';

@Component({
  selector: 'app-advanced-filters-drawer',
  standalone: true,
  imports: [CommonModule, DatePickerComponent, TranslocoDirective, RangeDropdownComponent, FilterDropdownComponent],
  templateUrl: './advanced-filters-drawer.component.html',
  styleUrl: './advanced-filters-drawer.component.css'
})
export class AdvancedFiltersDrawerComponent {
  @ViewChild('firstDatePicker') firstDatePicker!: DatePickerComponent;
  @ViewChild('secondDatePicker') secondDatePicker!: DatePickerComponent;
  @Input() Toggle$: Subject<void> = new Subject();
  @Input() FilterCleared = false;
  @Output() FilterChanged: EventEmitter<PropertyListFilter> = new EventEmitter<PropertyListFilter>();
  @Output() OnHidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() propertyTypes : FilterDropdownItem[] = [];
  @Input() minPriceRanges : RangeDropdownItem[] = []
  @Input() maxPriceRanges: RangeDropdownItem[] = []
  @Input() selectedPriceRange : RangeDropdownItem | undefined;
  @Input() selectedType: PropertyType[] | undefined;
  @Input() minSizeRanges: RangeDropdownItem[]=[];
  @Input() maxSizeRanges: RangeDropdownItem[]=[];
  @Input() selectedSizeRange: RangeDropdownItem | undefined;

  currentYear = new Date().getFullYear();
  minYear = 1950;
  maxYear = this.currentYear + 2;

  filtersTotalNumber = 0;

  isOpen = false;
  filter: PropertyListFilter = new PropertyListFilter()
  minConstructionYear: Date | undefined
  maxConstructionYear: Date | undefined
  floors: toggleButton<FloorFilter>[] = [
    {
      key: FloorFilter.Ground,
      label: "0",
      toggled: false,
    },
    {
      key: FloorFilter.First,
      label: "1",
      toggled: false,
    },
    {
      key: FloorFilter.Second,
      label: "2",
      toggled: false,
    },
    {
      key: FloorFilter.Third,
      label: "3",
      toggled: false,
    },
    {
      key: FloorFilter.FourthPlus,
      label: "4+",
      toggled: false,
    },
  ]
  parking: toggleButton<BedroomFilter>[] = [
    {
      key: BedroomFilter.One,
      label: "1",
      toggled: false,
    },
    {
      key: BedroomFilter.Two,
      label: "2",
      toggled: false,
    },
    {
      key: BedroomFilter.Three,
      label: "3",
      toggled: false,
    },
    {
      key: BedroomFilter.Four,
      label: "4+",
      toggled: false,
    },
    {
      key: BedroomFilter.FivePlus,
      label: "5+",
      toggled: false,
    }
  ]
  bedrooms: toggleButton<BedroomFilter>[] = [
    {
      key: BedroomFilter.One,
      label: "1",
      toggled: false,
    },
    {
      key: BedroomFilter.Two,
      label: "2",
      toggled: false,
    },
    {
      key: BedroomFilter.Three,
      label: "3",
      toggled: false,
    },
    {
      key: BedroomFilter.Four,
      label: "4",
      toggled: false,
    },
    {
      key: BedroomFilter.FivePlus,
      label: "5+",
      toggled: false,
    }
  ]
  bathrooms: toggleButton<BedroomFilter>[] = [
    {
      key: BedroomFilter.One,
      label: "1",
      toggled: false,
    },
    {
      key: BedroomFilter.Two,
      label: "2",
      toggled: false,
    },
    {
      key: BedroomFilter.Three,
      label: "3",
      toggled: false,
    },
    {
      key: BedroomFilter.Four,
      label: "4",
      toggled: false,
    },
    {
      key: BedroomFilter.FivePlus,
      label: "5+",
      toggled: false,
    }
  ]
  preApproved: toggleButton<string> = {
    key: 'preApproved',
    label: 'Pre Approved',
    toggled: false,
  };
  inAuction: toggleButton<string> = {
    key: 'inAuction',
    label: 'In Auction',
    toggled: false,
  }

  auctionRound: toggleButton<AuctionRoundFilter>[] = [
    {
      key: AuctionRoundFilter.First,
      label: "1st",
      toggled: false,
    },
    {
      key: AuctionRoundFilter.Second,
      label: "2nd",
      toggled: false,
    },
    {
      key: AuctionRoundFilter.Third,
      label: "3rd",
      toggled: false,
    },
    {
      key: AuctionRoundFilter.Fourth,
      label: "4th",
      toggled: false,
    }
  ]
  characteristics: toggleButton<string>[] = [
    { key: "hasPool", label: "Pool", toggled: false, icon: 'icon-pool'},
    { key: "hasGarden", label: "Garden", toggled: false, icon: 'icon-garden' },
    { key: "isPenthouse", label: "Penthouse", toggled: false, icon: 'icon-villas' },
    // { key: "securityDoor", label: "Security Door", toggled: false, icon: 'icon-door' },
    { key: "suitableForTouristUse", label: "Suitable for Tourist Use", toggled: false, icon: 'icon-suitable-tourists' },
    { key: "suitableForProfessionalUse", label: "Suitable for Professional Use", toggled: false, icon: 'icon-suitable-proffesionals' },
    { key: "hasAlarm", label: "Alarm", toggled: false, icon: 'icon-alarm' },
    { key: "isAiry", label: "Airy", toggled: false, icon: 'icon-light-and-airy' },
    { key: "hasElevator", label: "Elevator", toggled: false, icon: 'icon-elevator' },
    { key: "hasNightElectricCurrent", label: "Night Electric Current", toggled: false, icon: 'icon-great-view' },
    { key: "internalStaircase", label: "Internal Staircase", toggled: false, icon: 'icon-stairs' },
    { key: "isBright", label: "Bright", toggled: false, icon: 'icon-by-the-sea' },
    { key: "hasSolarWaterHeating", label: "Solar Water Heating", toggled: false, icon: 'icon-by-the-sea' },
    { key: "hasBoiler", label: "Boiler", toggled: false, icon: 'icon-by-the-sea' },
    { key: "accessibilityForDisabled", label: "Accessibility for Disabled", toggled: false, icon: 'icon-by-the-sea' },
    { key: "hasPlayroom", label: "Playroom", toggled: false, icon: 'icon-secluded' },
    { key: "petsAreAllowed", label: "Pets are Allowed", toggled: false, icon: 'icon-by-the-sea' },
    { key: "hasTents", label: "Tents", toggled: false, icon: 'icon-by-the-sea' },
    { key: "isFloorApartment", label: "Floor Apartment", toggled: false, icon: 'icon-by-the-sea' }
  ]
  heatingtype: toggleButton<HeatingType>[] = [
    {
      key: HeatingType.NaturalGas,
      label: "Natural gas",
      toggled: false,
      icon: 'icon-heating-gas'
    },
    {
      key: HeatingType.HeatingOil,
      label: "Heating oil",
      toggled: false,
      icon: 'icon-heating-autonomous'
    },
    {
      key: HeatingType.HeatPump,
      label: "Heatpump",
      toggled: false,
      icon: 'icon-heating-autonomous'
    },
  ]

  heatingControl: toggleButton<HeatingControl>[] = [
    {
      key: HeatingControl.Central,
      label: "Central",
      toggled: false,
      icon: 'icon-heating-central'
    },
    {
      key: HeatingControl.Autonomous,
      label: "Autonomous",
      toggled: false,
      icon: 'icon-heating-autonomous'
    },
  ]


  constructor(private activatedRoute: ActivatedRoute, private propertyListingService: PropertyListingService, private router: Router) { }
  ngOnInit() {
    this.Toggle$.subscribe(() => this.toggleDrawer());
    this.activatedRoute.queryParamMap.subscribe(params => {

      this.filter = this.propertyListingService.getSearchFiltersFromUrl(params);
      //Type filter
      if (this.filter.minConstructionYear) {
        this.minConstructionYear = new Date(this.filter.minConstructionYear, 1, 1);
      }
      if (this.filter.maxConstructionYear) {
        this.minConstructionYear = new Date(this.filter.maxConstructionYear, 1, 1);
      }
      this.handleFilters();
    })
  }

  openFirstDatePicker() {
    this.countSelectedFilters(this.filter);
    this.firstDatePicker.openCalendar();
    if (this.secondDatePicker.value) this.secondDatePicker.closeCalendar();
}

  openSecondDatePicker() {
    this.countSelectedFilters(this.filter);
    this.secondDatePicker.openCalendar();
    if (this.firstDatePicker.value) this.firstDatePicker.closeCalendar();
  }

  toggleDrawer() {
    this.isOpen = !this.isOpen;
    this.OnHidden.emit(!this.isOpen);
    this.countSelectedFilters(this.filter);
  }

  clearFilters(): void {
    this.filter =  new PropertyListFilter();
    this.countSelectedFilters(this.filter);
    this.FilterChanged.emit(this.filter);
    this.router.navigate([], {
      queryParams: {},
      replaceUrl: true,
    });
  }

  floorsChange(input: FloorFilter) {
    
    if (this.filter.floor && this.filter.floor.length > 0) {
      let index = this.filter.floor.indexOf(input);
      if (index > -1) {
        this.filter.floor.splice(index, 1);
      }
      else {
        this.filter.floor.push(input)
      }
    }
    else {
      this.filter.floor = [input];
    }
    if (this.filter.floor.length == 0) {
      this.filter.floor = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  parkingChange(input: BedroomFilter) {
    
    if (this.filter.parkingSpace && this.filter.parkingSpace.length > 0) {
      let index = this.filter.parkingSpace.indexOf(input);
      if (index > -1) {
        this.filter.parkingSpace.splice(index, 1);
      }
      else {
        this.filter.parkingSpace.push(input)
      }
    }
    else {
      this.filter.parkingSpace = [input];
    }
    if (this.filter.parkingSpace.length == 0) {
      this.filter.parkingSpace = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  roomsChange(input: BedroomFilter) {
    
    if (this.filter.bedrooms && this.filter.bedrooms.length > 0) {
      let index = this.filter.bedrooms.indexOf(input);
      if (index > -1) {
        this.filter.bedrooms.splice(index, 1);
      }
      else {
        this.filter.bedrooms.push(input)
      }
    }
    else {
      this.filter.bedrooms = [input];
    }
    if (this.filter.bedrooms.length == 0) {
      this.filter.bedrooms = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  bathroomsChange(input: BedroomFilter) {
    
    if (this.filter.bathrooms && this.filter.bathrooms.length > 0) {
      let index = this.filter.bathrooms.indexOf(input);
      if (index > -1) {
        this.filter.bathrooms.splice(index, 1);
      }
      else {
        this.filter.bathrooms.push(input)
      }
    }
    else {
      this.filter.bathrooms = [input];
    }
    if (this.filter.bathrooms.length == 0) {
      this.filter.bathrooms = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  auctionRoundChange(input: AuctionRoundFilter) {
    
    if (this.filter.auctionRound && this.filter.auctionRound.length > 0) {
      let index = this.filter.auctionRound.indexOf(input);
      if (index > -1) {
        this.filter.auctionRound.splice(index, 1);
      }
      else {
        this.filter.auctionRound.push(input)
      }
    }
    else {
      this.filter.auctionRound = [input];
    }
    if (this.filter.auctionRound.length == 0) {
      this.filter.auctionRound = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  toggleCharacteristics(key: string) {
    switch (key) {
      case "hasPool": {
        if (this.filter.hasPool) {
          this.filter.hasPool = !this.filter.hasPool
        }
        else {
          this.filter.hasPool = true;
        }
        break;
      }
      case "hasGarden": {
        if (this.filter.hasGarden) {
          this.filter.hasGarden = !this.filter.hasGarden
        }
        else {
          this.filter.hasGarden = true;
        }
        break;
      }
      case "isPenthouse": {
        if (this.filter.isPenthouse) {
          this.filter.isPenthouse = !this.filter.isPenthouse
        }
        else {
          this.filter.isPenthouse = true;
        }
        break;
      }
      case "suitableForTouristUse": {
        if (this.filter.suitableForTouristUse) {
          this.filter.suitableForTouristUse = !this.filter.suitableForTouristUse
        }
        else {
          this.filter.suitableForTouristUse = true;
        }
        break;
      }
      case "suitableForProfessionalUse": {
        if (this.filter.suitableForProfessionalUse) {
          this.filter.suitableForProfessionalUse = !this.filter.suitableForProfessionalUse
        }
        else {
          this.filter.suitableForProfessionalUse = true;
        }
        break;
      }
      case "hasAlarm": {
        if (this.filter.hasAlarm) {
          this.filter.hasAlarm = !this.filter.hasAlarm
        }
        else {
          this.filter.hasAlarm = true;
        }
        break;
      }
      case "isBright": {
        if (this.filter.isBright) {
          this.filter.isBright = !this.filter.isBright
        }
        else {
          this.filter.isBright = true;
        }
        break;
      }
      case "hasNightElectricCurrent": {
        if (this.filter.hasNightElectricCurrent) {
          this.filter.hasNightElectricCurrent = !this.filter.hasNightElectricCurrent
        }
        else {
          this.filter.hasNightElectricCurrent = true;
        }
        break;
      }
      case "hasElevator": {
        if (this.filter.hasElevator) {
          this.filter.hasElevator = !this.filter.hasElevator
        }
        else {
          this.filter.hasElevator = true;
        }
        break;
      }

      case "isAiry": {
        if (this.filter.isAiry) {
          this.filter.isAiry = !this.filter.isAiry
        }
        else {
          this.filter.isAiry = true;
        }
        break;
      }
      case "sieves": {
        if (this.filter.sieves) {
          this.filter.sieves = !this.filter.sieves
        }
        else {
          this.filter.sieves = true;
        }
        break;
      }
      case "hasSolarWaterHeating": {
        if (this.filter.hasSolarWaterHeating) {
          this.filter.hasSolarWaterHeating = !this.filter.hasSolarWaterHeating
        }
        else {
          this.filter.hasSolarWaterHeating = true;
        }
        break;
      }
      case "hasBoiler": {
        if (this.filter.hasBoiler) {
          this.filter.hasBoiler = !this.filter.hasBoiler
        }
        else {
          this.filter.hasBoiler = true;
        }
        break;
      }
      case "accessibilityForDisabled": {
        if (this.filter.accessibilityForDisabled) {
          this.filter.accessibilityForDisabled = !this.filter.accessibilityForDisabled
        }
        else {
          this.filter.accessibilityForDisabled = true;
        }
        break;
      }
      case "hasPlayroom": {
        if (this.filter.hasPlayroom) {
          this.filter.hasPlayroom = !this.filter.hasPlayroom
        }
        else {
          this.filter.hasPlayroom = true;
        }
        break;
      }
      case "petsAreAllowed": {
        if (this.filter.petsAreAllowed) {
          this.filter.petsAreAllowed = !this.filter.petsAreAllowed
        }
        else {
          this.filter.petsAreAllowed = true;
        }
        break;
      }
      case "hasTents": {
        if (this.filter.hasTents) {
          this.filter.hasTents = !this.filter.hasTents
        }
        else {
          this.filter.hasTents = true;
        }
        break;
      }
      case "isFloorApartment": {
        if (this.filter.isFloorApartment) {
          this.filter.isFloorApartment = !this.filter.isFloorApartment
        }
        else {
          this.filter.isFloorApartment = true;
        }
        break;
      }
      case "isFloorApartment": {
        if (this.filter.isFloorApartment) {
          this.filter.isFloorApartment = !this.filter.isFloorApartment
        }
        else {
          this.filter.isFloorApartment = true;
        }
        break;
      }
      case "internalStaircase": {
        if (this.filter.internalStaircase) {
          this.filter.internalStaircase = !this.filter.internalStaircase
        }
        else {
          this.filter.internalStaircase = true;
        }
        break;
      }
    }
    this.countSelectedFilters(this.filter);
  }

  parkingSelected(input: boolean) {
    
    if (this.filter.hasParking == input) {
      this.filter.hasParking = undefined;
    }
    else {
      this.filter.hasParking = input;
    }
    this.countSelectedFilters(this.filter);
  }

  preaprovedChanged(input: Event) {
    const inputVal = input?.target as HTMLInputElement;
    if (inputVal.checked) {
      this.filter.preApproved = true;
    }
    else {
      this.filter.preApproved = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  auctionChanged(input: Event) {
    const inputVal = input?.target as HTMLInputElement;
    if (inputVal.checked) {
      this.filter.inAuction = true;
    }
    else {
      this.filter.inAuction = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  heatingTypeChanged(input: HeatingType) {
    if (this.filter.heatingType && this.filter.heatingType.length > 0) {
      let index = this.filter.heatingType.indexOf(input);
      if (index > -1) {
        this.filter.heatingType.splice(index, 1);
      }
      else {
        this.filter.heatingType.push(input)
      }
    }
    else {
      this.filter.heatingType = [input];
    }
    if (this.filter.heatingType.length == 0) {
      this.filter.heatingType = undefined;
    }
    this.countSelectedFilters(this.filter);
  }

  // heatingControlChanged(input: HeatingControl) {
  //   if (this.filter.heatingControl && this.filter.heatingControl.length > 0) {
  //     let index = this.filter.heatingControl.indexOf(input);
  //     if (index > -1) {
  //       this.filter.heatingControl.splice(index, 1);
  //     }
  //     else {
  //       this.filter.heatingControl.push(input)
  //     }
  //   }
  //   else {
  //     this.filter.heatingControl = [input];
  //   }
  //   if (this.filter.heatingControl.length == 0) {
  //     this.filter.heatingControl = undefined;
  //   }
    //   this.countSelectedFilters(this.filter);
  // }

  constructionYearFrom(input: Date) {
    this.filter.minConstructionYear = input.getFullYear();
    this.swapIfNeeded();
    this.countSelectedFilters(this.filter);
  }

  constructionYearTo(input: Date) {
    this.filter.maxConstructionYear = input.getFullYear();
    this.swapIfNeeded();
    this.countSelectedFilters(this.filter);
  }

  constructionYearInputFrom(event: Event) {
    const inputYear = parseInt((event.target as HTMLInputElement)?.value, 10);
    this.filter.minConstructionYear = this.validateYear(inputYear);
    this.swapIfNeeded(); 
    this.firstDatePicker.closeCalendar();
    this.countSelectedFilters(this.filter);
  }

  constructionYearInputTo(event: Event) {
    const inputYear = parseInt((event.target as HTMLInputElement)?.value, 10);
    this.filter.maxConstructionYear = this.validateYear(inputYear);
    this.swapIfNeeded(); 
    this.secondDatePicker.closeCalendar();
    this.countSelectedFilters(this.filter);
  }

  // Centralized function for year validation
  private validateYear(year: number): number {
    if (year < this.minYear) {
      return this.minYear;
    } else if (year > this.maxYear) {
      return this.maxYear;
    }
    return year;
  }

  private swapIfNeeded() {
    if (this.filter.minConstructionYear !== undefined &&
        this.filter.maxConstructionYear !== undefined &&
        this.filter.minConstructionYear > this.filter.maxConstructionYear) {
      const temp = this.filter.minConstructionYear;
      this.filter.minConstructionYear = this.filter.maxConstructionYear;
      this.filter.maxConstructionYear = temp;
    }
  }

  clearMaxConstructionYear() {
    this.filter.maxConstructionYear = undefined;
    this.countSelectedFilters(this.filter);

  }
  clearMinConstructionYear() {
    this.filter.minConstructionYear = undefined;
    this.countSelectedFilters(this.filter);
  }

  private handleFloorsFilter() {
    this.floors.forEach(element => {
      element.toggled = this.filter.floor?.includes(element.key) ?? false
    });
  }
  private handleBedroomsFilter() {
    this.bedrooms.forEach(element => {
      element.toggled = this.filter.bedrooms?.includes(element.key) ?? false
    });
  }
  private handleBathroomsFilter() {
    this.bathrooms.forEach(element => {
      element.toggled = this.filter.bathrooms?.includes(element.key) ?? false
    });
  }
  private handleParkingSpaceFilter() {
    this.parking.forEach(element => {
      
      this.filter.parkingSpace?.includes(element.key)
      element.toggled = this.filter.parkingSpace?.includes(element.key) ?? false
    });
  }
  private handleAuctionRoundFilter() {
    this.auctionRound.forEach(element => {
      element.toggled = this.filter.auctionRound?.includes(element.key) ?? false
    });
  }
  private handleInAuctionFilter() {
    this.inAuction.toggled = this.filter.inAuction as boolean;
  }
  private handlePreApprovedFilter() {
    this.preApproved.toggled = this.filter.preApproved as boolean;
  }
  private handleHeatingTypeFilter() {
    this.heatingtype.forEach(element => {
      element.toggled = this.filter.heatingType?.includes(element.key) ?? false
    });
  }
  private handleCharacteristic() {
    this.characteristics.forEach(element => {
      switch (element.key) {
        case "hasPool": {
          element.toggled = this.filter.hasPool ?? false;
          break;
        }
        case "hasGarden": {
          element.toggled = this.filter.hasGarden ?? false;
          break;
        }
        case "isPenthouse": {
          element.toggled = this.filter.isPenthouse ?? false;
          break;
        }
        case "suitableForTouristUse": {
          element.toggled = this.filter.suitableForTouristUse ?? false;
          break;
        }
        case "suitableForProfessionalUse": {
          element.toggled = this.filter.suitableForProfessionalUse ?? false;
          break;
        }
        case "hasAlarm": {
          element.toggled = this.filter.hasAlarm ?? false;
          break;
        }
        case "isBright": {
          element.toggled = this.filter.isBright ?? false;
          break;
        }
        case "hasNightElectricCurrent": {
          element.toggled = this.filter.hasNightElectricCurrent ?? false;
          break;
        }
        case "hasElevator": {
          element.toggled = this.filter.hasElevator ?? false;
          break;
        }

        case "isAiry": {
          element.toggled = this.filter.isAiry ?? false;
          break;
        }
        case "sieves": {
          element.toggled = this.filter.sieves ?? false;
          break;
        }
        case "hasSolarWaterHeating": {
          element.toggled = this.filter.hasSolarWaterHeating ?? false;
          break;
        }
        case "hasBoiler": {
          element.toggled = this.filter.hasBoiler ?? false;
          break;
        }
        case "accessibilityForDisabled": {
          element.toggled = this.filter.accessibilityForDisabled ?? false;
          break;
        }
        case "hasPlayroom": {
          element.toggled = this.filter.hasPlayroom ?? false;
          break;
        }
        case "petsAreAllowed": {
          element.toggled = this.filter.petsAreAllowed ?? false;
          break;
        }
        case "hasTents": {
          element.toggled = this.filter.hasTents ?? false;
          break;
        }
        case "isFloorApartment": {
          element.toggled = this.filter.isFloorApartment ?? false;
          break;
        }
        case "isFloorApartment": {
          element.toggled = this.filter.isFloorApartment ?? false;
          break;
        }
        case "internalStaircase": {
          element.toggled = this.filter.internalStaircase ?? false;
          break;
        }
      }
    });
  }

  searchWithSizeFilter(range: RangeDropdownItem | undefined) {
    if (!range) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { minSqMeters: undefined, maxSqMeters: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      minSqMeters: range.from,
      maxSqMeters: range.to
    })
    this.countSelectedFilters(this.filter);
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }

  searchWithPriceFilter(range: RangeDropdownItem | undefined) {
    if (!range) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { minPrice: undefined, maxPrice: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      minPrice: range.from,
      maxPrice: range.to
    })
    this.countSelectedFilters(this.filter);
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
    
  }

  searchWithTypeFilter(propertyType: any | undefined) {
    if (!propertyType || propertyType.length == 0) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { type: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      type: propertyType.join(","),
    })
    this.countSelectedFilters(this.filter);
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }

  private handleFilters() {
    this.handleFloorsFilter();
    this.handleBedroomsFilter();
    this.handleBathroomsFilter();
    this.handleParkingSpaceFilter();
    this.handleAuctionRoundFilter();
    this.handleHeatingTypeFilter();
    this.handleCharacteristic();
    this.handleInAuctionFilter();
    this.handlePreApprovedFilter();
    this.filtersTotalNumber = this.countSelectedFilters(this.filter);
  }
  protected filterChanged = () =>  {
    this.countSelectedFilters(this.filter);
    this.FilterChanged.emit(this.filter);
    this.toggleDrawer();
  };

  private countSelectedFilters(filterObj: { [key: string]: any }): number {
    let count = 0;

    for (const key in filterObj) {
        const value = filterObj[key];

        // Count arrays if they have elements
        if (Array.isArray(value) && value.length > 0) {
            count++;
        }
        // Count boolean values if they are true
        else if (typeof value === "boolean" && value === true) {
            count++;
        }
        // Count numbers (assuming any specified number is considered a selected filter)
        else if (typeof value === "number") {
            count++;
        }
    }

    return this.filtersTotalNumber = count;
  }
}


interface toggleButton<T> {
  key: T,
  label: string,
  toggled: boolean,
  icon?: string
}
