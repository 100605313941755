import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PropertyListingTab, SurroundingsFormGroup } from '../property-listing.component';
import { Advisor, Orientation, Surrounding, View } from 'src/app/services/portal-api.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AdvisorCallbackComponent } from 'src/app/shared/components/advisor-callback/advisor-callback.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-surroundings',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AdvisorCallbackComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './surroundings.component.html',
  styleUrl: './surroundings.component.css'
})
export class SurroundingsComponent implements OnInit {
  @Input() surroundingsFormGroup: FormGroup<SurroundingsFormGroup> = new FormGroup(new SurroundingsFormGroup());
  @Input() advisor: Advisor | undefined;

  @Output() onNextTab: Subject<PropertyListingTab> = new Subject();

  public view = View;
  public orientation = Orientation;
  public surrounding = Surrounding;
  public propertyListingTab = PropertyListingTab;

  public highwayEl = new FormControl<string | null>(null);
  public highwayEn = new FormControl<string | null>(null);
  public nearbyHighwaysEl = new FormControl<string[]>([]);
  public nearbyHighwaysEn = new FormControl<string[]>([]);

  ngOnInit(): void {
    this.nearbyHighwaysEl.setValue(this.surroundingsFormGroup.controls.nearbyHighwaysEl.value?.split(',') ?? []);
    this.nearbyHighwaysEn.setValue(this.surroundingsFormGroup.controls.nearbyHighwaysEn.value?.split(',') ?? []);


    this.highwayEl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe((val: string | null) => {
        if (val) {
          this.nearbyHighwaysEl.value?.push(val);
          this.nearbyHighwaysEl.patchValue(this.nearbyHighwaysEl.value);
          this.highwayEl.setValue(null);
        }
      });

    this.nearbyHighwaysEl.valueChanges.subscribe((val: string[] | null) => {
      this.surroundingsFormGroup.controls.nearbyHighwaysEl.patchValue(val ? val.join(',') : null);
    });

    this.highwayEn.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe((val: string | null) => {
        if (val) {
          this.nearbyHighwaysEn.value?.push(val);
          this.nearbyHighwaysEn.patchValue(this.nearbyHighwaysEn.value);
          this.highwayEn.setValue(null);
        }
      });

    this.nearbyHighwaysEn.valueChanges.subscribe((val: string[] | null) => {
      this.surroundingsFormGroup.controls.nearbyHighwaysEn.patchValue(val ? val.join(',') : null);
    });
  }

  public onMultipleCheckboxClick(option: string, formControl: FormControl<string[]>) {
    let formControlVal = formControl?.value;
    let index = formControlVal.indexOf(option);

    if (index === -1) {
      formControlVal?.push(option);
    } else {
      formControlVal?.splice(index, 1);
    }
    formControl.patchValue(formControlVal);
  }

  public onHighwayRemove(highway: string, formControl: FormControl<string[] | null>) {
    let formControlVal = formControl?.value;
    let index = formControlVal?.indexOf(highway);

    if (index !== undefined && index !== -1) {
      formControlVal?.splice(index, 1);
    }
    formControl.patchValue(formControlVal);
  }

  public navigateToTab(tab: PropertyListingTab) {
    this.onNextTab.next(tab);
  }

  public unsorted() {
    return 0;
  }
}
