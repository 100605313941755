import { TranslocoDirective } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Modal, ModalOptions } from '@indice/ng-components';
import { PropertyListFilter } from 'src/app/services/portal-api.service';
import { PropertyListingService } from 'src/app/services/property.service';

@Component({
  selector: 'app-save-search-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslocoDirective],
  templateUrl: './save-search-modal.component.html',
  styleUrl: './save-search-modal.component.css'
})
export class SaveSearchModalComponent {

  @Input() SearchFilters: PropertyListFilter | undefined;

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
  });

  constructor(public modalRef: Modal, @Inject(ModalOptions) private _options: ModalOptions, private _propertyListingService: PropertyListingService) { }

  ngOnInit(): void {
    this.SearchFilters = (this._options?.initialState?.['SearchFilters'] as PropertyListFilter);
  }

  close = () => this.modalRef.hide({ name: this.form.get('name')?.value });

  save = () => {
    this._propertyListingService.saveSearch(this.SearchFilters, this.form.get('name')?.value).subscribe(
      {
        complete: () => { },
        error: (errors: any) => {
          this.form.get('name')?.setErrors({ error: 'Failed to save the search, please try again.' });
          console.log(errors);
        },
        next: () => {
          //success
          this.modalRef.hide({ name: this.form.get('name')?.value, success: true })
        }
      }

    );
  }
}
