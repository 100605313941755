@if (isLoggedIn) {
<ng-container *transloco="let t; prefix: 'offer-modal'">
  <div class="md:rounded-b-medium rounded-t-medium p-8 flex flex-col h-full">
    <div class="flex items-start justify-between pb-6 border-b border-gray-300 mb-6">
      <div>
        <div class="text-primary-600 font-semibold text-xl mb-2" *ngIf="stepVisible">
          Βήμα {{ currentStep }} από 4
        </div>
        <h3 class="text-[1.75rem] text-black leading-[28px]">
          {{ handleTitle() }}
        </h3>
      </div>
      <button class="p-2">
        <i class="icon-close text-black" (click)="closeModal()"></i>
      </button>
    </div>
    <form [formGroup]="offerForm" class="flex-1 flex flex-col justify-between" [ngClass]="{ hidden: currentStep > 4 }">
      @if (currentStep === 0) {
      <div class="rounded bg-gray-50 p-4">
        <div class="flow-root">
          <ul role="list" class="max-w-[593px]">
            <li>
              <div class="relative pb-6">
                <span class="absolute left-[8px] top-1 -ml-px h-full w-0.5 bg-primary-600" aria-hidden="true"></span>
                <div class="relative flex space-x-3">
                  <div>
                    <span
                      class="flex h-4 w-4 font-semibold text-2xl transition-all items-center justify-center rounded-full bg-primary-600">
                      <span class="w-[4px] h-[4px] rounded-full bg-white"></span>
                    </span>
                  </div>
                  <div class="mt-[-5px]">
                    <div class="flex min-w-0 flex-1 justify-between space-x-4">
                      <div class="text-lg transition-all leading-[22px] text-gray-900">
                        {{ t("instructions-line-1") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="relative pb-6">
                <span class="absolute left-[8px] top-1 -ml-px h-full w-0.5 bg-primary-600" aria-hidden="true"></span>
                <div class="relative flex space-x-3">
                  <div>
                    <span
                      class="flex h-4 w-4 font-semibold text-2xl transition-all items-center justify-center rounded-full bg-primary-600">
                      <span class="w-[4px] h-[4px] rounded-full bg-white"></span>
                    </span>
                  </div>
                  <div class="mt-[-5px]">
                    <div class="flex min-w-0 flex-1 justify-between space-x-4">
                      <div class="text-lg transition-all leading-[22px] text-gray-900">
                        {{ t("instructions-line-2") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="relative pb-6">
                <span class="absolute left-[8px] top-1 -ml-px h-full w-0.5 bg-primary-600" aria-hidden="true"></span>
                <div class="relative flex space-x-3">
                  <div>
                    <span
                      class="flex h-4 w-4 font-semibold text-2xl transition-all items-center justify-center rounded-full bg-primary-600">
                      <span class="w-[4px] h-[4px] rounded-full bg-white"></span>
                    </span>
                  </div>
                  <div class="mt-[-5px]">
                    <div class="flex min-w-0 flex-1 justify-between space-x-4">
                      <div class="text-lg transition-all leading-[22px] text-gray-900">
                        {{ t("instructions-line-3") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="relative">
                <div class="relative flex space-x-3">
                  <div>
                    <span
                      class="flex h-4 w-4 font-semibold text-2xl transition-all items-center justify-center rounded-full bg-primary-600">
                      <span class="w-[4px] h-[4px] rounded-full bg-white"></span>
                    </span>
                  </div>
                  <div class="mt-[-5px]">
                    <div class="flex min-w-0 flex-1 justify-between space-x-4">
                      <div class="text-lg transition-all leading-[22px] text-gray-900">
                        {{ t("instructions-line-4") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center">
        <button class="btn-primary mt-10" (click)="onNextClick()">
          {{ "shared.navigation-buttons.buttons.start" | transloco }}
        </button>
      </div>
      }
      @if (currentStep === 1) {
      <ng-container [formGroupName]="currentStep">
        <div>
          <div class="text-gray-900 mb-6">
            Ζητούμενη τιμή:
            <span class="text-black font-semibold text-2xl">€{{ askingPrice | number }}</span>
          </div>
          <div class="text-black text-[1.75rem] mb-6">
            {{ t("what-is-your-price") }}
          </div>
          <div class="flex flex-col lg:flex-row gap-4 items-center max-w-[430px] mb-6">
            <div class="flex-1">
              <input type="range" [min]="getMinOfferPrice()" [max]="getMaxOfferPrice()" [formControlName]="'price'"
                [value]="offerForm.get('1.price')?.value" />
            </div>
            <div
              class="flex-1 flex max-w-[150px] bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
              <span class="text-gray-900 font-semibold">€</span>
              <input type="text" class="outline-none max-w-[100px]" [formControlName]="'price'"
                [thousandSeparator]="'.'" mask="separator.2" [value]="
                      offerForm.get(currentStep + '.price')?.value | number
                    " />
            </div>
          </div>
          <div *ngIf="hasError('1.price')" class="text-error">
            {{ t("invalid-price-range") }}
          </div>
          <div class="mb-6">{{ t("offer-disclaimer") }}</div>
          <label class="checkbox-selector border border-gray-400 rounded p-4 bg-white mb-14">
            <input type="checkbox" class="hidden" [formControlName]="'tnc'" />
            <div class="checkbox-selector-content justify-center ml-4">
              <span class="checkbox-selector-text text-black font-normal text-md">
                <a>{{ t("tnc-accept") }}</a>
              </span>
            </div>
          </label>
          <div *ngIf="hasError('1.tnc')" class="text-error">
            {{ "shared.checkout.step-buyer-info.acceptTncError" | transloco }}
          </div>
        </div>
      </ng-container>
      <div class="flex flex-row items-center justify-between">
        <button class="btn-text gap-2 items-center flex flex-row" (click)="onPreviousClick()">
          <i class="icon-arrow-left"></i>
          <span>{{
            "shared.navigation-buttons.buttons.previous" | transloco
            }}</span>
        </button>
        <button class="btn-primary" (click)="onNextClick()">
          {{ "shared.navigation-buttons.buttons.next" | transloco }}
        </button>
      </div>
      }
      @if (currentStep === 2) {
      <ng-container [formGroupName]="currentStep">
        <div>
          <label class="inline-flex items-center cursor-pointer p-4 border border-gray-300 rounded-lg w-full mb-10">
            <input [formControlName]="'includeLoan'" type="checkbox" value="" class="sr-only peer toggle-input" />
            <div
              class="toggle-visual relative w-12 h-8 bg-gray-400 hover:bg-gray-300 peer-disabled:bg-gray-100 transition-all rounded-full peer peer-checked:after:translate-x-[16px] rtl:peer-checked:after:-translate-x-[16px] after:absolute after:top-[1px] after:start-[1px] after:bg-white after:rounded-full after:h-[30px] after:w-[30px] after:transition-all peer-disabled:after:bg-gray-300 peer-checked:after:text-primary-600 peer-checked:bg-primary-600">
            </div>
            <div class="flex flex-col max-w-[258px]">
              <span class="ms-4 font-semibold text-black">{{ t("interested-for-loan") }}</span>
            </div>
          </label>
          @if (includeLoanControl.value) {
          <div class="text-[1.75rem] text-black font-semibold">
            {{ t("define-loan-amount") }}
          </div>
          <div class="flex flex-col lg:flex-row gap-4 items-center max-w-[430px] mb-5">
            <div class="flex-1">
              <input type="range" [min]="getMinLoanAmount()" [max]="getMaxLoanAmount()" [formControlName]="'loanAmount'"
                [value]="offerForm.get(currentStep + '.loanAmount')?.value" />
            </div>
            <div
              class="flex-1 flex max-w-[150px] bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
              <span class="text-gray-900 font-semibold">€</span>
              <input type="text" class="outline-none max-w-[100px]" [formControlName]="'loanAmount'"
                [thousandSeparator]="'.'" mask="separator.2"
                [value]="offerForm.get(currentStep + '.loanAmount')?.value| number" />
            </div>
          </div>
          <div *ngIf="hasError('2.loanAmount')" class="text-error">
            {{ t("invalid-loan-range") }}
          </div>
          }
          <div class="bg-gray-50 text-gray-900 p-5 rounded-lg flex flex-row items-center gap-2">
            @if (includeLoanControl.value) {
            <div class="flex flex-row items-center flex-1 gap-2">
              {{ t("loan-amount") }}
              <span class="text-2xl font-semibold text-black">€{{ loanAmountControl.value | number }}</span>
            </div>
            }
            <div class="flex flex-row items-center flex-1 gap-2">
              {{ t("equity") }}
              <span class="text-2xl font-semibold text-black">€ {{ equity | number }}</span>
            </div>
          </div>
          {{ t("loan-amount-disclaimer") }}
        </div>
      </ng-container>
      <div class="flex flex-row items-center justify-between">
        <button class="btn-text flex items-center gap-2" (click)="onPreviousClick()">
          <i class="icon-arrow-left"></i>
          <span>{{
            "shared.navigation-buttons.buttons.previous" | transloco
            }}</span>
        </button>
        <button class="btn-primary" (click)="onNextClick()">
          {{ "shared.navigation-buttons.buttons.next" | transloco }}
        </button>
      </div>
      }
      @if (currentStep === 3) {
      <ng-container [formGroupName]="currentStep">
        <div>
          <div class="mb-6">
            {{ t("step-title-card-disclaimer") }}
          </div>

          <div>
            <div class="flex flex-row items-center gap-8 mb-6">
              <fieldset class="flex flex-col gap-2 group/input">
                <label class="font-semibold flex items-center gap-2 text-black group-disabled:text-gray-300">
                  {{ "shared.checkout.step-payment-details.labels.cardNumber" | transloco }}
                  <span class="text-red"> * </span>
                </label>
                <div
                  class="flex text-gray-500 rounded-full bg-white border border-gray-200 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                  <button class="flex justify-center items-center gap-1 pr-4 border-r border-gray-300 mr-4">
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                  </button>
                  <input
                    class="bg-white outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300"
                    placeholder="XXXX XXXX XXXX XXXX" />
                </div>
              </fieldset>
              <div>
                <div class="font-semibold flex items-center gap-2 text-black group-disabled:text-gray-300 mb-2">
                  {{ "shared.checkout.step-payment-details.labels.expirationDate" | transloco }}
                  <span class="text-red"> * </span>
                </div>
                <div class="flex flex-row items-center gap-2">
                  <fieldset class="flex flex-col gap-2 group/input">
                    <input placeholder="Μήνας"
                      class="bg-white max-w-[95px] text-gray-500 rounded-full border border-gray-200 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" />
                  </fieldset>
                  <span class="text-gray-400">/</span>
                  <fieldset class="flex flex-col gap-2 group/input">
                    <input placeholder="Έτος"
                      class="bg-white max-w-[95px] text-gray-500 rounded-full border border-gray-200 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" />
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="flex flex-row items-center gap-8">
              <fieldset class="flex flex-col gap-2 group/input flex-1">
                <label class="font-semibold flex items-center gap-2 text-black group-disabled:text-gray-300">
                  {{ "shared.checkout.step-payment-details.labels.cardholderName" | transloco }}
                  <span class="text-red"> * </span>
                </label>
                <div
                  class="flex text-gray-500 rounded-full bg-white border border-gray-200 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                  <input
                    class="bg-white max-w-[332px] outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300" />
                </div>
              </fieldset>
              <div>
                <fieldset class="flex flex-col gap-2 group/input">
                  <label class="font-semibold flex items-center gap-2 text-black group-disabled:text-gray-300">
                    {{ "shared.checkout.step-payment-details.labels.securityCode" | transloco }}
                  </label>
                  <div
                    class="flex text-gray-500 rounded-full bg-white border border-gray-200 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    <input
                      class="bg-white max-w-[145px] outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300" />
                    <button class="flex justify-center items-center">
                      <i class="icon-eye text-gray-700 text-xl"></i>
                    </button>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="flex flex-row items-center justify-between">
        <button (click)="onPreviousClick()" class="btn-text flex flex-row gap-2 items-center">
          <i class="icon-arrow-left"></i>
          <span>
            {{ "shared.navigation-buttons.buttons.previous" | transloco }}
          </span>
        </button>
        <button (click)="onNextClick()" class="btn-primary">
          {{ "shared.navigation-buttons.buttons.next" | transloco }}
        </button>
      </div>
      }
      @if (currentStep === 4) {
      <ng-container [formGroupName]="currentStep">
        <div>
          <div class="mb-6">
            {{ this.totpPhoneDisclaimerUiText }}
          </div>
          <div class="flex flex-row items-center gap-4" toptCursorMove>
            <input [formControlName]="'digit1'" type="text"
              class="w-10 flex-1 md:h-[84px] text-center text-3xl bg-white rounded border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300 outline-none"
              maxlength="1" />
            <input [formControlName]="'digit2'" type="text"
              class="w-10 flex-1 md:h-[84px] text-center text-3xl bg-white rounded border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300 outline-none"
              maxlength="1" />
            <input [formControlName]="'digit3'" type="text"
              class="w-10 flex-1 md:h-[84px] text-center text-3xl bg-white rounded border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300 outline-none"
              maxlength="1" />
            <input [formControlName]="'digit4'" type="text"
              class="w-10 flex-1 md:h-[84px] text-center text-3xl bg-white rounded border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300 outline-none"
              maxlength="1" />
            <input [formControlName]="'digit5'" type="text"
              class="w-10 flex-1 md:h-[84px] text-center text-3xl bg-white rounded border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300 outline-none"
              maxlength="1" />
            <input [formControlName]="'digit6'" type="text"
              class="w-10 flex-1 md:h-[84px] text-center text-3xl bg-white rounded border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300 outline-none"
              maxlength="1" />
          </div>
          @if (hasError('4.digit1') || hasError('4.digit2') || hasError('4.digit3') || hasError('4.digit4') ||
          hasError('4.digit5') || hasError('4.digit6')) {
          <div class="text-error">{{ t("phone-digit-error") }}</div>
          }@else if (hasPhoneVerificationError){
          <div class="text-error">
            {{ phoneVerificationErrorText ?? t("phone-digit-error") }}
          </div>
          }
        </div>
      </ng-container>
      <div>
        <div class="flex flex-col items-center mb-8">
          <div class="text-black">{{ t("did-not-receive-code?") }}</div>
          <button class="btn-text" (click)="resendTotp()">
            <span>{{ t("resend-totp") }}</span>
          </button>
        </div>
        <div class="flex flex-row items-center justify-between">
          <button class="btn-text flex flex-row items-center gap-2" (click)="onPreviousClick()">
            <i class="icon-arrow-left"></i>
            <span>{{
              "shared.navigation-buttons.buttons.previous" | transloco
              }}</span>
          </button>
          <button class="btn-primary" (click)="onPhoneSubmitClick()">
            {{ "shared.navigation-buttons.buttons.submit" | transloco }}
          </button>
        </div>
      </div>
      }
    </form>
    @if (currentStep > 4) {
    <div class="flex flex-col items-center justify-between flex-1">
      <div>
        {{ t("step-title-congrats") }}
        {{ t("congrats-text") }}
      </div>
      <img src="../../../../../assets/img/make-an-offer-suc.png" alt="congrats" />
      <button class="btn-primary" (click)="onGoToOffersClick()">
        {{ t("go-to-offers") | transloco }}
      </button>
    </div>
    }
  </div>
</ng-container>
} @else {
<app-requests-unauthorized (login)="login()" (signUp)="signUp()" (close)="closeModal()"></app-requests-unauthorized>
}