import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
// import { ClickOutsideDirective } from '../directives/click-outside.directive';

@Component({
  selector: 'app-dropdown-nationality',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    // ClickOutsideDirective
  ],
  templateUrl: './dropdown-nationality.component.html',
  styleUrl: './dropdown-nationality.component.css'
})

export class DropdownΝationalityComponent {
  @Input() Title: string | undefined
  @Input() Content: DropdownItem[] | undefined
  @Input() selected: any | undefined;
  @Output() DropdownSelected: EventEmitter<string> = new EventEmitter<string>();

  DropdownId: string | undefined
  hideDropdown: boolean = true;
  Value: string | undefined;
  Flag: string | undefined;
  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.DropdownId = `${this.Title}-dropdown`;
    this.Value = this.Title;
    if (this.selected) {
      this.Value = this.Content?.find(x => x.key == this.selected)?.label;
      this.Flag = this.Content?.find(x => x.key == this.selected)?.icon?.toLocaleLowerCase();
    }
  }

  ngOnChanges() {
    this.Value = this.Title;
    console.log("Selected", this.selected)
    if (this.selected) {
      this.Value = this.Content?.find(x => x.key == this.selected)?.label;
      this.Flag = this.Content?.find(x => x.key == this.selected)?.icon?.toLocaleLowerCase();
    }
  }

  dropDownSelected(item: DropdownItem) {
    if (item.key == "default") {
      this.Value = this.Title;
      this.Flag = '';
    }
    else {
      this.Value = item.label;
      this.Flag = item.icon?.toLocaleLowerCase();
    }
    this.DropdownSelected.emit(item.label);
    this.toggleDropdown();
  }

  toggleDropdown() {
    this.hideDropdown = !this.hideDropdown;
  }

  closeDropdown() {
    this.hideDropdown = true;
  }
}

export interface DropdownItem {
  key: any,
  icon: any,
  label: string
}
