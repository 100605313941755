import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Advisor } from 'src/app/services/portal-api.service';

@Component({
  selector: 'app-advisor-callback',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './advisor-callback.component.html',
  styleUrl: './advisor-callback.component.css'
})
export class AdvisorCallbackComponent {

  @Input() advisor: Advisor | undefined

}
