import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Modal, ModalOptions, ModalService } from '@indice/ng-components';
import { ModalImageItemLargeComponent } from './modal-image-item-large/modal-image-item-large.component';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-image-item',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './image-item.component.html',
  styleUrl: './image-item.component.css'
})
export class ImageItemComponent {
  @Input() imageSrc: string = "";
  @Input() propertyTitle: string = "";

  @Output() modalImageHideEvent = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) { }

  openLargeImage() {
    this.modalImageHideEvent.emit(true);
    const imageItemLargeModal = this.modalService.show(ModalImageItemLargeComponent, {
      animated: true,
      initialState: {
        imageSrc: this.imageSrc,
        propertyTitle: this.propertyTitle,
      },
      keyboard: true
    });
    imageItemLargeModal.onHidden?.subscribe((response: any) => {
      this.modalImageHideEvent.emit(false);
    });
  }
}
