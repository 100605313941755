<div class="bg-white rounded-t-2xl md:rounded-2xl w-full p-6 flex-col justify-start items-end gap-y-28 inline-flex"
    *transloco="let t; prefix: 'save-search-modal'">
    <div class="self-stretch flex-col justify-start items-start gap-6 flex">
        <div class="self-stretch justify-end items-start gap-6 inline-flex">
            <div class="grow shrink basis-0 text-black text-2xl font-normal">{{t('headers.main')}}</div>
            <div class="w-5 h-5 relative">
                <i class="icon-close text-xl" (click)="close()"></i>
            </div>
        </div>

        <div class="divider-line1 border-b-tone-05"></div>

        <div class="self-stretch flex-col justify-start items-start flex">
            <div class="self-stretch rounded-lg flex-col justify-center items-start gap-2 flex">
                <div class="self-stretch h-6 flex-col justify-center items-start gap-1 flex">
                    <div class="self-stretch justify-start items-center gap-2 inline-flex">
                        <div class="justify-start items-center gap-1 flex">
                            <div class="text-black text-base font-semibold">Όνομα αναζήτησης</div>
                        </div>
                        <div class="w-5 h-5 relative">
                            <i class="icon-info-circle text-gray-900 text-lg"></i>
                        </div>
                    </div>
                </div>
                <div class="self-stretch h-12 flex-col justify-start items-start flex group/input" [formGroup]="form">
                    
                    <input formControlName="name"
                        class="flex-col justify-center items-start gap-4 flex self-stretch h-12 p-4 bg-white text-tone-09 rounded-full border border-gray-200 py-3 px-4 outline-none">
                    <div class="flex flex-col gap-4">
                        <span class="self-stretch text-sm font-light leading-tight">{{t('headers.input')}}</span>
                    </div>
                    <span *ngIf=" !form.get('name')?.valid && (form.get('name')?.touched || form.get('name')?.dirty)">
                        {{form.get('name')?.getError('error')}}
                    </span>
                </div>

            </div>
        </div>
    </div>

    <button class="btn-primary self-stretch w-full h-12 py-4 rounded-full justify-center items-center gap-2 inline-flex" [disabled]="form.pristine || !form.get('name')?.value" (click)="save()">{{t('buttons.save')}}</button>
</div>

