<section class="min-h-screen" *transloco="let t; prefix: 'complete-request-callback'">
  <div class="container flex flex-col gap-4 rfs:pt-20 items-center justify-center">
    <p class="text-gray-700">{{t('paragraphs.phone')}}</p>
    <h1 class="h1 oversized text-black">{{t('header')}}</h1>
    <div class="rfs:pt-2 max-w-[600px] flex flex-col gap-4">
      @if (modelItem$ | async; as modelItem) {
      <app-property-card-item id="{{modelItem.id}}" [propertySummary]="modelItem"
        [routerLink]="['/properties/'+ modelItem.id]" class="hover: cursor-pointer"></app-property-card-item>
      <div class="flex flex-col md:flex-row gap-4 pb-2 relative">
        <p class="w-[360px]"> {{t('paragraphs.callback', { phoneNumber: this.currentUser?.profile?.phone_number, subject:
          requestData?.action == 'walkthrough' ? t('aWalkthroughFor') : '', type: t(modelItem.type!), prefecture:
          modelItem.address?.prefecture})}}
        </p>
        <img class="h-[110px] object-center object-contain absolute right-0 top-[-16px]"
          src="../../../assets/request-callback/make-offer-advisors.png"
          alt="Advisors avatar, Giorgios, Maria and Sophia">
      </div>
      }

      <div class="divider-line1 border-b-gray-300"></div>
      <p class="text-black pt-3">
        {{t('paragraphs.questions')}}</p>
      <div class="flex flex-col gap-4 mt-4">
        <button class="btn-primary pt-3" (click)="onContinue()">{{t('button')}}</button>
        <a (click)="onSkip()"
          class="text-secondary font-semibold text-center flex flex-row gap-2 items-center w-full justify-center cursor-pointer">{{t('anchor')}} <i class="icon-chevron-right text-xl"></i></a>
      </div>
    </div>
  </div>
</section>