import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ILoanApplicationModel } from '../models';
import { TranslocoDirective, TranslocoPipe, } from '@jsverse/transloco';
import { DoughnutChartComponent } from "../../../shared/components/loan-calulator/doughnut-chart/doughnut-chart.component";

@Component({
  selector: 'app-loan-application-result',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, TranslocoPipe, DoughnutChartComponent],
  templateUrl: './loan-application-result.component.html',
  styleUrl: './loan-application-result.component.css'
})
export class LoanApplicationResultComponent {
  @Input() formData!: ILoanApplicationModel;

  protected propertyMortgageCategoryId = '9EEC3373-4411-44C1-82F9-8B12A3C30566'; //this is the category id as provided by nbg
  protected propertyRenovationCategoryId = '300951CE-9CC4-4904-8921-540AD2B6CC20'; //this is the category id as provided by nbg

  constructor() {

  }

}
