import { AfterViewInit, Component, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BasicInfoFormGroup, PropertyListingTab } from '../property-listing.component';
import { CommonModule } from '@angular/common';
import { Advisor, PropertyType } from 'src/app/services/portal-api.service';
import { AdvisorCallbackComponent } from 'src/app/shared/components/advisor-callback/advisor-callback.component';
import { BehaviorSubject, Observable, Subject, scan, tap } from 'rxjs';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { DtoMarker } from '../../property-details/map-area-section/map-area-section.component';
import { GoogleMapsSearchAutocompleteComponent } from 'src/app/shared/components/autocomplete/google-maps-search-autocomplete/google-maps-search-autocomplete.component';
import { PlaceViewModel } from 'src/app/services/property.service';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { ChangeContext, NgxSliderModule, Options } from '@angular-slider/ngx-slider';


@Component({
  selector: 'app-basic-information',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, AdvisorCallbackComponent, GoogleMapsModule, GoogleMapsSearchAutocompleteComponent, TranslocoDirective, TranslocoPipe, NgxSliderModule],
  templateUrl: './basic-information.component.html',
  styleUrl: './basic-information.component.css'
})
export class BasicInformationComponent implements OnInit, AfterViewInit {
  @Input() basicInfoFormGroup: FormGroup<BasicInfoFormGroup> = new FormGroup(new BasicInfoFormGroup());
  @Input() advisor: Advisor | undefined;
  @ViewChild("propertyMap") map: GoogleMap | undefined;
  @ViewChild("addressSearch") addressSearch: GoogleMapsSearchAutocompleteComponent | undefined;

  @Output() onNextTab: Subject<PropertyListingTab> = new Subject();

  public showPrices = false;
  public propertyType = PropertyType;
  public propertyListingTab = PropertyListingTab;

  public minPrice: number = 20000;
  public maxPrice: number = 2000000;

  public sliderOptions: Options = {
    floor: 20000,
    ceil: 2000000,
    step: 1000
  };

  private bounds = new google.maps.LatLngBounds();

  markerAddedSubject: BehaviorSubject<DtoMarker[]> = new BehaviorSubject<DtoMarker[]>([]);
  public markers$: Observable<DtoMarker[]> = this.markerAddedSubject.pipe(
    scan((all: DtoMarker[], current: DtoMarker[]) => {
      all = current;

      this.bounds.extend(new google.maps.LatLng(this.basicInfoFormGroup?.controls?.address?.controls?.geoPoint?.value?.split(',')?.at(0), this.basicInfoFormGroup?.controls?.address?.controls?.geoPoint?.value?.split(',')?.at(1)));

      return all;
    }),
    tap(t => {
      this.mapOptions.zoom = undefined;
      this.map?.fitBounds(this.bounds);
    }),
  );

  protected mapOptions: google.maps.MapOptions = {
    maxZoom: 20,
    disableDefaultUI: true, //removes default control icons. this is closer to the designed look.
    streetViewControl: true,
    center: new google.maps.LatLng(39.0742, 21.8243), //default map center Athens (when no properties available),
    zoom: 6,
  }

  ngOnInit(): void {
    this.showPrices = this.basicInfoFormGroup?.controls?.lowestPrice?.value && this.basicInfoFormGroup?.controls?.askingPrice?.value;
    if (this.basicInfoFormGroup?.controls?.lowestPrice?.value) {
      this.minPrice = this.basicInfoFormGroup?.controls.lowestPrice.value;
    }
    if (this.basicInfoFormGroup?.controls?.askingPrice?.value) {
      this.maxPrice = this.basicInfoFormGroup?.controls.askingPrice.value;
    }
  }


  ngAfterViewInit(): void {
    this.markPropertyOnMap();
  }

  public onPriceAdjust(): void {
    let lowestPrice = this.basicInfoFormGroup.controls?.lowestPrice.value;
    let realLowestPrice: number | undefined = undefined;
    let realAskingPrice: number | undefined = undefined;
    if (lowestPrice !== null) {
      realLowestPrice = Number(lowestPrice);
      if (realLowestPrice < 20000) {
        realLowestPrice = 20000;
      }
      if (realLowestPrice > 2000000) {
        realLowestPrice = 2000000;
      }
    }

    let askingPrice = this.basicInfoFormGroup.controls?.askingPrice.value;
    if (askingPrice !== null) {
      realAskingPrice = Number(askingPrice);
      if (realAskingPrice < 20000) {
        realAskingPrice = 20000;
      }
      if (realAskingPrice > 2000000) {
        realAskingPrice = 2000000;
      }
    }

    if (realLowestPrice && realAskingPrice && realLowestPrice > realAskingPrice) {
      realAskingPrice = realLowestPrice;
    }

    if (realLowestPrice) {
      this.minPrice = realLowestPrice;
    }
    if (realAskingPrice) {
      this.maxPrice = realAskingPrice;
    }

    this.basicInfoFormGroup.patchValue({
      lowestPrice: realLowestPrice,
      askingPrice: realAskingPrice
    });
  }

  public onPlaceSelected(place: PlaceViewModel): void {
    this.basicInfoFormGroup.controls.address.patchValue({
      country: place.Country,
      prefecture: place.Prefecture,
      streetName: place.StreetName,
      streetNumber: place.StreetNumber,
      postalCode: place.PostalCode,
      geoPoint: place.Lat && place.Lon ? `${place.Lat},${place.Lon}` : null
    });
    this.addressSearch?.clearItems();
    this.markPropertyOnMap();
  }

  public onNext(): void {
    this.onNextTab?.next(this.propertyListingTab.Content);
  }

  public onPriceSliderChange(changeContext: ChangeContext) {
    if (this.minPrice > this.maxPrice) {
      this.maxPrice = this.minPrice;
    }

    this.basicInfoFormGroup.patchValue({
      lowestPrice: changeContext.value,
      askingPrice: changeContext.highValue ?? null
    });
  }

  private markPropertyOnMap(): void {
    let propertyMarker = new DtoMarker();
    propertyMarker.icon = {
      anchor: new google.maps.Point(54, 54),
      url: "../../../assets/PropertyPoint.png"
    }

    propertyMarker.position = new google.maps.LatLng(this.basicInfoFormGroup?.controls?.address?.controls?.geoPoint?.value?.split(',')?.at(0), this.basicInfoFormGroup?.controls?.address?.controls?.geoPoint?.value?.split(',')?.at(1));

    this.markerAddedSubject.next([propertyMarker]);
  }
}
