import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs';
import { CardInfoModel } from '../models';
import { TranslocoDirective } from '@jsverse/transloco';
import { ServicesPaymentsApiService, CompleteSessionResponse } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-checkout-processing',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './checkout-processing.component.html',
  styleUrl: './checkout-processing.component.css'
})
export class CheckoutProcessingComponent {
  private orderNumber: string = '';
  constructor(
    private _paymentsApiService: ServicesPaymentsApiService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this._activatedRoute.params.pipe(
      switchMap((params: Params) => {
        this.orderNumber = params['orderNumber'];
        const transactionId = params['transactionId'];

        return this._paymentsApiService.gatewayPay(this.orderNumber, transactionId);
      }),
      tap((response: CompleteSessionResponse) => {

        this._router.navigate([`/checkout/${this.orderNumber}/complete`]);
      }),
      catchError((error: any) => {
        if (error.status === 400) {
          this._router.navigate([`/checkout/${this.orderNumber}/fail`]);
        }
        throw error;
      })
    ).subscribe()
  }
}
