<div class="flex-1" [formGroup]="constructionFormGroup" *transloco="let t; prefix: 'property-listing.construction'">
    <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
            <h2 class="h2 text-black">{{'property-listing.tabs.heatingAndConstruction' | transloco}}</h2>
        </div>

        <div class="flex flex-col">
            <h3 class="h3 text-black mb-6">{{t('subheaders.heating')}}</h3>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.heatingType')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('helperText.checkBoxes')}}</span>
            <div class="flex flex-col gap-2 mb-6">
                <label for="heatingOil" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="heatingOil" name="checkbox-selector-heatingOil"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.HeatingOil"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.HeatingOil ? null : heatingType.HeatingOil)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.HeatingOil | transloco}}</span>



                    </div>
                </label>
                <label for="naturalGas" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="naturalGas" name="checkbox-selector-naturalGas"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.NaturalGas"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.NaturalGas ? null : heatingType.NaturalGas)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.NaturalGas | transloco}}</span>



                    </div>
                </label>
                <label for="electricAirConditioning" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="electricAirConditioning"
                        name="checkbox-selector-electricAirConditioning"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.ElectricAirConditioning"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.ElectricAirConditioning ? null : heatingType.ElectricAirConditioning)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.ElectricAirConditioning | transloco}}</span>



                    </div>
                </label>
                <label for="electricHeatPump" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="electricHeatPump"
                        name="checkbox-selector-electricHeatPump"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.ElectricHeatPump"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.ElectricHeatPump ? null : heatingType.ElectricHeatPump)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.ElectricHeatPump | transloco}}</span>



                    </div>
                </label>
                <label for="electricRadiators" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="electricRadiators"
                        name="checkbox-selector-electricRadiators"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.ElectricRadiators"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.ElectricRadiators ? null : heatingType.ElectricRadiators)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.ElectricRadiators | transloco}}</span>



                    </div>
                </label>
                <label for="electricHeatPumpWaterHeater" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="electricHeatPumpWaterHeater"
                        name="checkbox-selector-electricHeatPumpWaterHeater"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.ElectricHeatPumpWaterHeater"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.ElectricHeatPumpWaterHeater ? null : heatingType.ElectricHeatPumpWaterHeater)">
                    <div class="checkbox-selector-content">
                        <span
                            class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.ElectricHeatPumpWaterHeater | transloco}}</span>



                    </div>
                </label>
                <label for="fireplaceSimple" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="fireplaceSimple" name="checkbox-selector-fireplaceSimple"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.FireplaceSimple"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.FireplaceSimple ? null : heatingType.FireplaceSimple)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.FireplaceSimple | transloco}}</span>



                    </div>
                </label>
                <label for="energyEfficientFireplace" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="energyEfficientFireplace"
                        name="checkbox-selector-energyEfficientFireplace"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.EnergyEfficientFireplace"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.EnergyEfficientFireplace ? null : heatingType.EnergyEfficientFireplace)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.EnergyEfficientFireplace | transloco}}</span>



                    </div>
                </label>
                <label for="pelletStove" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="pelletStove" name="checkbox-selector-pelletStove"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.PelletStove"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.PelletStove ? null : heatingType.PelletStove)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.PelletStove | transloco}}</span>



                    </div>
                </label>
                <label for="heatPump" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="heatPump" name="checkbox-selector-heatPump"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.HeatPump"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.HeatPump ? null : heatingType.HeatPump)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.HeatPump | transloco}}</span>



                    </div>
                </label>
                <label for="geothermal" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="geothermal" name="checkbox-selector-geothermal"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.Geothermal"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.Geothermal ? null : heatingType.Geothermal)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.Geothermal | transloco}}</span>



                    </div>
                </label>
                <label for="otherHeating" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="otherHeating" name="checkbox-selector-otherHeating"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.OtherHeating"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.OtherHeating ? null : heatingType.OtherHeating)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.OtherHeating | transloco}}</span>



                    </div>
                </label>
                <label for="other" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="other" name="checkbox-selector-other"
                        [checked]="constructionFormGroup.controls.heatingType.value === heatingType.Other"
                        (click)="constructionFormGroup.controls.heatingType.setValue(constructionFormGroup.controls.heatingType.value === heatingType.Other ? null : heatingType.Other)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.heatingType.' + heatingType.Other | transloco}}</span>



                    </div>
                </label>
            </div>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.heatingControl')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('helperText.checkBoxes')}}</span>
            <div class="flex flex-col gap-2 mb-6">
                <label role="button" for="radio-selector-radio-1" class="radio-selector">
                    <input type="radio" class="hidden" id="radio-selector-radio-1" name="heatingControl"
                        [checked]="constructionFormGroup.controls.heatingControl.value === heatingControl.Autonomous"
                        (click)="constructionFormGroup.controls.heatingControl.setValue(constructionFormGroup.controls.heatingControl.value === heatingControl.Autonomous ? null : heatingControl.Autonomous)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{ 'shared.enum.heatingControl.' + heatingControl.Autonomous | transloco }}</span>
                        <span class="radio-selector-text font-normal text-gray-700 text-sm">
                            {{t('helperText.heatingControlAutonomous')}}
                        </span>
                    </div>
                </label>
                <label role="button" for="radio-selector-radio-2" class="radio-selector">
                    <input type="radio" class="hidden" id="radio-selector-radio-2" name="heatingControl"
                        [checked]="constructionFormGroup.controls.heatingControl.value === heatingControl.Central"
                        (click)="constructionFormGroup.controls.heatingControl.setValue(constructionFormGroup.controls.heatingControl.value === heatingControl.Central ? null : heatingControl.Central)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{ 'shared.enum.heatingControl.' + heatingControl.Central | transloco }}</span>
                        <span class="radio-selector-text font-normal text-gray-700 text-sm">
                            {{t('helperText.heatingControlCentral')}}
                        </span>
                    </div>
                </label>
            </div>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.energyClass')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('helperText.checkBoxes')}}</span>
            <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4">
                <label for="energyClassAPlus" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassAPlus" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.APlus"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.APlus ? null : energyClass.APlus)"
                        formcontrolName="energyClass">
                    <span class=" text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.APlus | transloco }}+</span>
                </label>
                <label for="energyClassA" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassA" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.A"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.A ? null : energyClass.A)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' +  energyClass.A | transloco }}</span>
                </label>
                <label for="energyClassBPlus" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassBPlus" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.BPlus"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.BPlus ? null : energyClass.BPlus)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.BPlus | transloco }}+</span>
                </label>
                <label for="energyClassB" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassB" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.B"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.B ? null : energyClass.B)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.B | transloco }}</span>
                </label>
                <label for="energyClassC" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassC" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.C"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.C ? null : energyClass.C)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.C | transloco }}</span>
                </label>
                <label for="energyClassD" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassD" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.D"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.D ? null : energyClass.D)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.D | transloco }}</span>
                </label>
                <label for="energyClassE" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassE" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.E"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.E ? null : energyClass.E)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.E | transloco }}</span>
                </label>
                <label for="energyClassF" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassF" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.F"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.F ? null : energyClass.F)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.F | transloco }}</span>
                </label>
                <label for="energyClassG" class="visual-checkbox flex flex-col">
                    <input type="checkbox" id="energyClassG" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.energyClass.value === energyClass.G"
                        (click)="constructionFormGroup.controls.energyClass.setValue(constructionFormGroup.controls.energyClass.value === energyClass.G ? null : energyClass.G)"
                        formcontrolName="energyClass">
                    <span class="text-4xl font-semibold">{{ 'shared.enum.energyClass.' + energyClass.G | transloco }}</span>
                </label>
            </div>
        </div>

        <div class="flex flex-col">
            <h3 class="h3 text-black">{{t('subheaders.construction')}}</h3>
            <fieldset class="flex flex-col gap-2 group/input max-w-[400px] mb-6">
                <label class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                    {{t('labels.constructionYear')}}
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                </label>

                <span class="text-sm text-gray-700 disabled:text-gray-300">
                    {{t('helperText.constructionYear')}}
                </span>
                <input #constructionYearInput placeholder="1970" formControlName="constructionYear" type="number" [min]="1830" [max]="currentYear" (change)="constructionYearInput.value < constructionYearInput.min ? constructionYearInput.value = constructionYearInput.min : constructionYearInput; constructionYearInput.value > constructionYearInput.max ? constructionYearInput.value = constructionYearInput.max : constructionYearInput;"
                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
            </fieldset>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                {{t('labels.constructionMaterial')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('helperText.addAnyThatApply')}}</span>
            <div class="flex flex-wrap gap-2 mb-6">
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.constructionMaterial.value ?? []).indexOf(constructionMaterial.Concrete) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onConstructionMaterialClick(constructionMaterial.Concrete)">
                    {{ 'shared.enum.constructionMaterial.' + constructionMaterial.Concrete | transloco }}
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.constructionMaterial.value ?? []).indexOf(constructionMaterial.Brick) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onConstructionMaterialClick(constructionMaterial.Brick)">
                    {{ 'shared.enum.constructionMaterial.' + constructionMaterial.Brick | transloco }}
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.constructionMaterial.value ?? []).indexOf(constructionMaterial.Stone) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onConstructionMaterialClick(constructionMaterial.Stone)">
                    {{ 'shared.enum.constructionMaterial.' + constructionMaterial.Stone | transloco }}
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.constructionMaterial.value ?? []).indexOf(constructionMaterial.Wood) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onConstructionMaterialClick(constructionMaterial.Wood)">
                    {{ 'shared.enum.constructionMaterial.' + constructionMaterial.Wood | transloco }}
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.constructionMaterial.value ?? []).indexOf(constructionMaterial.Prefabricate) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onConstructionMaterialClick(constructionMaterial.Prefabricate)">
                    {{ 'shared.enum.constructionMaterial.' + constructionMaterial.Prefabricate | transloco }}
                </div>
            </div>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                {{t('labels.floorMaterial')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('helperText.addAnyThatApply')}}</span>
            <div class="flex flex-wrap gap-2 mb-6">
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.floorMaterial.value ?? []).indexOf(floorMaterial.Tile) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onFloorMaterialClick(floorMaterial.Tile)">
                    {{ 'shared.enum.floorMaterial.' + floorMaterial.Tile | transloco }}
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.floorMaterial.value ?? []).indexOf(floorMaterial.Wooden) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onFloorMaterialClick(floorMaterial.Wooden)">
                    {{ 'shared.enum.floorMaterial.' + floorMaterial.Wooden | transloco }}Wooden
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.floorMaterial.value ?? []).indexOf(floorMaterial.Luminate) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onFloorMaterialClick(floorMaterial.Luminate)">
                    {{ 'shared.enum.floorMaterial.' + floorMaterial.Luminate | transloco }}
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.floorMaterial.value ?? []).indexOf(floorMaterial.Marble) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onFloorMaterialClick(floorMaterial.Marble)">
                    {{ 'shared.enum.floorMaterial.' + floorMaterial.Marble | transloco }}
                </div>
                <div class="border rounded-full px-5 py-2 cursor-pointer"
                    [ngClass]="(constructionFormGroup.controls.floorMaterial.value ?? []).indexOf(floorMaterial.Carpet) > -1 ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'"
                    (click)="onFloorMaterialClick(floorMaterial.Carpet)">
                    {{ 'shared.enum.floorMaterial.' + floorMaterial.Carpet | transloco }}
                </div>
            </div>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-6">
                {{t('labels.propertyCondition')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col md:flex-row gap-4">

                <label for="conditionCompleted" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="conditionCompleted" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.condition.value == propertyCondition.Completed"
                        (click)="constructionFormGroup.controls.condition.setValue(constructionFormGroup.controls.condition.value == propertyCondition.Completed ? null : propertyCondition.Completed)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyCondition.' + propertyCondition.Completed | transloco}}</span>
                </label>


                <label for="conditionIncomplete" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="conditionIncomplete" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.condition.value == propertyCondition.Incomplete"
                        (click)="constructionFormGroup.controls.condition.setValue(constructionFormGroup.controls.condition.value == propertyCondition.Incomplete ? null : propertyCondition.Incomplete)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyCondition.' + propertyCondition.Incomplete | transloco}}</span>
                </label>


                <label for="conditionNewlyBuilt" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="conditionNewlyBuilt" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.condition.value == propertyCondition.NewlyBuilt"
                        (click)="constructionFormGroup.controls.condition.setValue(constructionFormGroup.controls.condition.value == propertyCondition.NewlyBuilt ? null : propertyCondition.NewlyBuilt)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyCondition.' + propertyCondition.NewlyBuilt | transloco}}</span>
                </label>


                <label for="conditionGood" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="conditionGood" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.condition.value == propertyCondition.Good"
                        (click)="constructionFormGroup.controls.condition.setValue(constructionFormGroup.controls.condition.value == propertyCondition.Good ? null : propertyCondition.Good)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyCondition.' + propertyCondition.Good | transloco}}</span>
                </label>


                <label for="conditionNeedsRenovation" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="conditionNeedsRenovation" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.condition.value == propertyCondition.NeedsRenovation"
                        (click)="constructionFormGroup.controls.condition.setValue(constructionFormGroup.controls.condition.value == propertyCondition.NeedsRenovation ? null : propertyCondition.NeedsRenovation)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyCondition.' + propertyCondition.NeedsRenovation | transloco}}</span>
                </label>

                <label for="conditionRenovated" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="conditionRenovated" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.condition.value == propertyCondition.Renovated"
                        (click)="constructionFormGroup.controls.condition.setValue(constructionFormGroup.controls.condition.value == propertyCondition.Renovated ? null : propertyCondition.Renovated)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyCondition.' + propertyCondition.Renovated | transloco}}</span>
                </label>

                <label for="conditionExcellent" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="conditionExcellent" class="opacity-0 absolute h-0 w-0"
                        [checked]="constructionFormGroup.controls.condition.value == propertyCondition.Excellent"
                        (click)="constructionFormGroup.controls.condition.setValue(constructionFormGroup.controls.condition.value == propertyCondition.Excellent ? null : propertyCondition.Excellent)">
                    <i class="icon-maisonette text-[40px]"></i>
                    <span class="">{{'shared.enum.propertyCondition.' + propertyCondition.Excellent | transloco}}</span>
                </label>

            </div>
        </div>

        <app-advisor-callback [advisor]="advisor"></app-advisor-callback>

        <div class="border-t-2 pt-7 flex justify-between">
            <button (click)="navigateToTab(propertyListingTab.Content)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                <i class="icon-arrow-left text-lg"></i>
                {{'property-listing.tabs.titleAndDescription' | transloco}}
            </button>
            <button (click)="navigateToTab(propertyListingTab.Surroundings)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                {{'property-listing.tabs.settingAndSurroundings' | transloco}}
                <i class="icon-arrow-right text-lg"></i>
            </button>
        </div>
    </div>
</div>