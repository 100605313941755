<div *transloco="let t; prefix: ''"
    class="rounded px-2 w-[640px] max-h-[550px] overflow-y-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[0%] bg-white">
    <div class="flex justify-end">
        <button class="p-2" (click)="close()"><i class="icon-close text-xl"></i></button>
    </div>
    <div class="p-2 font-semibold text-xl text-black flex justify-center">{{t('title')}}</div>

    <div class="py-2 flex flex-row items-start mb-2">
        <span>Are you sure ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum</span>
    </div>
    <div class="container rfs:pt-10">
        <div class="flex pb-6">
            <div>
                <div class="flex flex-col gap-y-3">
                    <div class="py-3 flex flex-row justify-between">
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="w-full flex justify-center">
        <button class="m-2 items-center text-black" (click)="close()">{{
            'shared.duplicate-properties-modal.buttons.close' |
            transloco}}</button>
    </div>
</div>