import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  standalone: true,
  imports: [],
  template: ''
})
export class AutocompleteBaseComponent implements OnInit {
  public debouncer: Subject<string> = new Subject<string>();

  constructor() { }

  private _defaultItemsFilter = (item: any) => {
    const selectedItem = this.selectedItems.find(x => x == item);
    return selectedItem == null || selectedItem == undefined;
  }

  @Input() public placeHolder: string | undefined;
  @Input() public itemTemplate: TemplateRef<HTMLElement> | undefined = undefined;
  @Input() public selectedItemTemplate: TemplateRef<HTMLElement> | undefined = undefined;
  @Input() public noResultsTemplate: TemplateRef<unknown> | undefined = undefined;
  @Input() public busy: boolean = true;
  @Input() public defaultValue: string | undefined
  @Input() public debounceMs: number = 500;
  @Input() public minLength: number = 3;
  @Output() public onItemSelected: EventEmitter<any> = new EventEmitter();
  @Output() public onItemRemoved: EventEmitter<any> = new EventEmitter();
  @Output() public onSearch: EventEmitter<void> = new EventEmitter();
  public showResults: boolean = false;
  public items: any[] | undefined = undefined;
  public selectedItems: any[] = [];
  public value: string | undefined;
  protected searchTerm: string = '';
  public HideList = false;
  public ngOnInit(): void {
    this.debouncer
      .pipe(
        debounceTime(this.debounceMs),
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        if (value.length >= this.minLength) {
          this.searchTerm = value;
        }
      });
  }

  public onInputClick(): void {
    this.showResults = true;
  }

  public onInputClickOutside(): void {
    this.showResults = false;
    this.HideList = true;
  }

  public onInputKeyUp(event: any): void {
    this.debouncer.next(event.currentTarget.value);
  }

  public onListItemSelected(item: any): void {
    this.selectedItems.push(item);
    this.onItemSelected.emit(item);
  }

  public onItemRemove(item: any): void {
    const index = this.selectedItems.indexOf(item);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
      this.onItemRemoved.emit(item);
    }
  }

  public emitSearchEvent(): void {
    if (this.selectedItems.length > 0)
      this.onSearch.emit();
  }
}
