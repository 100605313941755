<ng-container *transloco="let t; prefix: 'shared.navigation'">

  <!-- Mobile menu -->
  <div *ngIf="isMobileMenuOpen" class="h-screen flex flex-col justify-end relative z-50 lg:hidden" role="dialog" aria-modal="true">
    <div class="flex fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>

    <div class="fixed inset-0 z-[80] flex w-screen h-screen">

      <!-- Menu Header -->
      <div class="relative flex justify-between w-full flex-col overflow-y-auto bg-white shadow-xl px-4">
        <div>
          <div class="flex pb-2 justify-between">
            <span class="block text-[2.5rem] font-bold text-gray-300">MENU</span>
            <button type="button" (click)="handleMobileMenu()"
              class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-black">
              <span class="absolute -inset-0.5"></span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true" data-slot="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <!-- Links -->
          <div class="flex-col justify-start items-start gap-4 flex">
            <div class="flex-col justify-start items-start flex">
              <div class="self-stretch py-4 justify-start items-center gap-2 inline-flex">
                <div class="w-6 h-6 relative">
                  <i class="icon-buy-property text-primary-700 text-2xl font-semibold"></i>
                </div>
                <button (click)="navigateAndClose('properties')" class="btn-text">
                  <div class="text-black text-xl font-normal">{{t('anchors.buy')}}</div>
                </button>
              </div>
              <div class="self-stretch py-4 justify-start items-center gap-2 inline-flex">
                <div class="w-6 h-6 relative">
                   <i class="icon-sell-property text-primary-700 text-2xl font-semibold"></i>
                </div>
                <button (click)="navigateAndClose('/sell-property-get-started')" class="btn-text">
                  <div class="text-black text-xl font-normal">{{t('anchors.sellWithUs')}}</div>
                </button>
              </div>
              <div class="self-stretch py-4 justify-start items-center gap-2 inline-flex">
                <div class="w-6 h-6 relative">
                  <i class="icon-investment text-primary-700 text-2xl font-semibold"></i>
                </div>
                <button (click)="navigateAndClose('/financing')" class="btn-text">
                  <div class="text-black text-xl font-normal">{{t('anchors.financing')}}</div>
                </button>
              </div>
            </div>

            <div class="divider-line1"></div>

            <section class="w-full">
              <div class="accordion">
                  <div class="flex flex-col justify-between">
                      <button (click)="handleShowDropdown('services')" class="flex justify-between items-center pt-4 pb-4 duration-300 ease-out" [ngClass]="{ 'dropdown-active': openDropdowns['services'] }">
                          <div class="text-black text-xl font-normal">{{t('anchors.services')}}</div>
                          <i class="icon-chevron-up text-primary-700 text-xl font-semibold transition-all dropdown-chevron"></i>
                      </button>
                      <!-- if not collapsed -->
                      <div class="flex flex-col gap-5" [ngClass]="{ 'dropdown-active': openDropdowns['services'], 'hidden': !openDropdowns['services'] }">
                        <div class="border-b border-b-primary-700"></div>
                      </div>
                      <div class="transition-all duration-500 flex flex-col gap-12 py-6" [ngClass]="{ 'dropdown-active': openDropdowns['services'], 'hidden': !openDropdowns['services'] }">
                        <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                          <div class="text-gray-600 text-xl font-normal ">{{t('anchors.buy')}}</div>
                          <div class="self-stretch flex-col justify-start items-start gap-5 flex">
                            <button class="btn-text">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.legalReport')}}</div>
                            </button>
                            <button class="btn-text">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.propertyValuation')}}
                              </div>
                            </button>
                          </div>
                        </div>
                        <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                          <div class="text-gray-600 text-xl font-normal ">{{t('anchors.sellWithUs')}}</div>
                          <div class="self-stretch flex-col justify-start items-start gap-5 flex">
                            <button class="btn-text" (click)="navigateAndClose()">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.propertyAccrediation')}}</div>
                            </button>
                            <button class="btn-text" (click)="navigateAndClose()">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.legalReport')}}</div>
                            </button>
                            <button class="btn-text" (click)="navigateAndClose()">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.propertyValuation')}}
                              </div>
                            </button>
                            <button class="btn-text" (click)="navigateAndClose()">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.propertyFileConstitution')}}
                              </div>
                            </button>
                            <button class="btn-text" (click)="navigateAndClose()">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.propertyPhotoService')}}</div>
                            </button>
                          </div>
                        </div>
                        <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                          <div class="text-gray-600 text-xl font-normal">{{t('anchors.financing')}}</div>
                          <div class="self-stretch flex-col justify-start items-start gap-5 flex">
                            <button class="btn-text" (click)="navigateAndClose()">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.mortgage')}}</div>
                            </button>
                            <button class="btn-text" (click)="navigateAndClose()">
                              <div class="self-stretch text-black text-base font-normal">{{t('anchors.renovation')}}</div>
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </section>

            <div class="divider-line1"></div>

            <section class="w-full">
                <div class="accordion collapsed">
                  <div class="flex flex-col justify-between">
                    <button (click)="handleShowDropdown('discover')" class="flex justify-between items-center pt-4 pb-4 duration-300 ease-out" [ngClass]="{ 'dropdown-active': openDropdowns['discover'] }">
                        <div class="text-black text-xl font-normal">{{t('anchors.discoverUniko')}}</div>
                        <i class="icon-chevron-up text-primary-700 text-xl font-semibold transition-all dropdown-chevron"></i>
                    </button>

                    <div class="flex flex-col gap-5" [ngClass]="{ 'dropdown-active': openDropdowns['discover'], 'hidden': !openDropdowns['discover'] }">
                      <div class="border-b border-b-primary-700"></div>
                    </div>
                    <div class="transition-all duration-500 flex flex-col gap-12 py-6" [ngClass]="{ 'dropdown-active': openDropdowns['discover'], 'hidden': !openDropdowns['discover'] }">
                      <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                        <div class="self-stretch flex-col justify-start items-start gap-5 flex">
                          <button class="btn-text" (click)="navigateAndClose('/home')">
                            <div class="self-stretch text-black text-base font-normal">{{t('anchors.aboutUs')}}</div>
                          </button>
                          <button class="btn-text" (click)="navigateAndClose('/services')">
                            <div class="self-stretch text-black text-base font-normal">{{t('anchors.allServices')}}
                            </div>
                          </button>
                          <button class="btn-text" (click)="navigateAndClose('/contact')">
                            <div class="self-stretch text-black text-base font-normal">{{t('anchors.contactUs')}}
                            </div>
                          </button>
                          <button class="btn-text" (click)="navigateAndClose('/faq')">
                            <div class="self-stretch text-black text-base font-normal">{{t('anchors.faqs')}}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

          </div>
        </div>

        <div class="self-end z-10 translate-x-4">
          <svg width="200" height="206" viewBox="0 0 200 206" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M248.802 132.896V252.474C248.802 256.134 245.721 259.082 241.939 259.082H218.679C214.877 259.082 211.796 256.116 211.796 252.474V138.621C211.796 137.044 211.211 135.505 210.12 134.304L129.597 44.3853C126.848 41.3254 121.934 41.3254 119.185 44.3853L38.6622 134.304C37.5898 135.505 36.9854 137.044 36.9854 138.621V224.297C36.9854 227.958 40.0659 230.905 43.8679 230.905H99.805C103.607 230.905 106.688 227.939 106.688 224.297V152.25C106.688 148.589 109.768 145.623 113.57 145.623H135.426C139.228 145.623 142.309 148.589 142.309 152.25V252.455C142.309 256.116 139.228 259.063 135.426 259.063H6.88189C3.07996 259.063 -0.000579834 256.097 -0.000579834 252.455V132.877C-0.000579834 131.244 0.623327 129.667 1.75416 128.447L119.283 2.52348C122.012 -0.404963 126.77 -0.404963 129.499 2.52348L247.028 128.465C248.158 129.686 248.782 131.263 248.782 132.896H248.802Z"
              fill="#EDF0F2" />
          </svg>
        </div>

      </div>

    </div>
  </div>

  <div *ngIf="isProfileMenuOpen" (clickOutside)="!isProfileMenuOpen" class="relative z-[90] lg:hidden" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>
    <div class="fixed inset-0 z-[80] flex ">
      <!-- Menu Header -->
       <div
        class="relative flex w-full flex-col overflow-y-auto bg-white shadow-xl px-4 py-8 gap-8 self-stretch">
        <div class="self-stretch justify-between items-center inline-flex">
          <span class="block self-stretch text-black text-xl font-semibold">Παπαδόπουλος Αλέξανδρος </span>
          <button type="button"
            (click)="handleProfileMenu()"
            class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-black">
            <span class="absolute -inset-0.5"></span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true" data-slot="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
        </div>


        <div class="divider-line1"></div>
        <!-- Links -->
        <div class="self-stretch flex-col items-start gap-4 flex h-full justify-between">
          <div class="px-0 w-full">
            <div class="self-stretch flex-col justify-center items-start gap-2.5 flex"></div>
            <div class="self-stretch flex-col justify-center items-start gap-4 flex">
              <div class="self-stretch pl-3 justify-start items-center gap-4 inline-flex">
                <div class="text-gray-700 text-base font-normal">{{t('anchors.tasks')}}</div>
              </div>
              <div class="self-stretch flex-col justify-center items-start flex">
                <button
                  (click)="navigateAndClose('my-profile/listings')"
                  class="self-stretch px-3 py-[18px] bg-green-500/5 rounded justify-start items-center gap-2.5 inline-flex w-full flex-row text-left">
                  <div class="w-6 h-6 relative">
                    <i class="icon-house text-black text-xl font-semibold"></i>
                  </div>
                  <div class="grow shrink basis-0 h-6 text-green-700 text-base font-semibold">{{t('anchors.myRecords')}}</div>
                </button>
                <button
                  (click)="navigateAndClose('/my-profile/favourites')"
                  class="self-stretch px-3 py-[18px] rounded justify-start items-center gap-2.5 inline-flex w-full flex-row text-left">
                  <div class="w-6 h-6 relative">
                    <i class="icon-heart-line text-black text-xl font-semibold"></i>
                  </div>
                  <div class="text-black text-base font-semibold">{{t('anchors.favorites')}}</div>
                </button>
                <button
                  (click)="navigateAndClose()"
                  class="self-stretch px-3 py-[18px] rounded justify-start items-center gap-2.5 inline-flex w-full flex-row text-left">
                  <div class="w-6 h-6 relative">
                    <i class="icon-investment text-black text-xl font-semibold"></i>
                  </div>
                  <div class="text-black text-base font-semibold">{{t('anchors.offers')}}</div>
                </button>
                <button
                  (click)="navigateAndClose()"
                  class="self-stretch px-3 py-[18px] rounded justify-between items-center gap-2.5 inline-flex w-full flex-row text-left">
                  <div class="justify-start items-center gap-2.5 flex">
                    <div class="w-6 h-6 relative">
                      <i class="icon-list-todo text-black text-xl font-semibold"></i>
                    </div>
                    <div class="text-black text-base font-semibold">{{t('anchors.requests')}}</div>
                  </div>
                  <div
                    class="w-6 h-6 bg-green-200 rounded-[104px] flex-col justify-center items-center gap-2 inline-flex">
                    <div class="w-6 h-6 text-center text-green-800 content-center text-xs font-normal">7</div>
                  </div>
                </button>
                <button
                  (click)="navigateAndClose('/account-settings/saved-searches')"
                  class="self-stretch px-3 py-[18px] rounded justify-start items-center gap-2.5 inline-flex w-full flex-row text-left">
                  <div class="w-6 h-6 relative">
                    <i class="icon-bookmark text-black text-xl font-semibold"></i>
                  </div>
                  <div class="text-black text-base font-semibold">{{t('anchors.savedSearches')}}</div>
                </button>
              </div>
            </div>
          </div>
          <div class="px-0 w-full">
            <div class="divider-line1"></div>
            <div class="self-stretch h-[120px] flex-col justify-center items-start flex">
              <button
                  (click)="navigateAndClose('/account-settings')"
                  class="self-stretch px-3 py-[18px] rounded justify-start items-center gap-2.5 inline-flex w-full flex-row text-left">
                <div class="w-6 h-6 relative">
                  <i class="icon-cog text-black text-xl font-semibold"></i>
                </div>
                <div class="text-black text-base font-semibold">Διαχείριση λογαριασμού</div>
              </button>
              <button
                  (click)="logout()"
                  class="self-stretch px-3 py-[18px] rounded justify-start items-center gap-2.5 inline-flex w-full flex-row text-left">
                <div class="w-6 h-6 relative">
                  <i class="icon-log-out text-black text-xl font-semibold group-hover:text-primary-700"></i>
                </div>
                <div class="text-black text-base font-semibold">{{t('buttons.logout')}}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <header *ngIf="!alternav && !listingNav" class="header z-[80]"
    [ngClass]="[isPropertyDetails ? 'property-details': '']">
    <nav aria-label="Top" class="w-full mx-auto px-0">
      <div class="flex h-16 items-center justify-between lg:flex-row">
        <!-- Logo -->
        <div class="block">
          <a aria-label="Home" (click)="navigateAndClose('/')">
            <img src="../../../../assets/svgs/unico-logo.svg" alt="Uniko Logo">
          </a>
        </div>

        <div class="justify-center items-center gap-4 inline-flex lg:hidden">
          <div
          *ngIf="!isLoggedIn"
            class="justify-center items-center inline-flex">
            <button (click)="navigateAndClose('/login')" class="px-5 py-3 h-11 gap-2 rounded-full flex flex-row items-center border border-primary-300">
              <div class="text-center text-white text-sm font-semibold">{{t('anchors.logIn')}}</div>
            </button>
          </div>

          <div *ngIf="isLoggedIn" class="justify-center items-center gap-2 flex h-11">
            <button class="p-1 rounded-full border border-white/40 justify-start items-center gap-2 flex">
              <div class="w-9 h-9 relative">
                <button
                  (click)="handleProfileMenu()"
                  class="w-full h-full absolute bg-green-600 rounded-full flex items-center justify-center text-white font-bold">
                    <img src="{{ authUrl }}/avatar/{{ fullName }}/32/278888.png" alt=""
                      class="w-full h-full object-cover rounded-full" />
                   </button>
                <div [ngClass]="{'hidden': hideDot}" class="w-3 h-3 absolute top-0 left-8 bg-green-300 rounded-full border-2 border-green-700">
                </div>
              </div>
            </button>
          </div>

          <!-- Burger Button -->
          <div class="justify-center items-center gap-2 flex h-11">
            <button type="button" (click)="handleMobileMenu()" class="w-12 h-12 relative rounded-full p-2 text-white border border-white/40 lg:hidden">
              <div class="w-5 h-5 flex justify-self-center relative">
                <i class="icon-hamburger-menu text-gray-100 text-xl"></i>
              </div>
            </button>
          </div>
        </div>

        <!-- Menu Items -->
        <div class="hidden lg:ml-8 lg:block lg:self-stretch">
          <div class="flex h-full justify-end">
            <div class="inline-flex text-left self-center gap-2">
                <div class="justify-center items-center inline-flex">
                  <button (click)="navigateAndClose('/properties')" class="px-5 py-3 gap-2 rounded-full text-center flex flex-row items-center text-white text-sm">{{t('anchors.buy')}}</button>
                </div>
                <div class="justify-center items-center inline-flex">
                  <button (click)="navigateAndClose('/sell-property-get-started')" class="px-5 py-3 gap-2 rounded-full text-center flex flex-row items-center text-white text-sm">{{t('anchors.sellWithUs')}}</button>
                </div>
                <div class="justify-center items-center inline-flex">
                  <button (click)="navigateAndClose('/services')" class="px-5 py-3 gap-2 rounded-full text-center flex flex-row items-center text-white text-sm">{{t('anchors.financing')}}</button>
                </div>
                <div class="justify-center items-center inline-flex" (clickOutside)="closeMobileMenu()">
                <button data-collapse-toggle="mega-menu-full" aria-controls="mega-menu-full" aria-expanded="false" (click)="handleMobileMenu()" class="px-5 py-3 h-11 gap-2 rounded-full flex flex-row items-center group" [ngClass]="{'bg-primary-600': isMobileMenuOpen}">
                  <div class="text-center text-white text-sm">{{t('anchors.more')}}</div>
                  <div class="w-5 h-5 relative">
                    <i class="icon-plus text-gray-100 text-sm"></i>
                  </div>
                </button>

                <div [ngClass]="!isMobileMenuOpen ? 'invisible' : 'visible'" class="relative bg-white ml-auto rfs:translate-y-[60px] rfs:-translate-x-[3.85rem] w-4 h-4 rotate-45 rounded"></div>
                <div *ngIf="isMobileMenuOpen"  class="absolute inset-x-0 top-full text-sm text-gray-500 mt-4">
                  <div class="relative p-4 bg-white rounded-xl shadow mx-4">
                    <div class="w-full flex-col justify-start items-start gap-6 inline-flex">
                      <div class="w-full justify-start items-start gap-5 inline-flex">
                        <div class="w-1/4 self-stretch p-4 hover:bg-green-500/5 rounded-lg flex-col justify-start items-start gap-2 inline-flex">
                          <div class="self-stretch p-3 rounded justify-start items-center gap-2 inline-flex">
                            <div class="w-6 h-6 relative">
                              <i class="icon-buy-property text-primary-700 text-2xl font-semibold"></i>
                            </div>
                            <div class="text-black text-base font-semibold">{{t('anchors.buy')}}</div>
                          </div>
                          <div class="self-stretch flex-col justify-center items-start gap-1 flex">
                            <button (click)="navigateAndClose()" class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700">
                              <div class="header-link text-sm font-semibold">{{t('anchors.legalReport')}}</div>
                              <div class="w-5 h-5 relative">
                                <i class="icon-arrow-right text-xl text-primary-700 invisible"></i>
                              </div>
                            </button>
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700">
                              <div class="header-link font-semibold">{{t('anchors.propertyValuation')}}</div>
                              <div class="w-5 h-5 relative">
                                <i class="icon-arrow-right text-xl text-primary-700 invisible"></i>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div class="w-1/4 p-4 hover:bg-green-500/5 rounded-lg flex-col justify-start items-start gap-2 inline-flex">
                          <div class="self-stretch p-3 rounded justify-start items-center gap-2 inline-flex">
                            <div class="w-6 h-6 relative">
                               <i class="icon-sell-property text-primary-700 text-2xl font-semibold"></i>
                            </div>
                            <div class="text-black text-base font-semibold">{{t('anchors.sellWithUs')}}</div>
                          </div>
                          <div class="self-stretch flex-col justify-center items-start gap-1 flex">
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700 ">
                              <div class="header-link font-semibold">{{t('anchors.propertyAccreditation')}}</div>
                              <div class="w-5 h-5 relative"><i class="icon-arrow-right text-xl text-primary-700 invisible"></i></div>
                            </button>
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700 ">
                              <div class="header-link font-semibold">{{t('anchors.legalReport')}}</div>
                              <div class="w-5 h-5 relative"><i class="icon-arrow-right text-xl text-primary-700 invisible"></i></div>
                            </button>
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700 ">
                              <div class="header-link font-semibold">{{t('anchors.propertyValuation')}}</div>
                              <div class="w-5 h-5 relative"><i class="icon-arrow-right text-xl text-primary-700 invisible"></i></div>
                            </button>
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700 ">
                              <div class="header-link font-semibold">{{t('anchors.propertyFileConstitution')}}</div>
                              <div class="w-5 h-5 relative"><i class="icon-arrow-right text-xl text-primary-700 invisible"></i></div>
                            </button>
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700 ">
                              <div class="header-link font-semibold">{{t('anchors.propertyPhotoService')}}</div>
                              <div class="w-5 h-5 relative"><i class="icon-arrow-right text-xl text-primary-700 invisible"></i></div>
                            </button>
                          </div>
                        </div>
                        <div class="w-1/4 self-stretch p-4 hover:bg-green-500/5 rounded-lg flex-col justify-start items-start gap-2 inline-flex">
                          <div class="self-stretch p-3 rounded justify-start items-center gap-2 inline-flex text-black ">
                            <div class="w-6 h-6 relative">
                              <i class="icon-investment text-primary-700 text-2xl font-semibold"></i>
                            </div>
                            <div class="text-base font-semibold">{{t('anchors.financing')}}</div>
                          </div>
                          <div class="self-stretch flex-col justify-center items-start gap-1 flex">
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700">
                              <div class="header-link font-semibold">{{t('anchors.mortgage')}}</div>
                              <div class="w-5 h-5 relative">
                                <i class="icon-arrow-right text-xl text-primary-700 invisible"></i>
                              </div>
                            </button>
                            <button (click)="navigateAndClose()"  class="self-stretch px-3 py-4 justify-between items-center inline-flex text-black hover:icon-visible hover:text-primary-700">
                              <div class="header-link font-semibold">{{t('anchors.renovation')}}</div>
                              <div class="w-5 h-5 relative">
                                <i class="icon-arrow-right text-xl text-primary-700 invisible"></i>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div class="w-px bg-gray-200 self-stretch mt-4 mb-4" aria-hidden="true"></div>
                        <div class="w-1/4 self-stretch p-4 hover:bg-green-500/5 rounded-lg flex-col justify-start items-start gap-2 inline-flex z-50">
                          <div class="self-stretch px-3 py-3.5 rounded justify-start items-center gap-2 inline-flex">
                            <div class="text-gray-700 text-base font-normal">{{t('anchors.discoverUniko')}}</div>
                          </div>
                          <div class="self-stretch flex-col justify-center items-start gap-1 flex">
                            <button (click)="navigateAndClose('/services')"  class="flyout-menu-btn hover:active hover:underline hover:underline-offset-[20px]">
                              <div class="header-link hover:header-link-active text-xl ">{{t('anchors.allServices')}}</div>
                              <div class="hidden divider-line2 border-b-primary-700 rfs:pt-1"></div>
                            </button>
                            <button (click)="navigateAndClose('/home')"  class="flyout-menu-btn hover:active hover:underline hover:underline-offset-[20px]">
                              <div class="header-link hover:header-link-active text-xl">{{t('anchors.aboutUs')}}</div>
                            </button>
                            <button (click)="navigateAndClose('/contact')"  class="flyout-menu-btn hover:active hover:underline hover:underline-offset-[20px]">
                              <div class="header-link hover:header-link-active text-xl">{{t('anchors.contactUs')}}</div>
                            </button>
                            <button (click)="navigateAndClose('/faq')"  class="flyout-menu-btn hover:active hover:underline hover:underline-offset-[20px]">
                              <div class="header-link hover:header-link-active text-xl">{{t('anchors.faqs')}}</div>
                            </button>
                            <div class="w-[120px] h-[167px] absolute bottom-0 right-0 self-end" style="z-index: -1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="120" height="167" viewBox="0 0 120 167" fill="none">
                                <g id="Frame 1000003042" clip-path="url(#clip0_1976_130704)">
                                  <path id="Vector" d="M150 122.924V195.016C150 197.223 148.143 199 145.862 199H131.839C129.547 199 127.69 197.212 127.69 195.016V126.376C127.69 125.425 127.337 124.497 126.679 123.773L78.1326 69.5621C76.4752 67.7174 73.513 67.7174 71.8557 69.5621L23.3093 123.773C22.6628 124.497 22.2984 125.425 22.2984 126.376V178.029C22.2984 180.236 24.1556 182.012 26.4478 182.012H60.1716C62.4638 182.012 64.321 180.224 64.321 178.029V134.592C64.321 132.385 66.1782 130.597 68.4703 130.597H81.6472C83.9393 130.597 85.7966 132.385 85.7966 134.592V195.005C85.7966 197.212 83.9393 198.989 81.6472 198.989H4.14936C1.85722 198.989 0 197.201 0 195.005V122.913C0 121.928 0.376146 120.977 1.05791 120.242L71.9144 44.3241C73.5601 42.5586 76.4282 42.5586 78.0738 44.3241L148.93 120.253C149.612 120.989 149.988 121.939 149.988 122.924H150Z" fill="#EDF0F2"></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1976_130704">
                                    <rect width="120" height="167" fill="white"></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="justify-center items-center inline-flex">

                <div class="justify-center items-center gap-2 flex h-11" (clickOutside)="closeProfileMenu()">
                  <button *ngIf="!isLoggedIn" (click)="navigateAndClose('/login')" class="px-5 py-3 h-11 gap-2 rounded-full flex flex-row items-center border border-primary-300">
                    <div class="text-center text-white text-sm font-semibold">{{t('anchors.logIn')}}</div>
                  </button>
                  <button *ngIf="isLoggedIn"  class="p-1 rounded-full border border-white/40 justify-start items-center gap-2 flex">
                    <div class="w-9 h-9 relative">
                      <button
                        (click)="handleProfileMenu()"
                        class="w-full h-full absolute bg-green-600 rounded-full flex items-center justify-center text-white font-bold">
                          <img src="{{ authUrl }}/avatar/{{ fullName }}/32/278888.png" alt=""
                            class="w-full h-full object-cover rounded-full" />
                         </button>
                      <div [ngClass]="{'hidden': hideDot}" class="w-3 h-3 absolute top-0 left-8 bg-green-300 rounded-full border-2 border-green-700">
                      </div>
                    </div>
                  </button>

                  <!-- Profile Dropdown Menu -->
                  <div *ngIf="isProfileMenuOpen"  [ngClass]="!isProfileMenuOpen ? 'md:invisible' : 'md:visible'" class="absolute z-40 text-left rfs:translate-y-[50px] rfs:-translate-x-[18.85rem]">
                    <div  class="absolute left-0 z-10 mt-2 w-56 ring-opacity-5 focus:outline-none" role="dialog" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                      <div class="w-[358px] px-3 pt-6 pb-4 bg-white rounded-xl shadow border flex-col justify-center items-center gap-6 inline-flex">
                        <div class="self-stretch px-3 justify-start items-center gap-5 inline-flex">
                            <div class="self-stretch text-black text-base font-semibold">{{fullName}}</div>
                        </div>

                        <div class="divider-line1"></div>

                        <div class="self-stretch flex-col justify-center items-start gap-4 flex">
                            <div class="self-stretch flex-col justify-center items-start gap-2.5 flex"></div>
                            <div class="self-stretch flex-col justify-center items-start gap-4 flex">
                                <div class="self-stretch pl-3 justify-start items-center gap-4 inline-flex">
                                    <div class="text-gray-700 text-base font-normal">{{t('anchors.tasks')}}</div>
                                </div>
                                <div class="self-stretch flex-col justify-center items-start flex">
                                  <button (click)="navigateAndClose('my-profile/listings')"
                                    class="group self-stretch px-3 py-[18px] rounded justify-start items-center gap-2.5 inline-flex hover:bg-green-500/5">
                                    <div class="w-6 h-6 relative">
                                      <i class="icon-house text-black text-xl font-semibold group-hover:text-primary-700"></i>
                                    </div>
                                    <div class="text-black group-hover:text-primary-700 group-focus:text-primary-700 text-base font-semibold break-all">
                                      {{t('anchors.myRecords')}}</div>
                                  </button>
                                  <button (click)="navigateAndClose('/my-profile/favourites')"
                                    class="group self-stretch px-3 py-[18px] justify-start items-center gap-2.5 inline-flex hover:bg-green-500/5">
                                    <div class="w-6 h-6 relative">
                                      <i class="icon-heart-line text-black text-xl font-semibold group-hover:text-primary-700"></i>
                                    </div>
                                    <div class="text-black group-hover:text-primary-700 group-focus:text-primary-700 text-base font-semibold break-all">
                                      {{t('anchors.favorites')}}</div>
                                  </button>
                                  <button (click)="navigateAndClose()"
                                    class="group self-stretch px-3 py-[18px] justify-start items-center gap-2.5 inline-flex hover:bg-green-500/5">
                                    <div class="w-6 h-6 relative">
                                      <i class="icon-investment text-black text-xl font-semibold group-hover:text-primary-700"></i>
                                    </div>
                                    <div class="text-black group-hover:text-primary-700 group-focus:text-primary-700 text-base font-semibold break-all">
                                      {{t('anchors.offers')}}</div>
                                  </button>

                                  <button (click)="navigateAndClose()"
                                    class="group self-stretch px-3 py-[18px] justify-start items-center gap-2.5 inline-flex hover:bg-green-500/5 justify-between">
                                      <div class="flex flex-row justify-between gap-2.5">
                                        <div class="w-6 h-6 relative">
                                          <i class="icon-list-todo text-black text-xl font-semibold group-hover:text-primary-700"></i>
                                        </div>
                                        <div class="text-black group-hover:text-primary-700 group-focus:text-primary-700 text-base font-semibold break-all">
                                          {{t('anchors.requests')}}</div>
                                      </div>

                                      @if (numberOfRequests > 0) {
                                        <span
                                          class="group w-6 h-6 bg-green-200 rounded-[104px]  flex-col justify-center items-center gap-1.5 inline-flex">
                                          <div class="w-6 h-6 text-center content-center text-green-800 text-xs font-bold">{{numberOfRequests}}
                                          </div>
                                        </span>
                                      }
                                  </button>

                                  <button (click)="navigateAndClose('/account-settings/saved-searches')"
                                    class="group self-stretch px-3 py-[18px] justify-start items-center gap-2.5 inline-flex hover:bg-green-500/5">
                                    <div class="w-6 h-6 relative">
                                      <i class="icon-bookmark text-black text-xl font-semibold group-hover:text-primary-700"></i>
                                    </div>
                                    <div class="text-black group-hover:text-primary-700 group-focus:text-primary-700 text-base font-semibold break-all">
                                      {{t('anchors.savedSearches')}}</div>
                                  </button>
                                </div>
                            </div>

                            <div class="divider-line1"></div>
                            <div class="self-stretch h-[120px] flex-col justify-center items-start flex">
                                <button (click)="navigateAndClose('account-settings')" class="group self-stretch px-3 py-[18px] justify-start items-center gap-2.5 inline-flex">
                                    <div class="w-6 h-6 relative">
                                      <i class="icon-cog text-black text-xl font-semibold group-hover:text-primary-700"></i>
                                    </div>
                                    <div class="text-black group-hover:text-primary-700 text-base font-semibold">Διαχείριση λογαριασμού</div>
                                  </button>
                                <button (click)="logout()" class="self-stretch px-3 py-[18px] justify-start items-center gap-2.5 inline-flex">
                                    <div class="w-6 h-6 relative">
                                      <i class="icon-log-out text-black text-xl font-semibold group-hover:text-primary-700"></i>
                                    </div>
                                    <div class="text-black group-hover:text-primary-700 text-base font-semibold">{{t('buttons.logout')}}</div>
                                </button>
                            </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </nav>
  </header>

  <header *ngIf="alternav && !listingNav" class="md:visible header h-max z-[80]">
    <nav aria-label="Top" class="w-full mx-auto px-0 flex-col flex">
      <div class="flex  items-center justify-between px-4 py-6 gap-4 lg:flex-row">
        <div class="block">
          <a aria-label="Home" (click)="navigateAndClose('/')">
            <img src="../../../../assets/svgs/unico-logo.svg" alt="Uniko Logo">
          </a>
        </div>
        <div class="hidden md:visible justify-center items-center flex">
          <div class="text-center text-white text-xl font-normal">{{alternavTitle}}</div>
        </div>
        <div class="justify-end items-center gap-8 flex cursor-pointer">
          <div class="h-8 justify-end items-center gap-2 flex">
            <div class="w-8 h-8 p-1.5 justify-start items-center gap-2 flex" (click)="openModalComponent()">
              <i class="icon-close text-gray-100 text-xl"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="md:hidden divider-line1 border-gray-300"></div>

      <div class="md:hidden text-center text-white text-xl font-normal py-6">{{alternavTitle}}</div>
    </nav>
  </header>

  <header *ngIf="listingNav && !alternav" class="md:visible header h-max z-[80]">
    <nav aria-label="Top" class="w-full mx-auto px-0 flex-col flex">
      <div class="flex  items-center justify-between px-4 py-6 gap-4 lg:flex-row">
        <div class="block">
          <a aria-label="Home" (click)="navigateAndClose('/')">
            <img src="../../../../assets/svgs/unico-logo.svg" alt="Uniko Logo">
          </a>
        </div>
        <div class="hidden md:visible justify-center items-center md:flex">
          <div class="text-center text-white text-xl font-normal">{{listingNavTitle}}</div>
        </div>
        <div class="justify-end items-center gap-8 flex cursor-pointer">
          <div class="h-8 justify-end items-center gap-2 flex">
            <div class="w-8 h-8 p-1.5 justify-start items-center gap-2 flex" (click)="openModalComponent()">
              <i class="icon-close text-gray-100 text-xl"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="md:hidden divider-line1 border-gray-300"></div>

      <div class="md:hidden text-center text-white text-xl font-normal py-6">{{listingNavTitle}}</div>
    </nav>
  </header>
</ng-container>
