<div class="flex items-end pt-8 w-full" *transloco="let t; prefix: 'shared.navigation-buttons'">

    <div
        class="bg-white border-t border-gray-200 py-4 px-4 md:px-[4.5rem] flex flex-row items-center justify-between w-full">
        <button *ngIf="!HidePrevious" class="text-primary-700 font-semibold flex flex-row items-center gap-2"
            (click)="PreviousButton()"><i *ngIf="!HidePreviousArrow" class="icon-arrow-left text-[20px]"></i><span
                class="sm:block hidden">{{CustomPreviousTitle ?
                CustomPreviousTitle : t('buttons.previous')}}</span></button>
        <div *ngIf="HidePrevious"></div>
        <div class="flex flex-row items-center justify-end gap-4">
            <div class=" flex">
                <button *ngIf="!HideSkip" class="btn-secondary" (click)="SkipButton()">{{CustomSkipTitle ?
                    CustomSkipTitle : t('buttons.skip')}}
                </button>
            </div>
            <button *ngIf="!HideNext" class="btn-primary flex flex-row items-center gap-2"
                (click)="NextButton()">{{CustomNextTitle ? CustomNextTitle : t('buttons.next')}}<i
                    *ngIf="!HideNextArrow" class="icon-arrow-right"></i></button>
        </div>
    </div>
</div>