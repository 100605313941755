import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CardInfoModel } from '../models';
import { Observable, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StepReviewComponent } from "../step-review/step-review.component";
import { NavigationButtonsComponent } from "../../navigation-buttons/navigation-buttons.component";
import { TranslocoDirective } from '@jsverse/transloco';
import { OrderResponse, ServicesPaymentsApiService } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-checkout-fail',
  standalone: true,
  templateUrl: './checkout-fail.component.html',
  styleUrl: './checkout-fail.component.css',
  imports: [CommonModule, StepReviewComponent, NavigationButtonsComponent, TranslocoDirective]
})
export class CheckoutFailComponent {
  protected cardData: CardInfoModel | undefined;
  protected order$: Observable<OrderResponse>;
  protected order: OrderResponse | undefined;
  protected orderNumber: string = '';
  protected loading: boolean = false;

  constructor(
    private _paymentsApiService: ServicesPaymentsApiService,
    private activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this.order$ = this.activatedRoute.params.pipe(
      tap(t => this.loading = true),
      switchMap((params: Params) => {
        this.orderNumber = params['orderNumber'];
        return this._paymentsApiService.getOrderByNumber(this.orderNumber);
      }),
      tap((response: OrderResponse) => {
        this.loading = false;
        this.order = response;
        const cardInfoJson = JSON.parse(response.transaction?.cardInfo ?? '{}');
        this.cardData = {
          number: cardInfoJson.number,
          nameOnCard: cardInfoJson.name,
          expiryDate: `${cardInfoJson.month}/${cardInfoJson.year}`,
        }
      }),
    );
  }

  protected onNextClick(): void {

    this._router.navigate([`checkout/` + this.orderNumber],
      {
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

  }
}