<div class="accordion" [ngClass]="{ 'collapsed': isCollapsed }">
    <div class="flex flex-col justify-between w-full">
        <button (click)="toggleCollapse()"
            class="flex justify-between items-center py-6 gap-4 text-left duration-300 ease-out">
            <h5 class="text-xl text-gray-900 font-light" [ngClass]="isCollapsed ? 'text-black font-normal': ''">{{ question }}</h5>
            <i class="icon-plus text-[20px] duration-300 ease-out transition-transform"
                [ngClass]="{ 'icon-plus rotate-0 opacity-100 ' : !isCollapsed, 'icon-minus rotate-180 opacity-100 text-green-700': isCollapsed }"></i>
        </button>
        <div class="accordion-details">
            <div class="flex flex-col justify-between gap-6 pb-6">
                <span class="text-gray-900 text-base font-light opacity-90">{{ answer }}</span>
            </div>
        </div>

        <div class="border-b border-b-tone-05" [ngClass]="isCollapsed ? 'border-b border-green-700' : ''"></div>
    </div>
</div>