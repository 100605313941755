<div class="flex-1" [formGroup]="featuresFormGroup" *transloco="let t; prefix: 'property-listing.features'">
    <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
            <h2 class="h2 text-black">{{'property-listing.tabs.roomsAndFeatures' | transloco}}</h2>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.rooms')}}</h3>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                {{t('labels.bedrooms')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col md:flex-row gap-4">

                <label for="oneBedroom" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="oneBedroom" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfRooms.value === 1"
                        (click)="featuresFormGroup.controls.numberOfRooms.setValue(1)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">1</span>
                </label>


                <label for="twoBedrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="twoBedrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfRooms.value === 2"
                        (click)="featuresFormGroup.controls.numberOfRooms.setValue(2)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">2</span>
                </label>


                <label for="threeBedrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="threeBedrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfRooms.value === 3"
                        (click)="featuresFormGroup.controls.numberOfRooms.setValue(3)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">3</span>
                </label>


                <label for="fourBedrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="fourBedrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfRooms.value === 4"
                        (click)="featuresFormGroup.controls.numberOfRooms.setValue(4)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">4</span>
                </label>


                <label for="fivePlusBedrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="fivePlusBedrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfRooms.value >= 5"
                        (click)="featuresFormGroup.controls.numberOfRooms.setValue(5)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">5+</span>
                </label>

            </div>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                {{t('labels.bathrooms')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col md:flex-row gap-4">

                <label for="oneBathroom" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="oneBathroom" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfBathrooms.value === 1"
                        (click)="featuresFormGroup.controls.numberOfBathrooms.setValue(1)">
                    <i class="icon-bath text-[40px]"></i>
                    <span class="">1</span>
                </label>


                <label for="twoBathrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="twoBathrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfBathrooms.value === 2"
                        (click)="featuresFormGroup.controls.numberOfBathrooms.setValue(2)">
                    <i class="icon-bath text-[40px]"></i>
                    <span class="">2</span>
                </label>


                <label for="threeBathrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="threeBathrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfBathrooms.value === 3"
                        (click)="featuresFormGroup.controls.numberOfBathrooms.setValue(3)">
                    <i class="icon-bath text-[40px]"></i>
                    <span class="">3</span>
                </label>


                <label for="fourBathrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="fourBathrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfBathrooms.value === 4"
                        (click)="featuresFormGroup.controls.numberOfBathrooms.setValue(4)">
                    <i class="icon-bath text-[40px]"></i>
                    <span class="">4</span>
                </label>


                <label for="fivePlusBathrooms" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="fivePlusBathrooms" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfBathrooms.value >= 5"
                        (click)="featuresFormGroup.controls.numberOfBathrooms.setValue(5)">
                    <i class="icon-bath text-[40px]"></i>
                    <span class="">5+</span>
                </label>

            </div>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                WCs
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col md:flex-row gap-4">

                <label for="oneWC" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="oneWC" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfWC.value === 1"
                        (click)="featuresFormGroup.controls.numberOfWC.setValue(1)">
                    <i class="icon-toilet text-[40px]"></i>
                    <span class="">1</span>
                </label>


                <label for="twoWCs" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="twoWCs" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfWC.value === 2"
                        (click)="featuresFormGroup.controls.numberOfWC.setValue(2)">
                    <i class="icon-toilet text-[40px]"></i>
                    <span class="">2</span>
                </label>


                <label for="threeWCs" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="threeWCs" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfWC.value === 3"
                        (click)="featuresFormGroup.controls.numberOfWC.setValue(3)">
                    <i class="icon-toilet text-[40px]"></i>
                    <span class="">3</span>
                </label>


                <label for="fourWCs" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="fourWCs" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfWC.value === 4"
                        (click)="featuresFormGroup.controls.numberOfWC.setValue(4)">
                    <i class="icon-toilet text-[40px]"></i>
                    <span class="">4</span>
                </label>


                <label for="fivePlusWCs" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="fivePlusWCs" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.numberOfWC.value >= 5"
                        (click)="featuresFormGroup.controls.numberOfWC.setValue(5)">
                    <i class="icon-toilet text-[40px]"></i>
                    <span class="">5+</span>
                </label>

            </div>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.accessibility')}}</h3>
            <div class="flex items-start">
                <label for="checkbox-accessibilityForDisabled" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="checkbox-accessibilityForDisabled"
                        name="checkbox-accessibilityForDisabled"
                        [checked]="featuresFormGroup.controls.amenities.controls.accessibilityForDisabled.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.accessibilityForDisabled.setValue(!featuresFormGroup.controls.amenities.controls.accessibilityForDisabled.value)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{t('labels.accessibilityForDisabled')}}</span>
                        <span
                            class="checkbox-selector-text text-gray-700 font-normal text-sm">{{t('infoText.accessibilityForDisabled')}}</span>
                    </div>
                </label>
            </div>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.features')}}</h3>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                {{t('helperText.selectAllValues')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4">

                <label for="hasElevator" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasElevator" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasElevator.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasElevator.setValue(!featuresFormGroup.controls.amenities.controls.hasElevator.value)">
                    <i class="icon-elevator text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasElevator' | transloco}}</span>
                </label>

                <label for="hasAlarm" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasAlarm" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasAlarm.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasAlarm.setValue(!featuresFormGroup.controls.amenities.controls.hasAlarm.value)">
                    <i class="icon-alarm text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasAlarm' | transloco}}</span>
                </label>

                <label for="isAiry" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="isAiry" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.isAiry.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.isAiry.setValue(!featuresFormGroup.controls.amenities.controls.isAiry.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.isAiry' | transloco}}</span>
                </label>
                
                <label for="sieves" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="sieves" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.sieves.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.sieves.setValue(!featuresFormGroup.controls.amenities.controls.sieves.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.sieves' | transloco}}</span>
                </label>

                <label for="hasSolarWaterHeating" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasSolarWaterHeating" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasSolarWaterHeating.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasSolarWaterHeating.setValue(!featuresFormGroup.controls.amenities.controls.hasSolarWaterHeating.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasSolarWaterHeating' | transloco}}</span>
                </label>

                <label for="hasBoiler" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasBoiler" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasBoiler.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasBoiler.setValue(!featuresFormGroup.controls.amenities.controls.hasBoiler.value)">
                    <i class="icon-boiler text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasBoiler' | transloco}}</span>
                </label>

                <label for="suitableForProfessionalUse" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="suitableForProfessionalUse" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.suitableForProfessionalUse.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.suitableForProfessionalUse.setValue(!featuresFormGroup.controls.amenities.controls.suitableForProfessionalUse.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.suitableForProfessionalUse' | transloco}}</span>
                </label>

                <label for="suitableForTouristUse" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="suitableForTouristUse" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.suitableForTouristUse.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.suitableForTouristUse.setValue(!featuresFormGroup.controls.amenities.controls.suitableForTouristUse.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.suitableForTouristUse' | transloco}}</span>
                </label>

                <label for="hasNightElectricCurrent" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasNightElectricCurrent" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasNightElectricCurrent.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasNightElectricCurrent.setValue(!featuresFormGroup.controls.amenities.controls.hasNightElectricCurrent.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasNightElectricCurrent' | transloco}}</span>
                </label>

                <label for="hasSafetyDoor" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasSafetyDoor" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasSafetyDoor.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasSafetyDoor.setValue(!featuresFormGroup.controls.amenities.controls.hasSafetyDoor.value)">
                    <i class="icon-safety-door text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasSafetyDoor' | transloco}}</span>
                </label>
                
                <label for="isBright" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="isBright" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.isBright.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.isBright.setValue(!featuresFormGroup.controls.amenities.controls.isBright.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.isBright' | transloco}}</span>
                </label>
                
                <label for="hasPlayroom" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasPlayroom" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasPlayroom.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasPlayroom.setValue(!featuresFormGroup.controls.amenities.controls.hasPlayroom.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasPlayroom' | transloco}}</span>
                </label>

                <label for="isPenthouse" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="isPenthouse" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.isPenthouse.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.isPenthouse.setValue(!featuresFormGroup.controls.amenities.controls.isPenthouse.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.isPenthouse' | transloco}}</span>
                </label>
                
                <label for="hasGarden" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasGarden" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasGarden.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasGarden.setValue(!featuresFormGroup.controls.amenities.controls.hasGarden.value)">
                    <i class="icon-garden text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasGarden' | transloco}}</span>
                </label>


                <label for="hasPool" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasPool" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasPool.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasPool.setValue(!featuresFormGroup.controls.amenities.controls.hasPool.value)">
                    <i class="icon-pool text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasPool' | transloco}}</span>
                </label>

                <label for="hasStorageRoom" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasStorageRoom" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasStorageRoom.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasStorageRoom.setValue(!featuresFormGroup.controls.amenities.controls.hasStorageRoom.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasStorageRoom' | transloco}}</span>
                </label>

                <label for="petsAreAllowed" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="petsAreAllowed" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.petsAreAllowed.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.petsAreAllowed.setValue(!featuresFormGroup.controls.amenities.controls.petsAreAllowed.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.petsAreAllowed' | transloco}}</span>
                </label>

                <label for="hasTents" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="hasTents" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.hasTents.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.hasTents.setValue(!featuresFormGroup.controls.amenities.controls.hasTents.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.hasTents' | transloco}}</span>
                </label>

                <label for="isFloorApartment" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="isFloorApartment" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.isFloorApartment.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.isFloorApartment.setValue(!featuresFormGroup.controls.amenities.controls.isFloorApartment.value)">
                    <i class="icon-bed text-[40px]"></i>
                    <span class="">{{'shared.amenities.isFloorApartment' | transloco}}</span>
                </label>

                <label for="internalStaircase" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="internalStaircase" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.amenities.controls.internalStaircase.value === true"
                        (click)="featuresFormGroup.controls.amenities.controls.internalStaircase.setValue(!featuresFormGroup.controls.amenities.controls.internalStaircase.value)">
                    <i class="icon-stairs text-[40px]"></i>
                    <span class="">{{'shared.amenities.internalStaircase' | transloco}}</span>
                </label>
            </div>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.parking')}}</h3>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                {{t('labels.parkingType')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col md:flex-row gap-4">

                <label for="parkingTypeNone" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingTypeNone" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingType.value == parkingType.None"
                        (click)="featuresFormGroup.controls.parkingType.setValue(parkingType.None); featuresFormGroup.controls.parkingSpace.setValue(featuresFormGroup.controls.parkingType.value == parkingType.None ? 0 : featuresFormGroup.controls.parkingSpace.value)">
                    <i class="icon-car-garage text-[40px]"></i>
                    <span class="">{{'shared.enum.parkingType.' + parkingType.None.toString() | transloco}}</span>
                </label>


                <label for="parkingTypeClosed" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingTypeClosed" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingType.value == parkingType.Closed"
                        (click)="featuresFormGroup.controls.parkingType.setValue(parkingType.Closed)">
                    <i class="icon-car-garage text-[40px]"></i>
                    <span class="">{{'shared.enum.parkingType.' + parkingType.Closed.toString() | transloco}}</span>
                </label>


                <label for="parkingTypeUnderground" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingTypeUnderground" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingType.value == parkingType.Underground"
                        (click)="featuresFormGroup.controls.parkingType.setValue(parkingType.Underground)">
                    <i class="icon-car-garage text-[40px]"></i>
                    <span class="">{{'shared.enum.parkingType.' + parkingType.Underground.toString() |
                        transloco}}</span>
                </label>


                <label for="parkingTypeCarport" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingTypeCarport" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingType.value == parkingType.Carport"
                        (click)="featuresFormGroup.controls.parkingType.setValue(parkingType.Carport)">
                    <i class="icon-car-garage text-[40px]"></i>
                    <span class="">{{'shared.enum.parkingType.' + parkingType.Carport.toString() | transloco}}</span>
                </label>

            </div>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                {{t('labels.parkingSpace')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col md:flex-row gap-4">

                <label for="parkingSpaceOne" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingSpaceOne" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingSpace.value === 1"
                        (click)="featuresFormGroup.controls.parkingSpace.setValue(1)"
                        [disabled]="featuresFormGroup.controls.parkingType.value == parkingType.None">
                    <i class="icon-car text-[40px]"></i>
                    <span class="">1</span>
                </label>


                <label for="parkingSpaceTwo" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingSpaceTwo" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingSpace.value === 2"
                        (click)="featuresFormGroup.controls.parkingSpace.setValue(2)"
                        [disabled]="featuresFormGroup.controls.parkingType.value == parkingType.None">
                    <i class="icon-car text-[40px]"></i>
                    <span class="">2</span>
                </label>


                <label for="parkingSpaceThree" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingSpaceThree" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingSpace.value === 3"
                        (click)="featuresFormGroup.controls.parkingSpace.setValue(3)"
                        [disabled]="featuresFormGroup.controls.parkingType.value == parkingType.None">
                    <i class="icon-car text-[40px]"></i>
                    <span class="">3</span>
                </label>


                <label for="parkingSpaceFour" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingSpaceFour" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingSpace.value === 4"
                        (click)="featuresFormGroup.controls.parkingSpace.setValue(4)"
                        [disabled]="featuresFormGroup.controls.parkingType.value == parkingType.None">
                    <i class="icon-car text-[40px]"></i>
                    <span class="">4</span>
                </label>


                <label for="parkingSpaceFivePlus" class="visual-checkbox flex flex-col flex-1">
                    <input type="checkbox" id="parkingSpaceFivePlus" class="opacity-0 absolute h-0 w-0"
                        [checked]="featuresFormGroup.controls.parkingSpace.value >= 5"
                        (click)="featuresFormGroup.controls.parkingSpace.setValue(5)"
                        [disabled]="featuresFormGroup.controls.parkingType.value == parkingType.None">
                    <i class="icon-car text-[40px]"></i>
                    <span class="">5+</span>
                </label>

            </div>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.storage')}}</h3>

            <form id="property-wizard">
                <fieldset class="flex flex-col gap-3 group/input">
                    <label
                        class="font-semibold flex gap-2 items-center text-gray-950 group-disabled:text-gray-300">{{t('labels.storageAreaAmount')}}
                        <i class="icon-info-circle text-xl text-gray-900"></i></label>
                    <div class="flex flex-col lg:flex-row gap-4 items-center justify-start">
                        <!-- <div class="min-w-[300px] flex-1">
                            <label for="Floor space"></label>
                            <input type="range" id="Floor space" min="0" max="1000" value="0">
                        </div> -->
                        <input placeholder="" formControlName="storageAreaAmount" type="number"
                            onchange="if(this.value < 0) { this.value = 0; }"
                            class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    </div>
                </fieldset>
            </form>
            <form id="property-wizard">
                <fieldset class="flex flex-col gap-3 group/input">
                    <label
                        class="font-semibold flex gap-2 items-center text-gray-950 group-disabled:text-gray-300">{{t('labels.storageArea')}}
                        <i class="icon-info-circle text-xl text-gray-900"></i></label>
                    <div class="flex flex-col lg:flex-row gap-4 items-center justify-center">
                        <div class="min-w-[300px] flex-1">
                            <label for="storageAreaRangeInput"></label>
                            <input #storageAreaRangeInput type="range" id="storageAreaRangeInput" min="20" max="800"
                                step="1" [value]="featuresFormGroup.controls.storageArea.value"
                                (input)="featuresFormGroup.controls.storageArea.setValue(storageAreaRangeInput.value)">
                        </div>
                        <div
                            class="flex-1 justify-between flex max-w-[150px] bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                            <input placeholder="Label" formControlName="storageArea" type="number" min="20" max="800"
                                placeholder="122"
                                onchange="if(this.value < 20) { this.value = 20; } else if (this.value > 800) { this.value = 800; }">
                            <span class="text-gray-900 font-semibold">m²</span>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>

        <app-advisor-callback [advisor]="advisor"></app-advisor-callback>

        <div class="border-t-2 pt-7 flex justify-between">
            <button (click)="navigateToTab(propertyListingTab.Content)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                <i class="icon-arrow-left text-lg"></i>
                {{'property-listing.tabs.titleAndDescription' | transloco}}
            </button>
            <button (click)="navigateToTab(propertyListingTab.Construction)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                {{'property-listing.tabs.heatingAndConstruction' | transloco}}
                <i class="icon-arrow-right text-lg"></i>
            </button>
        </div>
    </div>
</div>