import { Component, Inject } from '@angular/core';
import { Modal, ModalOptions } from '@indice/ng-components';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-modal-image-item-large',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './modal-image-item-large.component.html',
  styleUrl: './modal-image-item-large.component.css'
})
export class ModalImageItemLargeComponent {
  public propertyTitle: string = "";
  public imageSrc: string = "";

  constructor(
    public modal: Modal,
    @Inject(ModalOptions) private options: ModalOptions<ModalImageItemLargeComponent>
  ) {
    this.imageSrc = options?.initialState?.imageSrc as string;
    this.propertyTitle = options?.initialState?.propertyTitle as string;
  }

  closeModal() {
    this.modal.hide({});
  }
}
