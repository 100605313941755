import { Component, EventEmitter, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-requests-unauthorized',
  standalone: true,
  imports: [RouterModule, TranslocoDirective],
  templateUrl: './requests-unauthorized.component.html',
})
export class RequestsUnauthorizedComponent {
  @Output() close = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() signUp = new EventEmitter();

  onClose = () => this.close.emit();

  onLogin = () => this.login.emit();
  onSignUp = () => this.signUp.emit();
}
