import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Modal } from '@indice/ng-components';
import { Location } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-exit-modal',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './exit-modal.component.html',
  styleUrl: './exit-modal.component.css'
})
export class ExitModalComponent {
  protected selfHidden: boolean = false;

  constructor(
    public modal: Modal, private _location: Location,
  ) {
  }
  selfHideSwitch(event: boolean) {
    this.selfHidden = event;
  }
  closeModal() {
    this.modal.hide({});
    this._location.back();
  }
}