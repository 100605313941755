import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { tap } from 'rxjs';

@Component({
  selector: 'app-seller-finish-page',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './finish-page.component.html',
  styleUrl: './finish-page.component.css'
})
export class SellerFinishPageComponent {
  @Input() PropertyTypes: string = ''
  @Input() SupportTypes: string[] = []
  PropertyTypesString: string = '';
  SupportTypeString: string = '';

  constructor(private translocoService: TranslocoService) {

  }
  ngOnChanges(): void {
    this.SetSupportTypesString();
  }
  ngOnInit(): void {
    this.translocoService.selectTranslateObject('onboarding.onboarding-seller').pipe(
      tap(t => {
        this.TranslatePropertyTypes(t);
        this.TranslateSupportTypes(t);
      })
    ).subscribe();
    this.SetSupportTypesString();
  }

  private TranslatePropertyTypes(t: any) {
    this.PropertyTypes = t?.buttons ? t.buttons[this.PropertyTypes] : this.PropertyTypes
  }
  private TranslateSupportTypes(t: any) {
    console.log(this.SupportTypes)

    this.SupportTypes = this.SupportTypes.map(sp => t[sp] ?? sp)
  }
  private SetSupportTypesString() {
    this.SupportTypeString = this.SupportTypes.join(', ');
  }
}
