import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BuyerInfoModel, InvoiceBuyerInfo, ProofOfPayment } from '../models';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ModalService } from '@indice/ng-components';
import { TermsConditionsModalComponent, TermsConditionsType } from '../../terms-conditions-modal/terms-conditions-modal.component';

@Component({
  selector: 'app-step-buyer-info',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoDirective, TranslocoPipe, NgxMaskDirective],
  templateUrl: './step-buyer-info.component.html',
  styleUrl: './step-buyer-info.component.css',
  providers: [provideNgxMask()],
})
export class StepBuyerInfoComponent implements OnInit {
  @Input() oldState: BuyerInfoModel | any;
  @Output() formDataEvent = new EventEmitter<BuyerInfoModel>();
  protected ProofOfPaymentEnum = ProofOfPayment;  //makes the enum available in the html template
  protected proofOfPayment: ProofOfPayment = ProofOfPayment.Receipt;
  protected buyerForm: FormGroup;
  protected mainForm: FormGroup | undefined;
  private invoiceSubForm: FormGroup | undefined;
  private loading: boolean = false;

  constructor(private _fb: FormBuilder,
    private _modalService: ModalService
  ) {
    this.buyerForm = this._fb.group({
      mainForm: this._fb.group({
        proofOfPayment: [this.proofOfPayment, Validators.required],
        fullName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        address: ['', Validators.required],
        zipCode: ['', [Validators.required, Validators.minLength(5)]],
        city: ['', Validators.required],
        mobilePhone: ['', [Validators.required, Validators.minLength(10)]],
        termsConditionsAccepted: [false, Validators.requiredTrue],
        landLine: [''],
      }),
    });

    this.buyerForm.addControl('invoiceSubForm', this._fb.group({
      taxNumber: ['', Validators.required],
      companyName: ['', Validators.required],
      taxOffice: ['', Validators.required],
      profession: ['', Validators.required],
    }));

    this.mainForm = this.buyerForm.get('mainForm') as FormGroup;
    this.invoiceSubForm = this.buyerForm.get('invoiceSubForm') as FormGroup;
  }
  ngOnInit(): void {
    this.loadOldState();
  }

  public onSubmit(): boolean {
    if (this.isFormValid()) {
      this.emitFormData();

      return true;
    }
    else {
      this.markAllAsTouched(this.buyerForm);

      return false;
    }
  }

  private isFormValid(): boolean {
    if (!this.mainForm) return false;

    let subFormIsValidOrExcluded = true;
    if (this.proofOfPayment != ProofOfPayment.Receipt) {
      subFormIsValidOrExcluded = this.invoiceSubForm?.valid ?? true;
    }

    const result = this.mainForm.valid && subFormIsValidOrExcluded;

    return result;
  }

  private emitFormData(): void {
    let result: BuyerInfoModel = {
      proofOfPayment: this.proofOfPayment,
      fullName: this.mainForm?.get('fullName')?.value,
      email: this.mainForm?.get('email')?.value,
      address: this.mainForm?.get('address')?.value,
      zipCode: this.mainForm?.get('zipCode')?.value,
      city: this.mainForm?.get('city')?.value,
      mobilePhone: this.mainForm?.get('mobilePhone')?.value,
      landLine: this.mainForm?.get('landLine')?.value,
      termsConditionsAccepted: this.mainForm?.get('termsConditionsAccepted')?.value,
    };

    if (this.proofOfPayment == ProofOfPayment.Invoice) {
      let invoiceData: InvoiceBuyerInfo = {
        taxNumber: this.invoiceSubForm?.get('taxNumber')?.value,
        companyName: this.invoiceSubForm?.get('companyName')?.value,
        taxOffice: this.invoiceSubForm?.get('taxOffice')?.value,
        profession: this.invoiceSubForm?.get('profession')?.value,
      }
      result.invoiceData = invoiceData;
    }

    this.formDataEvent.emit(result);
  }

  private markAllAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }

  protected onProofOfPaymentChange(type: ProofOfPayment) {
    this.proofOfPayment = type;
  }

  loadOldState() {
    if (this.oldState) {
      this.buyerForm?.get('mainForm.fullName')?.setValue(this.oldState.fullName);
      this.mainForm?.get('email')?.setValue(this.oldState.email);
      this.mainForm?.get('address')?.setValue(this.oldState.address);
      this.mainForm?.get('zipCode')?.setValue(this.oldState.zipCode);
      this.mainForm?.get('city')?.setValue(this.oldState.city);
      this.mainForm?.get('mobilePhone')?.setValue(this.oldState.mobilePhone);
      this.mainForm?.get('landLine')?.setValue(this.oldState.landLine);
      this.mainForm?.get('termsConditionsAccepted')?.setValue(this.oldState.termsConditionsAccepted);
    }
  }

  controlHasError(formAndControlName: string): boolean {
    let result = false;
    const formControl = this.buyerForm.get(formAndControlName);
    const isInvalid = formControl ? !formControl.valid : false;
    const isTouched = formControl ? formControl.touched : false;
    result = isInvalid && isTouched;
    return result;
  }

  protected openTermsAndConditions(event: any): void {
    return;
    this._modalService.show(TermsConditionsModalComponent, {
      initialState: {
        type: TermsConditionsType.payment,
      }
    });
  }
}
