import { Component, OnInit } from '@angular/core';
import { ToastComponent } from './toast.component';
import { Toast, ToasterService } from 'src/app/services/toaster.service';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'lib-toaster-container',
    templateUrl: './toast-container.component.html',
    standalone: true,
    imports: [ToastComponent, CommonModule]
})
export class ToasterContainerComponent implements OnInit {

    toasts: Toast[] = [];

    constructor(private toaster: ToasterService) { }

    ngOnInit(): void {
        this.toaster.resetToast();
        this.toaster.toast$
            .subscribe(toast => {
                this.toasts = [toast, ...this.toasts];
                setTimeout(() => this.toasts.pop(), toast.delay || 6000);
            });
    }

    remove(index: number): void {
        this.toasts = this.toasts.slice(0, index).concat(this.toasts.slice(index + 1));
    }

}