@if (isRequest) {
<app-complete-request-callback [requestData]="requestData" (continue)="continue()"
    (skip)="skip()"></app-complete-request-callback>
}
@else {
<div class="flex flex-grow container py-8" *transloco="let t; prefix: 'onboarding.onboarding-welcome'">
    <div class="flex flex-grow container py-8">
        <div class="flex-grow flex lg:flex-row flex-col lg:items-start items-center justify-center">
            <div class=" flex flex-1 justify-center">
                <div class="flex flex-col rfs:gap-8">
                    <div class="flex flex-col gap-2">
                        <h1 class="text-black text-[2.5rem] leading-[48px]">{{t('header')}}</h1>
                        <p class="text-gray-900">{{t('infoText')}}</p>
                    </div>
                    <ng-container *ngIf="nav != 'home'">
                        <div>
                            <form>
                                <button [routerLink]="['/'+ nav]" role="button" type="submit"
                                    class="btn-primary ">{{t('anchors.knowYourNeeds')}}
                                </button>
                            </form>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="nav == 'home'">
                        <div>
                            <form class="flex flex-col w-full gap-6 md:flex-row">
                                <button (click)="goToHome()" role="button"
                                    class="btn-primary no-wrap">{{t('anchors.goToHome')}}</button>

                                <button (click)="goToServices()" role="button"
                                    class="btn-secondary">{{t('anchors.discoverOurServices')}}</button>
                            </form>

                            <section class="mt-10 w-full">
                                <button
                                    class="expert-button-wrapper border flex-row items-center w-full justify-between">
                                    <div class="flex flex-row items-center gap-8">
                                        <div class="flex -space-x-2 rtl:space-x-reverse">
                                            <div class="w-6 h-6 rounded-full border-2 border-white overflow-hidden">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
                                                    alt="" class="">
                                            </div>
                                            <div class="w-6 h-6 rounded-full border-2 border-white overflow-hidden">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
                                                    alt="" class="">
                                            </div>
                                            <div class="w-6 h-6 rounded-full border-2 border-white overflow-hidden">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
                                                    alt="" class="">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="button-title">
                                                <span>{{t("talkToUnikoExperts.title")}}</span>
                                            </div>
                                            <p class="button-content">
                                                {{t("talkToUnikoExperts.info-text")}}
                                            </p>
                                        </div>
                                    </div>
                                    <i class="icon-chevron-right text-[#BD7456]"></i>
                                </button>
                            </section>

                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="flex-1 min-h-[355px] w-full on-boarding-bg-1"></div>
        </div>
    </div>
</div>
}