import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { MediaType, Photo, PhotoResultSet, PortalApiService, PropertyDetails } from 'src/app/services/portal-api.service';
import { Toast, ToastType, ToasterService } from 'src/app/services/toaster.service';
import { ModalService } from '@indice/ng-components';
import { ModalImageGalleryComponent } from './modal-image-gallery/modal-image-gallery.component';
import { Location } from '@angular/common';
import { RequestCallbackModalComponent } from 'src/app/shared/components/request-callback-modal/request-callback-modal.component';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestWalkthroughModalComponent } from 'src/app/shared/components/request-walkthrough-modal/request-walkthrough-modal.coponent';
import { TranslocoDirective } from '@jsverse/transloco';
import { ThreedViewerComponent } from 'src/app/shared/threed-viewer/threed-viewer.component';
@Component({
  selector: 'app-hero-section',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './hero-section.component.html',
  styleUrl: './hero-section.component.css'
})
export class HeroSectionComponent {

  @Input() model = new PropertyDetails();
  @Input() 
  set photos(value: PhotoResultSet | undefined) {
    this._photos = value;
    this.floorPlanExists = this.photos?.items?.some(x => x.type === MediaType.PropertyElevation) == true; 
  }
 
  get photos(): PhotoResultSet | undefined {
    return this._photos;
  }

  private _photos: PhotoResultSet | undefined;
  vrCode: string | undefined;
  toast$: Observable<Toast> = new Observable();

  public floorPlanExists = false;

  constructor(private modalService: ModalService, private _location: Location, private _authService: AuthService, @Inject(ToasterService) private _toastService: ToasterService, private portalService: PortalApiService) { }
  ngOnInit() {
    if (this.model?.defaultPhoto) {
      this.model.defaultPhoto.uri = this.model.defaultPhoto.photoItems?.find(pi => pi.uri?.includes('-xl.webp'))?.uri ?? undefined
    }
    // this.portalService.getPhotos(this.model.id ?? '').subscribe({
    //   next: (res) => { 
    //     this.photos = res;
    //     this.floorPlanExists = this.photos?.items?.some(x => x.type === MediaType.PropertyElevation) == true; 
    //   }
    // })
    this.portalService.getThreeDCode(this.model.id ?? '').subscribe({
      next: (result) => { this.vrCode = result?.spaceId }
    })
  }
  openModalComponent() {
    const imageGalleryModal = this.modalService.show(ModalImageGalleryComponent, {
      animated: true,
      initialState: {
        title: this.model?.title,
        photos: this.photos
      },
      keyboard: true
    });
    imageGalleryModal.onHidden?.subscribe((response: any) => {

    });
  }
  openFloorPlanComponent() {
    const items = this.photos?.items?.filter(x => x.type == MediaType.PropertyElevation);
    const imageGalleryModal = this.modalService.show(ModalImageGalleryComponent, {
      animated: true,
      initialState: {
        title: this.model?.title,
        photos: new PhotoResultSet({
          items: items,
          count: items?.length
        })
      },
      keyboard: true
    });
    imageGalleryModal.onHidden?.subscribe((response: any) => {

    });
  }
  openVrModalComponent() {
    const vrModal = this.modalService.show(ThreedViewerComponent, {
      animated: true,
      initialState: {
        SpaceId: this.vrCode
      },
      keyboard: true
    });
    vrModal.onHidden?.subscribe((response: any) => {

    });
  }
  GoBack() {
    this._location.back();
  }
}
