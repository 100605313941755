<section class="flex-1 flex justify-center items-center container pt-[72px]" *transloco="let t; prefix: 'shared.checkout.checkout-processing'">
    <div class="flex-col md:flex-row flex rounded-lg overflow-hidden max-w-[1024px]">
        <div class="p-8 flex flex-col rounded-lg">
            <!-- Loader 3 -->
            <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                <circle fill="none" stroke="#0BC582" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;" />
                <circle fill="#fff" stroke="#0BC582" stroke-width="3" cx="8" cy="54" r="6">
                    <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52"
                        repeatCount="indefinite" />



                </circle>

            </svg>


            <p class="max-w-[380px]">
                {{t('processingInfoText')}}</p>
        </div>
    </div>
</section>