import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NbgEcommerceService } from 'src/app/services/nbg-ecommerce.service';
import { Observable, catchError, switchMap, tap } from 'rxjs';
import { CardInfoModel } from '../models';
import { ToastType, ToasterService } from 'src/app/services/toaster.service';
import { ToasterContainerComponent } from "../../toast/toast-container.component";
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { CreateSessionRequest, CreateSessionResponse, ServicesPaymentsApiService, WayOfPayment } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-step-payment-details',
  standalone: true,
  templateUrl: './step-payment-details.component.html',
  styleUrl: './step-payment-details.component.css',
  imports: [CommonModule, ReactiveFormsModule, ToasterContainerComponent, TranslocoDirective, TranslocoPipe],
})
export class StepPaymentDetailsComponent implements OnInit {
  @Input() orderNumber: string = '';
  @Output() cardDataUpdatedEvent = new EventEmitter<CardInfoModel>();
  private paymentSession$: Observable<CreateSessionResponse> | undefined;
  private scriptLoader$: Observable<void> | undefined;
  protected paymentMethodEnum = WayOfPayment; //makes the enum available in the html template
  protected paymentMethod: WayOfPayment = WayOfPayment.CreditCard;
  protected loading = false;

  private cardData: CardInfoModel = new CardInfoModel()

  protected paymentForm: FormGroup<any>;
  protected cardForm: FormGroup<any>;
  protected formCardError = {
    cardHolder: false,
    cardNumber: false,
    expiry: false,
    securityCode: false,
  };

  constructor(
    private _paymentService: ServicesPaymentsApiService,
    private _nbgEcommerceService: NbgEcommerceService,
    private _fb: FormBuilder,
    @Inject(ToasterService) private _toastService: ToasterService
  ) {
    this.paymentForm = this._fb.group({
      paymentMethod: [this.paymentMethod, Validators.required],
      cardForm: this._fb.group({
        cardholderName: ['', Validators.required],
        cardNumber: ['', [Validators.required]],
        expiryMonth: ['', Validators.required],
        expiryYear: ['', Validators.required],
        securityCode: ['', Validators.required],
      })
    });
    this.cardForm = this.paymentForm.get('cardForm') as FormGroup;
  }

  ngOnInit(): void {
    const requestBody = new CreateSessionRequest({
      invoiceType: WayOfPayment.CreditCard,
    });
    this.paymentSession$ = this._paymentService.createGatewaySession(this.orderNumber, requestBody);

    this.paymentSession$.pipe(
      tap(t => this.loading = true),
      switchMap((s: CreateSessionResponse) => {
        this.cardData.sessionId = s.sessionId ?? '';
        this.cardData.merchant = s.merchant ?? '';
        this.cardData.version = s.version ?? '';
        this.cardData.transactionId = s.transactionId;
        this.cardData.orderReference = s.orderReference;
        return this._nbgEcommerceService.loadSessionScript(this.cardData.merchant, this.cardData.version).pipe(
          tap(t => { this.paymentSessionConfigure(this.cardData.sessionId ?? '') })
        );
      }),
      catchError((err: any) => {
        this._toastService.show(ToastType.Error, 'Failed to create session', err.message || 'Unknown error', 10000);

        throw err;
      }),
      tap(t => this.loading = false),
    ).subscribe({
      complete: () => { this.loading = false; },
      error: (err: any) => {
        console.log(err)
      },
    });
  }


  private markAllAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }
  public updateSessionFromCardForm(): void {
    PaymentSession.updateSessionFromForm('card');
  }

  private paymentSessionConfigure(sessionId: string) {
    PaymentSession.configure({
      session: sessionId,
      fields: {
        // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
        card: {
          number: "#card_number",
          securityCode: "#security_code",
          expiryMonth: "#expiry_month",
          expiryYear: "#expiry_year",
          nameOnCard: "#cardholder_name"
        }
      },
      //SPECIFY YOUR MITIGATION OPTION HERE
      frameEmbeddingMitigation: ["javascript"],
      callbacks: {
        initialized: (response: any) => {
          if (response.status === "ok") {
            console.log("Ready for payment")
          }
        },
        formSessionUpdate: (response: any) => {
          this.resetErrorFlags();
          if (response.status) {
            if ("ok" == response.status) {
              console.log("Session updated with data: " + response.session.id);
              console.log("Card Entered: " + response.sourceOfFunds.provided.card.number)
              console.log("Card Type: " + response.sourceOfFunds.provided.card.scheme)

              //check if the security code was provided by the user
              if (response.sourceOfFunds.provided.card.securityCode) {
                console.log("Security code was provided.");
              }
              //check if the user entered a MasterCard credit card
              if (response.sourceOfFunds.provided.card.scheme == 'MASTERCARD') {
                console.log("The user entered a MasterCard credit card.")
              }
              const sourceOfFunds = response.sourceOfFunds;

              this.cardData.nameOnCard = sourceOfFunds.provided.card.nameOnCard;
              this.cardData.number = sourceOfFunds.provided.card.number;
              const month = sourceOfFunds.provided.card.expiry.month;
              const year = sourceOfFunds.provided.card.expiry.year;
              this.cardData.expiryDate = `${month}/${year}`;

              this.emitCardData();

            } else if ("fields_in_error" == response.status) {
              console.log(response);
              // HANDLE FIELD ERROR RESPONSES
              console.log("Session update failed with field errors.");
              if (response.errors.cardNumber) {
                this.formCardError.cardNumber = true;
                console.log("Card number invalid or missing.");
              }
              if (response.errors.expiryYear) {
                this.formCardError.expiry = true;
                console.log("Expiry year invalid or missing.");
              }
              if (response.errors.expiryMonth) {
                this.formCardError.expiry = true;
                console.log("Expiry month invalid or missing.");
              }
              if (response.errors.securityCode) {
                this.formCardError.securityCode = true;
                console.log("Security code invalid.");
              }
            } else if ("request_timeout" == response.status) {
              this._toastService.show(ToastType.Error, 'Session update failed with request timeout', response.errors.message || 'Unknown error', 10000);
            } else if ("system_error" == response.status) {
              this._toastService.show(ToastType.Error, 'Session update failed with system error', response.errors.message || 'Unknown error', 10000);
              console.log("Session update failed with system error: " + response.errors.message);
            }
          } else {
            this._toastService.show(ToastType.Error, 'Session update failed', response || 'Unknown error', 10000);
            console.log("Session update failed: " + response);
          }
        },
      },
      interaction: {
        displayControl: {
          formatCard: "EMBOSSED",
          invalidFieldCharacters: "REJECT"
        }
      }
    });
  }
  resetErrorFlags() {
    this.formCardError = {
      cardHolder: false,
      cardNumber: false,
      expiry: false,
      securityCode: false
    }
  }

  public emitCardData(): void {
    this.cardDataUpdatedEvent.emit(this.cardData);
  }
  private isFormValid(): boolean {
    if (!this.paymentForm) return false;

    let subFormIsValidOrExcluded = true;
    if (this.paymentMethod != WayOfPayment.CreditCard) {
      subFormIsValidOrExcluded = this.cardForm?.valid ?? true;
    }

    const result = this.paymentForm.valid && subFormIsValidOrExcluded;

    return result;
  }

}
