<!-- <ng-container
    *transloco="
let t; prefix:
'requests.request-detail-technical-report.technical-report-basic-information.technical-report-exists-confirmation-modal'"> -->
<div class="w-full px-2">
    <div class="flex flex-col gap-6 p-4">
        Are you sure??
        <div class="flex justify-center mt-6">

            <div class="flex gap-4">
                <button (click)="onConfirm()" class="btn-primary">{{'shared.buttons.confirm' | transloco}}</button>
                <button (click)="closeModal()" class="btn-secondary">{{'shared.buttons.cancel' | transloco }}</button>
            </div>
        </div>


    </div>
</div>
<!-- </ng-container> -->