import { CommonModule } from '@angular/common';
import { Component, DestroyRef } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { FaqPage } from 'src/app/services/cms-api.service';
import { CmsService } from 'src/app/services/cms.service';
import { FaqItemComponent } from "./faq-item/faq-item.component";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-faq',
  standalone: true,
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css',
  imports: [CommonModule, FaqItemComponent, TranslocoDirective]
})
export class FaqComponent {
  model: FaqPage = new FaqPage();
  faqGroup: any;
  
  constructor(private modelService: CmsService, private destroyRef: DestroyRef) {
    
    this.modelService.getFaqContent().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(apiResponse => {
      this.model = apiResponse;
      this.faqGroup = this.model.groups;
    })
  }
}
