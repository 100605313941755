<div class="py-6 mx-auto max-w-[1056px] flex flex-col gap-6 w-full">
  <h3 class="h2 text-black">
    {{ "shared.headers.financeThisProperty" | transloco }}
  </h3>
  <div>
    <form>
      <div
        class="flex flex-col items-center md:flex-row justify-between gap-12"
      >
        <app-loan-calulator
          [propertyValue]="propertyValue"
        ></app-loan-calulator>
        <div class="flex flex-col items-center justify-center">
          <app-doughnut-chart [showEstimatedFees]="true"></app-doughnut-chart>
          <div
            class="mt-6 text-gray-700 font-semibold flex flex-row gap-2 text-xs"
          >
            ΕΚΠΛΗΡΩΘΗΚΕ ΑΠΟ
            <img src="assets/static/NBG-logo.png" alt="NBG Logo" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isLoggedIn" class="flex flex-col md:flex-row gap-6 items-center">
    <a
      class="btn-primary"
      [routerLink]="['/loan-application']"
      [queryParams]="{ property: propertyId }"
      >{{ "shared.anchors.loanApplication" | transloco }}</a
    >
    <a
      class="btn-secondary"
      [routerLink]="['/loan-eligibility']"
      [queryParams]="{ property: propertyId, propertyValue: propertyValue }"
    >
      {{ "shared.anchors.loanEligibility" | transloco }}
    </a>
  </div>
</div>
