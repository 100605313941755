<div class="flex-1" [formGroup]="ownershipFormGroup" *transloco="let t; prefix: 'property-listing.ownership'">
    <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
            <h2 class="h2 text-black">{{'property-listing.tabs.ownership' | transloco}}</h2>
        </div>

        <div class="flex flex-col">
            <h3 class="h3 text-black mb-6">{{t('subheaders.propertyUse')}}</h3>

            <fieldset class="flex flex-col gap-2 group/input max-w-[400px] mb-6">
                <label class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                    {{t('labels.kaek')}}
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                </label>
                <input #maskedKaekInput type="text" placeholder="XX XXX XX XX XXX" mask="00 000 00 00 000"
                    [formControl]="maskedKaekFormControl" [validation]="true"
                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                <span class="text-sm text-gray-700 disabled:text-gray-300">
                    {{t('infoText.kaek')}}
                </span>
            </fieldset>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.propertyUsageCategory')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">
                {{t('helperText.checkBoxes')}}
            </span>
            <div class="flex flex-col gap-2 mb-6">
                <label for="usageCommercial" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="usageCommercial" name="checkbox-selector-usageCommercial"
                        [checked]="ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Commercial"
                        (click)="ownershipFormGroup.controls.usageCategory.setValue(ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Commercial ? null : propertyUsageCategory.Commercial)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.propertyUsageCategory.' +
                            propertyUsageCategory.Commercial.toString() | transloco}}</span>
                        <span
                            class="checkbox-selector-text text-gray-700 font-normal text-sm">{{t('helperText.propertyUsageCommercial')}}</span>


                    </div>
                </label>
                <label for="usageResidential" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="usageResidential"
                        name="checkbox-selector-usageResidential"
                        [checked]="ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Residential"
                        (click)="ownershipFormGroup.controls.usageCategory.setValue(ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Residential ? null : propertyUsageCategory.Residential)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.propertyUsageCategory.' +
                            propertyUsageCategory.Residential.toString() | transloco}}</span>
                        <span
                            class="checkbox-selector-text text-gray-700 font-normal text-sm">{{t('helperText.propertyUsageResidential')}}</span>


                    </div>
                </label>
                <label for="usageIndustrial" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="usageIndustrial" name="checkbox-selector-usageIndustrial"
                        [checked]="ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Industrial"
                        (click)="ownershipFormGroup.controls.usageCategory.setValue(ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Industrial ? null : propertyUsageCategory.Industrial)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.propertyUsageCategory.' +
                            propertyUsageCategory.Industrial.toString() | transloco}}</span>


                    </div>
                </label>
                <label for="usageLand" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="usageLand" name="checkbox-selector-usageLand"
                        [checked]="ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Land"
                        (click)="ownershipFormGroup.controls.usageCategory.setValue(ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Land ? null : propertyUsageCategory.Land)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.propertyUsageCategory.' +
                            propertyUsageCategory.Land.toString() | transloco}}</span>


                    </div>
                </label>
                <label for="usageLeisure" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="usageLeisure" name="checkbox-selector-usageLeisure"
                        [checked]="ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Leisure"
                        (click)="ownershipFormGroup.controls.usageCategory.setValue(ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Leisure ? null : propertyUsageCategory.Leisure)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.propertyUsageCategory.' +
                            propertyUsageCategory.Leisure.toString() | transloco}}</span>


                    </div>
                </label>
                <label for="usageOther" class="checkbox-selector">
                    <input type="checkbox" class="hidden" id="usageOther" name="checkbox-selector-usageOther"
                        [checked]="ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Other"
                        (click)="ownershipFormGroup.controls.usageCategory.setValue(ownershipFormGroup.controls.usageCategory.value === propertyUsageCategory.Other ? null : propertyUsageCategory.Other)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.propertyUsageCategory.' +
                            propertyUsageCategory.Other.toString() | transloco}}</span>


                    </div>
                </label>
            </div>
        </div>
        <div class="flex flex-col">
            <h3 class="h3 text-black mb-6">{{t('subheaders.ownership')}}</h3>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.ownershipType')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('infoText.ownershipType')}}</span>
            <div class="flex flex-col gap-2 mb-6">
                <label role="button" for="ownershipFull" class="radio-selector">
                    <input type="radio" class="hidden" id="ownershipFull" name="ownershipType"
                        [checked]="ownershipFormGroup.controls.ownershipType.value === ownershipType.Full"
                        (click)="ownershipFormGroup.controls.ownershipType.setValue(ownershipFormGroup.controls.ownershipType.value === ownershipType.Full ? null : ownershipType.Full)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{'shared.enum.ownershipType.' +
                            ownershipType.Full.toString() | transloco}}</span>
                        <span class="radio-selector-text font-normal text-gray-700 text-sm">
                            {{t('helperText.ownershipTypeFull')}}
                        </span>
                    </div>
                </label>
                <label role="button" for="ownershipBare" class="radio-selector">
                    <input type="radio" class="hidden" id="ownershipBare" name="ownershipType"
                        [checked]="ownershipFormGroup.controls.ownershipType.value === ownershipType.Bare"
                        (click)="ownershipFormGroup.controls.ownershipType.setValue(ownershipFormGroup.controls.ownershipType.value === ownershipType.Bare ? null : ownershipType.Bare)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{'shared.enum.ownershipType.' +
                            ownershipType.Bare.toString() | transloco}}</span>
                        <span class="radio-selector-text font-normal text-gray-700 text-sm">
                            {{t('helperText.ownershipTypeBare')}}
                        </span>
                    </div>
                </label>
                <label role="button" for="ownershipUsufruct" class="radio-selector">
                    <input type="radio" class="hidden" id="ownershipUsufruct" name="ownershipType"
                        [checked]="ownershipFormGroup.controls.ownershipType.value === ownershipType.Split"
                        (click)="ownershipFormGroup.controls.ownershipType.setValue(ownershipFormGroup.controls.ownershipType.value === ownershipType.Split ? null : ownershipType.Split)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{'shared.enum.ownershipType.' +
                            ownershipType.Split.toString() | transloco}}</span>
                        <span class="radio-selector-text font-normal text-gray-700 text-sm">
                            {{t('helperText.ownershipTypeUsufruct')}}
                        </span>
                    </div>
                </label>
            </div>

            <form id="property-wizard" class="mb-6" [formGroup]="ownershipFormGroup">
                <fieldset class="flex flex-col gap-3 group/input">
                    <label class="font-semibold flex gap-2 items-center text-gray-950 group-disabled:text-gray-300">
                        {{t('labels.ownershipPercentage')}}
                        <i class="icon-info-circle text-xl text-gray-900"></i>
                    </label>
                    <div class="flex flex-col lg:flex-row gap-4 items-center justify-center">
                        <div class="min-w-[300px] flex-1">
                            <label for="ownershipPercentageRange"></label>
                            <input #ownershipPercentageRangeInput type="range" id="ownershipPercentageRange" min="1"
                                max="100" step="1" [value]="ownershipFormGroup.controls.ownershipPercentage.value ?? 1"
                                (input)="ownershipFormGroup.controls.ownershipPercentage.setValue(ownershipPercentageRangeInput.value)">
                        </div>
                        <input #ownershipPercentageInput type="number" min="1" max="100" placeholder="40"
                            class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                            formControlName="ownershipPercentage"
                            oninput="if(this.value < 1) { this.value = null; } else if (this.value > 100) { this.value = 100; }"
                            (input)="ownershipFormGroup.controls.ownershipPercentage.setValue(ownershipPercentageInput.value ? ownershipPercentageInput.value : null)">
                    </div>
                </fieldset>
            </form>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-3">
                {{t('labels.availability')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col gap-2 mb-6">
                <label role="button" for="availabilityRented" class="radio-selector">
                    <input type="radio" class="hidden" id="availabilityRented" name="availability"
                        [checked]="ownershipFormGroup.controls.availability.value === propertyAvailability.Rented"
                        (click)="ownershipFormGroup.controls.availability.setValue(ownershipFormGroup.controls.availability.value === propertyAvailability.Rented ? null : propertyAvailability.Rented)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{'shared.enum.availability.' +
                            propertyAvailability.Rented.toString() | transloco}}</span>
                    </div>
                </label>
                <label role="button" for="availabilityVacant" class="radio-selector">
                    <input type="radio" class="hidden" id="availabilityVacant" name="availability"
                        [checked]="ownershipFormGroup.controls.availability.value === propertyAvailability.Vacant"
                        (click)="ownershipFormGroup.controls.availability.setValue(ownershipFormGroup.controls.availability.value === propertyAvailability.Vacant ? null : propertyAvailability.Vacant)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{'shared.enum.availability.' +
                            propertyAvailability.Vacant.toString() | transloco}}</span>
                    </div>
                </label>
            </div>
        </div>

        <app-advisor-callback [advisor]="advisor"></app-advisor-callback>

        <div class="border-t-2 pt-7 flex justify-between">
            <button (click)="navigateToTab(propertyListingTab.Construction)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                <i class="icon-arrow-left text-lg"></i>
                {{'property-listing.tabs.settingAndSurroundings' | transloco}}
            </button>
            <button (click)="navigateToTab(propertyListingTab.Photos)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                {{'property-listing.tabs.photos' | transloco}}
                <i class="icon-arrow-right text-lg"></i>
            </button>
        </div>
    </div>
</div>