import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProgressStepperComponent } from 'src/app/shared/components/progress-stepper/progress-stepper.component';
import { NavigationButtonsComponent } from '../navigation-buttons/navigation-buttons.component';
import { ModalService, ToastType } from '@indice/ng-components';
import { ExitModalComponent } from './exit-modal/exit-modal.component';
import { CommonModule } from '@angular/common';
import { BuyerInfoModel, CardInfoModel } from './models';
import { StepBuyerInfoComponent } from "./step-buyer-info/step-buyer-info.component";
import { StepReviewComponent } from "./step-review/step-review.component";
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { ToasterContainerComponent } from "../toast/toast-container.component";
import { StepPaymentDetailsComponent } from "./step-payment-details/step-payment-details.component";
import { ToasterService } from 'src/app/services/toaster.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { Address, OrderResponse, ServicesPaymentsApiService, UpdateOrderRequest, WayOfPayment } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-checkout',
  standalone: true,
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css',
  imports: [CommonModule, ProgressStepperComponent, NavigationButtonsComponent, StepBuyerInfoComponent, StepReviewComponent, ToasterContainerComponent, StepPaymentDetailsComponent, TranslocoDirective]
})
export class CheckoutComponent implements OnInit {
  @ViewChild(StepBuyerInfoComponent) stepBuyerInfoComponent: StepBuyerInfoComponent | undefined;
  @ViewChild(StepPaymentDetailsComponent) stepPaymentDetailsComponent: StepPaymentDetailsComponent | undefined;
  @ViewChild(StepReviewComponent) stepReviewComponent: StepReviewComponent | undefined;
  protected Step: string = "Step1"
  protected Steps: string[] = [
    "1. BUYER INFO",
    "2. PAYMENT DETAILS",
    "3. OVERVIEW",
  ]
  protected step2Loaded: boolean = false;
  public orderNumber: string = '';
  protected order$: Observable<OrderResponse> | undefined;
  protected order: OrderResponse | undefined;
  public buyerInfoData: BuyerInfoModel | undefined;
  public cardData: CardInfoModel | undefined;
  public paymentDetailsData: any;
  private loading: boolean = false;

  constructor(
    private _paymentsApiService: ServicesPaymentsApiService,
    private _modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    @Inject(ToasterService) private _toastService: ToasterService
  ) { }

  ngOnInit(): void {
    this.order$ = this.activatedRoute.params.pipe(
      tap(t => this.loading = true),
      switchMap((params: Params) => {
        this.orderNumber = params['orderNumber'];
        return this._paymentsApiService.getOrderByNumber(this.orderNumber);
      })
    );
    this.order$.pipe(
      switchMap((res: OrderResponse) => {
        this.order = res;
        this.buyerInfoData = {
          email: res.billingAddress?.email,
          fullName: res.billingAddress?.fullName,
          address: res.billingAddress?.street,
          zipCode: res.billingAddress?.postalCode,
          city: res.billingAddress?.city,
          mobilePhone: res.billingAddress?.phoneNumber1,
          landLine: res.billingAddress?.phoneNumber2
        }
        return of(res);
      }),
      catchError((err: any) => {
        this._toastService.show(ToastType.Error, 'Failed to get order', err.message || 'Unknown error', 10000);

        throw err;
      })
    ).subscribe({
      complete: () => { this.loading = false; },
      error: (err: any) => {
        console.log(err)
      },
    });

  }

  protected submitStep1(): void {
    if (this.stepBuyerInfoComponent?.onSubmit()) {
      this.updateOrderBilling();
      this.step2Loaded = true;
      this.Goto("Step2")
    }
  }
  protected submitStep2(): void {
    this.stepPaymentDetailsComponent?.updateSessionFromCardForm()
  }
  protected submitStep3(): void {
    var optionalParams = {
      fullScreenRedirect: true,
    };
    const orderNumber = this.order?.number ?? '';
    const transactionId = this.cardData?.transactionId ?? '';
    ThreeDS.authenticatePayer(orderNumber, transactionId, () => { }, optionalParams);

  }
  protected submitStep4(): void {
    throw new Error('Method not implemented.');
  }

  protected Goto(step: string): void {
    this.Step = step;
  }

  protected handleCardData(cardData: CardInfoModel): void {
    this.cardData = cardData;
    this.Goto("Step3");
  }

  private updateOrderBilling(): void {
    const requestBody = new UpdateOrderRequest({
      wayOfPayment: WayOfPayment.CreditCard,
      billingAddress: new Address({
        email: this.buyerInfoData?.email,
        fullName: this.buyerInfoData?.fullName,
        street: this.buyerInfoData?.address,
        postalCode: this.buyerInfoData?.zipCode,
        city: this.buyerInfoData?.city,
        phoneNumber1: this.buyerInfoData?.mobilePhone,
        phoneNumber2: this.buyerInfoData?.landLine ?? undefined,
      })
    });

    this._paymentsApiService.updateMyOrder(this.orderNumber, requestBody).pipe(
      catchError((err: any) => {
        this._toastService.show(ToastType.Error, 'Failed to update order', err.message || 'Unknown error', 10000);

        throw err;
      }),
      tap((response: OrderResponse) => {
        this.order = response;
      })

    ).subscribe({
      complete: () => { this.loading = false; },
      error: (err: any) => {
        console.log(err)
      },
    });
  }
  protected handleFormData(formData: BuyerInfoModel): void {
    this.buyerInfoData = formData;
  }
  protected OpenModalComponent(): void {
    const exitModal = this._modalService.show(ExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }

}
