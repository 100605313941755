import { Injectable, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { CmsApiService, FaqPage } from "./cms-api.service";

export const CMS_API_KEY = new InjectionToken<string>('CMS_API_KEY');

@Injectable({
    providedIn: 'root'
})
export class CmsService {

    constructor(private _cmsApi: CmsApiService) {}

    public getFaqContent(): Observable<FaqPage> {
        return this._cmsApi.getFaqResults();
    }
}