import { KindOfSupportPreference, LookingForPropertyOfType } from "src/app/services/portal-api.service";

export class TogglePropertyTypeButton {
  id: LookingForPropertyOfType | undefined;
  title: string = "";
  icon: string = "";
  isToggled: boolean = false;
}


export class ToggleSupportingServicesTypeButton {
  id: KindOfSupportPreference = KindOfSupportPreference.Unspecified;
  title: string = "";
  icon?: string = "";
  isToggled: boolean = false;
}

export class OnboardingData {
  propertyType: LookingForPropertyOfType | undefined;
  services: KindOfSupportPreference[] = [];
}

export class PlaceViewModel {
  FormatedAddress: any
  Country: any
  Prefecture: any
  AdministrativeAreas: any
  StreetName: any
  StreetNumber: any
  PostalCode: any
  Lon: any
  Lat: any
}