<ng-container *transloco="let t; prefix: 'loan-wizard'">
    <ng-container *ngIf="!showResultPage">
        <div class="flex-1">
            <div class="container mb-[20px]">
                <progress-stepper [StepNo]="this.stepKeys.indexOf(this.activeStep) + 1"
                    [Steps]="stepValues"></progress-stepper>
            </div>
            <ng-container [formGroup]="loanForm">
                <div class="container flex flex-row flex-1 gap-8">
                    <div class="bg-white rounded-[1rem] p-8 flex flex-col gap-8 min-h-[570px] flex-1">
                        <ng-container *ngIf="activeStep=='loan'" [formGroupName]="'loan'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('reviewLoanCalculation')}}</h4>

                            <app-loan-calulator [propertyValue]="propertyValue"></app-loan-calulator>
                        </ng-container>

                        <ng-container *ngIf="activeStep=='household'" [formGroupName]="'household'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('yourDetails')}}</h4>
                            <div class="flex flex-col gap-8">
                                <h5 class="text-black font-semibold text-2xl">{{t('tellAboutFamily')}}</h5>
                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label tooltip="'this is my tooltip'"
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatYearBorn')}}<span class="text-error">
                                                *</span></div>
                                        <i class="relative icon-info-circle text-gray-700
                                        text-base"></i>
                                    </label>
                                    <div
                                        class="border border-gray-300 rounded-full bg-gray-50 overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">{{t('year')}}</span>
                                        <input [formControlName]="'yearBorn'"
                                            class="text-gray-500 bg-transparent flex-1 outline-none">
                                    </div>
                                    <div
                                        class="py-[11px] px-4 flex flex-row items-center gap-6 bg-gray-100 rounded-s text-gray-900 text-sm">
                                        <i class="icon-info-circle text-gray-700 text-base"></i>
                                        {{t('payBackBy75')}}
                                    </div>
                                    <span *ngIf="hasError('household.yearBorn')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>

                                <div class="flex flex-col gap-4">
                                    <div class="text-black flex flex-row items-center font-semibold gap-4">
                                        <div>{{t('whatMarriageStatus')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </div>
                                    <label role="button" for="radio-marriage-status-1" class="radio-selector">
                                        <input [formControlName]="'marriageStatus'" [value]="'single'" type="radio"
                                            class="hidden" id="radio-marriage-status-1">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{t('single')}}</span>
                                        </div>
                                    </label>
                                    <label role="button" for="radio-marriage-status-2" class="radio-selector">
                                        <input [formControlName]="'marriageStatus'" [value]="'married'" type="radio"
                                            class="hidden" id="radio-marriage-status-2">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{t('married')}}</span>
                                        </div>
                                    </label>
                                    <label role="button" for="radio-marriage-status-3" class="radio-selector">
                                        <input [formControlName]="'marriageStatus'" [value]="'cohabitation'"
                                            type="radio" class="hidden" id="radio-marriage-status-3">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{t('cohabitation')}}</span>
                                        </div>
                                    </label>
                                    <span *ngIf="hasError('household.marriageStatus')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </div>

                                <div class="flex flex-col gap-4 mb-[4.5rem]">
                                    <div class="text-black flex flex-row items-center font-semibold gap-4">
                                        <div>{{t('howManyChildren')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </div>
                                    <div class="flex flex-row justify-between items-center">
                                        <label role="button" for="children-radio-0" class="radio-selector">
                                          <input [formControlName]="'children'" [value]="'0'" type="radio" class="hidden"
                                              id="children-radio-0" name="children">
                                          <div class="radio-selector-content">
                                              <span class="radio-selector-text font-semibold">0</span>
                                          </div>
                                        </label>
                                        <label role="button" for="radio-children-1" class="radio-selector">
                                            <input [formControlName]="'children'" [value]="'1'" type="radio"
                                                class="hidden" id="radio-children-1">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">1</span>
                                            </div>
                                        </label>
                                        <label role="button" for="radio-children-2" class="radio-selector">
                                            <input [formControlName]="'children'" [value]="'2'" type="radio"
                                                class="hidden" id="radio-children-2">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">2</span>
                                            </div>
                                        </label>
                                        <label role="button" for="radio-children-3" class="radio-selector">
                                            <input [formControlName]="'children'" [value]="'3'" type="radio"
                                                class="hidden" id="radio-children-3">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">3</span>
                                            </div>
                                        </label>
                                        <label role="button" for="radio-children-4" class="radio-selector">
                                            <input [formControlName]="'children'" [value]="'4'" type="radio"
                                                class="hidden" id="radio-children-4">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">4</span>
                                            </div>
                                        </label>
                                        <label role="button" for="radio-children-5" class="radio-selector">
                                            <input [formControlName]="'children'" [value]="'5+'" type="radio"
                                                class="hidden" id="radio-children-5">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">5+</span>
                                            </div>
                                        </label>
                                    </div>
                                    <span *ngIf="hasError('household.children')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </div>

                                <h5 class="text-black font-semibold text-2xl">{{t('tellHouseholdFinances')}}</h5>

                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatIncomePerYear')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </label>
                                    <div
                                        class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">€</span>
                                        <input [formControlName]="'householdIncomePerYear'" mask="separator.2"
                                            [thousandSeparator]="thousandSeparator"
                                            class="text-gray-500 bg-transparent outline-none flex-1">
                                    </div>
                                    <div class="flex flex-row items-center gap-6 text-gray-500 text-sm">
                                        {{t('helpHowConfortablePay')}}
                                    </div>
                                    <span *ngIf="hasError('household.householdIncomePerYear')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>

                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatTaxPerYear')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </label>
                                    <div class="flex flex-row items-center gap-1 text-gray-500 text-sm">
                                        {{t('calculateTaxAt')}}<a href="https://aftertax.gr/" target="_blank"
                                            class="text-secondary underline">AfterTax.gr</a>
                                    </div>
                                    <div
                                        class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">€</span>
                                        <input [formControlName]="'taxPerYear'" mask="separator.2"
                                            [thousandSeparator]="thousandSeparator"
                                            class="text-gray-500 bg-transparent outline-none flex-1">
                                    </div>
                                    <span *ngIf="hasError('household.taxPerYear')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>

                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatHouseholdDebt')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </label>
                                    <div
                                        class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">€</span>
                                        <input [formControlName]="'householdDebt'" mask="separator.2"
                                            [thousandSeparator]="thousandSeparator"
                                            class="text-gray-500 bg-transparent outline-none flex-1">
                                    </div>
                                    <div class="flex flex-row items-center gap-6 text-gray-500 text-sm">
                                        {{t('soWeKnowOtherDebts')}}
                                    </div>
                                    <span *ngIf="hasError('household.householdDebt')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>

                                <div class="flex flex-col gap-4">
                                    <div class="text-black flex flex-row items-center font-semibold gap-4">
                                        <div>{{t('areYouNbgCustomer')}}<span class="text-error">
                                                *</span></div>
                                        <i tooltip="tooltip" class="icon-info-circle text-gray-700 text-base"></i>
                                    </div>
                                    <label role="button" for="radio-selector-is-nbg-customer-1" class="radio-selector">
                                        <input [formControlName]="'isNbgCustomer'" [value]="true" type="radio"
                                            class="hidden" id="radio-selector-is-nbg-customer-1">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{'shared.answers.yes' |
                                                transloco
                                                }}</span>
                                        </div>
                                    </label>
                                    <label role="button" for="radio-selector-is-nbg-customer-2" class="radio-selector">
                                        <input [formControlName]="'isNbgCustomer'" [value]="false" type="radio"
                                            class="hidden" id="radio-selector-is-nbg-customer-2">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{'shared.answers.no' |
                                                transloco
                                                }}</span>
                                        </div>
                                    </label>
                                    <span *ngIf="hasError('household.isNbgCustomer')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="activeStep=='guarantor'" [formGroupName]="'guarantor'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('yourGuarantor')}}</h4>
                            <div class="flex flex-col gap-8">
                                <h5 class="text-black font-semibold text-2xl">{{t('addFinancialSupporter')}}</h5>
                                <div class="flex flex-col gap-4 mb-[4.5rem]">
                                    <div class="text-black flex flex-row items-center font-semibold gap-4">
                                        <div>{{t('wantGuarantor')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </div>
                                    <label role="button" for="radio-want-guarantor-1" class="radio-selector">
                                        <input [formControlName]="'wantGuarantor'" [value]="true" type="radio"
                                            class="hidden" id="radio-want-guarantor-1">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{'shared.answers.yes' |
                                                transloco
                                                }}</span>
                                        </div>
                                    </label>
                                    <label role="button" for="radio-want-guarantor-2" class="radio-selector">
                                        <input [formControlName]="'wantGuarantor'" [value]="false" type="radio"
                                            class="hidden" id="radio-want-guarantor-2">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{'shared.answers.no' |
                                                transloco
                                                }}</span>
                                        </div>
                                    </label>
                                    <span *ngIf="hasError('guarantor.wantGuarantor')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </div>

                                <ng-container *ngIf="loanForm.get('guarantor.wantGuarantor')?.value === true">
                                    <h5 class="text-black font-semibold text-2xl">{{t('tellAboutGuarantor')}}</h5>

                                    <fieldset class="flex flex-col gap-2 group/input">
                                        <label
                                            class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                            <div>{{t('whatYearBornGuarantor')}}<span class="text-error">
                                                    *</span></div><i
                                                class="icon-info-circle text-gray-700 text-base"></i>
                                        </label>
                                        <div
                                            class="border border-gray-300 rounded-full bg-gray-50 overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                            <span class="text-gray-900 font-semibold">{{t('year')}}</span>
                                            <input [formControlName]="'yearBornGuarantor'"
                                                class="text-gray-500 bg-transparent flex-1 outline-none">
                                        </div>

                                        <span *ngIf="hasError('guarantor.yearBornGuarantor')" class="text-error">{{
                                            'shared.error.requiredField'
                                            | transloco}}</span>
                                    </fieldset>

                                    <div class="flex flex-col gap-4">
                                        <div class="text-black flex flex-row items-center font-semibold gap-4">
                                            <div>{{t('howManyChildrenGuarantor')}}<span class="text-error">
                                                    *</span></div><i
                                                class="icon-info-circle text-gray-700 text-base"></i>
                                        </div>
                                        <div class="flex flex-row justify-between items-center">
                                            <label role="button" for="radio-children-guarantor-0"
                                                class="radio-selector">
                                                <input [formControlName]="'childrenGuarantor'" [value]="'0'"
                                                    type="radio" class="hidden" id="radio-children-guarantor-0">
                                                <div class="radio-selector-content">
                                                    <span class="radio-selector-text font-semibold">0</span>
                                                </div>
                                            </label>
                                            <label role="button" for="radio-children-guarantor-1"
                                                class="radio-selector">
                                                <input [formControlName]="'childrenGuarantor'" [value]="'1'"
                                                    type="radio" class="hidden" id="radio-children-guarantor-1">
                                                <div class="radio-selector-content">
                                                    <span class="radio-selector-text font-semibold">1</span>
                                                </div>
                                            </label>
                                            <label role="button" for="radio-children-guarantor-2"
                                                class="radio-selector">
                                                <input [formControlName]="'childrenGuarantor'" [value]="'2'"
                                                    type="radio" class="hidden" id="radio-children-guarantor-2">
                                                <div class="radio-selector-content">
                                                    <span class="radio-selector-text font-semibold">2</span>
                                                </div>
                                            </label>
                                            <label role="button" for="radio-children-guarantor-3"
                                                class="radio-selector">
                                                <input [formControlName]="'childrenGuarantor'" [value]="'3'"
                                                    type="radio" class="hidden" id="radio-children-guarantor-3">
                                                <div class="radio-selector-content">
                                                    <span class="radio-selector-text font-semibold">3</span>
                                                </div>
                                            </label>
                                            <label role="button" for="radio-children-guarantor-4"
                                                class="radio-selector">
                                                <input [formControlName]="'childrenGuarantor'" [value]="'4'"
                                                    type="radio" class="hidden" id="radio-children-guarantor-4">
                                                <div class="radio-selector-content">
                                                    <span class="radio-selector-text font-semibold">4</span>
                                                </div>
                                            </label>
                                            <label role="button" for="radio-children-guarantor-5"
                                                class="radio-selector">
                                                <input [formControlName]="'childrenGuarantor'" [value]="'5+'"
                                                    type="radio" class="hidden" id="radio-children-guarantor-5">
                                                <div class="radio-selector-content">
                                                    <span class="radio-selector-text font-semibold">5+</span>
                                                </div>
                                            </label>
                                        </div>
                                        <span *ngIf="hasError('guarantor.childrenGuarantor')" class="text-error">{{
                                            'shared.error.requiredField'
                                            | transloco}}</span>
                                    </div>

                                    <div class="flex flex-col gap-4 mb-[4.5rem]">
                                        <div class="text-black flex flex-row items-center font-semibold gap-4">
                                            <div>{{t('whatMarriageStatusGuarantor')}}<span class="text-error">
                                                    *</span></div><i
                                                class="icon-info-circle text-gray-700 text-base"></i>
                                        </div>
                                        <label role="button" for="radio-marriage-status-guarantor-1"
                                            class="radio-selector">
                                            <input [formControlName]="'marriageStatusGuarantor'" [value]="'single'"
                                                type="radio" class="hidden" id="radio-marriage-status-guarantor-1">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">{{t('single')}}</span>
                                            </div>
                                        </label>
                                        <label role="button" for="radio-marriage-status-guarantor-2"
                                            class="radio-selector">
                                            <input [formControlName]="'marriageStatusGuarantor'" [value]="'married'"
                                                type="radio" class="hidden" id="radio-marriage-status-guarantor-2">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">{{t('married')}}</span>
                                            </div>
                                        </label>
                                        <label role="button" for="radio-marriage-status-guarantor-3"
                                            class="radio-selector">
                                            <input [formControlName]="'marriageStatusGuarantor'"
                                                [value]="'cohabitation'" type="radio" class="hidden"
                                                id="radio-marriage-status-guarantor-3">
                                            <div class="radio-selector-content">
                                                <span
                                                    class="radio-selector-text font-semibold">{{t('cohabitation')}}</span>
                                            </div>
                                        </label>
                                        <span *ngIf="hasError('guarantor.marriageStatusGuarantor')"
                                            class="text-error">{{ 'shared.error.requiredField'
                                            | transloco}}</span>
                                    </div>

                                    <h5 class="text-black font-semibold text-2xl">{{t('tellHouseholdFinances')}}</h5>

                                    <fieldset class="flex flex-col gap-2 group/input">
                                        <label
                                            class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                            <div>{{t('whatIncomePerYearGuarantor')}}<span class="text-error">
                                                    *</span></div><i
                                                class="icon-info-circle text-gray-700 text-base"></i>
                                        </label>
                                        <div
                                            class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                            <span class="text-gray-900 font-semibold">€</span>
                                            <input [formControlName]="'householdIncomeGuarantor'" mask="separator.2"
                                                [thousandSeparator]="thousandSeparator"
                                                class="text-gray-500 bg-transparent outline-none flex-1">
                                        </div>
                                        <span *ngIf="hasError('guarantor.householdIncomeGuarantor')"
                                            class="text-error">{{ 'shared.error.requiredField'
                                            | transloco}}</span>
                                    </fieldset>

                                    <fieldset class="flex flex-col gap-2 group/input">
                                        <label
                                            class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                            <div>{{t('whatTaxPerYearGuarantor')}}<span class="text-error">
                                                    *</span></div><i
                                                class="icon-info-circle text-gray-700 text-base"></i>
                                        </label>
                                        <div
                                            class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                            <span class="text-gray-900 font-semibold">€</span>
                                            <input [formControlName]="'taxPerYearGuarantor'" mask="separator.2"
                                                [thousandSeparator]="thousandSeparator"
                                                class="text-gray-500 bg-transparent outline-none flex-1">
                                        </div>
                                        <span *ngIf="hasError('guarantor.taxPerYearGuarantor')" class="text-error">{{
                                            'shared.error.requiredField'
                                            | transloco}}</span>
                                    </fieldset>

                                    <fieldset class="flex flex-col gap-2 group/input">
                                        <label
                                            class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                            <div>{{t('whatHouseholdDebtGuarantor')}}<span class="text-error">
                                                    *</span></div><i
                                                class="icon-info-circle text-gray-700 text-base"></i>
                                        </label>
                                        <div
                                            class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                            <span class="text-gray-900 font-semibold">€</span>
                                            <input [formControlName]="'householdDebtGuarantor'" mask="separator.2"
                                                [thousandSeparator]="thousandSeparator"
                                                class="text-gray-500 bg-transparent outline-none flex-1">
                                        </div>
                                        <span *ngIf="hasError('guarantor.householdDebtGuarantor')" class="text-error">{{
                                            'shared.error.requiredField'
                                            | transloco}}</span>
                                    </fieldset>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="max-w-[360px] w-full">
                        <app-doughnut-chart [showEstimatedFees]="true"></app-doughnut-chart>
                    </div>
                </div>
                <ng-container *ngIf="activeStep=='loan'">
                    <app-navigation-buttons [HideSkip]=" true" [HidePrevious]="true"
                        (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                        (NextButtonClicked)="onNextClick()" [CustomNextTitle]=""></app-navigation-buttons>
                </ng-container>
                <ng-container *ngIf="activeStep=='household'">
                    <app-navigation-buttons [HideSkip]=" true" [HidePrevious]="false"
                        (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                        (NextButtonClicked)="onNextClick()" [CustomNextTitle]=""></app-navigation-buttons>
                </ng-container>
                <ng-container *ngIf="activeStep=='guarantor'">
                    <app-navigation-buttons [HideSkip]=" true" [HidePrevious]="false"
                        (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                        (NextButtonClicked)="onNextClick()"
                        [CustomNextTitle]="t('getFullResultButton')"></app-navigation-buttons>
                </ng-container>

            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="showResultPage">
        <app-loan-eligibility-result [resultData]="loanResultData" [formData]="loanFormData">
        </app-loan-eligibility-result>
    </ng-container>
</ng-container>

<lib-toaster-container></lib-toaster-container>
<!-- <input mask="separator.2" separatorLimit="10000000000000" [thousandSeparator]="thousandSeparator" /> -->
