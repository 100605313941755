<form class="w-full flex flex-col justify-center items-center gap-5">
    <fieldset class="flex flex-col gap-2 group/input w-full items-center relative">
        <label
            class="max-w-[592px] w-full flex flex-row text-tone-09 bg-tone-00 rounded-full border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300">
            <input class="outline-none bg-transparent w-full flex-1" type="text" autocomplete="off"
                (click)="onInputClick()" (keyup)="onInputKeyUp($event)" (keyup.enter)="emitSearchEvent()"
                [value]="value || ''" [placeholder]="placeHolder || ''">
            <i class="icon-search text-[2rem] cursor-pointer" (click)="emitSearchEvent()"></i>
        </label>
        <div *ngIf="showResults && items && items.length > 0 && !HideList"
            class="max-w-[592px] w-full menu-item-search-card absolute z-10 top-full translate-y-[5px]"
            (clickOutside)="onInputClickOutside()">
            <button type="button" *ngFor="let item of items; let i = index;"
                class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light border-b border-tone-05 py-[2px] px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full"
                (click)="onListItemSelected(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                    [ngTemplateOutletContext]="{ $implicit: item}">
                </ng-container>
            </button>
        </div>
        <div *ngIf="items && !busy && !resultsFound && !HideList"
            class="max-w-[592px] w-full menu-item-search-card absolute z-10 top-full translate-y-[5px]"
            (clickOutside)="onInputClickOutside()">
            <ng-container [ngTemplateOutlet]="noResultsTemplate || defaultNoResultsTemplate"
                [ngTemplateOutletContext]="{ $implicit: searchTerm}">
            </ng-container>
        </div>
    </fieldset>
</form>

<ng-template #defaultNoResultsTemplate let-searchTerm>
    <div class="flex items-center" *transloco="let t; prefix: 'shared.autocomplete.google-maps-search-autocomplete-single'">
        <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{t('text.noResultsFoundFor')}}
            <i>{{searchTerm}}</i>
        </span>
    </div>
</ng-template>
<ng-template #defaultItemTemplate let-item>
    <div class="flex items-center">
        <i class="group-disabled/menu-item:text-tone-06 icon-location mr-4"></i>
        <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{item.description}}</span>
    </div>
</ng-template>