import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Modal, ModalOptions } from '@indice/ng-components';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-terms-conditions-modal',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, TranslocoPipe],
  templateUrl: './terms-conditions-modal.component.html',
  styleUrl: './terms-conditions-modal.component.css'
})
export class TermsConditionsModalComponent {
  public type: TermsConditionsType | undefined;

  constructor(
    private _modal: Modal,
    @Inject(ModalOptions) private _options: ModalOptions<TermsConditionsModalComponent>,) {
    this.type = _options.initialState?.type;
  }


  protected close() {
    this._modal.hide();
  }
}
export enum TermsConditionsType {
  payment,
  loan,
}
