import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detail-table-row',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './detail-table-row.component.html',
  styleUrl: './detail-table-row.component.css'
})
export class DetailTableRowComponent {
  @Input() title: string = "";
  @Input() text: string | any;
}
