import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { GeoSearchAddressMessage, GeoSearchBoundsMessage, GeoSearchMessage } from '../models';
import { NumberSymbol } from '@angular/common';
import { PlaceViewModel } from 'src/app/services/property.service';

export interface GeoLocation {
  latitude: number;
  longitude: number;
  elevation?: number;
}

export interface GeoAddress {
  postalCode: string;
  streetNumber: string;
  streetName: string;
  country: string;
  city: string;
  location: GeoLocation;
}
export interface GeoRouteDuration {
  address: string;
  walking: string;
  driving: string;
  bicycling: string;
  transit: string;
  airport: string;
  distanceKm: string;
  endLocation: google.maps.LatLngLiteral | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {
  private _geocoder: google.maps.Geocoder = new google.maps.Geocoder();
  private _autocomplete: any;
  private _autocompleteObservable: Observable<GeoAddress> | undefined;
  private _directionsService: google.maps.DirectionsService =
    new google.maps.DirectionsService();
  constructor(private _ngZone: NgZone) { }

  public autocomplete(input: HTMLInputElement): Observable<GeoAddress> {
    if (this._autocompleteObservable) {
      return this._autocompleteObservable;
    }
    this._autocompleteObservable = new Observable<GeoAddress>(
      (observer: Subscriber<GeoAddress>) => {
        if (!this._autocomplete) {
          this._autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: { country: 'gr' },
          });
        }
        this._autocomplete.addListener('place_changed', () => {
          this._ngZone.run(() => {
            const place: google.maps.places.PlaceResult =
              this._autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
              observer.next({
                postalCode: this.findAddressComponent(place, 'postal_code'),
                streetNumber: this.findAddressComponent(place, 'street_number'),
                streetName: this.findAddressComponent(place, 'route'),
                country: this.findAddressComponent(place, 'country'),
                city: this.findAddressComponent(place, 'locality'),
                location: {
                  latitude: place.geometry.location.lat(),
                  longitude: place.geometry.location.lng(),
                },
              } as GeoAddress);
            } else {
              observer.next({} as GeoAddress);
            }
          });
        });
      }
    );
    return this._autocompleteObservable;
  }

  public getAddress(location: GeoLocation): Observable<GeoAddress> {
    return new Observable<GeoAddress>((observer: Subscriber<GeoAddress>) => {
      this._geocoder.geocode(
        { location: { lat: location.latitude, lng: location.longitude } },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results) {
            observer.next({
              postalCode: this.findAddressComponent(results[0], 'postal_code'),
              streetNumber: this.findAddressComponent(
                results[0],
                'street_number'
              ),
              streetName: this.findAddressComponent(results[0], 'route'),
              country: this.findAddressComponent(results[0], 'country'),
              city: this.findAddressComponent(results[0], 'locality'),
              location: {
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng(),
              },
            } as GeoAddress);
          } else {
            observer.error(status);
          }
          observer.complete();
        }
      );
    });
  }

  private findAddressComponent(address: any, type: string): string | undefined {
    const component = address.address_components.find(
      (x: { types: string[] }) => x.types.indexOf(type) > -1
    );
    if (component) {
      return component.long_name;
    }
    return undefined;
  }
  public getRouteDurations(
    origin: google.maps.LatLngLiteral,
    destination: google.maps.LatLngLiteral
  ): Promise<GeoRouteDuration> {
    return new Promise<GeoRouteDuration>((resolve, reject) => {
      const routeRequest: google.maps.DirectionsRequest = {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      const geoRouteDuration: GeoRouteDuration = {
        address: '-',
        distanceKm: '-',
        walking: '-',
        driving: '-',
        bicycling: '-',
        transit: '-',
        airport: '-',
        endLocation: destination,
      };

      this._directionsService.route(routeRequest, (response, status) => {
        if (
          status === google.maps.DirectionsStatus.OK &&
          response &&
          response.routes &&
          response.routes[0].legs &&
          response.routes[0].legs[0].duration &&
          response.routes[0].legs[0].distance
        ) {
          geoRouteDuration.driving = response.routes[0].legs[0].duration.text;
          geoRouteDuration.distanceKm =
            response.routes[0].legs[0].distance.text;
          geoRouteDuration.address = response.routes[0].legs[0].end_address;
        }
        if (geoRouteDuration.address == '-') {
          reject(new Error('Unable to find address'));
        }
        routeRequest.travelMode = google.maps.TravelMode.WALKING;
        this._directionsService.route(routeRequest, (response, status) => {
          if (
            status === google.maps.DirectionsStatus.OK &&
            response &&
            response.routes &&
            response.routes[0].legs &&
            response.routes[0].legs[0].duration
          ) {
            geoRouteDuration.walking = response.routes[0].legs[0].duration.text;
          }
          routeRequest.travelMode = google.maps.TravelMode.BICYCLING;
          this._directionsService.route(routeRequest, (response, status) => {
            if (
              status === google.maps.DirectionsStatus.OK &&
              response &&
              response.routes &&
              response.routes[0].legs &&
              response.routes[0].legs[0].duration
            ) {
              geoRouteDuration.bicycling =
                response.routes[0].legs[0].duration.text;
            }
            routeRequest.travelMode = google.maps.TravelMode.TRANSIT;
            this._directionsService.route(routeRequest, (response, status) => {
              if (
                status === google.maps.DirectionsStatus.OK &&
                response &&
                response.routes &&
                response.routes[0].legs &&
                response.routes[0].legs[0].duration
              ) {
                geoRouteDuration.transit =
                  response.routes[0].legs[0].duration.text;
                resolve(geoRouteDuration);
              } else {
                resolve(geoRouteDuration);
              }
            });
          });
        });
      });
    });
  }

  public protoEncodeGeoSearch(place: PlaceViewModel): Uint8Array {    
    let protoModel: GeoSearchMessage = new GeoSearchMessage({
      Bounds: new GeoSearchBoundsMessage({
        swLng: place.Bounds.SwLon,
        neLat: place.Bounds.NeLat,
        swLat: place.Bounds.SwLat,
        neLng: place.Bounds.NeLon
      }),
      Address: new GeoSearchAddressMessage({
        lng: place.StreetNumber ? place.Lon : null,
        lat: place.StreetNumber ? place.Lat : null
      })
    });
    return GeoSearchMessage.encode(protoModel).finish();
  }
  public findNearby(latitude: number, longtitude: number) {
    let sw = new google.maps.LatLng(latitude - 1, longtitude + 1)
    let ne = new google.maps.LatLng(latitude + 1, longtitude - 1)

    let bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(sw, ne);
    this._geocoder.geocode(
      {
        bounds: bounds,
      },
      (results, status) => { console.log(results) }
    );
  }
}
