import { Injectable } from '@angular/core';

export interface CountryCodeAndCitizen {
  code: string;
  citizen: string;
}

@Injectable({
  providedIn: 'root'
})


export class NationalityService {

  constructor() {}

  private countryCodesAndCitizens : CountryCodeAndCitizen[] = [
    { code: 'AF', citizen: 'Afghan' },
    { code: 'AL', citizen: 'Albanian' },
    { code: 'DZ', citizen: 'Algerian' },
    { code: 'AD', citizen: 'Andorran' },
    { code: 'AO', citizen: 'Angolan' },
    { code: 'AG', citizen: 'Citizen of Antigua and Barbuda' },
    { code: 'AR', citizen: 'Argentine' },
    { code: 'AM', citizen: 'Armenian' },
    { code: 'AU', citizen: 'Australian' },
    { code: 'AT', citizen: 'Austrian' },
    { code: 'AZ', citizen: 'Azerbaijani' },
    { code: 'BH', citizen: 'Bahraini' },
    { code: 'BD', citizen: 'Bangladeshi' },
    { code: 'BB', citizen: 'Barbadian' },
    { code: 'BY', citizen: 'Belarusian' },
    { code: 'BE', citizen: 'Belgian' },
    { code: 'BZ', citizen: 'Belizean' },
    { code: 'BJ', citizen: 'Beninese' },
    { code: 'BT', citizen: 'Bhutanese' },
    { code: 'BO', citizen: 'Bolivian' },
    { code: 'BA', citizen: 'Citizen of Bosnia and Herzegovina' },
    { code: 'BW', citizen: 'Botswanan' },
    { code: 'BR', citizen: 'Brazilian' },
    { code: 'BN', citizen: 'Bruneian' },
    { code: 'BG', citizen: 'Bulgarian' },
    { code: 'BF', citizen: 'Burkinan' },
    { code: 'BI', citizen: 'Burundian' },
    { code: 'KH', citizen: 'Cambodian' },
    { code: 'CM', citizen: 'Cameroonian' },
    { code: 'CA', citizen: 'Canadian' },
    { code: 'CV', citizen: 'Cape Verdean' },
    { code: 'CF', citizen: 'Central African' },
    { code: 'TD', citizen: 'Chadian' },
    { code: 'CL', citizen: 'Chilean' },
    { code: 'CN', citizen: 'Chinese' },
    { code: 'CO', citizen: 'Colombian' },
    { code: 'KM', citizen: 'Comoran' },
    { code: 'CG', citizen: 'Congolese (Republic of the Congo)' },
    { code: 'CD', citizen: 'Congolese (DRC)' },
    { code: 'CR', citizen: 'Costa Rican' },
    { code: 'HR', citizen: 'Croatian' },
    { code: 'CU', citizen: 'Cuban' },
    { code: 'CY', citizen: 'Cypriot' },
    { code: 'CZ', citizen: 'Czech' },
    { code: 'DK', citizen: 'Danish' },
    { code: 'DJ', citizen: 'Djiboutian' },
    { code: 'DM', citizen: 'Dominican' },
    { code: 'DO', citizen: 'Citizen of the Dominican Republic' },
    { code: 'TL', citizen: 'East Timorese' },
    { code: 'EC', citizen: 'Ecuadorean' },
    { code: 'EG', citizen: 'Egyptian' },
    { code: 'SV', citizen: 'Salvadorean' },
    { code: 'GQ', citizen: 'Equatorial Guinean' },
    { code: 'ER', citizen: 'Eritrean' },
    { code: 'EE', citizen: 'Estonian' },
    { code: 'SZ', citizen: 'Swazi' },
    { code: 'ET', citizen: 'Ethiopian' },
    { code: 'FJ', citizen: 'Fijian' },
    { code: 'FI', citizen: 'Finnish' },
    { code: 'FR', citizen: 'French' },
    { code: 'GA', citizen: 'Gabonese' },
    { code: 'GE', citizen: 'Georgian' },
    { code: 'DE', citizen: 'German' },
    { code: 'GH', citizen: 'Ghanaian' },
    { code: 'GR', citizen: 'Greek' },
    { code: 'GD', citizen: 'Grenadian' },
    { code: 'GT', citizen: 'Guatemalan' },
    { code: 'GN', citizen: 'Guinean' },
    { code: 'GW', citizen: 'Citizen of Guinea-Bissau' },
    { code: 'GY', citizen: 'Guyanese' },
    { code: 'HT', citizen: 'Haitian' },
    { code: 'HN', citizen: 'Honduran' },
    { code: 'HU', citizen: 'Hungarian' },
    { code: 'IS', citizen: 'Icelandic' },
    { code: 'IN', citizen: 'Indian' },
    { code: 'ID', citizen: 'Indonesian' },
    { code: 'IR', citizen: 'Iranian' },
    { code: 'IQ', citizen: 'Iraqi' },
    { code: 'IE', citizen: 'Irish' },
    { code: 'IL', citizen: 'Israeli' },
    { code: 'IT', citizen: 'Italian' },
    { code: 'CI', citizen: 'Ivorian' },
    { code: 'JM', citizen: 'Jamaican' },
    { code: 'JP', citizen: 'Japanese' },
    { code: 'JO', citizen: 'Jordanian' },
    { code: 'KZ', citizen: 'Kazakh' },
    { code: 'KE', citizen: 'Kenyan' },
    { code: 'KI', citizen: 'Citizen of Kiribati' },
    { code: 'XK', citizen: 'Kosovan' },
    { code: 'KW', citizen: 'Kuwaiti' },
    { code: 'KG', citizen: 'Kyrgyz' },
    { code: 'LA', citizen: 'Lao' },
    { code: 'LV', citizen: 'Latvian' },
    { code: 'LB', citizen: 'Lebanese' },
    { code: 'LS', citizen: 'Citizen of Lesotho' },
    { code: 'LR', citizen: 'Liberian' },
    { code: 'LY', citizen: 'Libyan' },
    { code: 'LI', citizen: 'Liechtenstein citizen' },
    { code: 'LT', citizen: 'Lithuanian' },
    { code: 'LU', citizen: 'Luxembourger' },
    { code: 'MG', citizen: 'Citizen of Madagascar' },
    { code: 'MW', citizen: 'Malawian' },
    { code: 'MY', citizen: 'Malaysian' },
    { code: 'MV', citizen: 'Maldivian' },
    { code: 'ML', citizen: 'Malian' },
    { code: 'MT', citizen: 'Maltese' },
    { code: 'MH', citizen: 'Marshallese' },
    { code: 'MR', citizen: 'Mauritanian' },
    { code: 'MU', citizen: 'Mauritian' },
    { code: 'MX', citizen: 'Mexican' },
    { code: 'FM', citizen: 'Micronesian' },
    { code: 'MD', citizen: 'Moldovan' },
    { code: 'MC', citizen: 'Monegasque' },
    { code: 'MN', citizen: 'Mongolian' },
    { code: 'ME', citizen: 'Montenegrin' },
    { code: 'MA', citizen: 'Moroccan' },
    { code: 'MZ', citizen: 'Mozambican' },
    { code: 'MM', citizen: 'Citizen of Myanmar' },
    { code: 'NA', citizen: 'Namibian' },
    { code: 'NR', citizen: 'Nauruan' },
    { code: 'NP', citizen: 'Nepalese' },
    { code: 'NL', citizen: 'Dutch' },
    { code: 'NZ', citizen: 'New Zealander' },
    { code: 'NI', citizen: 'Nicaraguan' },
    { code: 'NE', citizen: 'Nigerien' },
    { code: 'NG', citizen: 'Nigerian' },
    { code: 'KP', citizen: 'North Korean' },
    { code: 'MK', citizen: 'Macedonian' },
    { code: 'NO', citizen: 'Norwegian' },
    { code: 'OM', citizen: 'Omani' },
    { code: 'PK', citizen: 'Pakistani' },
    { code: 'PW', citizen: 'Palauan' },
    { code: 'PA', citizen: 'Panamanian' },
    { code: 'PG', citizen: 'Papua New Guinean' },
    { code: 'PY', citizen: 'Paraguayan' },
    { code: 'PE', citizen: 'Peruvian' },
    { code: 'PH', citizen: 'Filipino' },
    { code: 'PL', citizen: 'Polish' },
    { code: 'PT', citizen: 'Portuguese' },
    { code: 'QA', citizen: 'Qatari' },
    { code: 'RO', citizen: 'Romanian' },
    { code: 'RU', citizen: 'Russian' },
    { code: 'RW', citizen: 'Rwandan' },
    { code: 'KN', citizen: 'Citizen of St Christopher (St Kitts) and Nevis' },
    { code: 'LC', citizen: 'St Lucian' },
    { code: 'VC', citizen: 'Vincentian' },
    { code: 'WS', citizen: 'Samoan' },
    { code: 'SM', citizen: 'San Marinese' },
    { code: 'ST', citizen: 'Sao Tomean' },
    { code: 'SA', citizen: 'Saudi Arabian' },
    { code: 'SN', citizen: 'Senegalese' },
    { code: 'RS', citizen: 'Serbian' },
    { code: 'SC', citizen: 'Citizen of Seychelles' },
    { code: 'SL', citizen: 'Sierra Leonean' },
    { code: 'SG', citizen: 'Singaporean' },
    { code: 'SK', citizen: 'Slovak' },
    { code: 'SI', citizen: 'Slovenian' },
    { code: 'SB', citizen: 'Solomon Islander' },
    { code: 'SO', citizen: 'Somali' },
    { code: 'ZA', citizen: 'South African' },
    { code: 'KR', citizen: 'South Korean' },
    { code: 'SS', citizen: 'South Sudanese' },
    { code: 'ES', citizen: 'Spanish' },
    { code: 'LK', citizen: 'Sri Lankan' },
    { code: 'SD', citizen: 'Sudanese' },
    { code: 'SR', citizen: 'Surinamese' },
    { code: 'SE', citizen: 'Swedish' },
    { code: 'CH', citizen: 'Swiss' },
    { code: 'SY', citizen: 'Syrian' },
    { code: 'TJ', citizen: 'Tajik' },
    { code: 'TZ', citizen: 'Tanzanian' },
    { code: 'TH', citizen: 'Thai' },
    { code: 'BS', citizen: 'Bahamian' },
    { code: 'GM', citizen: 'Gambian' },
    { code: 'TG', citizen: 'Togolese' },
    { code: 'TO', citizen: 'Tongan' },
    { code: 'TT', citizen: 'Trinidad and Tobago citizen' },
    { code: 'TN', citizen: 'Tunisian' },
    { code: 'TR', citizen: 'Turk, Turkish' },
    { code: 'TM', citizen: 'Turkmen' },
    { code: 'TV', citizen: 'Tuvaluan' },
    { code: 'UG', citizen: 'Ugandan' },
    { code: 'UA', citizen: 'Ukrainian' },
    { code: 'AE', citizen: 'Citizen of the United Arab Emirates' },
    { code: 'GB', citizen: 'Briton, British' },
    { code: 'US', citizen: 'American' },
    { code: 'UY', citizen: 'Uruguayan' },
    { code: 'UZ', citizen: 'Uzbek' },
    { code: 'VU', citizen: 'Citizen of Vanuatu' },
    { code: 'VA', citizen: 'Vatican citizen' },
    { code: 'VE', citizen: 'Venezuelan' },
    { code: 'VN', citizen: 'Vietnamese' },
    { code: 'YE', citizen: 'Yemeni' },
    { code: 'ZM', citizen: 'Zambian' },
    { code: 'ZW', citizen: 'Zimbabwean' }
  ];

  getCountryCodesAndCitizens() {
    return this.countryCodesAndCitizens;
  }
}
