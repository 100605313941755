import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ModalService } from '@indice/ng-components';
import { TranslocoDirective } from '@jsverse/transloco';
import { Photo, PortalApiService } from 'src/app/services/portal-api.service';
import { ThreedViewerComponent } from 'src/app/shared/threed-viewer/threed-viewer.component';

@Component({
  selector: 'app-floor-plan-section',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './floor-plan-section.component.html',
  styleUrl: './floor-plan-section.component.css'
})
export class FloorPlanSectionComponent {
  @Input() address: string | any;
  @Input() plotArea: number | any;
  @Input() numOfRooms: number | any;
  @Input() propertyId: string | undefined;
  @Input() floorPlans: Photo[] = [];
  protected vrCode: string | undefined;
  public selectedIndex = 0;

  constructor(private portalService: PortalApiService,
    private modalService: ModalService
  ) {}


  ngOnInit() {
    this.portalService.getThreeDCode(this.propertyId ?? '').subscribe({
      next: (result) => { this.vrCode = result?.spaceId }
    })
  }
  openVrModalComponent() {
    const vrModal = this.modalService.show(ThreedViewerComponent, {
      animated: true,
      initialState: {
        SpaceId: this.vrCode
      },
      keyboard: true
    });
    vrModal.onHidden?.subscribe((response: any) => {

    });
  }
  next() {
    if (this.selectedIndex == this.floorPlans.length - 1) {
      return;
    }
    this.selectedIndex = this.selectedIndex + 1;
  }
  previous() {
    if (this.selectedIndex == 0) {
      return;
    }
    this.selectedIndex = this.selectedIndex - 1;
  }
}
