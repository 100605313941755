import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { User } from 'oidc-client-ts';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { LoggerService } from '../../services/logger.service';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    constructor(
        private authService: AuthService,
        private router: Router,
        private logger: LoggerService
    ) {
        this._subscription = Subscription.EMPTY;
        this.logger.info('AuthCallbackComponent constructor was called.');
    }

    public ngOnInit(): void {
        this.logger.info('AuthCallbackComponent ngOnInit method was called.');
        this._subscription = this.authService
            .signinRedirectCallback()
            .subscribe((user: User) => {
                if (user) {
                  this.router.navigateByUrl((<any>user.state).url || '/');
                }
            });
    }

    public ngOnDestroy(): void {
        this.logger.info('AuthCallbackComponent ngOnDestroy method was called.');
        this._subscription?.unsubscribe();
    }
}
