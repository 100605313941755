<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" *transloco="let t; prefix: 'property-details.hero-section.modal-image-gallery.image-item.modal-image-item-large'">
    <div class="bg-white rounded-small p-6">
        <div class="flex justify-between">
            <button (click)="closeModal()" class="p-2"><i class="icon-arrow-left">{{t('buttons.viewAll')}}</i></button>
        </div>
        <div class="h-[656px] overflow-hidden pt-2">
            <img class="rounded-small w-[100%] h-[100%] object-cover object-center img-disable-context" src="{{ imageSrc }}">
        </div>
        <div class="flex justify-between items-center pt-6">
            <h4 class="h4">{{ propertyTitle }}</h4>
        </div>

    </div>
</div>