<section
  class="pt-6 pb-12"
  *transloco="let t; prefix: 'property-details.costs-section'"
>
  <h2 class="text-[1.75rem] font-semibold text-black pb-6">
    {{ t("header") }}
  </h2>

  <div class="relative">
    <div class="cost-wrapper">
      <div class="cost-row heading">
        <div class="cost-row-item extra-span">
          {{ t("table-header.title") }}
          <div class="cost-mobile-item">
            {{ propertyValue | currency: 'EUR' : 'symbol' : '1.0-0' }}
          </div>
        </div>
        <div class="cost-row-item">{{ propertyValue | currency: 'EUR' : 'symbol' : '1.0-0' }}</div>
        <div class="cost-row-item">% {{ t("table-header.precentage") }}</div>
      </div>

      <div class="cost-row">
        <div class="cost-row-item extra-span">
          <div
            class="button-wrapper"
            [ngClass]="{ active: accordionItems[0].active }"
          >
            <button
              class="w-full flex flex-row items-center gap-2 px-2"
              type="button"
              (click)="toggleAccordion(0)"
              [ngClass]="{ active: accordionItems[0].active }"
            >
              <i class="icon-chevron-up"></i>
              {{ t("table-body.notary-fee.title") }}
              <div class="cost-mobile-item">
                {{ fees.notaryExpenses?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
              </div>
            </button>
            <div class="cost-mobile-sub-item">
              % {{ fees.notaryExpenses?.percentage | number : ".2" }}%
            </div>
          </div>
          <div
            class="overflow-hidden rfs:pl-8"
            [ngClass]="{ 'h-0': !accordionItems[0].active }"
          >
            Σταθερή αμοιβή συμβολαιογράφου €20 <br />
            Ειδικό τέλος ακινήτου που αφορά το ακίνητο €3.900 (0,65% της αξίας
            του ακινήτου) <br />
            Ενδέχεται να προκύψουν πρόσθετα τέλη για επιπλέον σελίδες του
            συμβολαίου ή της συμβολαιογραφικής πράξης
          </div>
        </div>
        <div class="cost-row-item">
          <div>{{ fees.notaryExpenses?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}</div>
          <div>Περιλαμβάνει €240,80 ΦΠΑ</div>
        </div>
        <div class="cost-row-item">
          {{ fees.notaryExpenses?.percentage | number : ".2" }}%
        </div>
      </div>

      <div class="cost-row">
        <div class="cost-row-item extra-span">
          <div
            class="button-wrapper"
            [ngClass]="{ active: accordionItems[1].active }"
          >
            <button
              class="w-full flex flex-row items-center gap-2 px-2"
              type="button"
              (click)="toggleAccordion(1)"
              [ngClass]="{ active: accordionItems[1].active }"
            >
              <i class="icon-chevron-up"></i>
              {{ t("table-body.property-transfer-tax.title") }}
              <div class="cost-mobile-item">
                {{ fees.propertyTransferTax?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
              </div>
            </button>
            <div class="cost-mobile-sub-item">
              {{ fees.propertyTransferTax?.percentage | number : ".2" }}%
            </div>
          </div>
          <div
            class="overflow-hidden rfs:pl-8"
            [ngClass]="{ 'h-0': !accordionItems[1].active }"
          >
            Σταθερή αμοιβή συμβολαιογράφου €20 <br />
            Ειδικό τέλος ακινήτου που αφορά το ακίνητο €3.900 (0,65% της αξίας
            του ακινήτου) <br />
            Ενδέχεται να προκύψουν πρόσθετα τέλη για επιπλέον σελίδες του
            συμβολαίου ή της συμβολαιογραφικής πράξης
          </div>
        </div>
        <div class="cost-row-item">
          {{ fees.propertyTransferTax?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
        </div>
        <div class="cost-row-item">
          {{ fees.propertyTransferTax?.percentage | number : ".2" }}%
        </div>
      </div>
      <div class="cost-row">
        <div class="cost-row-item extra-span">
          <div
            class="button-wrapper"
            [ngClass]="{ active: accordionItems[2].active }"
          >
            <button
              class="w-full flex flex-row items-center gap-2 px-2"
              type="button"
              (click)="toggleAccordion(2)"
              [ngClass]="{ active: accordionItems[2].active }"
            >
              <i class="icon-chevron-up"></i>
              {{ t("table-body.land-registry-fees.title") }}
              <div class="cost-mobile-item">
                {{ fees.landRegistryFees?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
              </div>
            </button>
            <div class="cost-mobile-sub-item">
              {{ fees.landRegistryFees?.percentage | number : ".2" }}%
            </div>
          </div>
          <div
            class="overflow-hidden rfs:pl-8"
            [ngClass]="{ 'h-0': !accordionItems[2].active }"
          >
            Σταθερή αμοιβή συμβολαιογράφου €20 <br />
            Ειδικό τέλος ακινήτου που αφορά το ακίνητο €3.900 (0,65% της αξίας
            του ακινήτου) <br />
            Ενδέχεται να προκύψουν πρόσθετα τέλη για επιπλέον σελίδες του
            συμβολαίου ή της συμβολαιογραφικής πράξης
          </div>
        </div>
        <div class="cost-row-item">
          {{ fees.landRegistryFees?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
        </div>
        <div class="cost-row-item">
          {{ fees.landRegistryFees?.percentage | number : ".2" }}%
        </div>
      </div>
      <div class="cost-row">
        <div class="cost-row-item extra-span">
          <div
            class="button-wrapper"
            [ngClass]="{ active: accordionItems[3].active }"
          >
            <button
              class="w-full flex flex-row items-center gap-2 px-2"
              type="button"
              (click)="toggleAccordion(3)"
              [ngClass]="{ active: accordionItems[3].active }"
            >
              <i class="icon-chevron-up"></i>
              {{ t("table-body.engineer-fee.title") }}
              <div class="cost-mobile-item">
                {{ fees.engineerFee?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
              </div>
            </button>
            <div class="cost-mobile-sub-item">
              {{ fees.engineerFee?.percentage | number : ".2" }}%
            </div>
          </div>
          <div
            class="overflow-hidden rfs:pl-8"
            [ngClass]="{ 'h-0': !accordionItems[3].active }"
          >
            Σταθερή αμοιβή συμβολαιογράφου €20 <br />
            Ειδικό τέλος ακινήτου που αφορά το ακίνητο €3.900 (0,65% της αξίας
            του ακινήτου) <br />
            Ενδέχεται να προκύψουν πρόσθετα τέλη για επιπλέον σελίδες του
            συμβολαίου ή της συμβολαιογραφικής πράξης
          </div>
        </div>
        <div class="cost-row-item">
          {{ fees.engineerFee?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
        </div>
        <div class="cost-row-item">
          {{ fees.engineerFee?.percentage | number : ".2" }}%
        </div>
      </div>
      <div class="cost-row">
        <div class="cost-row-item extra-span">
          <div
            class="button-wrapper"
            [ngClass]="{ active: accordionItems[4].active }"
          >
            <button
              class="w-full flex flex-row items-center gap-2 px-2"
              type="button"
              (click)="toggleAccordion(4)"
              [ngClass]="{ active: accordionItems[4].active }"
            >
              <i class="icon-chevron-up"></i>
              {{ t("table-body.lawyer-fee.title") }}
              <div class="cost-mobile-item">
                {{ fees.lawyerFee?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
              </div>
            </button>
            <div class="cost-mobile-sub-item">
              {{ fees.lawyerFee?.percentage | number : ".2" }}%
            </div>
          </div>
          <div
            class="overflow-hidden rfs:pl-8"
            [ngClass]="{ 'h-0': !accordionItems[4].active }"
          >
            Σταθερή αμοιβή συμβολαιογράφου €20 <br />
            Ειδικό τέλος ακινήτου που αφορά το ακίνητο €3.900 (0,65% της αξίας
            του ακινήτου) <br />
            Ενδέχεται να προκύψουν πρόσθετα τέλη για επιπλέον σελίδες του
            συμβολαίου ή της συμβολαιογραφικής πράξης
          </div>
        </div>
        <div class="cost-row-item">
          {{ fees.lawyerFee?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
        </div>
        <div class="cost-row-item">
          {{ fees.lawyerFee?.percentage | number : ".2" }}%
        </div>
      </div>
      <div class="cost-row">
        <div class="cost-row-item extra-span">
          <div
            class="button-wrapper"
            [ngClass]="{ active: accordionItems[5].active }"
          >
            <button
              class="w-full flex flex-row items-center gap-2 px-2"
              type="button"
              (click)="toggleAccordion(5)"
              [ngClass]="{ active: accordionItems[5].active }"
            >
              <i class="icon-chevron-up"></i>
              {{ t("table-body.real-estate-agency-fee.title") }}
              <div class="cost-mobile-item">
                {{ fees.realEstateAgencyFee?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
              </div>
            </button>
            <div class="cost-mobile-sub-item">
              {{ fees.realEstateAgencyFee?.percentage | number : ".2" }}%
            </div>
          </div>
          <div
            class="overflow-hidden rfs:pl-8"
            [ngClass]="{ 'h-0': !accordionItems[5].active }"
          >
            Σταθερή αμοιβή συμβολαιογράφου €20 <br />
            Ειδικό τέλος ακινήτου που αφορά το ακίνητο €3.900 (0,65% της αξίας
            του ακινήτου) <br />
            Ενδέχεται να προκύψουν πρόσθετα τέλη για επιπλέον σελίδες του
            συμβολαίου ή της συμβολαιογραφικής πράξης
          </div>
        </div>
        <div class="cost-row-item">
          {{ fees.realEstateAgencyFee?.fee | currency: 'EUR' : 'symbol' : '1.0-0' }}
        </div>
        <div class="cost-row-item">
          {{ fees.realEstateAgencyFee?.percentage | number : ".2" }}%
        </div>
      </div>
    </div>
    <app-locked-content-overlay
      [isVissible]="!isLoggedIn"
    ></app-locked-content-overlay>
  </div>
</section>
