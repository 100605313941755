<ng-container *transloco="let t; prefix: 'loan-wizard'">
    <ng-container *ngIf="!showResultPage">
        <div class="flex-1">
            <div class="container mb-[20px]">
                <progress-stepper [StepNo]="this.stepKeys.indexOf(this.activeStep) + 1"
                    [Steps]="stepValues"></progress-stepper>
            </div>
            <ng-container [formGroup]="loanForm">
                <div class="container flex flex-row flex-1 gap-8">
                    <div class="bg-white rounded-[1rem] p-8 flex flex-col gap-8 min-h-[570px] flex-1">
                        <ng-container *ngIf="activeStep=='property'" [formGroupName]="'property'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('theProperty')}}</h4>
                            <ng-container *ngIf="showChoosePropertyUi">
                                <h5 class="text-black font-semibold text-2xl">{{t('choosePropertyTitle')}}</h5>
                                <div>
                                    <fieldset class="flex flex-col gap-2 group/input">
                                        <label
                                            class="font-semibold flex items-center justify-between text-gray-950 group-disabled:text-gray-300">{{t('yourNeonPropertyId')}}<i
                                                class="icon-info-circle text-gray-700 text-xl"></i></label>
                                        <input [formControl]="searchProperty" type="text"
                                            placeholder="{{t('pasteLinkToProperty')}}"
                                            class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    </fieldset>
                                </div>

                                <app-property-card-item [newWindow]="true" *ngIf="propertySummary" [withBorder]="true"
                                    [propertySummary]="this.propertySummary"></app-property-card-item>

                                <div class="flex flex-row items-center gap-4">
                                    <span class="text-gray-700 text-xl">{{t('orChooseFromBelow')}}</span>
                                    <span class="h-px flex-1 bg-gray-300"></span>
                                </div>
                                <div
                                    class="w-full p-1 border-full border border-gray-500 bg-gray-50 flex flex-row rounded-full">
                                    <button (click)="showFavouritesUi = true" class="tab-pill"
                                        [ngClass]="{'active': showFavouritesUi}"><i
                                            class="icon-star"></i>{{t('yourFavourites')}}</button>
                                    <button (click)="showFavouritesUi = false" class="tab-pill"
                                        [ngClass]="{'active': !showFavouritesUi}"><i class="
                                        icon-clock"></i>{{t('recentlyViewed')}}</button>
                                </div>
                                <ng-container *ngIf="showFavouritesUi">
                                    <ng-container *ngFor="let propertyFavouriteSummary of propertyFavourites">

                                        <app-property-card-item [newWindow]="true" [isClickable]="false"
                                            [withBorder]="true"
                                            (click)="chooseThisProperty(propertyFavouriteSummary.id)"
                                            [propertySummary]="propertyFavouriteSummary"></app-property-card-item>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!showFavouritesUi">
                                    <ng-container *ngFor="let propertyRecentlyViewedSummary of propertyRecentlyViewed">
                                        <app-property-card-item [newWindow]="true" [isClickable]="false"
                                            [withBorder]="true"
                                            (click)="chooseThisProperty(propertyRecentlyViewedSummary.id)"
                                            [propertySummary]="propertyRecentlyViewedSummary"></app-property-card-item>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!showChoosePropertyUi">
                                <ng-container *ngIf="propertySummary">
                                    <h5 class="text-black font-semibold text-2xl">{{t('confirmPropertyTitle')}}</h5>
                                    <fieldset class="flex flex-col gap-2 group/input">
                                        <label
                                            class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">{{t('yourNeonPropertyId')}}<i
                                                class="icon-info-circle text-gray-700 text-base"></i></label>
                                        <div
                                            class="max-w-[318px] border border-gray-300 rounded-full bg-gray-50 overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                            <input type="text" disabled [value]="'ID number ' + propertySummary.code"
                                                class="text-gray-500 bg-transparent outline-none">
                                        </div>
                                    </fieldset>

                                    <div *ngIf="propertySummary"
                                        class="flex-row flex rounded-lg overflow-hidden border border-gray-500">
                                        <app-property-card-item [newWindow]="true"
                                            [propertySummary]="this.propertySummary"></app-property-card-item>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="activeStep=='loan'" [formGroupName]="'loan'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('reviewLoanCalculation')}}</h4>
                            <app-loan-calulator [propertyValue]="propertySummary?.price"></app-loan-calulator>
                        </ng-container>

                        <ng-container *ngIf="activeStep=='details'" [formGroupName]="'details'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('yourDetails')}}</h4>

                            <h5 class="text-black font-semibold text-2xl">{{t('tellAboutFamily')}}</h5>

                            <fieldset class="flex flex-col gap-2 group/input">
                                <label tooltip="'this is my tooltip'"
                                    class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                    <div>{{t('whatYearBorn')}}<span class="text-error">
                                            *</span></div>
                                    <i class="relative icon-info-circle text-gray-700
                                text-base"></i>
                                </label>
                                <div
                                    class="border border-gray-300 rounded-full bg-gray-50 overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <span class="text-gray-900 font-semibold">{{t('year')}}</span>
                                    <input [formControlName]="'yearBorn'" type="number"
                                        class="text-gray-500 bg-transparent flex-1 outline-none">
                                </div>
                                <div
                                    class="py-[11px] px-4 flex flex-row items-center gap-6 bg-gray-100 rounded-s text-gray-900 text-sm">
                                    <i class="icon-info-circle text-gray-700 text-base"></i>
                                    {{t('payBackBy75')}}
                                </div>
                                <span *ngIf="hasError('details.yearBorn')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </fieldset>

                            <div class="flex flex-col gap-4">
                                <div class="text-black flex flex-row items-center font-semibold gap-4">
                                    <div>{{t('professionalStatus')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </div>
                                <label role="button" for="professionalStatus-radio-1" class="radio-selector">
                                    <input [formControlName]="'professionalStatus'" value="employed" type="radio"
                                        class="hidden" id="professionalStatus-radio-1" name="professionalStatus">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('employed')}}</span>
                                    </div>
                                </label>
                                <label role="button" for="professionalStatus-radio-2" class="radio-selector">
                                    <input [formControlName]="'professionalStatus'" value="selfEmployed" type="radio"
                                        class="hidden" id="professionalStatus-radio-2" name="professionalStatus">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('selfEmployed')}}</span>
                                    </div>
                                </label>
                                <label role="button" for="professionalStatus-radio-3" class="radio-selector">
                                    <input [formControlName]="'professionalStatus'" type="radio" class="hidden"
                                        value="retired" id="professionalStatus-radio-3" name="professionalStatus">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('retired')}}</span>
                                    </div>
                                </label>
                                <span *ngIf="hasError('details.professionalStatus')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </div>


                            <div class="flex flex-col gap-4">
                                <div class="text-black flex flex-row items-center font-semibold gap-4">
                                    <div>{{t('marriageStatus')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </div>
                                <label role="button" for="marriageStatus-radio-1" class="radio-selector">
                                    <input [formControlName]="'marriageStatus'" value="single" type="radio"
                                        class="hidden" id="marriageStatus-radio-1" name="marriageStatus">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('single')}}</span>
                                    </div>
                                </label>
                                <label role="button" for="marriageStatus-radio-2" class="radio-selector">
                                    <input [formControlName]="'marriageStatus'" value="married" type="radio"
                                        class="hidden" id="marriageStatus-radio-2" name="marriageStatus">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('married')}}</span>
                                    </div>
                                </label>
                                <label role="button" for="marriageStatus-radio-3" class="radio-selector">
                                    <input [formControlName]="'marriageStatus'" type="radio" class="hidden"
                                        value="cohabitation" id="marriageStatus-radio-3" name="marriageStatus">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('cohabitation')}}</span>
                                    </div>
                                </label>
                                <span *ngIf="hasError('details.marriageStatus')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </div>

                            <div class="flex flex-col gap-4 mb-[4.5rem]">
                                <div class="text-black flex flex-row items-center font-semibold gap-4">
                                    <div>{{t('howManyChildren')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </div>
                                <div class="flex flex-row justify-between items-center">
                                    <label role="button" for="children-radio-1" class="radio-selector">
                                        <input [formControlName]="'children'" [value]="'0'" type="radio" class="hidden"
                                            id="children-radio-1" name="children">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">0</span>
                                        </div>
                                    </label>
                                    <label role="button" for="children-radio-2" class="radio-selector">
                                        <input [formControlName]="'children'" [value]="'1'" type="radio" class="hidden"
                                            id="children-radio-2" name="children">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">1</span>
                                        </div>
                                    </label>
                                    <label role="button" for="children-radio-3" class="radio-selector">
                                        <input [formControlName]="'children'" [value]="'2'" type="radio" class="hidden"
                                            id="children-radio-3" name="children">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">2</span>
                                        </div>
                                    </label>
                                    <label role="button" for="children-radio-4" class="radio-selector">
                                        <input [formControlName]="'children'" [value]="'3'" type="radio" class="hidden"
                                            id="children-radio-4" name="children">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">3</span>
                                        </div>
                                    </label>
                                    <label role="button" for="children-radio-5" class="radio-selector">
                                        <input [formControlName]="'children'" [value]="'4'" type="radio" class="hidden"
                                            id="children-radio-5" name="children">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">4</span>
                                        </div>
                                    </label>
                                    <label role="button" for="children-radio-6" class="radio-selector">
                                        <input [formControlName]="'children'" [value]="'5+'" type="radio" class="hidden"
                                            id="children-radio-6" name="children">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">5+</span>
                                        </div>
                                    </label>
                                </div>
                                <span *ngIf="hasError('details.children')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </div>

                            <h5 class="text-black font-semibold text-2xl">{{t('tellHouseholdFinances')}}</h5>

                            <div class="flex flex-col gap-4">
                                <div class="text-black flex flex-row items-center font-semibold gap-4">
                                    <div>{{t('haveCoborrower')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </div>
                                <label role="button" for="haveCoborrower-radio-1" class="radio-selector">
                                    <input [formControlName]="'haveCoborrower'" [value]="true" type="radio"
                                        class="hidden" id="haveCoborrower-radio-1" name="haveCoborrower">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{'shared.answers.yes' |
                                            transloco
                                            }}</span>
                                    </div>
                                </label>
                                <label role="button" for="haveCoborrower-radio-2" class="radio-selector">
                                    <input [formControlName]="'haveCoborrower'" [value]="false" type="radio"
                                        class="hidden" id="haveCoborrower-radio-2" name="haveCoborrower">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{'shared.answers.no' |
                                            transloco
                                            }}</span>
                                    </div>
                                </label>
                                <span *ngIf="hasError('details.haveCoborrower')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </div>

                            <fieldset class="flex flex-col gap-2 group/input">
                                <label
                                    class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                    <div>{{t('whatGrossSalary')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </label>
                                <div
                                    class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <span class="text-gray-900 font-semibold">€</span>
                                    <input [formControlName]="'grossSalaryPerYear'" mask="separator.2"
                                        [thousandSeparator]="thousandSeparator" [placeholder]="t('yourSalaryBeforeTax')"
                                        class="text-gray-500 bg-transparent outline-none flex-1">
                                </div>
                                <div class="flex flex-row items-center gap-6 text-gray-500 text-sm">
                                    {{t('helpHowConfortablePay')}}
                                </div>
                                <span *ngIf="hasError('details.grossSalaryPerYear')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </fieldset>

                            <fieldset class="flex flex-col gap-2 group/input mb-[4.5rem]">
                                <label
                                    class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                    <div>{{t('whatHouseholdDebt')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </label>
                                <div
                                    class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <span class="text-gray-900 font-semibold">€</span>
                                    <input [formControlName]="'householdDebt'" mask="separator.2"
                                        [placeholder]="t('totalDebtObligation')" [thousandSeparator]="thousandSeparator"
                                        class="text-gray-500 bg-transparent outline-none flex-1">
                                </div>
                                <div class="flex flex-row items-center gap-6 text-gray-500 text-sm">
                                    {{t('soWeKnowOtherDebts')}}
                                </div>
                                <span *ngIf="hasError('details.householdDebt')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </fieldset>

                            <h5 class="text-black font-semibold text-2xl">{{t('tellTaxSituation')}}</h5>


                            <fieldset class="flex flex-col gap-2 group/input">
                                <label
                                    class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                    <div>{{t('whatNationality')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </label>

                                <app-dropdown-nationality [Title]="t('nationality')" [Content]="countryCodesAndCitizens"
                                (DropdownSelected)="onNationalityChange($event)"
                                ></app-dropdown-nationality>

                                <span *ngIf="hasError('details.nationality')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </fieldset>

                            <div class="flex flex-col gap-4">
                                <div class="text-black flex flex-row items-center font-semibold gap-4">
                                    <div>{{t('wherePayTax')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </div>
                                <label role="button" for="wherePayTax-radio-1" class="radio-selector">
                                    <input [formControlName]="'wherePayTax'" [value]="'inGreece'" type="radio"
                                        class="hidden" id="wherePayTax-radio-1" name="wherePayTax">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('inGreece')}}</span>
                                    </div>
                                </label>
                                <label role="button" for="wherePayTax-radio-2" class="radio-selector">
                                    <input [formControlName]="'wherePayTax'" [value]="'inEu'" type="radio"
                                        class="hidden" id="wherePayTax-radio-2" name="wherePayTax">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('inEu')}}</span>
                                    </div>
                                </label>
                                <label role="button" for="wherePayTax-radio-3" class="radio-selector">
                                    <input [formControlName]="'wherePayTax'" [value]="'outsideEu'" type="radio"
                                        class="hidden" id="wherePayTax-radio-3" name="wherePayTax">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{t('outsideEu')}}</span>
                                    </div>
                                </label>
                                <span *ngIf="hasError('details.wherePayTax')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </div>

                            <fieldset class="flex flex-col gap-2 group/input">
                                <label
                                    class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                    <div>{{t('whatTaxPerYear')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </label>
                                <div class="flex flex-row items-center gap-1 text-gray-500 text-sm">
                                    {{t('calculateTaxAt')}}<a href="https://aftertax.gr/" target="_blank"
                                        class="text-secondary underline">AfterTax.gr</a>
                                </div>
                                <div
                                    class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <span class="text-gray-900 font-semibold">€</span>
                                    <input [formControlName]="'taxPerYear'" mask="separator.2"
                                        [thousandSeparator]="thousandSeparator"
                                        class="text-gray-500 bg-transparent outline-none flex-1">
                                </div>
                                <span *ngIf="hasError('details.taxPerYear')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </fieldset>
                        </ng-container>

                        <ng-container *ngIf="activeStep=='guarantor'" [formGroupName]="'guarantor'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('yourGuarantor')}}</h4>

                            <div class="flex flex-col gap-4 mb-[4.5rem]">
                                <div class="text-black flex flex-row items-center font-semibold gap-4">
                                    <div>{{t('haveGuarantor')}}<span class="text-error">
                                            *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                </div>
                                <label role="button" for="haveGuarantor-radio-1" class="radio-selector">
                                    <input [formControlName]="'haveGuarantor'" [value]="true" type="radio"
                                        class="hidden" id="haveGuarantor-radio-1" name="haveGuarantor">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{'shared.answers.yes' |
                                            transloco
                                            }}</span>
                                    </div>
                                </label>
                                <label role="button" for="haveGuarantor-radio-2" class="radio-selector">
                                    <input [formControlName]="'haveGuarantor'" [value]="false" type="radio"
                                        class="hidden" id="haveGuarantor-radio-2" name="haveGuarantor">
                                    <div class="radio-selector-content">
                                        <span class="radio-selector-text font-semibold">{{'shared.answers.no' |
                                            transloco
                                            }}</span>
                                    </div>
                                </label>
                                <span *ngIf="hasError('guarantor.haveGuarantor')" class="text-error">{{
                                    'shared.error.requiredField'
                                    | transloco}}</span>
                            </div>
                            <ng-container *ngIf="loanForm.get('guarantor.haveGuarantor')?.value === true">
                                <h5 class="text-black font-semibold text-2xl">{{t('tellAboutGuarantor')}}</h5>

                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatYearBornGuarantor')}}<span class="text-error">
                                                *</span></div>
                                        <i class="relative icon-info-circle text-gray-700
                                    text-base"></i>
                                    </label>
                                    <div
                                        class="border border-gray-300 rounded-full bg-gray-50 overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">{{t('year')}}</span>
                                        <input [formControlName]="'yearBornGuarantor'" type="number"
                                            class="text-gray-500 bg-transparent flex-1 outline-none">
                                    </div>
                                    <span *ngIf="hasError('guarantor.yearBornGuarantor')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>


                                <div class="flex flex-col gap-4">
                                    <div class="text-black flex flex-row items-center font-semibold gap-4">
                                        <div>{{t('howManyChildrenGuarantor')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </div>
                                    <div class="flex flex-row justify-between items-center">
                                        <label role="button" for="arantor-radio-1" class="radio-selector">
                                            <input [formControlName]="'childrenGuarantor'" [value]="'0'" type="radio"
                                                class="hidden" id="arantor-radio-1">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">0</span>
                                            </div>
                                        </label>
                                        <label role="button" for="arantor-radio-2" class="radio-selector">
                                            <input [formControlName]="'childrenGuarantor'" [value]="'1'" type="radio"
                                                class="hidden" id="arantor-radio-2">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">1</span>
                                            </div>
                                        </label>
                                        <label role="button" for="arantor-radio-3" class="radio-selector">
                                            <input [formControlName]="'childrenGuarantor'" [value]="'2'" type="radio"
                                                class="hidden" id="arantor-radio-3">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">2</span>
                                            </div>
                                        </label>
                                        <label role="button" for="arantor-radio-4" class="radio-selector">
                                            <input [formControlName]="'childrenGuarantor'" [value]="'3'" type="radio"
                                                class="hidden" id="arantor-radio-4">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">3</span>
                                            </div>
                                        </label>
                                        <label role="button" for="arantor-radio-5" class="radio-selector">
                                            <input [formControlName]="'childrenGuarantor'" [value]="'4'" type="radio"
                                                class="hidden" id="arantor-radio-5">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">4</span>
                                            </div>
                                        </label>
                                        <label role="button" for="arantor-radio-6" class="radio-selector">
                                            <input [formControlName]="'childrenGuarantor'" [value]="'5+'" type="radio"
                                                class="hidden" id="arantor-radio-6">
                                            <div class="radio-selector-content">
                                                <span class="radio-selector-text font-semibold">5+</span>
                                            </div>
                                        </label>
                                    </div>
                                    <span *ngIf="hasError('guarantor.childrenGuarantor')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </div>

                                <div class="flex flex-col gap-4 mb-[4.5rem]">
                                    <div class="text-black flex flex-row items-center font-semibold gap-4">
                                        <div>{{t('marriageStatusGuarantor')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </div>
                                    <label role="button" for="marriageStatusGuarantor-radio-1" class="radio-selector">
                                        <input [formControlName]="'marriageStatusGuarantor'" value="single" type="radio"
                                            class="hidden" id="marriageStatusGuarantor-radio-1">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{t('single')}}</span>
                                        </div>
                                    </label>
                                    <label role="button" for="marriageStatusGuarantor-radio-2" class="radio-selector">
                                        <input [formControlName]="'marriageStatusGuarantor'" value="married"
                                            type="radio" class="hidden" id="marriageStatusGuarantor-radio-2">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{t('married')}}</span>
                                        </div>
                                    </label>
                                    <label role="button" for="marriageStatusGuarantor-radio-3" class="radio-selector">
                                        <input [formControlName]="'marriageStatusGuarantor'" type="radio" class="hidden"
                                            value="cohabitation" id="marriageStatusGuarantor-radio-3">
                                        <div class="radio-selector-content">
                                            <span class="radio-selector-text font-semibold">{{t('cohabitation')}}</span>
                                        </div>
                                    </label>
                                    <span *ngIf="hasError('guarantor.marriageStatusGuarantor')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </div>

                                <h5 class="text-black font-semibold text-2xl">{{t('tellAboutGuarantorFinances')}}</h5>

                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatHouseholdIncomeGuarantor')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </label>
                                    <div
                                        class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">€</span>
                                        <input [formControlName]="'householdIncomeGuarantor'" mask="separator.2"
                                            [thousandSeparator]="thousandSeparator"
                                            class="text-gray-500 bg-transparent outline-none flex-1">
                                    </div>
                                    <span *ngIf="hasError('guarantor.householdIncomeGuarantor')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>

                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatTaxEachYearGuarantor')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </label>
                                    <div class="flex flex-row items-center gap-1 text-gray-500 text-sm">
                                        {{t('calculateTaxAt')}}<a href="https://aftertax.gr/" target="_blank"
                                            class="text-secondary underline">AfterTax.gr</a>
                                    </div>
                                    <div
                                        class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">€</span>
                                        <input [formControlName]="'taxPerYearGuarantor'" mask="separator.2"
                                            [thousandSeparator]="thousandSeparator"
                                            class="text-gray-500 bg-transparent outline-none flex-1">
                                    </div>
                                    <span *ngIf="hasError('guarantor.taxPerYearGuarantor')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>


                                <fieldset class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">
                                        <div>{{t('whatHouseholdDebtGuarantor')}}<span class="text-error">
                                                *</span></div><i class="icon-info-circle text-gray-700 text-base"></i>
                                    </label>
                                    <div
                                        class="border border-gray-300 rounded-full overflow-hidden flex flex-row gap-2 items-center py-3 px-4 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span class="text-gray-900 font-semibold">€</span>
                                        <input [formControlName]="'householdDebtGuarantor'" mask="separator.2"
                                            [placeholder]="t('totalDebtObligation')"
                                            [thousandSeparator]="thousandSeparator"
                                            class="text-gray-500 bg-transparent outline-none flex-1">
                                    </div>
                                    <div class="flex flex-row items-center gap-6 text-gray-500 text-sm">
                                        {{t('soWeKnowOtherDebtsGuarantor')}}
                                    </div>
                                    <span *ngIf="hasError('guarantor.householdDebtGuarantor')" class="text-error">{{
                                        'shared.error.requiredField'
                                        | transloco}}</span>
                                </fieldset>
                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf="activeStep=='branch'" [formGroupName]="'branch'">

                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('preferredBranch')}}</h4>


                            <fieldset class="flex flex-col gap-2 group/input">
                                <label
                                    class="font-semibold flex items-center gap-4 text-black group-disabled:text-gray-300">{{t('selectBranch')}}<i
                                        class="icon-info-circle text-gray-700 text-base"></i></label>
                                <div
                                    class="border border-gray-300 rounded-full bg-white overflow-hidden flex flex-row gap-2 items-center py-2 px-2 group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <div class="flex flex-row items-center gap-2 flex-1">
                                        <i class="icon-search text-gray-700 text-base"></i>
                                        <input [formControl]="searchControl" type="text"
                                            (focus)="onSearchControlFocus()" (blur)="onSerchControlBlur()"
                                            class="text-gray-500 bg-transparent outline-none flex-1">
                                        <input [formControlName]="'branchInfo'" type="hidden">
                                    </div>
                                    <button
                                        class="w-8 h-8 rounded-full border border-gray-300 flex items-center justify-center">
                                        <i class="icon-arrow-right text-black text-lg"></i>
                                    </button>
                                </div>
                                <ng-container *ngIf="nbgBranchesObject$ | async">
                                    <div *ngIf="showBranchList" (mousedown)="onDivMouseDown()"
                                        class="translate-y-4 left-0 w-full rounded-xl px-4 py-4 shadow-2xl bg-gray-50 text-xl z-50">

                                        <button type="button" (click)="onBranchSelect(nbgBranches[0])"
                                            class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-[2px] px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-start min-w-[280px] w-full">
                                            {{t('branchNearestMe')}}
                                        </button>

                                        <section>
                                            <div class="flex items-center my-4">
                                                <span
                                                    class="font-semibold text-xl text-gray-950">{{t('nearbyBranches')}}</span>
                                                <div class="flex-grow border-t border-gray-300 ml-2"></div>
                                            </div>
                                            <div>
                                                <button *ngFor="let branch of filteredBranches; let i = index"
                                                    (click)="onBranchSelect(branch)"
                                                    class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-3 px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                                                    <div class="flex items-center text-xl">
                                                        <i class="group-disabled/menu-item:text-gray-50 mr-4"></i>
                                                        <span
                                                            class="bg-transparent group-disabled/menu-item:text-gray-50 text-left">{{concatBranchInfo(branch)}}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </section>
                                    </div>
                                </ng-container>
                            </fieldset>

                        </ng-container>

                        <ng-container *ngIf="activeStep=='review'" [formGroupName]="'review'">
                            <h4 class="text-primary font-semibold">{{this.stepKeys.indexOf(this.activeStep) + 1}}.
                                {{t('reviewYourDetails')}}</h4>

                            <div id="accordion-flush">
                                <h2>
                                    <button (click)="toggleVisibility(0)" type="button"
                                        class="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-900 border-b border-gray-300 gap-3">
                                        <span>1. {{t('yourLoanAmount')}}</span>
                                        <i [ngClass]="hiddenSections[0] ? 'icon-chevron-down' : 'icon-chevron-up'"
                                            class="text-xl text-black"></i>
                                    </button>
                                </h2>
                                <div [ngClass]="{'hidden': hiddenSections[0]}">
                                    <div class="py-5 border-gray-300">
                                        <div class="grid grid-cols-1 md:grid-cols-2 rfs:gap-8 pb-4">
                                            <div class="flex flex-col gap-[14px]">
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('loanType')}}</p>
                                                    <p class="flex-1 text-black font-semibold">
                                                        <ng-container
                                                            [ngSwitch]="loanForm.get('loan.categoryId')?.value">
                                                            <ng-container
                                                                *ngSwitchCase="propertyMortgageCategoryId">{{t('answerLoanTypeMortgage')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="propertyRenovationCategoryId">{{t('answerLoanTypeRenovation')}}</ng-container>
                                                        </ng-container>

                                                    </p>
                                                </div>
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('loanAmount')}}</p>
                                                    <p class="flex-1 text-black font-semibold">€
                                                        {{loanForm.get('loan.amount')?.value | number}}</p>
                                                </div>
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('fixedInterestDuration')}}</p>
                                                    <p class="flex-1 text-black font-semibold">
                                                        {{loanForm.get('loan.fixedInterestDuration')?.value}}
                                                        {{t('years')}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col gap-[14px]">
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('propertyValue')}}</p>
                                                    <p class="flex-1 text-black font-semibold">€
                                                        {{loanForm.get('loan.realEstateValue')?.value | number}}</p>
                                                </div>
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('loanDuration')}}</p>
                                                    <p class="flex-1 text-black font-semibold">
                                                        {{loanForm.get('loan.duration')?.value}} {{t('years')}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2>
                                    <button (click)="toggleVisibility(1)" type="button"
                                        class="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-900 border-b border-gray-300 gap-3">
                                        <span>2. {{t('propertyDetails')}}</span>
                                        <i [ngClass]="hiddenSections[1] ? 'icon-chevron-down' : 'icon-chevron-up'"
                                            class="text-xl text-black"></i>
                                    </button>
                                </h2>
                                <div [ngClass]="{'hidden': hiddenSections[1]}">
                                    <div class="py-5 border-gray-300">
                                        <div class="grid grid-cols-1 md:grid-cols-2 rfs:gap-8 pb-4">
                                            <div class="flex flex-col gap-[14px]">
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{'shared.checkout.step-buyer-info.labels.address'
                                                        | transloco
                                                        }}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('property.address')?.value }}</p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col gap-[14px]">

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2>
                                    <button (click)="toggleVisibility(2)" type="button"
                                        class="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-900 border-b border-gray-300 gap-3">
                                        <span>3. {{t('yourDetails')}}</span>
                                        <i [ngClass]="hiddenSections[2] ? 'icon-chevron-down' : 'icon-chevron-up'"
                                            class="text-xl text-black"></i>
                                    </button>
                                </h2>
                                <div [ngClass]="{'hidden': hiddenSections[2]}">
                                    <div class="py-5 border-gray-300">
                                        <div class="grid grid-cols-1 md:grid-cols-2 rfs:gap-8 pb-4">
                                            <div class="flex flex-col gap-[14px]">
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('yearBornTitle')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('details.yearBorn')?.value }}</p>
                                                </div>

                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">
                                                        {{t('marriageStatusTitle')}}
                                                    </p>
                                                    <p class="flex-1 text-black font-semibold">
                                                        <ng-container
                                                            [ngSwitch]="loanForm.get('details.marriageStatus')?.value">
                                                            <ng-container
                                                                *ngSwitchCase="'single'">{{t('answerSingle')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'married'">{{t('answerMarried')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'cohabitation'">{{t('answerCohabitation')}}</ng-container>
                                                        </ng-container>
                                                    </p>
                                                </div>

                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('coborrower')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('details.haveCoborrower')?.value ?
                                                        ('shared.answers.yes' | transloco) :
                                                        ('shared.answers.no' | transloco) }}</p>
                                                </div>

                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('householdDebt')}}</p>
                                                    <p class="flex-1 text-black font-semibold">€ {{
                                                        loanForm.get('details.householdDebt')?.value | number }}</p>
                                                </div>

                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('taxPerYear')}}</p>
                                                    <p class="flex-1 text-black font-semibold">€ {{
                                                        loanForm.get('details.taxPerYear')?.value | number}}</p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col gap-[14px]">
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">
                                                        {{t('professionalStatusTitle')}}
                                                    </p>
                                                    <p class="flex-1 text-black font-semibold">
                                                        <ng-container
                                                            [ngSwitch]="loanForm.get('details.professionalStatus')?.value">
                                                            <ng-container
                                                                *ngSwitchCase="'employed'">{{t('employed')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'selfEmployed'">{{t('selfEmployed')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'retired'">{{t('retired')}}</ng-container>
                                                        </ng-container>
                                                    </p>
                                                </div>
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('children')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('details.children')?.value }}</p>
                                                </div>
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('grossSalaryPerYear')}}</p>
                                                    <p class="flex-1 text-black font-semibold">€ {{
                                                        loanForm.get('details.grossSalaryPerYear')?.value | number }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('nationality')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('details.nationality')?.value}}</p>
                                                </div>

                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('wherePayTaxTitle')}}</p>
                                                    <p class="flex-1 text-black font-semibold">
                                                        <ng-container
                                                            [ngSwitch]="loanForm.get('details.wherePayTax')?.value">
                                                            <ng-container
                                                                *ngSwitchCase="'inGreece'">{{t('inGreece')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'inEu'">{{t('inEu')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'outsideEu'">{{t('outsideEu')}}</ng-container>
                                                        </ng-container>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2>
                                    <button (click)="toggleVisibility(3)" type="button"
                                        class="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-900 border-b border-gray-300 gap-3">
                                        <span>4. {{t('yourGuarantor')}}</span>
                                        <i [ngClass]="hiddenSections[3] ? 'icon-chevron-down' : 'icon-chevron-up'"
                                            class="text-xl text-black"></i>
                                    </button>
                                </h2>
                                <div [ngClass]="{'hidden': hiddenSections[3]}">
                                    <div class="py-5 border-gray-300">
                                        <div class="grid grid-cols-1 md:grid-cols-2 rfs:gap-8 pb-4">
                                            <div class="flex flex-col gap-[14px]">
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('haveGuarantorTitle')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('guarantor.haveGuarantor')?.value ?
                                                        ('shared.answers.yes' | transloco) :
                                                        ('shared.answers.no' | transloco) }}</p>
                                                </div>
                                                <div *ngIf="loanForm.get('guarantor.childrenGuarantor')?.value"
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('children')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('guarantor.childrenGuarantor')?.value }}</p>
                                                </div>



                                                <div *ngIf="loanForm.get('guarantor.householdIncomeGuarantor')?.value"
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('grossSalaryPerYear')}}</p>
                                                    <p class="flex-1 text-black font-semibold">€ {{
                                                        loanForm.get('guarantor.householdIncomeGuarantor')?.value |
                                                        number
                                                        }}</p>
                                                </div>


                                                <div *ngIf="loanForm.get('guarantor.householdDebtGuarantor')?.value"
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('householdDebt')}}</p>
                                                    <p class="flex-1 text-black font-semibold">€ {{
                                                        loanForm.get('guarantor.householdDebtGuarantor')?.value | number
                                                        }}</p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col gap-[14px]">
                                                <div *ngIf="loanForm.get('guarantor.yearBornGuarantor')?.value"
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('yearBornTitle')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('guarantor.yearBornGuarantor')?.value }}</p>
                                                </div>
                                                <div *ngIf="loanForm.get('guarantor.marriageStatusGuarantor')?.value"
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">
                                                        {{t('marriageStatusTitle')}}
                                                    </p>
                                                    <p class="flex-1 text-black font-semibold">
                                                        <ng-container
                                                            [ngSwitch]="loanForm.get('guarantor.marriageStatusGuarantor')?.value">
                                                            <ng-container
                                                                *ngSwitchCase="'single'">{{t('answerSingle')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'married'">{{t('answerMarried')}}</ng-container>
                                                            <ng-container
                                                                *ngSwitchCase="'cohabitation'">{{t('answerCohabitation')}}</ng-container>
                                                        </ng-container>
                                                    </p>
                                                </div>

                                                <div *ngIf="loanForm.get('guarantor.taxPerYearGuarantor')?.value"
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('taxPerYear')}}</p>
                                                    <p class="flex-1 text-black font-semibold" mask="">€ {{
                                                        loanForm.get('guarantor.taxPerYearGuarantor')?.value | number
                                                        }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2>
                                    <button (click)="toggleVisibility(4)" type="button"
                                        class="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-900 border-b border-gray-300 gap-3">
                                        <span>5. {{t('preferredBranch')}}</span>
                                        <i [ngClass]="hiddenSections[4] ? 'icon-chevron-down' : 'icon-chevron-up'"
                                            class="text-xl text-black"></i>
                                    </button>
                                </h2>
                                <div [ngClass]="{'hidden': hiddenSections[4]}">
                                    <div class="py-5 border-gray-300">
                                        <div class="grid grid-cols-1 md:grid-cols-2 rfs:gap-8 pb-4">
                                            <div class="flex flex-col gap-[14px]">
                                                <div
                                                    class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                                                    <p class="flex-1">{{t('preferredBranch')}}</p>
                                                    <p class="flex-1 text-black font-semibold">{{
                                                        loanForm.get('branch.branchInfo')?.value }}</p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col gap-[14px]">

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <label for="checkbox-selector-test-check-1" class="checkbox-selector">
                                <input [formControlName]="'consent'" type="checkbox" class="hidden"
                                    id="checkbox-selector-test-check-1" name="checkbox-selector-check-group-2">
                                <div class="checkbox-selector-content">
                                    <span class="checkbox-selector-text">{{t('consentLoan')}} <a
                                            class="text-primary">{{'shared.learnMore'
                                            | transloco
                                            }}</a></span>
                                </div>
                            </label>
                            <span *ngIf="hasError('review.consent')"
                                class="text-error">{{t('consentLoanInfoError')}}</span>
                        </ng-container>
                    </div>
                    <div class="max-w-[360px] w-full" *ngIf="activeStep!='property'">
                        <app-doughnut-chart [showEstimatedFees]="true"></app-doughnut-chart>
                    </div>
                </div>
            </ng-container>

            <app-navigation-buttons *ngIf="activeStep=='property'" [HideSkip]="true" [HidePrevious]="true"
                (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="onNextClick()"></app-navigation-buttons>
            <app-navigation-buttons *ngIf="activeStep=='loan'" [HideSkip]="true"
                (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                (NextButtonClicked)="onNextClick()"></app-navigation-buttons>
            <app-navigation-buttons *ngIf="activeStep=='details'" [HideSkip]="true"
                (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                (NextButtonClicked)="onNextClick()"></app-navigation-buttons>
            <app-navigation-buttons *ngIf="activeStep=='guarantor'" [HideSkip]="true"
                (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                (NextButtonClicked)="onNextClick()"></app-navigation-buttons>
            <app-navigation-buttons *ngIf="activeStep=='branch'" [HideSkip]="true"
                (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                (NextButtonClicked)="onNextClick()"></app-navigation-buttons>
            <app-navigation-buttons *ngIf="activeStep=='review'" [HideSkip]="true" [HideNext]="false"
                (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="onPrevClick()"
                (NextButtonClicked)="onNextClick()"
                [CustomNextTitle]="t('submitLoanAppButton')"></app-navigation-buttons>

        </div>
    </ng-container>
    <ng-container *ngIf="showResultPage">
        <app-loan-application-result [formData]="loanFormData"></app-loan-application-result>
        <app-navigation-buttons [HideSkip]="true" [HideNext]="false" [HidePrevious]="true"
            (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="goToMyRequests()"
            [CustomNextTitle]="t('trackLoanInMyRequestsButton')"></app-navigation-buttons>
    </ng-container>
</ng-container>

<lib-toaster-container></lib-toaster-container>
