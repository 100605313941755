import { Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from '@indice/ng-components';
import { TranslocoDirective } from '@jsverse/transloco';
import { catchError, filter, mergeMap, of, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { AccountOwner, PortalApiService } from 'src/app/services/portal-api.service';
import { SignPromptActionsModalComponent } from './sign-prompt-actions-modal/sign-prompt-actions-modal.component';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { response } from 'express';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [ReactiveFormsModule,TranslocoDirective,CommonModule,NgxMaskDirective],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css',
  providers: [provideNgxMask()],
})
export class ContactComponent {

  form = new UntypedFormGroup({
    fullName: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    message: new UntypedFormControl('',Validators.required),
    acceptTerms : new UntypedFormControl(false,Validators.required)
  });

  protected isLoggedIn: boolean = false;
  protected acceptedTerms : boolean = false;
  protected isHuman : boolean = false; // when captcha is implemented


  constructor(
    private portalApi: PortalApiService,
    private authService: AuthService,
    private modalService: ModalService
  ){
    this.form.get('acceptTerms')?.valueChanges.subscribe((checked) => {
      this.acceptedTerms = checked;
    });
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().pipe(
      tap(s=>this.isLoggedIn = s),
      mergeMap((res:boolean)=> {
       if(res) {
        return this.portalApi.getAccountOwner()
       }else {
        this.openSignIn();
        return of(null)
       }
      }),
      filter(res=> res !== null),
      tap(res=>{
        if(res){
          this.form.get('fullName')?.setValue(`${res.firstName} ${res.lastName}`);
          this.form.get('email')?.setValue(res.email);
        }
      }),
      catchError(err=> of(AccountOwner))
    ).subscribe();
  }

  openSignIn() {
    this.modalService.show(SignPromptActionsModalComponent, {
      keyboard: true,
    });
  }
}
