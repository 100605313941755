<div class="flex flex-col w-full px-4 py-10 md:px-[72px] md:pt-[72px] md:pb-8" *transloco="let t; prefix: 'faq'">
  <div class="flex-col md:flex-row md:justify-between justify-start items-start text-left gap-10 inline-flex">
    <div class="self-stretch flex-col justify-start items-start gap-4 flex md:w-6/12">
      <div class="self-stretch text-black text-2xl font-normal">{{t('title')}}</div>
      <div class="self-stretch opacity-90 text-gray-900 text-base font-normal">
        {{t('primaryHeader')}}</div>
    </div>
    <img class="w-[206.57px] h-[172px]" src="../../../assets/img/loudspeaker.png" />
  </div>
</div>

<section class="rfs:pb-28 px-4 md:px-[72px] md:pb-8 md:w-9/12" *transloco="let t; prefix: 'faq'">
  <div class="md:flex pt-10 gap-[84px]">
    <div class="flex-1">
      <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">

          <ng-container *ngFor="let faqGroup of faqGroup">
            <h2 class="text-secondary font-semibold text-2xl">
              {{ faqGroup.groupTitle }}
            </h2>
            <!-- <div class=""> -->
              <ng-container *ngFor="
                  let faqItem of faqGroup.faqItems;
                  index as index
                ">
                <app-faq-item [question]="faqItem.question" [answer]="faqItem.answer" [isLastItem]="
                    index + 1 ===
                    faqGroup.faqItems.length
                  "></app-faq-item>
              </ng-container>
            <!-- </div> -->
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>