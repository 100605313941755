import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MediaTag, PortalApiService } from 'src/app/services/portal-api.service';
import { Media } from '../photos.component';
import { CommonModule } from '@angular/common';
import { Modal, ModalOptions } from '@indice/ng-components';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-photo-tag-modal',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, TranslocoPipe],
  templateUrl: './photo-tag-modal.component.html',
  styleUrl: './photo-tag-modal.component.css'
})
export class PhotoTagModalComponent {
  propertyId: string | undefined;
  media: Media | undefined;
  isSecondary: boolean | undefined;

  public mediaTag = MediaTag;

  constructor(private _modal: Modal, private _portalApi: PortalApiService, @Inject(ModalOptions) private _options: ModalOptions<PhotoTagModalComponent>) {
    this.propertyId = _options?.initialState?.propertyId as string;
    this.media = _options?.initialState?.media as Media;
    this.isSecondary = _options.initialState?.isSecondary as boolean;
  }

  public onTagClick(selectedTag: string | undefined) {
    if (this.media) {
      this.media.tag = selectedTag;
    }
  }

  public closeModal() {
    this._modal.hide({ tag: this.media?.tag });
  }

  public onCancel() {
    this._modal.hide();
  }

  public onDeletePhoto() {
    if (this.media?.mediaGuid) {
      this._modal.hide({ delete: true, mediaGuid: this.media.mediaGuid, isSecondary: this.isSecondary });
    }
  }

  public onSaveTags() {
    if (this.propertyId && this.media?.mediaGuid) {
      let tagToSet = this.media?.tag ? MediaTag[this.media.tag as keyof typeof MediaTag] : undefined;
      this._portalApi.updateMediaTag(this.propertyId, this.media?.mediaGuid, tagToSet).subscribe(() => {
        this.closeModal();
      });
    }
  }

  public unsorted() {
    return 0;
  }



}
