import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeEn from '@angular/common/locales/en';
import localeGreek from '@angular/common/locales/el';

registerLocaleData(localeGreek);
registerLocaleData(localeEn);

import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AuthRenewComponent } from './components/auth-renew/auth-renew.component';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
  declarations: [AuthCallbackComponent, AuthRenewComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    LayoutModule
  ],
  exports: []
})
export class CoreModule {
  constructor(){
  }
}
