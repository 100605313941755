<div (clickOutside)="closeDropdown()">
    <div class="min-w-[276px] w-full px-1 py-1.5 flex-col justify-start items-start gap-1.5 inline-flex" [ngClass]="{'border-b border-gray-300': hasBorder}">
        @if (isHomepage) {
          <span *ngIf="title"
          class="font-semibold text-black text-base">
            <i [ngClass]="iconClasses"></i> {{title}}
          </span>
          <button
            (click)="toggleDropdown()"
            class="w-full justify-between items-center gap-2 flex text-base font-normal ml-5"
            [ngClass]="selectedItems.length ? 'text-black': 'text-gray-700'">
            {{selectedLabel}}
          </button>
        } @else {
          @if(!hasPlaceholder) {
            <div *ngIf="title" (click)="toggleDropdown()"
                class="text-black font-semibold text-lg flex justify-between w-full">
                <span>{{title}}</span>
                  <i
                    class="w-5 h-5 text-md font-normal"
                    [ngClass]="showResults? 'icon-chevron-down text-primary-700' : 'icon-chevron-up'">
                </i>
              </div>
          } @else {
            <span *ngIf="title"
              class="text-gray-700 text-xs font-semibold uppercase">{{title}}
            </span>
            <button
            (click)="toggleDropdown()"
            class="w-full justify-between items-center gap-2 flex text-base font-normal" [ngClass]="selectedItems.length ? 'text-black': 'text-gray-700'"> {{selectedLabel}}
              <i
                  class="w-5 h-5 text-md font-normal"
                  [ngClass]="showResults? 'icon-chevron-down text-primary-700' : 'icon-chevron-up'">
              </i>
            </button>
          }
        }

    </div>

    <div *ngIf="showResults"
        class="flex flex-col md:min-w-[320px] md:absolute md:top-[100%] translate-y-4 md:left-0 md:rounded-xl px-4 md:p-4 gap-1 md:shadow-2xl md:bg-white max-h-[300px] overflow-y-auto md:overflow-x-hidden w-full"
        [ngClass]="isHomepage ? 'bg-white md:translate-y-8' : 'bg-gray-50 md:translate-y-4'"
        >

        <label *ngFor="let item of dropdownItems; let i = index" role="button" [attr.for]="'menu-item-checkmark-'+i"
            class="menu-item-checkmark" [ngClass]="(item.checked ? 'checked ' : '') + (isHomepage ? '!min-w-full' : '')">
            <input type="checkbox" class="hidden" [attr.id]="'menu-item-checkmark-'+i" name="menu-item-checkmark-group1"
                (change)="ItemSelected(item)" [checked]="item.checked===true">
            <div class="menu-item-checkmark-content">
                <div class="flex justify-between items-center">
                    <span class="menu-item-checkmark-text" [ngClass]="item.checked ? 'text-green-700': ''">{{item.label}}</span>
                    <i [ngClass]="item.checked ? 'icon-check text-green-700': 'hidden'"></i>
                </div>
            </div>
        </label>
    </div>
</div>
