import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { TranslocoService } from '@jsverse/transloco';
import { NgProgressModule } from 'ngx-progressbar';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    CoreModule,
    LayoutModule,
    SharedModule,
    NgProgressModule
  ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'Neon Platform Portal';
  isHomePage = false;
  // Boolean to control dynamic classes for main
  siteScrolled: boolean = false;

  // Boolean to control dynamic classes for header
  public currentRoute: string = '';

  constructor(private router: Router, private _translate: TranslocoService, private _authService: AuthService) { }

  ngOnInit(): void {
    
    this._translate.setActiveLang(this._authService.getUserLocaleOrDefault().slice(0,2));
    
    // Get current route
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        this.currentRoute = routerData.url;
        if (this.currentRoute.includes("home") || this.currentRoute === "/" || this.currentRoute.includes("properties/")) {
          this.isHomePage = true;
        }
        else {
          this.isHomePage = false;
        }
      }

    });

  }

  /**
   * @description: Function to add dynamic classes to main
   * @param: none
   * @returns: none
   * @memberof AppComponent
   */
  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.pageYOffset > 0) {
      this.siteScrolled = true;
    } else {
      this.siteScrolled = false;
    }
  }
}
