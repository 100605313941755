import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapsDistanceService{


  calculateDistancesForAllModes(origin: google.maps.LatLngLiteral, destination: google.maps.LatLngLiteral) {
    const service = new google.maps.DistanceMatrixService();

    const travelModes: google.maps.TravelMode[] = [
      google.maps.TravelMode.DRIVING,
      google.maps.TravelMode.WALKING,
      google.maps.TravelMode.TRANSIT,
      google.maps.TravelMode.BICYCLING
    ];

    const promises = travelModes.map((mode) => {
      const request = {
        origins: [new google.maps.LatLng(origin.lat, origin.lng)],
        destinations: [new google.maps.LatLng(destination.lat, destination.lng)],
        travelMode: mode
      };

      return new Promise((resolve, reject) => {
        service.getDistanceMatrix(request, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            resolve({ mode, response });
          } else {
            reject(`Error fetching distance for mode ${mode}: ${status}`);
          }
        });
      });
    });

    return Promise.all(promises);
  }

  calculateAllDistances(target : google.maps.LatLngLiteral, property: any) : Promise<calculatedTravels[]> {
    return this.calculateDistancesForAllModes(target, property).then((results: any) => {
      let distances: calculatedTravels[] = [];
      results.forEach((result: any) => {
        const mode = result.mode;
        const response = result.response;
        if (response.rows[0].elements[0].status === 'OK') {
          distances.push({
            travelMode: mode,
            distance: response.rows[0].elements[0].distance.text,
            duration: response.rows[0].elements[0].duration.text
          } as calculatedTravels);
        }
      });
      return distances;
    });
  }

}

export interface calculatedTravels{
    travelMode: string | undefined,
    duration: string | undefined,
    distance: string | undefined
}
