<div class="flex-1" [formGroup]="contentFormGroup" *transloco="let t; prefix: 'property-listing.content'">
    <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
            <h2 class="h2 text-black">{{'property-listing.tabs.titleAndDescription' | transloco}}</h2>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.greekText')}}</h3>
            <fieldset class="flex flex-col gap-2 group/input">
                <label class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                    {{t('labels.titleEl')}}
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                </label>
                <span class="text-sm text-gray-700 disabled:text-gray-300">
                    {{t('helperText.titleEl')}}
                </span>
                <input placeholder="" [maxLength]="1024"
                    class="bg-white rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                    formControlName="titleEl">
            </fieldset>
            <fieldset class="flex flex-col gap-2 group/input">
                <label class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                    {{t('labels.shortDescriptionEl')}}
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                </label>
                <textarea rows="6" placeholder="Η περιγραφή σας" formControlName="shortDescriptionEl"
                    class="bg-white rounded-[1.5rem] border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                </textarea>
            </fieldset>
        </div>

        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('subheaders.englishText')}}</h3>
            <fieldset class="flex flex-col gap-2 group/input">
                <label class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                    {{t('labels.titleEn')}}
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                </label>
                <span class="text-sm text-gray-700 disabled:text-gray-300">
                    {{t('helperText.titleEn')}}
                </span>
                <input placeholder="" formControlName="titleEn" [maxLength]="1024"
                    class="bg-white rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
            </fieldset>
            <fieldset class="flex flex-col gap-2 group/input">
                <label class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                    {{t('labels.shortDescriptionEn')}}
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                </label>
                <textarea rows="6" placeholder="Your description" formControlName="shortDescriptionEn"
                    class="bg-white rounded-[1.5rem] border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                </textarea>
            </fieldset>
        </div>

        <app-advisor-callback [advisor]="advisor"></app-advisor-callback>

        <div class="border-t-2 pt-7 flex justify-between">
            <button (click)="navigateToTab(propertyListingTab.BasicInfo)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                <i class="icon-arrow-left text-lg"></i>
                {{'property-listing.tabs.basicInformation' | transloco}}
            </button>
            <button (click)="navigateToTab(propertyListingTab.Features)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                {{'property-listing.tabs.roomsAndFeatures' | transloco}}
                <i class="icon-arrow-right text-lg"></i>
            </button>
        </div>
    </div>
</div>