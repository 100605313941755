import { CommonModule, Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, of, tap } from 'rxjs';
import { ServicesPaymentsApiService, InsertOrderItemRequest, InsertOrderRequest, OrderResponse } from 'src/app/core/services/payments-api-service';
import { UnikoServicesConfig, UnikoServiceTypes } from 'src/app/services/payments-api.service';
import { PortalApiService, PropertyListingDetails } from 'src/app/services/portal-api.service';
import { ToasterService, ToastType } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-service-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './service-details.component.html',
  styleUrl: './service-details.component.css',
})
export class ServiceDetailsComponent {
  protected propertyCode: string | undefined;
  protected serviceType: UnikoServiceTypes;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _portalApi: PortalApiService,
    private _paymentsApiService: ServicesPaymentsApiService,
    @Inject(ToasterService) private _toastService: ToasterService,
  ) {
    const serviceTypeParam = this._activatedRoute.snapshot.paramMap.get('serviceType');
    this.serviceType = UnikoServiceTypes[serviceTypeParam as keyof typeof UnikoServiceTypes];
    this.propertyCode = this._activatedRoute.snapshot.queryParams['property'];

    if (!this.serviceType) {
      this._location.back();
      console.error('service type not found');
    }
  }

  protected requestSelectedService() {
    if (this.propertyCode) {
      this._portalApi.getListingDetails(this.propertyCode).pipe(
        catchError((err: any) => {
          this._toastService.show(ToastType.Error, 'Failed to get property', err.message || 'Unknown error', 5000);

          return of(undefined);
        }),
        filter((f): f is PropertyListingDetails => !!f), // Filter out undefined values
        tap((res: PropertyListingDetails) => {
          const propertyId = res?.propertyId;
          const sku = UnikoServicesConfig.find(f => f.type == this.serviceType)?.sku;
          if (sku && propertyId) {
            let orderItems: InsertOrderItemRequest[] = [];

            orderItems.push(new InsertOrderItemRequest({
              sku: sku,
              quantity: 1,
              propertyId: propertyId,
            }));

            let body = new InsertOrderRequest({
              currency: 'EUR',
              orderItems: orderItems,
            });
            this._paymentsApiService.createMyOrder(body).pipe(
              tap((response: OrderResponse) => {
                if (response && response.number) {
                  this._router.navigate([`/checkout/${response.number}`]);
                }
              })

            ).subscribe();
          } else {
            console.error('sku or property id not found')
          }

        })).subscribe();
    }
    else {
      this.navigateToPropertySelect();
    }
  }

  protected navigateToPropertySelect(): void {
    const serviceTypeString = UnikoServiceTypes[this.serviceType];
    const url = `/services/${serviceTypeString}/select-property`;
    this._router.navigate([`/services/${serviceTypeString}/select-property`]);
  }

  protected backToAllServices(): void {
    this._router.navigate([`/services`], {
      queryParams: {
        property: this.propertyCode
      },
      queryParamsHandling: 'merge',
    });
  }

}

