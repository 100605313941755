<div
  class="chart-container bg-white rounded-medium px-3 py-6 flex flex-col min-w-[323px]"
  *transloco="let t; prefix: 'shared.loan-calculator.doughnut-chart'"
>
  <div class="flex justify-center">
    <div class="relative">
      <canvas id="yearsChart">{{ chart }}</canvas>
      <div class="absolute inset-0 flex justify-center items-center">
        <div class="text-center">
          <span class="font-semibold text-gray-700 block">{{
            t("text.totalLoanAmount")
          }}</span>
          <span class="text-black text-[2.5rem] font-bold block"
            >{{
              loanValues?.loanCalculatorValues?.loanAmount
                | currency: 'EUR' : 'symbol' : '1.0-0'
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="mt-6 min-h-[180px]">
    <div
      *ngIf="
        loanValues?.loanCalculatorValues &&
        loanValues?.loanCalculatorValues?.loanFixedDuration &&
        loanValues?.payments &&
        loanValues?.payments?.paymentAmountInitial
      "
      class="pl-4 flex flex-row justify-between relative before:absolute before:content-[' '] before:w-1 before:h-1 before:rounded-full before:bg-[#059B9C] before:top-[6px] before:left-0 before:translate-x-1/2"
    >
      <div class="flex flex-col gap-1">
        <div class="text-[#059B9C] font-semibold text-xs">
          {{
            (loanValues?.loanCalculatorValues)!.loanFixedDuration <
            (loanValues?.loanCalculatorValues)!.loanDuration
              ? t("text.first") +
                " " +
                loanValues?.loanCalculatorValues?.loanFixedDuration +
                " " +
                t("text.years")
              : ""
          }}
        </div>
        <div class="flex flex-row items-end font-semibold">
          <span class="text-[#059B9C] text-xl">{{
            loanValues?.payments?.paymentAmountInitial | currency: 'EUR' : 'symbol' : '1.0-2'
          }}</span>
          <span class="text-xs text-gray-900">/ {{ t("text.month") }}</span>
        </div>
        <div class="text-xs text-gray-900">
          {{ t("text.fixedInterestRate") }}: {{ this.loanValues!.fixedRate }}%
        </div>
      </div>
      <div
        class="w-9 h-9 rounded-full text-center flex items-center justify-center bg-[#059B9C] text-white font-semibold text-xs"
      >
        {{ loanValues?.loanCalculatorValues?.loanFixedDuration }} <br />
        {{ t("text.yearAbbreviation") }}
      </div>
    </div>
    <div
      class="pl-4 mt-2 flex flex-row justify-between relative before:absolute before:content-[' '] before:w-1 before:h-1 before:rounded-full before:bg-[#059B9C] before:top-[6px] before:left-0 before:translate-x-1/2"
      *ngIf="
        loanValues?.loanCalculatorValues &&
        (loanValues?.loanCalculatorValues)!.loanDuration !==
          (loanValues?.loanCalculatorValues)!.loanFixedDuration &&
        loanValues?.payments &&
        ((loanValues?.payments)!.paymentAmountInitial ||
          (loanValues?.payments)!.paymentAmountFinal)
      "
    >
      <div class="flex flex-col gap-1">
        <div class="text-[#009B67] font-semibold text-xs">
          {{
            (loanValues?.loanCalculatorValues)!.loanDuration >
              (loanValues?.loanCalculatorValues)!.loanFixedDuration &&
            (loanValues?.loanCalculatorValues)!.loanFixedDuration !== 0
              ? t("text.remaining") +
                " " +
                ((loanValues?.loanCalculatorValues)!.loanDuration -
                  (loanValues?.loanCalculatorValues)!.loanFixedDuration) +
                " " +
                t("text.years")
              : ""
          }}
        </div>
        <div class="flex flex-row items-end font-semibold">
          <span class="text-[#009B67] text-xl"
            >{{
              ((loanValues?.payments)!.paymentAmountFinal > 0
                ? loanValues?.payments?.paymentAmountFinal
                : loanValues?.payments?.paymentAmountInitial
              ) | currency: 'EUR' : 'symbol' : '1.0-2'
            }}</span
          >
          <span class="text-xs text-gray-900">/ {{ t("text.month") }} </span>
        </div>
        <div class="text-xs text-gray-900">
          {{ t("text.variableRate") }}: {{ t("text.currently") }}
          {{ loanValues?.variableRate }}%
        </div>
      </div>
      <div
        class="w-9 h-9 rounded-full text-center flex items-center justify-center bg-[#009B67] text-white font-semibold text-xs"
      >
        {{
          (loanValues?.loanCalculatorValues)!.loanDuration -
            (loanValues?.loanCalculatorValues)!.loanFixedDuration
        }}
        <br />
        {{ t("text.yearAbbreviation") }}
      </div>
    </div>
    @if (showEstimatedFees && mortgageLoanSelected) {
    <div
      class="border-t pt-4 mt-6 flex flex-row gap-x-2 items-center min-h-[60px]"
    >
      <span class="text-gray-700 text-sm max-w-[320px] font-normal"
        >{{ t("text.estimatedFees") }}: {{
          totalEstimatedFees | currency: 'EUR' : 'symbol' : '1.0-2'
        }}</span
      >
    </div>
    }
  </div>
</div>
