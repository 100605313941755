import { Injectable } from '@angular/core';

import * as environment from '../appSettings';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    constructor() { }

    public log(message: any, ...params: any[]): void {
        if (!environment.appSettings.production) {
            params ? console.log(message, params) : console.log(message);
        }
    }

    public info(message: any, ...params: any[]): void {
        if (!environment.appSettings.production) {
            params ? console.info(message, params) : console.info(message);
        }
    }

    public warn(message: any, ...params: any[]): void {
        if (!environment.appSettings.production) {
            params ? console.warn(message, params) : console.warn(message);
        }
    }

    public error(message: any, ...params: any[]): void {
        if (!environment.appSettings.production) {
            params ? console.error(message, params) : console.error(message);
        }
    }
}
