import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private mobileBreakpoint = 768; // Set mobile breakpoint width (in pixels)
  private windowResize$ = fromEvent(window, 'resize').pipe(
    debounceTime(100), // Debounce to limit how often the resize event triggers
    map(() => window.innerWidth)
  );

  // Observable that emits `true` if the window width is below the mobile breakpoint
  isMobile$ = this.windowResize$.pipe(
    startWith(window.innerWidth), // Emit initial width on subscription
    map((width) => width < this.mobileBreakpoint),
    startWith(window.innerWidth < this.mobileBreakpoint)
  );
}
