<ng-container *transloco="let t; prefix: 'shared.checkout.checkout-fail'">
    <main *ngIf="(order$ | async)" class="flex-1 flex flex-col mt-[5px]">

        <section class="flex-1 flex justify-center items-center container pt-[72px]">
            <div class="flex-col md:flex-row flex rounded-lg overflow-hidden max-w-[1024px]">
                <div class="p-8 flex flex-col rounded-lg">
                    <h1 class="h1 font-sm text-black leading-snug">{{t('header')}}</h1>
                    <p class="max-w-[380px]">
                        <span class="block rfs:text-[2rem]"></span>
                        {{t('tryAgainInfoText')}} <br />
                        {{t('errorCodeInfoText', { errorCode: order?.transaction?.errorCode })}}
                    </p>
                </div>
                <div class="p-4 flex flex-col rounded-lg">
                    <img class="object-center object-contain" src="assets/on-boarding/bg-4.webp"
                        alt="property with society karikatur">
                </div>
            </div>
        </section>


    </main>
    <app-navigation-buttons [HideSkip]="true" [HidePrevious]="true" (NextButtonClicked)="onNextClick()"
        [CustomNextTitle]="t('titles.tryAgain')" [HideNextArrow]="true"></app-navigation-buttons>
</ng-container>