import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProgressStepperLightComponent } from '../progress-stepper-light/progress-stepper-light.component';

@Component({
  selector: 'progress-stepper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-stepper.component.html',
  styleUrl: './progress-stepper.component.css'
})
export class ProgressStepperComponent {
  @Input() StepNo: number = 1;
  @Input() Steps: string[] = [];
  stepPercentage: number = 0;
  ngOnInit() {
    if (this.Steps.length > 0)
      this.stepPercentage = Math.floor((this.StepNo / this.Steps.length) * 100)
  }

}


