import { CommonModule } from '@angular/common';
import { Advisor, FileParameter, MediaOrder, MediaOrderRequest, Photo, PhotoResultSet } from '../../../services/portal-api.service';
import { Component, Input, OnInit, Output } from '@angular/core';
import { PortalApiService } from 'src/app/services/portal-api.service';
import { BehaviorSubject, Observable, Subject, debounceTime, distinctUntilChanged, forkJoin, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { AdvisorCallbackComponent } from 'src/app/shared/components/advisor-callback/advisor-callback.component';
import { PropertyListingTab } from '../property-listing.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop'
import { ModalService } from '@indice/ng-components';
import { PhotoTagModalComponent } from './photo-tag-modal/photo-tag-modal.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { RouterModule } from '@angular/router';
import { RequestPhotographyModalComponent } from './request-photography-modal/request-photography-modal.component';
import { ToasterContainerComponent } from "../../../shared/components/toast/toast-container.component";

@Component({
  selector: 'app-photos',
  standalone: true,
  imports: [CommonModule, AdvisorCallbackComponent, SharedModule, DragDropModule, TranslocoDirective, TranslocoPipe, RouterModule, ToasterContainerComponent],
  templateUrl: './photos.component.html',
  styleUrl: './photos.component.css'
})
export class PhotosComponent implements OnInit {
  @Input() propertyId: string | undefined;
  @Input() advisor: Advisor | undefined;

  @Output() onNextTab: Subject<PropertyListingTab> = new Subject();
  @Output() isComplete: Subject<boolean> = new Subject();

  public coverPhoto = new Media({ isDefault: true });
  public secondaryPhotos: Media[] = [];
  public propertyListingTab = PropertyListingTab;
  public coverDragover = false;
  public secondaryDragover = false;

  private coverPhotoSubject = new BehaviorSubject<Media | null>(null);
  private secondaryPhotosSubject = new BehaviorSubject<Media[]>([]);
  private photosReorderedSubject = new BehaviorSubject<MediaOrderRequest | null>(null);

  constructor(private _portalApi: PortalApiService, private _modalService: ModalService) {

  }

  ngOnInit(): void {
    if (this.propertyId) {
      this._portalApi.getPhotos(this.propertyId).subscribe((photos: PhotoResultSet) => {

        let coverPhoto = photos.items?.filter(x => x.isDefault)[0];
        if (coverPhoto) {
          this.coverPhoto.mediaGuid = coverPhoto.mediaGuid;
          this.coverPhoto.previewSrc = coverPhoto.uri;
          this.coverPhoto.fallbackSrc = coverPhoto.uri?.replace('webp', 'jpg');
          this.coverPhoto.tag = coverPhoto.tag;
        }

        let otherPhotos = photos.items?.filter(x => !x.isDefault);
        if (otherPhotos) {
          this.secondaryPhotos = otherPhotos.map((x) => new Media({
            previewSrc: x.uri,
            fallbackSrc: x.uri?.replace('webp', 'jpg'),
            order: x.order,
            mediaGuid: x.mediaGuid,
            isDefault: false,
            tag: x.tag
          }));
          this.outputCompletionStatus();
        }
      });
    }

    this.coverPhotoSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(() => {
        this.outputCompletionStatus();
        if (this.coverPhoto.file && this.propertyId) {
          let fileParam: FileParameter = { data: this.coverPhoto.file, fileName: this.coverPhoto.file.name };
          return this._portalApi.uploadPhoto(this.propertyId, fileParam, undefined, undefined, this.coverPhoto.isDefault);
        } else {
          return of(null);
        }
      })
    ).subscribe((res) => {
      if (res) {
        this.coverPhoto.mediaGuid = res.mediaGuid;
      }
    });

    this.secondaryPhotosSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((val) => {
        this.outputCompletionStatus();
        if (val && val.length > 0) {
          let chainedUploads: Observable<Photo>[] = [];
          val.forEach(secondaryPhoto => {
            if (secondaryPhoto.file && this.propertyId) {
              let fileParam: FileParameter = { data: secondaryPhoto.file, fileName: secondaryPhoto.file.name };
              chainedUploads.push(this._portalApi.uploadPhoto(this.propertyId, fileParam, undefined, undefined, secondaryPhoto.isDefault, secondaryPhoto.order));
            }
          })
          return forkJoin(chainedUploads);
        } else {
          return of(null);
        }
      })
    ).subscribe((res) => {
      if (res && res?.length > 0) {
        this.secondaryPhotosSubject.value.forEach((uploadedMedia, index) => {
          uploadedMedia.mediaGuid = res[index].mediaGuid;
        });
      }
    });

    this.photosReorderedSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((val) => {
        if (this.propertyId && val) {
          return this._portalApi.updateMediaOrder(this.propertyId, val);
        } else {
          return of(null);
        }
      })
    ).subscribe();
  }

  public onDragOver(event: any) {
    event.preventDefault();
  }

  public onCoverDropSuccess(event: any) {
    event.preventDefault();
    this.coverPhoto.file = event.dataTransfer.files[0];
    this.readFile(this.coverPhoto);

    if (this.coverPhoto.file) {
      this.coverPhotoSubject.next(this.coverPhoto);
    }
    this.coverDragover = false;
  }

  public onSecondaryDropSuccess(event: any) {
    event.preventDefault();
    let files: FileList | null = event.dataTransfer.files;
    let uploadedFiles: Media[] = [];
    Array.prototype.forEach.call(files, (file) => {
      let order = Math.max(...this.secondaryPhotos.map(x => x.order ?? 0), 0) + 1
      let media = new Media({ file: file, isDefault: false, order: order });
      this.readFile(media);
      if (media.file) {
        uploadedFiles.push(media);
        this.secondaryPhotos.push(media);
      }
    });

    if (uploadedFiles.length > 0) {
      this.secondaryPhotosSubject.next(uploadedFiles);
    }
    this.secondaryDragover = false;
  }

  public onCoverPhotoSelected(event: any): void {
    this.coverPhoto.file = event.target.files[0];
    this.readFile(this.coverPhoto);

    if (this.coverPhoto.file) {
      this.coverPhotoSubject.next(this.coverPhoto);
    }
  }

  public onSecondaryPhotosSelected(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    let files: FileList | null = element.files;
    let uploadedFiles: Media[] = [];
    Array.prototype.forEach.call(files, (file) => {
      let order = Math.max(...this.secondaryPhotos.map(x => x.order ?? 0), 0) + 1
      let media = new Media({ file: file, isDefault: false, order: order });
      this.readFile(media);
      if (media.file) {
        uploadedFiles.push(media);
        this.secondaryPhotos.push(media);
      }
    });

    if (uploadedFiles.length > 0) {
      this.secondaryPhotosSubject.next(uploadedFiles);
    }
  }

  public onDeletePhoto(mediaGuid: string | undefined, isSecondary = false) {
    if (this.propertyId && mediaGuid) {
      this._portalApi.deletePhoto(this.propertyId, mediaGuid).subscribe(() => {
        if (!isSecondary) {
          this.coverPhoto.previewSrc = undefined;
        } else {
          this.secondaryPhotos = this.secondaryPhotos.filter(x => x.mediaGuid != mediaGuid);
        }
        this.outputCompletionStatus();
      });
    }
  }

  public onSecondaryPhotoMove(event: CdkDragDrop<Media[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    event.container.data.forEach((photo, index) => {
      photo.order = index + 1;
    });
    let request = new MediaOrderRequest({ request: event.container.data.map((x) => new MediaOrder({ mediaId: x.mediaGuid, order: x.order })) });
    this.photosReorderedSubject.next(request);
  }

  public onTagAdd(photo: Media, isSecondary: boolean) {
    const tagModal = this._modalService.show(PhotoTagModalComponent, {
      animated: true,
      keyboard: true,
      initialState: {
        media: photo,
        propertyId: this.propertyId,
        isSecondary: isSecondary
      }
    });

    tagModal.onHidden?.pipe((take(1), mergeMap((r: any) => {
      if (r?.result?.tag) {
        photo.tag = r.result?.tag;
      } else if (r?.result?.delete && r?.result?.mediaGuid) {
        return of(this.onDeletePhoto(r?.result?.mediaGuid, r?.result?.isSecondary));
      }
      return of();
    }))).subscribe();
  }

  public navigateToTab(tab: PropertyListingTab) {
    this.onNextTab.next(tab);
  }

  private readFile(photo: Media) {
    if (photo.file && /\.(jpg)$/i.test(photo.file?.name)) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (reader.result) {
          photo.previewSrc = reader.result.toString();
        }
      }, false);

      reader.readAsDataURL(photo.file)
    } else {
      photo.file = undefined;
    }
  }

  private outputCompletionStatus() {
    let isComplete = (this.coverPhoto.previewSrc != undefined && this.secondaryPhotos.length >= 3) ?? false;
    this.isComplete.next(isComplete)
  }

  protected openPhotoServiceModal(): void {
    const pageModal = this._modalService.show(RequestPhotographyModalComponent, {
      animated: true,
      keyboard: true,
      initialState: {
        propertyId: this.propertyId,
      }
    });
    pageModal.onHidden?.pipe(
      tap((response: any) => {

      })
    ).subscribe();
  }
}

export class Media {
  file?: File | undefined;
  previewSrc?: string;
  fallbackSrc?: string;
  isDefault = false;
  order?: number | undefined;
  tag?: string | undefined;
  mediaGuid?: string;

  constructor(fields?: Media) {
    if (fields) Object.assign(this, fields);
  }
}
