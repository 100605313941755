import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CardInfoModel, IPreapprovalCases } from '../models';
import { map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StepReviewComponent } from "../step-review/step-review.component";
import { NavigationButtonsComponent } from "../../navigation-buttons/navigation-buttons.component";
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { PaymentsApiService, UnikoServicesConfig, UnikoServiceTypes } from 'src/app/services/payments-api.service';
import { InitiateWorkflowRequest, PortalApiService } from 'src/app/services/portal-api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { OrderResponse, OrderItemResponse, ServicesPaymentsApiService } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-checkout-result',
  standalone: true,
  templateUrl: './checkout-result.component.html',
  styleUrl: './checkout-result.component.css',
  imports: [CommonModule, StepReviewComponent, NavigationButtonsComponent, TranslocoDirective]
})
export class CheckoutResultComponent {
  protected cardData: CardInfoModel | undefined;
  protected order$: Observable<OrderResponse>;
  protected order: OrderResponse | undefined;
  protected orderNumber: string = '';
  protected loading: boolean = false;

  constructor(
    private _paymentsApiService: ServicesPaymentsApiService,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private _paymentsApi: PaymentsApiService,
    private _authService: AuthService,
    private _portalApiService: PortalApiService,
  ) {
    this.order$ = this.activatedRoute.params.pipe(
      tap(t => this.loading = true),
      switchMap((params: Params) => {
        this.orderNumber = params['orderNumber'];
        return this._paymentsApiService.getOrderByNumber(this.orderNumber);
      }),
      tap((response: OrderResponse) => {
        this.loading = false;
        this.order = response;
        const cardInfoJson = JSON.parse(response.transaction?.cardInfo ?? '{}');
        this.cardData = {
          number: cardInfoJson.number,
          nameOnCard: cardInfoJson.name,
          expiryDate: `${cardInfoJson.month}/${cardInfoJson.year}`,
        };
        this.createCase();
      }),
    );
  }
  private createCase(): void {
    this.order?.orderItems?.map((m: OrderItemResponse) => {
      const type = UnikoServicesConfig.find(f => f.sku == m.sku)?.type;
      switch (type) {
        case UnikoServiceTypes.preapproval:
          this.createPreapprovalCase(m);
          break;
        case UnikoServiceTypes.technicalReport:
          this.createTechnicalReportCase(m);
          break;
        default:
          break;
      }

    });
  }
  private createTechnicalReportCase(orderItem: OrderItemResponse): void {
    const propertyId = orderItem.propertyId;
    this._authService.getSubjectId().pipe(
      mergeMap(
        (userId: string | undefined) => {
          if (!userId) {
            console.error("unable to retrieve user id");
            return of(undefined);
          }

          const data: IPreapprovalCases = {
            propertyId: propertyId ?? '',
            userId: userId
          };

          const body = new InitiateWorkflowRequest({
            propertyId: propertyId,
            caseTypeCode: UnikoServicesConfig.find(f => f.type == UnikoServiceTypes.technicalReport)?.casesCode,
            data: data
          });

          return this._portalApiService.initiateWorkflow(body);
        }),
      tap(t => {
        if (t) console.log('case created');
      })
    ).subscribe();
  }

  private createPreapprovalCase(orderItem: OrderItemResponse): void {
    const propertyId = orderItem.propertyId;
    this._authService.getSubjectId().pipe(
      mergeMap(
        (userId: string | undefined) => {
          if (!userId) {
            console.error("unable to retrieve user id");
            return of(undefined);
          }

          const data: IPreapprovalCases = {
            propertyId: propertyId ?? '',
            userId: userId
          };

          const body = new InitiateWorkflowRequest({
            propertyId: propertyId,
            caseTypeCode: UnikoServicesConfig.find(f => f.type == UnikoServiceTypes.preapproval)?.casesCode,
            data: data
          });

          return this._portalApiService.initiateWorkflow(body);
        }),
      tap(t => {
        if (t) console.log('case created');
      })
    ).subscribe();


  }

  protected onNextClick(): void {
    this._router.navigate([`my-profile/requests`]);

  }
}
