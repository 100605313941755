<div class="flex flex-col flex-1 pt-10" *transloco="let t; prefix: 'onboarding.onboarding-seller'">
    <ng-container [ngSwitch]="Step">
        <!-- STEP 1 -->
        <ng-container *ngSwitchCase="'Step1'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=1 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col gap-12 items-center justify-center">
                    <div class="flex flex-col gap-4">
                        <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepTwo')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepTwo')}}
                        </p>
                    </div>
                    <app-property-type-buttons (SelectedItems)="buttonSelected($event)" [IsMultiple]="false"
                        [ToggledButtons]="SelectedPropertyType? [SelectedPropertyType]:[]"></app-property-type-buttons>
                </div>
            </div>
            <app-navigation-buttons (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="Goto('Step1')"
                [HidePrevious]="true" [HideSkip]="true" (NextButtonClicked)="SaveStep1()"></app-navigation-buttons>
        </ng-container>

        <!-- STEP 2 -->
        <ng-container *ngSwitchCase="'Step2'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=2 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col gap-12 items-center justify-center">
                    <div class="flex flex-col gap-4">
                        <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepThree')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepThree')}}
                        </p>
                    </div>
                    <div class="flex flex-col md:grid md:grid-cols-2 gap-4 justify-center items-centerw-full">
                        <ng-container *ngFor="let button of SupportServices">

                            <label [attr.for]="button.id" class="visual-checkbox flex flex-col flex-1">
                                <input type="checkbox" [attr.id]="button.id" class="opacity-0 absolute h-0 w-0"
                                    (click)="toggleStep3Select(button.id)" [checked]="button.isToggled">
                                <span class="">{{button.title}}</span>
                            </label>

                        </ng-container>
                    </div>
                </div>
            </div>

            <app-navigation-buttons (PreviousButtonClicked)="Goto('Step1')" [HideSkip]="true"
                (NextButtonClicked)="SaveStep2()"></app-navigation-buttons>
        </ng-container>

        <!-- Step 4 -->
        <ng-container *ngSwitchCase="'Step3'">
            <div class="flex flex-1 flex-col h-full">
                <app-seller-finish-page [PropertyTypes]="SelectedPropertyType ?? ''"
                    [SupportTypes]="SelectedSupportServices"></app-seller-finish-page>
                <app-navigation-buttons (NextButtonClicked)="SaveAnswers('/sell-property-get-started')"
                    (PreviousButtonClicked)="Goto('Step3')" [CustomNextTitle]="t('find-a-property')"
                    [HidePrevious]="true" [CustomSkipTitle]="t('go-to-home')" (SkipButtonClicked)="SaveAnswers('/home')"
                    [HideNextArrow]="true"></app-navigation-buttons>
            </div>
        </ng-container>
    </ng-container>
</div>