import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FeaturesVisualCheckboxComponent } from './features-visual-checkbox/features-visual-checkbox.component';
import { PropertyDetails } from 'src/app/services/portal-api.service';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-features-section',
  standalone: true,
  imports: [CommonModule, FeaturesVisualCheckboxComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './features-section.component.html',
  styleUrl: './features-section.component.css'
})
export class FeaturesSectionComponent {
  @Input() model = new PropertyDetails();
  protected isCollapsed: boolean = true;

  toggleCollapse(event: MouseEvent) {
    this.isCollapsed = !this.isCollapsed;
    let buttonNode = event.target as Node;
    let scrollTo = buttonNode.parentElement as Element;
    scrollTo?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
