   <div class="flex flex-col gap-6" *transloco="let t; prefix: 'shared.request-walkthrough-modal.request-walkthrough-verify-phone'">
    <h2 class="h2 text-black">{{t('headers.main')}}</h2>
    <div class="divider-line1"></div>
		<div class="flex flex-col gap-6 h-[600px] justify-between items-center" [formGroup]="form">
      <div class="flex flex-col gap-4 max-w-[364px]">
        <p>{{t('infoText.otp-1')}} {{phoneNumber}}. {{t('infoText.otp-1')}}</p>
        <div class="flex flex-row items-center justify-center gap-2" toptCursorMove>
          <!-- 6 digit totp input -->
          <input  formControlName="digit1" type="text" class="w-10 h-10 md:w-14 md:h-12 text-center text-3xl bg-white rounded border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" maxlength="1">
          <input  formControlName="digit2" type="text" class="w-10 h-10 md:w-14 md:h-12 text-center text-3xl bg-white rounded border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" maxlength="1">
          <input  formControlName="digit3" type="text" class="w-10 h-10 md:w-14 md:h-12 text-center text-3xl bg-white rounded border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" maxlength="1">
          <input  formControlName="digit4" type="text" class="w-10 h-10 md:w-14 md:h-12 text-center text-3xl bg-white rounded border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" maxlength="1">
          <input  formControlName="digit5" type="text" class="w-10 h-10 md:w-14 md:h-12 text-center text-3xl bg-white rounded border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" maxlength="1">
          <input  formControlName="digit6" type="text" class="w-10 h-10 md:w-14 md:h-12 text-center text-3xl bg-white rounded border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none" maxlength="1">
        </div>

        @if (error) {
          <span class="text-sm text-red disabled:text-tone-06">{{error}}</span>
      }
      </div>

      <div class="flex flex-col gap-6">
        <div class="flex flex-col items-center">
          <div>{{t('headers.codeNotReceived')}}</div>
          <button class="text-secondary block underline" (click)="sendTotp()">{{t('buttons.sendOtp')}}</button>
          <button class="text-secondary block underline" (click)="resetForm()">{{t('buttons.resetForm')}}</button>
          </div>
          <div class="divider-line1"></div>
          <button role="button"  [disabled]="form.invalid" (click)="verifyTotp()" class="btn-primary w-full flex flex-row items-center justify-center">{{t('buttons.verifyOtp')}}</button>
      </div>
    </div>
