<div class="bg-gray-50 rounded-medium pt-8 pb-8 pl-8 shadow-[0_4px_18px_0_rgba(0,0,0,0.17)]" *transloco="let t; prefix: 'shared.requests-unauthorized'">
  <div class="flex justify-between items-center pr-8">
    <div class="text-chip-dark">{{t('headers.haveAccount')}} <a class="text-primary font-medium cursor-pointer" (click)="onLogin()">{{t('anchors.login')}}</a></div>
    <button (click)="onClose()"><i class="icon-close text-xl"></i></button>
  </div>
  <div class="pr-8">
      <div class="divider-line1 border-b-gray-300 rfs:pt-8"></div>
  </div>
  <div class="flex flex-col md:flex-row">
      <div class="container flex flex-col rfs:gap-5 rfs:pt-6 px-4 w-full">
          <h2 class="h2 text-black" [innerHTML]="t('headers.signUp')"></h2>
          <div class="flex flex-col md:flex-row gap-4 pt-4">
              <p>
                  {{t('infoText.advisors')}}
              </p>

              <img class="h-[110px] w-full object-center object-contain "
                  src="../../../../assets/request-callback/make-offer-advisors.png"
                  alt="Advisors avatar, Giorgios, Maria and Sophia">
          </div>
          <ul class="pt-1 list-disc list-inside">
              <li [innerHTML]="t('infoText.tailoredRecommendations')"></li>
              <li [innerHTML]="t('infoText.personalGuidance')"></li>
              <li [innerHTML]="t('infoText.continuedSupport')"></li>
          </ul>
          <div class="flex flex-col gap-4">
              <button class="btn-primary" (click)="onSignUp()">{{t('buttons.signUp')}}</button>
              <div class="flex items-center justify-center">
                  <span class="text-sm">{{t('infoText.quickRegistration')}}</span>
              </div>
          </div>
      </div>
      <div class="max-h-[536px] w-full pt-2">
          <img class="h-full w-full"
              src="../../../../assets/request-callback/make-offer-happy-family.webp" alt="">
      </div>
  </div>
</div>
