<div class="w-full pt-">
  <div class="stepper-indicator-wrapper">
    <div *ngFor="let step of Steps; let i = index" class="step" [class]="
  'before:w-[' + stepPercentage + '%]'" [ngClass]="
  {'completed': StepNo > i+1, 'active': StepNo === i+1, '': StepNo < i+1}
">
      {{step}}
      <span>{{i+1 + '/' + Steps.length}}</span>
    </div>
  </div>
</div>