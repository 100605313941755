import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Observable, auditTime, fromEvent, map, startWith, tap } from 'rxjs';
import { WINDOW, WINDOW_PROVIDERS } from 'src/app/core/services/window.service';
import { TopNavigationService } from './top-navigation.service';
import { RouterModule } from '@angular/router';
import { IndicationSidePanelInputModel } from 'src/app/shared/components/indication-side-panel/IndicationSidePanelInputModel';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, RouterModule],
  providers: [WINDOW_PROVIDERS],
})
export class TopNavigationComponent implements AfterViewInit, OnDestroy {
  @Input() inputData: Array<IndicationSidePanelInputModel> = [];

  private _activeAnchor = 0;
  get activeAnchor(): number {
    return this._activeAnchor;
  }
  set activeAnchor(value: number) {
    this._activeAnchor = value;
    this.centerActiveTab();
  }

  @ViewChild('menu', { static: false, read: ElementRef })
  nav!: ElementRef<HTMLElement>;
  
  @ViewChildren('tabElement', { read: ElementRef })
  tabs!: QueryList<ElementRef>;
  
  private currentState = false;
  shouldFixNav$!: Observable<boolean>;

  private observer!: IntersectionObserver;

  constructor(
    @Inject(WINDOW) private window: Window,
    private service: TopNavigationService,
  ) {}

  ngAfterViewInit(): void {
    const navNative = this.nav.nativeElement;

    this.shouldFixNav$ = fromEvent(this.window, 'scroll').pipe(
      auditTime(50),
      map(() => {
        const elementOffsetTop = navNative.offsetTop; // Get distance from top of the document
        const scrollY = this.window.scrollY;
        const top = elementOffsetTop - scrollY; // Calculate distance from top of viewport
        return top < -584;
      }),
      tap((result) => {
        if (this.currentState === result) {
          return;
        }
        this.service.addClass(result);
      }),
      startWith(false),
    );

    this.observer = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries.filter((entry) => entry.isIntersecting);

        if (visibleEntries.length) {
          // Find the topmost visible anchor
          const topmostEntry = visibleEntries.reduce((prev, curr) => {

            return prev.boundingClientRect.top < curr.boundingClientRect.top
              ? prev
              : curr;
          });

          const anchorIndex = this.inputData.findIndex(
            (item) => item.scrollTo === topmostEntry.target.getAttribute('scroll-anchor')
          );

          if (anchorIndex !== -1) {
            this.activeAnchor = anchorIndex;
          }
        }
      },
      {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 100% of the anchor is visible
      }
    );


     // Observe each scroll anchor element
     this.inputData.forEach((item) => {
      const element = document.querySelector(
        `[scroll-anchor="${item.scrollTo}"]`
      ) as Element;
      if (element) {
        this.observer.observe(element);
      }
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  /**
   * Scrolls to section with attribute scroll-anchor=<scroll-to>
   */
  scrollToTarget(event: MouseEvent, index: number) {
    this.activeAnchor = index;
    let scrollTo =
      (event?.currentTarget as HTMLElement).getAttribute('scroll-to') ?? '';
    let scrollAnchor = document.querySelector(
      `[scroll-anchor="${scrollTo}"]`,
    ) as Element;
    const offset = -80;
    const y =
      scrollAnchor?.getBoundingClientRect().top + window.scrollY + offset;
    if (y) {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  centerActiveTab() {
    if (!this.tabs || !this.nav) {
      return;
    }
    const tabsArray = this.tabs.toArray();
    const activeTab = tabsArray[this.activeAnchor];
    const navElement = this.nav.nativeElement;
  
    if (activeTab && navElement) {
      const tabElement = activeTab.nativeElement as HTMLElement;
      const tabOffsetLeft = tabElement.offsetLeft;
      const tabWidth = tabElement.offsetWidth;
      const navWidth = navElement.offsetWidth;
  
      const scrollLeft = tabOffsetLeft - (navWidth / 2) + (tabWidth / 2);
  
      navElement.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  }
}
