import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Modal, ModalOptions } from '@indice/ng-components';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-onboarding-exit-modal',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoDirective],
  templateUrl: './onboarding-exit-modal.component.html',
  styleUrl: './onboarding-exit-modal.component.css'
})
export class OnboardingExitModalComponent {
  protected selfHidden: boolean = false;

  constructor(
    public modal: Modal,
  ) {
  }
  selfHideSwitch(event: boolean) {
    this.selfHidden = event;
  }
  closeModal() {
    this.modal.hide({});
  }
}
