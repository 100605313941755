import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { UserManager, User, SignoutResponse, UserProfile, Log } from 'oidc-client-ts';
import * as environment from '../appSettings';
import { LoggerService } from './logger.service';
import { Router } from '@angular/router';
import { __setFunctionName } from 'tslib';

/*** https://github.com/IdentityModel/oidc-client-js/wiki */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userManager: UserManager;
  private _user: User | null = null;
  private _userLoaded = new BehaviorSubject<User | null>(null);

  public userLoaded: Observable<User | null> = this._userLoaded;

  constructor(private logger: LoggerService, private _router: Router,) {
    if (!environment.appSettings.production) {
      Log.setLogger(console);
      Log.setLevel(Log.INFO);
    }
    this.logger.info(
      'AuthService was initialized with the following settings: ',
      environment.appSettings.auth_settings
    );
    this._userManager = new UserManager(environment.appSettings.auth_settings);
    this._userManager.clearStaleState();
    this._userManager.getUser().then((user) => (this._user = user));
    this._userManager.events.addUserLoaded((user: User) => {
      this._user = user;
      this._userLoaded.next(user);
      this.logger.info('User loaded event occurred.', user);
    });
    this._userManager.events.addAccessTokenExpiring((_) => {
      this.logger.info('Access token expiring event occurred.');
      // No need to call this._userManager.signinSilent() since automaticSilentRenew is set to true.
    });
    this._userManager.events.addUserSignedOut(() => {
      this.logger.info('User signed out event occurred.');
      this.removeUser();
    });
  }

    public INTENT_CLAIM = "account_intention";
    public ACCOUNT_OWNER_ID_CLAIM = "account_owner_id";
    public LEGAL_REPRESENATIVE_NAME_CLAIM = "legal_representative_name";
    public CONSENT_COMMERCIAL_DATE_CLAIM = "consent_commercial_date";
    public CONSENT_TERMS_DATE_CLAIM = "consent_terms_date";
    public TAX_NUMBER_CLAIM = "tax_number"; 
    public INTENT_BUYER = "Buyer";
    public INTENT_SELLER = "Seller";
    public INTENT_UNKNOWN = "Uknown";

  public isLoggedIn(): Observable<boolean> {
    return from(this._userManager.getUser()).pipe(
      map((user: User | null) => {
        return user ? true : false;
      }),
      catchError((error: any) => of(error))
    );
  }
  
  public getUserProfile(): Observable<UserProfile | undefined> {
    return from(this._userManager.getUser()).pipe(
      map((user: User | null) => user?.profile),
      catchError((error: any) => of(error))
    );
  }

  public getEmail(): Observable<string | undefined> {
    return this.getUserProfile().pipe(
      map((profile: UserProfile | undefined) => profile?.email)
    );
  }

  public getSubjectId(): Observable<string | undefined> {
    return this.getUserProfile().pipe(
      map((profile: UserProfile | undefined) => profile?.sub)
    );
  }

  public getDisplayName(): Observable<string | undefined> {
    return this.getUserProfile().pipe(
      map((profile: UserProfile | undefined) => {
        if (profile?.given_name && profile?.family_name) {
          return `${profile.given_name} ${profile.family_name}`;
        }
        if (profile?.email) {
          return profile.email;
        }
        if (profile?.name) {
          return profile.name;
        }
        return '';
      })
    );
  }

  public getCurrentUser(): User | null {
    return this._user;
  }

  public getAuthorizationHeaderValue(): string {
    if (this._user) {
      return `${this._user.token_type} ${this._user.access_token}`;
    }
    return '';
  }

  public removeUser(): void {
    this._userManager.removeUser().then(() => {
      this.logger.info('User was removed');
      // if this is needed add optional parameter with default value
      //window.location.reload();
    });
  }

  public signoutRedirect(): void {
    if (localStorage.getItem("showOnboarding") !== null) {
      localStorage.removeItem('showOnboarding');
    }
    this._userManager.signoutRedirect();
  }

  public signoutRedirectCallback(): Observable<SignoutResponse> {
    return from(this._userManager.signoutRedirectCallback()).pipe(
      map((response: SignoutResponse) => {
        this._user = null;
        return response;
      }),
      catchError((error: any) => of(error))
    );
  }

  public signinRedirect(location: string, extraQueryParams?: any | undefined): void {
    this._userManager
      .signinRedirect({ state: { url: location }, extraQueryParams })
      .catch((error: any) => { });
  }

  public signinRedirectCallback(): Observable<User> {
    return from(this._userManager.signinRedirectCallback()).pipe(
      map((user: User) => {
        this._user = user;
        return user;
      }),
      catchError((error: any) => of(error))
    );
  }

  public signinSilent(): Observable<User | null> {
    return from(this._userManager.signinSilent());
  }

  public signinSilentCallback(): Observable<void> {
    return from(this._userManager.signinSilentCallback()).pipe(
      catchError((error: any) => of(error))
    );
  }

  public getUserLocaleOrDefault(): string{
    if(this._user){
      return this._user.profile?.locale ?? (environment.appSettings.culture ?? 'el');
    }
    return environment.appSettings.culture ?? 'el';
  }
  
}
