import { pipe, tap } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { LoanCalulatorComponent } from "../../shared/components/loan-calulator/loan-calulator.component";
import { CommonModule, DecimalPipe } from "@angular/common";
import { DoughnutChartComponent } from "../../shared/components/loan-calulator/doughnut-chart/doughnut-chart.component";
import { RouterModule } from "@angular/router";
import { TranslocoDirective, TranslocoPipe } from "@jsverse/transloco";
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-financing',
  standalone: true,
  imports: [LoanCalulatorComponent, DecimalPipe, DoughnutChartComponent, RouterModule, TranslocoDirective, TranslocoPipe, CommonModule],
  templateUrl: './financing.component.html',
})
export class FinancingComponent implements OnInit {
  public isLoggedIn = false;

  constructor(private _authService: AuthService) {}

  ngOnInit(): void {
    this._authService.isLoggedIn().pipe(
      tap((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      })
    ).subscribe();
  }
}
