import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

@Directive({
  selector: '[toptCursorMove]',
  standalone: true
})
export class ToptCursorMoveDirective {

  subs: Subscription[] | undefined;

  constructor(private elementRef: ElementRef) { }


  ngAfterViewInit() {
    if (this.elementRef?.nativeElement?.children) {
      this.subs = Array.from(this.elementRef.nativeElement.children).map((x: any, index, arr) =>
        fromEvent(x, 'input').subscribe((event: any) => {
          if (event.data) {
            const next: any = arr[index + 1];
            if (next) next.focus();
          }
        })
      )
    }
  }

  ngOnDestroy() {
    if (this.subs) this.subs.forEach(x => x.unsubscribe())
  }
}
