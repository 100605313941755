<ng-container *transloco="let t; prefix: 'find-property.advanced-filters-drawer'">
    <div *ngIf="isOpen">
        <div class="fixed inset-0 bg-black bg-opacity-50 z-0" (click)="toggleDrawer()"></div>
        <div
            class="advance-filter-wrapper w-full md:!max-w-[808px] z-50 p-4 bg-gray-50 h-[calc(100vh-84px)] overflow-y-auto fixed right-0 top-[84px]">
            <div class="flex md:flex-row flex-row-reverse items-center gap-5 justify-between md:justify-normal mb-5">
                <button role="button" class="cursor-pointer md:rounded-full w-14 h-14 text-primary-800 md:bg-gray-100" (click)="toggleDrawer()">
                  <i class="icon-close "></i>
                </button>
                <h4 class="text-black text-xl">{{t('header')}}</h4>
            </div>

            <div class="divider-line1 my-8 md:hidden"></div>

            <div class="justify-between gap-8 flex flex-col md:hidden" >

              <app-filter-dropdown class="z-50" [dropdownItems]="propertyTypes" [title]="t('filterTitles.propertyType')"
                  [selectedKeys]="selectedType" [allValuesLabel]="t('filterAllValuesLabels.propertyType')"
                  [hasPlaceholder]="false" [hasBorder]="false"
                  (selectedItem)="searchWithTypeFilter($event)"></app-filter-dropdown>

              <app-range-dropdown class="z-20" [minDropdownItems]="minPriceRanges" [maxDropdownItems]="maxPriceRanges" [title]="t('filterTitles.price')"
                  [preselectedRange]="selectedPriceRange" [allValuesLabel]="t('filterAllValuesLabels.price')"
                  postfix="€" [hasPlaceholder]="false" [hasBorder]="false"
                  (selectedRangeEvent)="searchWithPriceFilter($event)"></app-range-dropdown>

              <app-range-dropdown [minDropdownItems]="minSizeRanges" [maxDropdownItems]="maxSizeRanges"
                  [title]="t('filterTitles.size')" [hasPlaceholder]="false" [hasBorder]="false"
                  [preselectedRange]="selectedSizeRange" [allValuesLabel]="t('filterAllValuesLabels.size')" postfix="τ.μ."
                  (selectedRangeEvent)="searchWithSizeFilter($event)"></app-range-dropdown>
          </div>

          <div class="divider-line1 my-8 md:hidden"></div>

            <div class="advance-filter-body">
                <form>
                  <div class="flex md:flex-row md:gap-0 justify-between flex-col gap-5 mb-5">

                    <div class="filter-section-wrapper">
                      <h5 class="filter-title">{{t('filters.bedrooms')}}</h5>
                      <div class="flex flex-row gap-[16px]">
                          <label *ngFor="let button of bedrooms" class="filter-radio-wrapper">
                              {{button.label}}
                              <input type="checkbox" class="hidden" [attr.id]="button.key"
                                  name="menu-item-radio-beedrooms" [checked]="button.toggled"
                                  (click)="roomsChange(button.key)">
                          </label>
                        </div>
                    </div>

                    <div class="filter-section-wrapper">
                      <h5 class="filter-title">{{t('filters.bathrooms')}}</h5>
                      <div class="flex flex-row gap-[16px]">
                          <label *ngFor="let button of bathrooms" class="filter-radio-wrapper">
                              {{button.label}}
                              <input type="checkbox" class="hidden" [attr.id]="button.key"
                                  name="menu-item-radio-beedrooms" [checked]="button.toggled"
                                  (click)="bathroomsChange(button.key)">
                          </label>
                      </div>
                    </div>
                  </div>

                  <div class="flex md:flex-row md:gap-0 justify-between flex-col gap-5">
                    <div class="filter-section-wrapper">
                      <h5 class="filter-title">{{t('filters.parking')}}</h5>
                      <div class="flex flex-row gap-[16px]">
                          <label *ngFor="let button of parking" class="filter-radio-wrapper">
                              {{button.label}}
                              <input type="checkbox" class="hidden" [attr.id]="button.key"
                                  name="menu-item-radio-floor" [checked]="button.toggled"
                                  (click)="parkingChange(button.key)"
                                  >
                          </label>
                      </div>
                    </div>

                    <div class="filter-section-wrapper">
                      <h5 class="filter-title">{{t('filters.floor')}}</h5>
                      <div class="flex flex-row gap-[16px]">
                          <label *ngFor="let button of floors" class="filter-radio-wrapper">
                              {{button.label}}
                              <input type="checkbox" class="hidden" [attr.id]="button.key"
                                  name="menu-item-radio-floor" [checked]="button.toggled"
                                  (click)="floorsChange(button.key)">
                          </label>
                      </div>
                    </div>
                  </div>

                  <div class="divider-line1 my-8"></div>

                    <div class="filter-section-wrapper">
                        <h5 class="filter-title">{{t('filters.constructionYear')}}</h5>
                        <div class="flex md:flex-row flex-col gap-5">
                            <fieldset class="flex-1">
                                <div
                                    class="flex text-gray-500 rounded-full items-center gap-2 bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-all transition-colors duration-300 outline-none">

                                    <input
                                        (click)="openFirstDatePicker();$event.stopPropagation()"
                                        class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300 cursor-pointer"
                                        [placeholder]="t('placeholders.yearFrom')"
                                        [value]="filter.minConstructionYear ?? null"
                                        (change)="constructionYearInputFrom($event)">
                                        <date-picker #firstDatePicker (valueChange)="constructionYearFrom($event)" [yearsOnly]="true" [displayRight]="true"
                                        ></date-picker>
                                    <i *ngIf="filter.minConstructionYear" class="icon-close"
                                        (click)="clearMinConstructionYear()"></i>
                                </div>
                            </fieldset>
                            <fieldset class="flex-1">
                                <div
                                    class="flex text-gray-500 rounded-full items-center gap-2 bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <input
                                        (click)="openSecondDatePicker();$event.stopPropagation()" 
                                        class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300 cursor-pointer"
                                        [placeholder]="t('placeholders.yearTo')"
                                        [value]="filter.maxConstructionYear ?? null"
                                        (change)="constructionYearInputTo($event)">
                                        <date-picker  #secondDatePicker [yearsOnly]="true" [displayRight]="true"
                                        (valueChange)="constructionYearTo($event)"></date-picker>
                                    <i *ngIf="filter.maxConstructionYear" class="icon-close"
                                        (click)="clearMaxConstructionYear()"></i>
                                </div>
                            </fieldset>
                        </div>

                        <div class="divider-line1 my-8"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.preApproved')}}</h5>
                            <div>
                              <img src="../../../../assets/svgs/nbg-filters-logo.svg" alt="Brand Logo"
                              class="w-[87px] object-cover object-center mb-3">
                              <div class="flex flex-row gap-16 mb-8">
                                <div class="flex flex-col flex-1">
                                  <p class="text-base text-black font-semibold">{{t('labels.preApproved-header')}}</p>
                                  <p class="text-gray-800 text-sm">{{t('labels.preApproved-subheader')}}</p>
                                </div>
                                <div>
                                  <label for="menu-item-checkmark-sm-1" class="menu-item-checkmark hover:!bg-gray-50 min-w-0 border-0 p-0">
                                    <input type="checkbox" class="hidden" id="menu-item-checkmark-sm-1" name="menu-item-checkmark-sm" [checked]="preApproved.toggled" (change)="preaprovedChanged($event)" />
                                  </label>
                                </div>
                              </div>
                          </div>
                        </div>

                        <div class="divider-line1 my-8"></div>

                        
                        <div class="flex md:flex-row flex-col gap-5">
                          <div class="filter-section-wrapper flex flex-row justify-between items-start flex-1">
                            <h5 class="filter-title">{{t('filters.auction')}}</h5>
                            <label for="menu-item-checkmark-sm-auction" class="menu-item-checkmark hover:!bg-gray-50 min-w-0 border-0 p-0">
                              <input type="checkbox" class="hidden" id="menu-item-checkmark-sm-auction" name="menu-item-checkmark-sm" [checked]="inAuction.toggled" (change)="auctionChanged($event)" />
                            </label>
                          </div>
                          @if (filter.inAuction) {
                            <div class="filter-section-wrapper flex-1">
                                <h5 class="filter-title">{{t('filters.auctionRound')}}</h5>
                                <div class="section-items !gap-[16px]">
                                    <label *ngFor="let button of auctionRound" class="filter-radio-wrapper">
                                        {{button.label}}
                                        <input type="checkbox" class="hidden" [attr.id]="button.key"
                                            name="menu-item-radio-auctionround" [checked]="button.toggled"
                                            (click)="auctionRoundChange(button.key)">
                                    </label>
                                </div>
                            </div>
                          }
                        </div>


                        <div class="divider-line1 my-8"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.characteristics')}}</h5>
                            <div class="section-items">
                                <label *ngFor="let button of characteristics" class="filter-radio-wrapper-rounded">
                                    <i class="mr-2 text-lg" [ngClass]="button.icon ?? ''"></i>{{button.label}}
                                    <input type="checkbox" class="hidden" [attr.id]="button.key"
                                        name="menu-item-radio-characteristics" [checked]="button.toggled"
                                        (click)="toggleCharacteristics(button.key)">
                                </label>
                            </div>
                        </div>

                        <div class="divider-line1 my-8"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.heatingType')}}</h5>
                            <div class="section-items">
                              <label *ngFor="let button of heatingControl" class="filter-radio-wrapper-rounded">
                                <i class="mr-2 text-lg" [ngClass]="button.icon ?? ''"></i> {{button.label}}
                                <input type="checkbox" class="hidden" [attr.id]="button.key"
                                    name="menu-item-radio-heatingtype" [checked]="button.toggled"
                                    >
                               </label>
                                <label *ngFor="let button of heatingtype" class="filter-radio-wrapper-rounded">
                                    <i class="mr-2 text-lg" [ngClass]="button.icon ?? ''"></i> {{button.label}}
                                    <input type="checkbox" class="hidden" [attr.id]="button.key"
                                        name="menu-item-radio-heatingtype" [checked]="button.toggled"
                                        (click)="heatingTypeChanged(button.key)">
                                </label>
                            </div>
                        </div>

                        <div class="divider-line1 my-8"></div>

                        <div class="flex md:flex-row flex-col-reverse justify-end md:gap-10 gap-5">
                          @if (filtersTotalNumber > 0) {
                            <button type="button" class="btn-text text-gray-800 underline" (click)="clearFilters();toggleDrawer();">
                              {{t('filters.buttons.clear')}}
                            </button>
                          }
                          <button type="button" class="btn-primary" (click)="filterChanged()" >
                            {{t('filters.buttons.save')}}
                          </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-container>
