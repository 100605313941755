import { DecimalPipe, CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { IGetDataResponse, IProduct, LoanCategories, LoanService, LoanValues } from 'src/app/services/loan.service';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { tap } from 'rxjs';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-loan-calulator',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, DecimalPipe, DoughnutChartComponent, NgxSliderModule, RouterModule, TranslocoDirective, TranslocoPipe],
  templateUrl: './loan-calulator.component.html',
  styleUrl: './loan-calulator.component.css'
})
export class LoanCalulatorComponent implements OnInit {
  @Input() propertyValue: number | undefined;

  public loanValues: LoanValues | undefined;

  public selectedProduct: IProduct | undefined;

  public loanCategories = LoanCategories;
  public selectedLoanCategory: string | undefined;
  public fields = Fields;
  public loanFixedDurationValues: number[] = [];

  public propertyValueOptions: Options = {
    floor: 10000,
    ceil: 2000000,
    step: 10000,
    showSelectionBar: true
  }

  public loanAmountOptions: Options = {
    floor: 10000,
    ceil: 999999,
    step: 2000,
    showSelectionBar: true
  }

  public loanDurationOptions: Options = {
    floor: 1,
    ceil: 40,
    step: 1,
    showSelectionBar: true,
    minLimit: 20,
    maxLimit: 40
  }

  public loanFixedDurationOptions: Options = {
    floor: 0,
    ceil: 30,
    stepsArray: [
      { value: 0 },
      { value: 3 },
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 20 },
      { value: 25 },
      { value: 30 },
    ],
    showSelectionBar: true
  }


  private _getDataResponse: IGetDataResponse | null = null;

  constructor(private _loanService: LoanService) { }

  ngOnInit(): void {
    this.resetData();
    this._loanService.currentLoanValues.pipe(
      tap((res) => {
        this.loanValues = res;
      })
    ).subscribe();

    this._loanService.currentSelectedCategory.pipe(
      tap((res) => {
        this.selectedLoanCategory = res;
      })
    ).subscribe();
  }

  private resetData() {
    this._loanService.resetLoanValues(this.propertyValue);
    this.resetRangeOptions();

    this._loanService.getData(this.selectedLoanCategory ?? '').pipe(
      tap((response) => {
        this._getDataResponse = response;
        this.calculateData();
      })
    ).subscribe();
  }

  public onRangeValueChange(event: any, field: Fields) {
    if (event && this.loanValues) {
      switch (field) {
        case this.fields.propertyValue:
          this._loanService.changePropertyValue(this.loanValues.loanCalculatorValues.propertyValue);
          break;
        case this.fields.loanAmount:
          this.calculateLoanAmounts();
          break;
        case this.fields.loanDuration:
          if (this.loanValues.loanCalculatorValues.loanFixedDuration === 10 || this.loanValues.loanCalculatorValues.loanFixedDuration === 15) {
            if (this.loanValues.loanCalculatorValues.loanDuration < 20) {
              this.loanValues.loanCalculatorValues.loanDuration = 20;
            }
          } else if (this.loanValues.loanCalculatorValues.loanFixedDuration === 3 || this.loanValues.loanCalculatorValues.loanFixedDuration === 5) {
            if (this.loanValues.loanCalculatorValues.loanDuration < 10) {
              this.loanValues.loanCalculatorValues.loanDuration = 10;
            }
          } else if (this.loanValues.loanCalculatorValues.loanDuration < this.loanValues.loanCalculatorValues.loanFixedDuration) {
            this.loanValues.loanCalculatorValues.loanDuration = this.loanValues.loanCalculatorValues.loanFixedDuration;
          }
          this.calculateLoanAmounts();
          break;
        case this.fields.fixedInterestRateDuration:
          if (this.loanValues.loanCalculatorValues.loanFixedDuration > this.loanValues.loanCalculatorValues.loanDuration) {
            if (this.loanValues.loanCalculatorValues.loanFixedDuration <= 5) {
              this.loanValues.loanCalculatorValues.loanDuration = 10;
            } else {
              this.loanValues.loanCalculatorValues.loanDuration = this.loanValues.loanCalculatorValues.loanFixedDuration;
            }
          } else if (this.loanValues.loanCalculatorValues.loanFixedDuration === this.loanValues.loanCalculatorValues.loanDuration) {
            if (this.loanValues.loanCalculatorValues.loanFixedDuration === 10) {
              this.loanValues.loanCalculatorValues.loanDuration = 20;
            }
          } else {
            if (this.loanValues.loanCalculatorValues.loanFixedDuration === 3 || this.loanValues.loanCalculatorValues.loanFixedDuration === 5) {
              if (this.loanValues.loanCalculatorValues.loanDuration < 10) {
                this.loanValues.loanCalculatorValues.loanDuration = 10;
              }
            }
          }
          let currentFixedDuration = this.loanValues.loanCalculatorValues.loanFixedDuration;
          if (currentFixedDuration === 30) {
            this.setLoanDurationMinLimit(30);
          } else if (currentFixedDuration === 25) {
            this.setLoanDurationMinLimit(25);
          }
          else if (currentFixedDuration <= 20 && currentFixedDuration >= 10) {
            this.setLoanDurationMinLimit(20);
          } else if (currentFixedDuration <= 5 && currentFixedDuration >= 3) {
            this.setLoanDurationMinLimit(10);
          } else {
            this.setLoanDurationMinLimit(1);
          }
          this.calculateLoanAmounts();
          break;
      }
    }
  }

  public onLoanCategorySelect(selectedCategory: string) {
    this._loanService.changeSelectedCategory(selectedCategory);
    this.resetData();
  }

  private setLoanDurationMinLimit(minLimit: number) {
    this.loanDurationOptions = {
      floor: 1,
      ceil: 40,
      step: 1,
      showSelectionBar: true,
      minLimit: minLimit,
      maxLimit: 40
    }
  }

  private resetRangeOptions() {
    this.propertyValueOptions = {
      floor: 10000,
      ceil: 2000000,
      step: 10000,
      showSelectionBar: true
    }

    this.loanAmountOptions = {
      floor: 10000,
      ceil: 999999,
      step: 2000,
      showSelectionBar: true
    }

    this.loanDurationOptions = {
      floor: 1,
      ceil: 40,
      step: 1,
      showSelectionBar: true,
      minLimit: 20,
      maxLimit: 40
    }

    this.loanFixedDurationOptions = {
      floor: 0,
      ceil: 30,
      stepsArray: [
        { value: 0 },
        { value: 3 },
        { value: 5 },
        { value: 10 },
        { value: 15 },
        { value: 20 },
        { value: 25 },
        { value: 30 },
      ],
      showSelectionBar: true
    }
  }

  private calculateData() {
    if (!this._getDataResponse) {
      this.resetRangeOptions();
      return;
    }
    this.loanAmountOptions.floor = Math.min(...this._getDataResponse.products.map(v => v.amount.valueMin ?? 0));
    this.loanAmountOptions.ceil = Math.max(...this._getDataResponse.products.map(v => v.amount.valueMax ?? 10000));

    this.loanFixedDurationOptions.ceil = Math.max(...this._getDataResponse.products.filter(v => v.fixedRates != null).map(v => v.fixedRates[0].fixedRateDurationMax ?? 0));
    let items = this._getDataResponse.products.filter(v => v.fixedRates != null).map(v => v.fixedRates[0].fixedRateDurationMax ?? 0);
    if (this._getDataResponse.products.filter(x => x.interestRateType == "Variable")) {
      items.push(0);
    }
    this.loanFixedDurationValues = (items.filter((x, i, a) => a.indexOf(x) == i)).sort((a, b) => { return (a ?? 0) - (b ?? 0) });
    this.loanFixedDurationOptions.floor = Math.min(...items);

    this.loanDurationOptions.floor = Math.min(...this._getDataResponse.products.map(v => v.duration.valueMin ?? 0));
    this.loanDurationOptions.ceil = Math.max(...this._getDataResponse.products.map(v => v.duration.valueMax ?? 0));

    this.calculateLoanAmounts();
  }

  private calculateLoanAmounts() {
    if (this.loanValues) {
      this.detectRates();
      this.loanValues!.payments = this._loanService.calculateLoanAmounts(this.loanValues.loanCalculatorValues.loanAmount, this.loanValues.loanCalculatorValues.loanDuration, this.loanValues.loanCalculatorValues.loanFixedDuration, this.loanValues.variableRate, this.loanValues.fixedRate, false, 1);
      this._loanService.changeLoanValues(new LoanValues(this.loanValues.loanCalculatorValues, this.loanValues.payments, this.loanValues.variableRate, this.loanValues.fixedRate));
    }
  }

  private detectRates() {
    this.loanValues!.variableRate = 0;
    this.loanValues!.fixedRate = 0;
    let products: IProduct[] = this._getDataResponse?.products ?? [];
    if (this.loanValues) {
      if (!this.hasFixedDuration()) {
        this.loanValues.loanCalculatorValues.loanFixedDuration = 0;
        products = products.filter(e => e.interestRateType.toLowerCase() == 'variable');
        products = products.filter(e => { return e.variableRates.find(c => c.loanDuration && c.loanDuration >= this.loanValues!.loanCalculatorValues.loanDuration) != null });
        if (products.length) {
          products = products.sort((a, b) => (a.variableRates[0].loanDuration ?? 1) - (b.variableRates[0].loanDuration ?? 1));
          this.selectedProduct = products[0];
          this.loanValues!.variableRate = this.selectedProduct.variableRates.map(d => d.interestRate).pop() ?? 0;
        }
      } else if (this.loanValues.loanCalculatorValues.loanDuration <= this.loanValues.loanCalculatorValues.loanFixedDuration) {
        products = products.filter(e => e.interestRateType.toLowerCase() == 'fixed');
        products = products.filter(e => { return e.fixedRates.find(c => c.loanDuration == this.loanValues!.loanCalculatorValues.loanFixedDuration) != null });
        if (products.length) {
          this.selectedProduct = products[0];
          this.loanValues!.fixedRate = this.selectedProduct.fixedRates.filter(c => c.loanDuration == this.loanValues!.loanCalculatorValues.loanFixedDuration).map(d => d.interestRate).pop() ?? 0;
          this.loanValues!.variableRate = this.loanValues!.fixedRate;
        }
      } else {
        products = products.filter(e => e.interestRateType.toLowerCase() == 'mixed');
        products = products.filter(e => { return e.fixedRates.find(c => c.fixedRateDurationMax && c.fixedRateDurationMax >= this.loanValues!.loanCalculatorValues.loanFixedDuration) != null });
        if (products.length) {
          products = products.filter(e => { return e.variableRates.find(c => c.loanDuration && c.loanDuration >= this.loanValues!.loanCalculatorValues.loanFixedDuration) != null });
          if (products.length) {
            products = products.sort((a, b) => (a.fixedRates[0].loanDuration ?? 1) - (b.fixedRates[0].loanDuration ?? 1)).sort((a, b) => (a.fixedRates[0].fixedRateDurationMax ?? 0) - (b.fixedRates[0].fixedRateDurationMax ?? 0));
            this.selectedProduct = products[0];
            this.loanValues!.fixedRate = this.selectedProduct.fixedRates.map(d => d.interestRate).pop() ?? 0;
            this.loanValues!.variableRate = this.selectedProduct.variableRates.map(d => d.interestRate).pop() ?? 0;
          }
        }
      }
    }
  }

  private hasFixedDuration(): boolean {
    return this.loanFixedDurationValues.length > 1 && this.loanValues!.loanCalculatorValues.loanFixedDuration > 0;
  }

}

enum Fields {
  propertyValue = 1,
  loanAmount = 2,
  loanDuration = 3,
  fixedInterestRateDuration = 4
}


