import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    private _subject: BehaviorSubject<Toast>;

    constructor() {
        this._subject = new BehaviorSubject<Toast>(NULL_TOAST);
        this.toast$ = this
            ._subject
            .asObservable()
            .pipe(filter((toast: Toast) => toast !== null && toast.title !== NULL_TOAST.title));
    }

    public toast$: Observable<Toast>;

    public show(type: ToastType, title?: string, body?: string, delay?: number): void {
        this._subject.next({ type, title, body, delay } as Toast);
    }

    public resetToast(): void {
        this._subject.next(NULL_TOAST);
    }
}

export enum ToastType {
    Info = 'info',
    Success = 'success',
    Error = 'error',
    Warning = 'warning'
}

export const NULL_TOAST = { title: '_____NULL_____' } as Toast;

export interface Toast {
    type: ToastType;
    title: string;
    body: string;
    delay: number;
}