import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter } from '@angular/core';
import { AutocompleteBaseComponent } from '../autocomplete-base.component';
import { CommonModule } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AddressComponent, AddressComponentModel } from '../../google-maps-autocomplete/helper';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { PlaceViewModel } from 'src/app/services/property.service';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-google-maps-search-autocomplete-single',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, TranslocoDirective],
  templateUrl: './google-maps-search-autocomplete.component.html',
  styleUrl: './google-maps-search-autocomplete.component.css'
})
export class GoogleMapsSearchAutocompleteSingleComponent extends AutocompleteBaseComponent implements AfterViewInit {

  public override onItemSelected: EventEmitter<PlaceViewModel> = new EventEmitter();
  public override onItemRemoved: EventEmitter<PlaceViewModel> = new EventEmitter();
  public override items: google.maps.places.AutocompletePrediction[] | undefined;

  private _autocompleteService: google.maps.places.AutocompleteService | undefined;
  private _placesService: google.maps.places.PlacesService | undefined;
  private autocompleteSessionToken: google.maps.places.AutocompleteSessionToken | undefined;

  public resultsFound: boolean = false;

  constructor(private _addressComponentHelper: AddressComponent, private _cdr: ChangeDetectorRef, private _geocodeService: GeocodeService) {
    super();
    this._autocompleteService = new google.maps.places.AutocompleteService();
    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  }
  public override ngOnInit(): void {
    if (this.defaultValue) {
      this.value = this.defaultValue
    }
    this.debouncer
      .pipe(
        debounceTime(this.debounceMs),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        console.log(this.autocompleteSessionToken)
        this.busy = true;
        if (value.length >= this.minLength) {
          this.searchTerm = value;
          this._autocompleteService?.getPlacePredictions(
            {
              input: value,
              sessionToken: this.autocompleteSessionToken,
              componentRestrictions: { country: 'GR' },
              types: ['geocode'],
            }, (results, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                this.items = results;
                this.resultsFound = true;
              } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                this.items = [];
                this.resultsFound = false;
              }
              this.busy = false;
              this.HideList = false;
            }
          )
        }
      });
  }

  public ngAfterViewInit(): void {
    let map = new google.maps.Map(document.createElement('div') as HTMLElement);
    this._placesService = new google.maps.places.PlacesService(map);
  }

  public override onListItemSelected(item: google.maps.places.AutocompletePrediction): void {
    this._placesService?.getDetails(
      {
        fields: ['address_component', 'geometry', 'formatted_address'],
        placeId: item.place_id,
        sessionToken: this.autocompleteSessionToken
      }, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
          let addressComponents: AddressComponentModel[] = result['address_components'] ?? [];
          let geometry: google.maps.places.PlaceGeometry = result['geometry'] ?? {};

          let place: PlaceViewModel = {
            PlaceId: item.place_id,
            FormatedAddress: result['formatted_address'],
            Country: this._addressComponentHelper.findCountry(addressComponents),
            Prefecture: this._addressComponentHelper.findLocality(addressComponents),
            AdministrativeAreas: this._addressComponentHelper.findAdministativeAreas(addressComponents),
            StreetName: this._addressComponentHelper.findRoute(addressComponents),
            StreetNumber: this._addressComponentHelper.findStreetNumber(addressComponents),
            PostalCode: this._addressComponentHelper.findPostalCode(addressComponents),
            Lon: geometry?.location?.lng() ?? null,
            Lat: geometry?.location?.lat() ?? null,
            Bounds: {
              SwLon: geometry?.viewport?.getSouthWest().lng() ?? undefined,
              NeLat: geometry?.viewport?.getNorthEast().lat() ?? undefined,
              SwLat: geometry?.viewport?.getSouthWest().lat() ?? undefined,
              NeLon: geometry?.viewport?.getNorthEast().lng() ?? undefined
            }
          }

          this.selectedItems.push(place);
          this.items = this.items;
          this._cdr.detectChanges();
          this.onItemSelected.emit(place);
          this.value = item.description;
          this.HideList = true;
        }
        this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
      })
  }

}
