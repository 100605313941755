<section
  class="pt-6 pb-12"
  id="details"
  *transloco="let t; prefix: 'property-details.detail-section'"
>
  <h2 class="text-[1.75rem] font-semibold text-black pb-6">
    {{ t("header") }}
  </h2>
  <div class="grid grid-cols-1 md:grid-cols-2 rfs:gap-8 pb-4 overflow-hidden">
    <div class="flex flex-col gap-[14px]">
      <app-detail-table-row
        [title]="t('titles.price')"
        [text]="model.price | currency: 'EUR' : 'symbol' : '1.0-0' "
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.pricePerSqM')"
        [text]="
          ((model.price ?? 0) / (model.propertyArea ?? 1) | currency: 'EUR' : 'symbol' : '1.0-0' ) + ' /m²'
        "
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.livingArea')"
        [text]="model.propertyArea + ' m²'"
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.floor')"
        [text]="model.floor"
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.constructionYear')"
        [text]="model.constructionYear"
      ></app-detail-table-row>
    </div>
    <div class="flex flex-col gap-[14px]">
      <app-detail-table-row
        [title]="t('titles.rooms')"
        [text]="model.numberOfRooms"
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.bathrooms')"
        [text]="model.numberOfBathrooms"
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.heatingSystem')"
        [text]="
          model.heatingType
            ? ('shared.enum.heatingType.' + model.heatingType | transloco)
            : '-'
        "
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.energyClass')"
        [text]="
          model.energyClass
            ? ('shared.enum.energyClass.' + model.energyClass | transloco)
            : '-'
        "
      ></app-detail-table-row>
      <app-detail-table-row
        [title]="t('titles.parkingType')"
        [text]="
          model.parkingType
            ? ('shared.enum.parkingType.' + model.parkingType | transloco)
            : '-'
        "
      ></app-detail-table-row>
    </div>
  </div>
  <!-- <button
    (click)="toggleCollapse($event)"
    class="btn-text flex gap-2 items-center py-[6px]"
  >
    <i
      class="icon-chevron-down text-xl button-chevron transition-all duration-500"
      [ngClass]="{ 'icon-chevron-down': this.isCollapsed,'icon-chevron-up': !this.isCollapsed, }"
    ></i
    >{{ t("buttons.collapse") }}
  </button> -->
</section>
