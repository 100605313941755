<div class="flex flex-col flex-1" *transloco="let t; prefix: 'property-upload-wizard'">
    <ng-container [ngSwitch]="Step">

        <!-- Step 1 -->
        <ng-container *ngSwitchCase="'Step1'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=1 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col gap-12 items-center justify-center">
                    <div class="flex flex-col gap-4">
                        <h2 class="h2 rfs:text-[40px] text-center font-semibold text-black">{{t('headers.stepOne')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light">{{t('infoText.stepOne')}}</p>
                    </div>
                    <app-property-type-buttons [IsMultiple]="false"
                        [ToggledButtons]="SelectedPropertyType? [SelectedPropertyType]:[]"
                        (SelectedItems)="propertyTypeSelected($event)"></app-property-type-buttons>
                </div>
            </div>
            <app-navigation-buttons [HideSkip]="true" [HidePrevious]="true" (ExitButtonClicked)="OpenModalComponent()"
                (NextButtonClicked)="SaveStep1()"></app-navigation-buttons>
        </ng-container>

        <!-- Step 2 -->
        <ng-container *ngSwitchCase="'Step2'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=2 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col flex-1 h-full">
                    <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                        <div class="flex flex-col gap-12 items-center justify-center">
                            <div class="flex flex-col gap-4">
                                <h2 class="h2 rfs:text-[40px] text-center font-semibold text-black">
                                    {{t('headers.stepTwo')}}</h2>
                                <p class="rfs:text-xl text-center font-light">{{t('infoText.stepTwo')}}</p>
                            </div>
                            <div>
                                <form id="property-wizard">
                                    <fieldset class="flex flex-col gap-3 group/input">
                                        <label
                                            class="font-semibold flex gap-2 items-center text-gray-950 group-disabled:text-gray-300">{{t('labels.propertyArea')}}
                                            <i tooltip="tooltip"
                                                class="relative icon-info-circle text-xl text-gray-900"></i></label>
                                        <div class="flex flex-col lg:flex-row gap-4 items-center justify-center">
                                            <div class="min-w-[300px] flex-1">
                                                <label for="FloorSpace"></label>
                                                <input [value]="propertyArea" placeholder="" type="range" #FloorSpace
                                                    min="0" max="1000" value="0" step="1"
                                                    (change)="AreaChanged(FloorSpace.value)"
                                                    (input)="AreaChanged(FloorSpace.value)">
                                            </div>
                                            <div class="relative">
                                                <input numericOnly #areaInput placeholder="" [value]="propertyArea"
                                                    name="area" (change)="AreaChanged(areaInput.value)"
                                                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                                <span
                                                    class="absolute inset-y-0 right-5 flex items-center pl-2 text-gray-700">m²</span>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-navigation-buttons [HideSkip]="true" (ExitButtonClicked)="OpenModalComponent()"
                (PreviousButtonClicked)="GoTo('Step1')" (NextButtonClicked)="SaveStep2()"></app-navigation-buttons>
        </ng-container>

        <!-- Step 3 -->
        <ng-container *ngSwitchCase="'Step3'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=3 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col gap-12 items-center justify-center">
                    <div class="flex flex-col gap-4">
                        <h2 class="h2 rfs:text-[40px] text-center font-semibold text-black">{{t('headers.stepThree')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light">{{t('infoText.stepThree')}}</p>
                    </div>
                    <app-google-maps-search-autocomplete-single (onItemSelected)="SelectedLocation($event)"
                        [defaultValue]="place?.FormatedAddress"></app-google-maps-search-autocomplete-single>
                    <div class="aspect-video w-full order-first lg:order-last">
                        <app-map [propertySummaries$]="placeMarker.asObservable()"></app-map>
                    </div>
                </div>
            </div>
            <app-navigation-buttons [HideSkip]="true" (ExitButtonClicked)="OpenModalComponent()"
                (PreviousButtonClicked)="GoTo('Step2')" (NextButtonClicked)="SaveStep3()"></app-navigation-buttons>
        </ng-container>

        <!-- Step 4 -->
        <ng-container *ngSwitchCase="'Step4'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=4 [Steps]=Steps></progress-stepper>
                <div class="container flex flex-1 flex-col rfs:gap-28 items-center justify-between">
                    <div class="flex flex-col gap-12 items-center justify-center">
                        <div class="flex flex-col gap-4">
                            <h2 class="h2 rfs:text-[40px] text-center font-semibold text-black">
                                {{t('headers.stepFour')}}</h2>
                            <p class="rfs:text-xl text-center font-light">{{t('infoText.stepFour')}}</p>
                        </div>
                    </div>
                    <form>
                        <div class="flex flex-col gap-6 items-center justify-center">
                            <div class="max-w-[594px] w-full">
                                <label for="Asking price"></label>
                            </div>
                            <ngx-slider [(value)]="minPrice" [(highValue)]="maxPrice" [options]="sliderOptions"
                                (userChange)="onPriceSliderChange($event)"></ngx-slider>
                            <fieldset class="flex gap-8">
                                <div class="flex gap-3 items-center group/input">
                                    <p class="text-black">{{t('labels.minPrice')}}:</p>
                                    <div class="relative">
                                        <input placeholder="540.000" numericOnly #minPriceInput
                                            [(ngModel)]="minPriceValue" name="minpricevalue"
                                            (change)="MinPriceChanged(minPriceInput.value)"
                                            class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span
                                            class="absolute inset-y-0 right-5 flex items-center pl-2 text-gray-700">€</span>
                                    </div>
                                </div>
                                <div class="flex gap-3 items-center group/input">
                                    <p class="text-black">{{t('labels.maxPrice')}}:</p>
                                    <div class="relative">
                                        <input placeholder="540.000" numericOnly #maxPriceInput
                                            [(ngModel)]="maxPriceValue" name="maxpricevalue"
                                            (change)="MaxPriceChanged(maxPriceInput.value)"
                                            class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <span
                                            class="absolute inset-y-0 right-5 flex items-center pl-2 text-gray-700">€</span>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </form>
                    <div class="relative w-full">
                        <app-related-property-swiper [area]="propertyData.PropertyArea"
                            [location]="encodedLocationBounds" [type]="propertyData.PropertyType"
                            [newWindow]="true"></app-related-property-swiper>
                    </div>
                </div>
            </div>
            <app-navigation-buttons [HideSkip]="true" (ExitButtonClicked)="OpenModalComponent()"
                (PreviousButtonClicked)="ReturnToStep3()" (NextButtonClicked)="SaveStep4()"></app-navigation-buttons>
        </ng-container>

        <!-- Step 5 -->
        <ng-container *ngSwitchCase="'Step5'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=5 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col flex-1 h-full">
                    <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                        <div class="flex flex-col gap-12 items-center justify-center">
                            <div class="flex flex-col gap-4 max-w-[801px]">
                                <h2 class="h2 rfs:text-[40px] text-center font-semibold text-black">
                                    {{t('headers.stepFive')}}</h2>
                                <p class="rfs:text-xl text-center font-light">{{t('infoText.stepFive')}}</p>
                            </div>
                        </div>
                        <!-- <form class="rfs:pb-14">
                            <fieldset class="flex flex-col gap-4 text-left">
                                <h3 class="h3 text-secondary pb-2">Total €20</h3>
                                <div class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex gap-1 items-center text-gray-950 group-disabled:text-gray-300">Cardholder
                                        name <span class="text-red">*</span></label>
                                    <div
                                        class="flex bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <input
                                            class="outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300"
                                            placeholder="">
                                    </div>
                                </div>
                                <div class="flex flex-col gap-2 group/input">
                                    <label
                                        class="font-semibold flex gap-1 items-center text-gray-950 group-disabled:text-gray-300">Card
                                        number <span class="text-red">*</span></label>
                                    <div
                                        class="flex bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                        <button
                                            class="flex justify-center items-center gap-1 pr-4 border-r border-gray-300 mr-4">
                                            <svg width="34" height="24" viewBox="0 0 34 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1.23926" y="1.02734" width="32" height="21.9429"
                                                    stroke="#C2CCD3" />
                                                <rect x="1.23926" y="1.02734" width="32" height="21.9429"
                                                    fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M21.5142 18.2203C24.9598 18.2203 27.753 15.3946 27.753 11.909C27.753 8.42332 24.9598 5.59766 21.5142 5.59766C19.97 5.59766 18.5568 6.1652 17.4673 7.10533C16.3779 6.1652 14.9647 5.59766 13.4205 5.59766C9.97488 5.59766 7.18164 8.42333 7.18164 11.909C7.18164 15.3946 9.97488 18.2203 13.4205 18.2203C14.9647 18.2203 16.3779 17.6527 17.4673 16.7126C18.5568 17.6527 19.97 18.2203 21.5142 18.2203Z"
                                                    fill="#ED0006" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M17.4678 16.7126C18.8092 15.555 19.6598 13.8325 19.6598 11.909C19.6598 9.98546 18.8092 8.26293 17.4678 7.10532C18.5572 6.16519 19.9704 5.59766 21.5146 5.59766C24.9602 5.59766 27.7535 8.42332 27.7535 11.909C27.7535 15.3946 24.9602 18.2203 21.5146 18.2203C19.9704 18.2203 18.5572 17.6527 17.4678 16.7126Z"
                                                    fill="#F9A000" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M17.4674 16.7126C18.8088 15.555 19.6594 13.8325 19.6594 11.9091C19.6594 9.98558 18.8088 8.26308 17.4674 7.10547C16.126 8.26308 15.2754 9.98558 15.2754 11.9091C15.2754 13.8325 16.126 15.555 17.4674 16.7126Z"
                                                    fill="#FF5E00" />
                                            </svg>
                                        </button>
                                        <input
                                            class="outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300"
                                            placeholder="XXXX XXXX XXXX XXXX">
                                        <button class="flex justify-center items-center">
                                            <i class="icon-eye text-gray-700 text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="flex gap-4">
                                    <div class="flex flex-col gap-2 group/input max-w-[146px]">
                                        <label
                                            class="font-semibold flex gap-1 items-center text-gray-950 group-disabled:text-gray-300">Expiration
                                            date <span class="text-red">*</span></label>
                                        <div
                                            class="flex bg-white text-gray-500 rounded-lg border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                            <input
                                                class="max-w-[100px] outline-none flex-1 group-hover/input:bg-white transition-colors duration-300"
                                                placeholder="XX/XXXX">
                                        </div>
                                    </div>
                                    <div class="flex flex-col gap-2 group/input max-w-[72px]">
                                        <label
                                            class="font-semibold flex gap-1 items-center text-gray-950 group-disabled:text-gray-300">CVV
                                            <span class="text-red">*</span></label>
                                        <div
                                            class="flex bg-white text-gray-500 rounded-lg border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                            <input
                                                class="max-w-[50px] outline-none flex-1 group-hover/input:bg-white transition-colors duration-300"
                                                placeholder="XXX">
                                        </div>
                                    </div>
                                </div>
                                <div class="flex"> <label for="checkbox-selector-" class="checkbox-selector">
                                        <input type="checkbox" class="hidden" id="checkbox-selector-"
                                            name="checkbox-selector-">
                                        <div class="checkbox-selector-content">
                                            <span class="checkbox-selector-text text-gray-700 font-normal text-sm">I
                                                accept and have
                                                read the <a class="text-secondary text-sm font-semibold underline"
                                                    href="#">brokerage
                                                    agreement</a></span>
                                        </div>
                                    </label>
                                </div>
                            </fieldset>
                        </form> -->
                    </div>
                </div>
            </div>
            <app-navigation-buttons CustomNextTitle="Pay and start perfecting your listing" CustomSkipTitle="Pay later"
                (ExitButtonClicked)="OpenModalComponent()" (PreviousButtonClicked)="GoTo('Step4')"
                (NextButtonClicked)="SaveDataWithPayment()"
                (SkipButtonClicked)="SaveDataPayLater()"></app-navigation-buttons>
        </ng-container>
    </ng-container>

</div>