import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { ResolveEnd, Router, RouterModule } from '@angular/router';
import { ModalService } from '@indice/ng-components';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { UserProfile } from 'oidc-client-ts';
import { of } from 'rxjs';
import * as environment from '../../../core/appSettings';
import { map, mergeMap } from 'rxjs/operators';
import { IDENTITY_API_BASE_URL } from 'src/app/services/identity-api.service';
import { AuthService } from './../../../core/services/auth.service';
import { OnboardingExitModalComponent } from '../../../features/onboarding/onboarding-exit-modal/onboarding-exit-modal.component';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslocoDirective,ClickOutsideDirective],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  isLoggedIn = false;
  greeting: string | undefined;
  fullName: string | undefined;
  readonly authorityUrl: string;
  // Boolean to control dynamic header classes
  public headerFixed = false;
  alternav = false;
  alternavTitle = '';
  isHomePage = false;
  listingNav = false;
  listingNavTitle = '';
  public isPropertyDetails = false;
  public currentRoute: string = '';
  public authUrl = environment.appSettings.auth_settings.authority;
  public isMobileDevice?: boolean;
  public hideDot: boolean = true; // The green dot on the Avatar
  public numberOfRequests: number = 0; // Number of Requests shown in the Avatar Dropdown menu

  // Current year
  public copyrightYear = new Date().getFullYear();
  public isMobileMenuOpen = false;
  public isProfileMenuOpen = false;
  showUserMenu = false;

  showDropdown = false;
  openDropdowns: { [key: string]: boolean } = {};

  constructor(
    private modalService: ModalService,
    private _authService: AuthService,
    private router: Router,
    private _modalService: ModalService,
    private _translate: TranslocoService,
    @Optional() @Inject(IDENTITY_API_BASE_URL) authUrl?: string,
  ) {
    this.authorityUrl =
      authUrl !== undefined && authUrl !== null ? authUrl : '';
  }

  ngOnInit(): void {
    this.getUser();

    const userLoaded$ = this._authService.userLoaded;
    userLoaded$
      .pipe(
        mergeMap((user) => {
          if (user) {
            this.isLoggedIn = true;
            this.fullName = `${user.profile?.given_name} ${user.profile?.family_name ?? ''}`;
            return this._authService.getUserProfile();
          } else {
            return of(undefined);
          }
        }),
      )
      .subscribe((response: UserProfile | undefined) => {
        if (response) {
          this.greeting = response.name || response.email;
        }
      });

    // Get current route
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        this.currentRoute = routerData.url;

        // Check if you are on the home page
        //if (this.currentRoute.includes("home") || this.currentRoute === "/") {
        //  this.isHomePage = true;
        //}
        //else {
        //  this.isHomePage = false;
        //}

        // Check if you are on the onboarding page
        if (
          this.currentRoute.includes('welcome') ||
          this.currentRoute.includes('on-boarding')
        ) {
          this.listingNav = true;
          this.alternavTitle = 'Tell us about your needs';
        } else if (routerData.url.includes('property-upload-wizard')) {
          this.alternav = true;
          this.alternavTitle = 'Create a listing';
        } else {
          this.alternav = false;
        }

        // Check if you are on the seller upload pages
        if (this.currentRoute.includes('brokerage-agreement')) {
          this.listingNav = true;
          this.listingNavTitle = 'Στείλε την καταχώρισή σου για έλεγχο';
        } else if (this.currentRoute.includes('upload-photos')) {
          this.listingNav = true;
          this.listingNavTitle = 'Φωτογραφίες';
        } else {
          this.listingNav = false;
        }

        // Check if you are on the property page with id
        if (this.currentRoute.includes('properties/')) {
          this.isPropertyDetails = true;
        } else {
          this.isPropertyDetails = false;
        }

        this.showUserMenu = false;
      }
    });

    this.isMobileDevice = this.isMobile();
  }

  isMobile(): boolean {
    return window.innerWidth <= 1024;
  }

  handleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  closeMobileMenu() {
    if(!this.isMobileDevice) {
      this.isMobileMenuOpen = false;
    }
  }

  handleProfileMenu(): void {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  navigateAndClose(route?: string) {
    if (!route) {
      return this.closeAllMenus();
    }
    console.log(route)
    this.router.navigate([route]).then(() => {
      this.closeAllMenus();
    })
  }

  closeProfileMenu() {
    if(!this.isMobileDevice) {
      this.isProfileMenuOpen = false;
    }
  }

  login(): void {
    this._authService.signinRedirect(this.currentRoute);
  }

  logout(): void {
    this._authService.signoutRedirect();
  }

  public translate() {
    let langToSet = this._translate.getActiveLang() === 'en' ? 'el' : 'en';
    this._translate.setActiveLang(langToSet);
  }

  /**
   * @description: Function to add dynamic classes to header
   * @param: none
   * @returns: none
   * @memberof NavigationComponent
   */
  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.pageYOffset > 0) {
      this.headerFixed = true;
    } else {
      this.headerFixed = false;
    }
  }

  handleShowDropdown(id: string): void {
    this.showDropdown = !this.showDropdown;
    this.openDropdowns[id] = !this.openDropdowns[id];
  }

  private closeAllMenus() {
    this.showDropdown = false;
    this.isProfileMenuOpen = false;
    this.isMobileMenuOpen = false;
    this.showUserMenu = false;
  }

  private getUser() {
    this._authService
      .isLoggedIn()
      .pipe(
        map((res) => {
          this.isLoggedIn = res;

          if (this.isLoggedIn) {
            this._authService
              .getUserProfile()
              .pipe(
                map((profile) => {
                  this.greeting = profile?.name || profile?.email;
                  this.fullName = `${profile?.given_name} ${profile?.family_name ?? ''}`;
                }),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();
  }
  public goToFavorites(): void {
    this.router.navigateByUrl('/properties?favorites=1');
  }

  openModalComponent() {
    const exitModal = this.modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
}
