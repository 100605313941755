import { Component } from '@angular/core';
import { IndicationSidePanelComponent } from "../../shared/components/indication-side-panel/indication-side-panel.component";
import { IndicationSidePanelInputModel } from 'src/app/shared/components/indication-side-panel/IndicationSidePanelInputModel';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UnikoServiceTypes } from 'src/app/services/payments-api.service';
import { ModalService } from '@indice/ng-components';
import { SaveSearchAnonymousModalComponent } from '../save-search-modal/save-search-anonymous-modal/save-search-anonymous-modal.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [CommonModule, IndicationSidePanelComponent, IndicationSidePanelComponent],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent {
  protected sidePanelData: Array<IndicationSidePanelInputModel> = [];
  protected unikoServiceTypeEnum = UnikoServiceTypes;
  protected propertyCode: string | undefined;
  protected isLoggedIn: boolean = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _modalService: ModalService,
    private authService: AuthService
  ) {
    this.propertyCode = this._activatedRoute.snapshot.queryParams['property'];

    this.authService.isLoggedIn().pipe(
      tap((t: boolean) => this.isLoggedIn = t)
    ).subscribe()

    this.populateSidePanelData();
  }

  protected populateSidePanelData() {
    this.sidePanelData = [
      {
        label: "Listing enchanement",
        scrollTo: "listingEnchancement",
      },
      {
        label: "Moving and cleaning",
        scrollTo: "movingCleaning",
      },
      {
        label: "Property documentation",
        scrollTo: "propertyDocumentation",
      },
      {
        label: "Other services",
        scrollTo: "otherServices",
      },
    ];
  }

  protected goToServicePage(serviceType: UnikoServiceTypes): void {
    if (!this.isLoggedIn) {
      this._modalService.show(SaveSearchAnonymousModalComponent, {
        keyboard: true,
      });

      return;
    }

    const serviceTypeString = UnikoServiceTypes[serviceType];
    if (serviceTypeString) {
      this._router.navigate([`/services/${serviceTypeString}`], {
        queryParams: {
          property: this.propertyCode
        },
        queryParamsHandling: 'merge',
      });
    } else {
      console.error('service type not found');
    }
  }
}
