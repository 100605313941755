import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Modal, ModalService } from '@indice/ng-components';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { AuthService } from 'src/app/core/services/auth.service';
import { PortalApiService, PropertySummary } from 'src/app/services/portal-api.service';
import { FavoriteAnonymousModalComponent } from '../favorite-anonymous-modal/favorite-anonymous-modal.component';

@Component({
  selector: 'app-property-card-item',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoDirective, TranslocoPipe],
  templateUrl: './property-card-item.component.html',
  styleUrl: './property-card-item.component.css'
})

export class PropertyCardItemComponent implements OnChanges {
  @Input() propertySummary?: PropertySummary;
  @Input() focus: boolean = false;
  @Input() newWindow: boolean = false;
  @Input() isClickable: boolean = true;
  @Input() size: number = 0; //changes the size of the card, 0 is the default size, the rest is the big
  @Input() withBorder: boolean = false;
  @Output() favoritePressed: EventEmitter<void> = new EventEmitter();

  isLoggedIn: boolean = false;
  constructor(private _router: Router, private apiService: PortalApiService, private authService: AuthService, private _modalService: ModalService, private cdref: ChangeDetectorRef) {
    this.authService.isLoggedIn().subscribe(x => this.isLoggedIn = x);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.propertySummary?.defaultPhoto) {
      this.propertySummary.defaultPhoto.uri = this.propertySummary.defaultPhoto.photoItems?.find(pi => pi.uri?.includes('-s.webp'))?.uri ?? undefined
    }
  }

  onPropertyClick(propertyId: string | undefined) {
    if (this.isClickable === false) {
      return;
    }
    if (this.newWindow === false) {
      this._router.navigate(['/properties/' + propertyId]);
    } else {
      const url = this._router.serializeUrl(
        this._router.createUrlTree(['/properties/' + propertyId]));

      window.open(url, '_blank');
    }

  }

  toggleFavorite(event: any) {
    event.stopPropagation();
    if (this.isLoggedIn) {
      this.apiService.togglePropertyFavorite(this.propertySummary?.id ?? '').subscribe({
        next: () => {
          if (this.propertySummary) {
            this.propertySummary.isFavorite = !this.propertySummary?.isFavorite;
            this.cdref.detectChanges();
            this.favoritePressed.emit();
          }
        },
        error: (error) => {
          console.error(error)
        }
      });
    }
    else {
      let favoriteAnonymousModal: Modal<FavoriteAnonymousModalComponent> = new Modal<FavoriteAnonymousModalComponent>;

      favoriteAnonymousModal = this._modalService.show(FavoriteAnonymousModalComponent, {
        keyboard: true,
      })
    }
  }
}
