import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-navigation-buttons',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './navigation-buttons.component.html',
  styleUrl: './navigation-buttons.component.css'
})
export class NavigationButtonsComponent {
  @Input() HideSkip: boolean = false;
  @Input() HidePrevious: boolean = false;
  @Input() HideExit: boolean = false;
  @Input() HideNext: boolean = false;
  @Input() HidePreviousArrow: boolean = false;
  @Input() HideNextArrow: boolean = false;
  @Input() CustomPreviousTitle: string | undefined;
  @Input() CustomNextTitle: string | undefined;
  @Input() CustomSkipTitle: string | undefined;
  @Output() ExitButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() PreviousButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() NextButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() SkipButtonClicked: EventEmitter<void> = new EventEmitter();

  ExitButton() {
    this.ExitButtonClicked.emit();
  }
  SkipButton() {
    this.SkipButtonClicked.emit();
  }
  NextButton() {
    this.NextButtonClicked.emit();
  }
  PreviousButton() {
    this.PreviousButtonClicked.emit();
  }

}
