import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, input } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { tap } from 'rxjs';
import { LookingForPropertyOfType, SearchHistoryDateRange } from 'src/app/services/portal-api.service';
import { PlaceViewModel } from 'src/app/services/property.service';
import { isEmptyBindingElement } from 'typescript';

@Component({
  selector: 'app-onboarding-buyer-finish-page',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './finish-page.component.html',
  styleUrl: './finish-page.component.css'
})
export class OnboardingBuyerFinishPageComponent implements OnInit, OnChanges {
  @Input() PropertyTypes: string[] = []
  @Input() PriceMax: number = 0
  @Input() PriceMin: number = 0
  @Input() SelectedPlaces: PlaceViewModel[] = []
  @Input() SelectedHistory: string = ''

  PropertyTypesString: string = '';
  constructor(private translocoService: TranslocoService) {

  }
  ngOnChanges(): void {
    this.SetPropertyTypesString()
  }
  ngOnInit(): void {
    this.translocoService.selectTranslateObject('onboarding.onboarding-buyer').pipe(
      tap(t => {
        this.TranslatePropertyTypes(t);
        this.TranslateHistory(t);

      })
    ).subscribe();
    this.SetPropertyTypesString();
  }

  private TranslateHistory(t: any) {
    this.SelectedHistory = t?.history ? t.history[this.SelectedHistory] : this.SelectedHistory
  }

  private TranslatePropertyTypes(t: any) {
    this.PropertyTypes = this.PropertyTypes.map(sp => t?.buttons ? t.buttons[sp] : sp)
  }
  private SetPropertyTypesString() {
    this.PropertyTypesString = this.PropertyTypes.join(', ');
  }
}
