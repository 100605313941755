import { GoogleMapsSearchAutocompleteComponent } from 'src/app/shared/components/autocomplete/google-maps-search-autocomplete/google-maps-search-autocomplete.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import * as environment from '../core/appSettings';
import { GoogleMapsAutocompleteComponent } from './components/google-maps-autocomplete/google-maps-autocomplete.component';

@NgModule({
  declarations: [
    ProfileMenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    GoogleMapsAutocompleteComponent,
    GoogleMapsSearchAutocompleteComponent
  ],
  exports: [
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    GoogleMapsAutocompleteComponent,
    GoogleMapsSearchAutocompleteComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.appSettings.recaptchaPublicKey,
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'el',
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://www.google.com/recaptcha/api.js',
    },
  ],
})
export class SharedModule { }
