import { CommonModule } from '@angular/common';
import { ProgressStepperComponent } from 'src/app/shared/components/progress-stepper/progress-stepper.component';
import { Component } from '@angular/core';
import * as _ from 'lodash';
import { OnboardingData, Step1Button } from './models';
import { GoogleMapsAutocompleteComponent } from 'src/app/shared/components/google-maps-autocomplete/google-maps-autocomplete.component';
import { AddressComponentModel, AddressComponent } from 'src/app/shared/components/google-maps-autocomplete/helper';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { LookingForPropertyOfType, ProfilingAnswers, SearchHistoryDateRange } from 'src/app/services/portal-api.service';
import { Router, RouterModule } from '@angular/router';
import { ModalService } from '@indice/ng-components';
import { OnboardingExitModalComponent } from '../onboarding-exit-modal/onboarding-exit-modal.component';
import { NavigationButtonsComponent } from 'src/app/shared/components/navigation-buttons/navigation-buttons.component';
import { PropertyTypeButtonsComponent } from 'src/app/shared/components/property-type-buttons/property-type-buttons.component';
import { PlaceBoundsViewModel, PlaceViewModel } from 'src/app/services/property.service';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Button, SelectButtonComponent } from "../../../shared/components/select-buttons/select-buttons.component";
import { ChangeContext, NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { SearchPropertyComponent } from "../../../shared/components/autocomplete/search-property/search-property.component";
import { OnboardingBuyerFinishPageComponent } from "./finish-page/finish-page.component";
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-onboarding-buyer',
  standalone: true,
  imports: [CommonModule,
    ProgressStepperComponent,
    RouterModule,
    NavigationButtonsComponent,
    PropertyTypeButtonsComponent,
    TranslocoDirective,
    SelectButtonComponent,
    NgxSliderModule,
    SearchPropertyComponent, OnboardingBuyerFinishPageComponent],
  templateUrl: './onboarding-buyer.component.html',
  styleUrl: './onboarding-buyer.component.css'
})
export class OnboardingBuyerComponent {


  Step: string = "Step1"
  StepKeys: string[] = [
    "ideal-property-type",
    "search-history",
    "price-range",
    "location"];
  Steps: string[] = [
  ]
  debounceTime = 800;
  PropertyTypes: LookingForPropertyOfType[] = []
  SearchHistory = SearchHistoryDateRange
  SearchHistoryButtons: Button[] = []
  PriceMin: number = 20000;
  PriceMax: number = 2000000;
  PriceMin$: Subject<number> = new Subject();
  PriceMax$: Subject<number> = new Subject();
  SearchText: string = "";
  PlacesText: string = "";
  OnboardingData: OnboardingData = new OnboardingData;
  SelectedPlaces: PlaceViewModel[] = [];
  SelectedHistory: SearchHistoryDateRange = SearchHistoryDateRange.JustLooking;
  disableSearchSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  clearSearchSubject: Subject<void> = new Subject<void>;
  sliderOptions: Options = {
    floor: 20000,
    ceil: 2000000,
    step: 1000
  };
  constructor(private _addressComponentHelper: AddressComponent, private onboardingService: OnboardingService, private _router: Router, private modalService: ModalService, private translocoService: TranslocoService, private _authService: AuthService) { }
  ngOnInit() {
    this.translocoService.selectTranslateObject("onboarding.onboarding-buyer").pipe(
      tap(t => {
        this.StepKeys.forEach(step => {
          this.Steps.push(t.titles[step]);
        });
        Object.keys(this.SearchHistory).forEach(history => {
          console.log(history)
          this.SearchHistoryButtons.push({
            title: t?.history ? t.history[history] : history,
            id: history,
            isToggled: false
          } as Button)
        })
        console.log(this.SearchHistoryButtons)
      })
    ).subscribe();

    this.PriceMin$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(x => {
      this.PriceMin = x;
      if (this.PriceMax && this.PriceMax < this.PriceMin) {
        this.PriceMin = this.PriceMax;
      }
    })

    this.PriceMax$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(x => {
      this.PriceMax = x;
      if (this.PriceMin && this.PriceMax < this.PriceMin) {
        this.PriceMax = this.PriceMin;
      }
    })
  }
  buttonSelected(selection: LookingForPropertyOfType[]) {
    this.PropertyTypes = selection;
  }
  searchHistorySelected(selected: Button[]) {
    if (selected.length > 0) {
      this.SelectedHistory = selected[0].id as SearchHistoryDateRange
    }
    else {
      this.SelectedHistory = SearchHistoryDateRange.JustLooking;
    }
  }
  Goto(step: string) {
    this.OnboardingDataToString()
    this.Step = step;
  }
  private OnboardingDataToString() {
    this.SearchText = "";
    this.PlacesText = "";
    if (this.OnboardingData && this.OnboardingData.propertyTypes) {
      this.SearchText += this.OnboardingData.propertyTypes.join(" and ");
    }
    if (this.OnboardingData && this.OnboardingData.locations && this.OnboardingData.locations.length > 0) {
      this.SearchText += this.SearchText.length > 0 ? " in " : "in ";
      this.SearchText += _.uniq(this.OnboardingData.locations.map(x => x.Prefecture)).join(", ");
      this.PlacesText += `See property in ${_.first(this.OnboardingData.locations)?.Prefecture}`;
    }
    else {
      this.PlacesText += `See related properties`
    }

  }

  onPriceSliderChange($event: ChangeContext) {
    if (this.PriceMin > this.PriceMax) {
      this.PriceMax = this.PriceMin;
    }
  }

  SaveStep1() {
    this.OnboardingData.propertyTypes = this.PropertyTypes;
    this.Goto("Step2")
  }
  SaveStep2() {
    this.OnboardingData.priceMin = this.PriceMin;
    this.OnboardingData.priceMax = this.PriceMax;
    this.Goto("Step3")
  }

  SaveStep3() {
    this.OnboardingData.priceMin = this.PriceMin;
    this.OnboardingData.priceMax = this.PriceMax;
    this.Goto("Step4")
  }
  SaveStep4() {
    this.OnboardingData.locations = this.SelectedPlaces;
    this.OnboardingDataToString();
    this.Goto("Step5")
  }

  SaveAnswers(navigate?: string) {
    var locations: string[] | undefined

    if (this.OnboardingData.locations) {
      locations = this.OnboardingData.locations.map(x => x.FormatedAddress as string)
    }
    var profilingAnswers: ProfilingAnswers = new ProfilingAnswers({
      buyerPropertyLocations: locations,
      buyerPropertyPriceMax: this.OnboardingData.priceMax,
      buyerPropertyPriceMin: this.OnboardingData.priceMin,
      buyerPropertyTypes: this.OnboardingData.propertyTypes,
      buyerHistoryRange: this.SelectedHistory
    });
    this.onboardingService.updateAccountOwner(undefined, profilingAnswers).subscribe();
    if (!navigate) {
      this._authService.signinRedirect('/home');
    }
    else {
      this._authService.signinRedirect(navigate);
    }
  }
  AddPlace(places: PlaceViewModel[]) {
    if (places.length > 0) {
      this.SelectedPlaces = places
    }
    this.clearSearchSubject.next();
    if (this.SelectedPlaces.length > 4) {
      this.disableSearchSubject.next(true);
    }
    else {
      this.disableSearchSubject.next(false);
    }
  }
  SetMinPrice(value: string) {
    this.PriceMin$.next(Number.parseFloat(value));
  }
  SetMaxPrice(value: string) {
    this.PriceMax$.next(Number.parseFloat(value));
  }
  openModalComponent() {
    const exitModal = this.modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
}