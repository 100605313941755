import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { tap } from 'rxjs';
import { LookingForPropertyOfType } from 'src/app/services/portal-api.service';
import { Button, SelectButtonComponent } from '../select-buttons/select-buttons.component';

@Component({
  selector: 'app-property-type-buttons',
  standalone: true,
  imports: [CommonModule, SelectButtonComponent],
  templateUrl: './property-type-buttons.component.html',
  styleUrl: './property-type-buttons.component.css'
})
export class PropertyTypeButtonsComponent implements OnChanges {
  @Input() IsMultiple: boolean = false;
  @Input() ToggledButtons: LookingForPropertyOfType[] = [];
  @Output() SelectedItems: EventEmitter<LookingForPropertyOfType[]> = new EventEmitter();

  SelectedButtons: string[] = []
  Buttons: Button[] = [
    {
      "id": LookingForPropertyOfType.Villa,
      "title": "detached",
      "icon": "icon-villas",
      "isToggled": false
    },
    {
      "id": LookingForPropertyOfType.Apartment,
      "title": "apartment",
      "icon": "icon-apartment",
      "isToggled": false
    },
    {
      "id": LookingForPropertyOfType.Maisonette,
      "title": "maisonette",
      "icon": "icon-maisonette",
      "isToggled": false
    },
    {
      "id": LookingForPropertyOfType.HolidayHome,
      "title": "holiday-home",
      "icon": "icon-country-house",
      "isToggled": false
    }

  ]
  constructor(private translocoService: TranslocoService) {

  }
  ngOnInit() {
    this.translocoService.selectTranslateObject("onboarding.onboarding-buyer.buttons").pipe(
      tap(t => {
        this.Buttons.forEach(b => {
          b.title = t[b.id];
        })
      })
    ).subscribe();

    this.ToggledButtons.forEach(toggled => {
      this.Buttons.forEach(button => {
        if (button.id === toggled) {
          button.isToggled = true;
        }
      });
    })
  }
  ngOnChanges() {
    this.ToggledButtons.forEach(toggled => {
      this.Buttons.forEach(button => {
        if (button.id === toggled) {
          button.isToggled = true;
        }
      });
    })
  }
  toggleSelect(buttons: Button[]) {
    this.SelectedItems.emit(buttons.map(x => { return x.id as LookingForPropertyOfType }))
  }
}


