import { CommonModule, Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { PropertyCardItemComponent } from "../../../shared/components/property-card-item/property-card-item.component";
import { PortalApiService, PropertyDetails, PropertySummaryResultSet } from 'src/app/services/portal-api.service';
import { catchError, debounceTime, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { ToasterService, ToastType } from 'src/app/services/toaster.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IUnikoServicesConfig, PaymentsApiService, UnikoServicesConfig, UnikoServiceTypes } from 'src/app/services/payments-api.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { NavigationButtonsComponent } from "../../../shared/components/navigation-buttons/navigation-buttons.component";
import { InsertOrderItemRequest, InsertOrderRequest, OrderResponse, ServicesPaymentsApiService } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-service-property-select',
  standalone: true,
  imports: [CommonModule, PropertyCardItemComponent, ReactiveFormsModule, TranslocoPipe, NavigationButtonsComponent, NavigationButtonsComponent],
  templateUrl: './service-property-select.component.html',
  styleUrl: './service-property-select.component.css',
})
export class ServicePropertySelectComponent {
  protected showFavouritesUi = true;
  protected serviceType: UnikoServiceTypes;
  protected propertySummary: PropertyDetails | undefined;
  protected propertyFavourites: PropertyDetails[] | undefined;
  protected propertyRecentlyViewed: PropertyDetails[] | undefined;
  protected searchProperty: FormControl = new FormControl();

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _portalService: PortalApiService,
    private _location: Location,
    private _paymentsApiService: ServicesPaymentsApiService,
    @Inject(ToasterService) private _toastService: ToasterService,
  ) {
    const serviceTypeParam = this._activatedRoute.snapshot.paramMap.get('serviceType');
    this.serviceType = UnikoServiceTypes[serviceTypeParam as keyof typeof UnikoServiceTypes];

    if (!this.serviceType) {
      this._location.back();
      console.error('service type not found');
    }

    this._portalService.getMyFavorites(1, 100).pipe(
      catchError((err: any) => {
        this._toastService.show(ToastType.Error, 'Failed to get favourites', err.message || 'Unknown error', 5000);

        return of(undefined);
      }),
      tap((t: PropertySummaryResultSet | undefined) => {
        this.propertyFavourites = t?.items;
      }),
    ).subscribe();

    this._portalService.getMyRecentlyViewed(1, 100).pipe(
      catchError((err: any) => {
        this._toastService.show(ToastType.Error, 'Failed to get recently viewed', err.message || 'Unknown error', 5000);

        return of(undefined);
      }),
      tap((t: PropertySummaryResultSet | undefined) => {
        this.propertyRecentlyViewed = t?.items;
      }),
    ).subscribe();

    this.searchProperty.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((s) => {
        const propertyUrlNormalized = this.extractPropertyIdFromUrl(s);
        if (!propertyUrlNormalized.isValid) {
          this._toastService.show(ToastType.Error, 'Failed to get property', 'The provided URL is not valid.', 5000);
          return of(undefined);
        }

        return this._portalService.getPropertyById(propertyUrlNormalized.id).pipe(
          catchError((err: any) => {
            this._toastService.show(ToastType.Error, 'Failed to get property', err.message || 'Unknown error', 5000);

            return of(undefined);
          })
        );
      }),
      tap((t: PropertyDetails | undefined) => {
        this.propertySummary = t;
      }),
    ).subscribe();
  }

  protected proceedToCheckout(): void {
    const propertyId = this.propertySummary?.id;
    const sku = UnikoServicesConfig.find(f => f.type == this.serviceType)?.sku;
    if (sku && propertyId) {
      let orderItems: InsertOrderItemRequest[] = [];

      orderItems.push(new InsertOrderItemRequest({
        sku: sku,
        quantity: 1,
        propertyId: propertyId,
      }));

      let body = new InsertOrderRequest({
        currency: 'EUR',
        orderItems: orderItems,
      });
      this._paymentsApiService.createMyOrder(body).pipe(
        tap((response: OrderResponse) => {
          if (response && response.number) {
            this._router.navigate([`/checkout/${response.number}`]);
          }
        })

      ).subscribe();
    }
  }

  protected chooseThisProperty(propertyId: string | undefined): void {
    this.searchProperty.setValue(`${location.origin}/properties/${propertyId}`);
  }
  protected navigationBack(): void {
    this._location.back();
  }

  /**
  * Returns if url is valid property url and the value is guid. If valid returns the guid.
  * @param url - The url of propertys. Example http://localhost:4200/properties/2705fdb9-2f0d-4860-ac1e-08dca4d6d10d
  */
  private extractPropertyIdFromUrl(url: string): { isValid: boolean, id: string } {
    const guidPattern = /\/properties\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
    const match = url.match(guidPattern);
    let result = { isValid: false, id: '' };
    if (match && match[1]) {
      const guid = match[1];
      const isValidGuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid);
      result.isValid = true;
      result.id = guid;
    }
    return result;
  }
}
