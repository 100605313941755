import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Modal, ModalService } from "@indice/ng-components";
import { TranslocoDirective } from "@jsverse/transloco";
import { AuthService } from "src/app/core/services/auth.service";
import { PortalApiService, PropertyDetails } from "src/app/services/portal-api.service";
import { FavoriteAnonymousModalComponent } from "src/app/shared/components/favorite-anonymous-modal/favorite-anonymous-modal.component";

@Component({
  selector: 'app-property-description',
  standalone: true,
  templateUrl: './property-description.component.html',
  imports: [CommonModule, TranslocoDirective]
})
export class PropertyDescriptionComponent {
  public descriptionOpen: boolean = false;
  public accordionApproved: boolean = false;
  isLoggedIn: boolean = false;
  @Input() public model: PropertyDetails | undefined;

  public toggleDescription() {
    this.descriptionOpen = !this.descriptionOpen;
  }

  constructor(
    private authService: AuthService,
    private apiService: PortalApiService,
    private _modalService: ModalService
  ) {
    this.authService.isLoggedIn().subscribe(x => this.isLoggedIn = x);
  }

  public toggleAccordionApproved() {
    this.accordionApproved = !this.accordionApproved;
  }

  toggleFavorite(event: any) {
    event.stopPropagation();
    if (this.isLoggedIn) {
      this.apiService.togglePropertyFavorite(this.model?.id ?? '').subscribe({
        next: () => {
          if (this.model) {
            this.model.isFavorite = !this.model?.isFavorite;
          }
        }
      });
    }
    else {
      let favoriteAnonymousModal: Modal<FavoriteAnonymousModalComponent> = new Modal<FavoriteAnonymousModalComponent>;

      favoriteAnonymousModal = this._modalService.show(FavoriteAnonymousModalComponent, {
        keyboard: true,
      })
    }
  }
}
