<form [formGroup]="buyerForm" class="flex flex-col gap-8" *transloco="let t; prefix: 'shared.checkout.step-buyer-info'">
    <ng-container [formGroupName]="'mainForm'">
        <div class="flex gap-5">
            <label role="button" for="{{ ProofOfPaymentEnum.Receipt }}" class="radio-selector">
                <input [formControlName]="'proofOfPayment'"
                    (change)="onProofOfPaymentChange(ProofOfPaymentEnum.Receipt)" checked="checked"
                    value="{{ ProofOfPaymentEnum.Receipt }}" type="radio" class="hidden" id="radio-selector-1"
                    id="{{ ProofOfPaymentEnum.Receipt }}" name="proofOfPayment"
                    [attr.checked]="proofOfPayment===ProofOfPaymentEnum.Receipt ? 'checked': null">
                <div class="radio-selector-content">
                    <span
                        class="radio-selector-text font-semibold">{{'shared.enum.proofOfPayment.'+ProofOfPaymentEnum.Receipt
                        | transloco}}</span>
                </div>
            </label>
            <label role="button" for="{{ ProofOfPaymentEnum.Invoice }}" class="radio-selector">
                <input [formControlName]="'proofOfPayment'"
                    (change)="onProofOfPaymentChange(ProofOfPaymentEnum.Invoice)"
                    value="{{ ProofOfPaymentEnum.Invoice }}" type="radio" class="hidden"
                    id="{{ ProofOfPaymentEnum.Invoice }}" name="proofOfPayment"
                    [attr.checked]="proofOfPayment===ProofOfPaymentEnum.Invoice ? 'checked': null"
                    [attr.disabled]="true">
                <div class="radio-selector-content">
                    <span
                        class="radio-selector-text font-semibold">{{'shared.enum.proofOfPayment.'+ProofOfPaymentEnum.Invoice
                        | transloco}}</span>
                </div>
            </label>
        </div>

        <div class="flex gap-8">
            <div class="flex-1 flex flex-col gap-6">
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': controlHasError('mainForm.fullName') }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.fullName')}}<span
                            class="text-error"> *</span></label>
                    <input [formControlName]="'fullName'"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    <span *ngIf="controlHasError('mainForm.fullName')" class="text-error">{{
                        'shared.error.requiredField'
                        | transloco}}</span>
                </fieldset>
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': controlHasError('mainForm.address') }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.address')}}<span
                            class="text-error">
                            *</span></label>
                    <input [formControlName]="'address'"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    <span *ngIf="controlHasError('mainForm.address')" class="text-error">{{ 'shared.error.requiredField'
                        | transloco}}</span>
                </fieldset>
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': controlHasError('mainForm.city') }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.city')}}<span
                            class="text-error">
                            *</span></label>
                    <input [formControlName]="'city'"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    <span *ngIf="controlHasError('mainForm.city')" class="text-error">{{ 'shared.error.requiredField'
                        | transloco}}</span>
                </fieldset>
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': controlHasError('mainForm.landLine') }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.landLine')}}</label>
                    <input [formControlName]="'landLine'" type="text" [mask]="'9999999999'"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    <span *ngIf="controlHasError('mainForm.landLine')" class="text-error">{{
                        'shared.error.requiredField'
                        | transloco}}</span>
                </fieldset>
            </div>

            <div class="flex-1 flex flex-col gap-6">
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': controlHasError('mainForm.email') }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.email')}}<span
                            class="text-error">
                            *</span></label>
                    <input [formControlName]="'email'"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    <span *ngIf="controlHasError('mainForm.email')" class="text-error">{{
                        'shared.error.requiredField'
                        | transloco}}</span>
                </fieldset>

                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': controlHasError('mainForm.zipCode') }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.zipCode')}}<span
                            class="text-error">
                            *</span></label>
                    <input [formControlName]="'zipCode'"
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    <span *ngIf="controlHasError('mainForm.zipCode')" class="text-error">{{
                        'shared.error.invalidField'
                        | transloco}}</span>
                </fieldset>
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': controlHasError('mainForm.mobilePhone') }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.mobilePhone')}}
                        <span class="text-error">*</span></label>
                    <div
                        class="flex text-gray-500 rounded-full border border-gray-300 py-3 px-4 bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                        <button type="button"
                            class="flex justify-center items-center gap-1 pr-4 border-r border-gray-300 mr-4">
                            <i class="icon-info-circle text-gray-700 text-xl"></i>
                            <i class="icon-chevron-down text-gray-700 text-xl"></i>
                        </button>
                        +30
                        <input [formControlName]="'mobilePhone'" type="text" [mask]="'9999999999'"
                            class="bg-white outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300">
                    </div>
                    <span *ngIf="controlHasError('mainForm.mobilePhone')" class="text-error">{{
                        'shared.error.invalidField'
                        | transloco}}</span>
                </fieldset>
            </div>
        </div>
        <div class="flex flex-row items-center gap-1">
            <label for="checkbox-selector-test-check-1" class="checkbox-selector !min-w-fit">
                <input [formControlName]="'termsConditionsAccepted'" type="checkbox" class="hidden"
                    id="checkbox-selector-test-check-1" name="checkbox-selector-check-group-2">
                <div class="checkbox-selector-content">
                    <span class="checkbox-selector-text ml-2">I accept and have read the</span>
                </div>
            </label>
            <a (click)="openTermsAndConditions($event)" class="text-primary cursor-pointer font-bold">Terms of
                use
                and
                transactions</a>
        </div>
        <span *ngIf="controlHasError('mainForm.termsConditionsAccepted')"
            class="text-error">{{t('acceptTncError')}}</span>
    </ng-container>
    <ng-container *ngIf="proofOfPayment===ProofOfPaymentEnum.Invoice">
        <ng-container [formGroupName]="'invoiceSubForm'">
            <fieldset class="flex flex-col gap-2 group/input">
                <label [ngClass]="{ 'text-red': controlHasError('invoiceSubForm.taxNumber') }"
                    class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.taxNumber')}}<span
                        class="text-error">
                        *</span></label>
                <input [formControlName]="'taxNumber'"
                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
            </fieldset>
            <fieldset class="flex flex-col gap-2 group/input">
                <label [ngClass]="{ 'text-red': controlHasError('invoiceSubForm.companyName') }"
                    class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.companyName')}}<span
                        class="text-error">
                        *</span></label>
                <input [formControlName]="'companyName'"
                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
            </fieldset>
            <fieldset class="flex flex-col gap-2 group/input">
                <label [ngClass]="{ 'text-red': controlHasError('invoiceSubForm.taxOffice') }"
                    class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.taxOffice')}}<span
                        class="text-error">
                        *</span></label>
                <input [formControlName]="'taxOffice'"
                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
            </fieldset>
            <fieldset class="flex flex-col gap-2 group/input">
                <label [ngClass]="{ 'text-red': controlHasError('invoiceSubForm.profession') }"
                    class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.profession')}}<span
                        class="text-error">
                        *</span></label>
                <input [formControlName]="'profession'"
                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
            </fieldset>
        </ng-container>
    </ng-container>
</form>