import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, } from '@angular/core';
import { TranslocoDirective, TranslocoPipe, TranslocoService, } from '@jsverse/transloco';
import { Modal, ModalOptions } from '@indice/ng-components';
import { RequestsUnauthorizedComponent } from '../../../../shared/components/requests-unauthorized/requests-unauthorized.component';
import { AuthService } from 'src/app/core/services/auth.service';
import * as environment from '../../../../core/appSettings';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators, } from '@angular/forms';
import { catchError, EMPTY, Observable, Subject, switchMap, tap } from 'rxjs';
import { NgxMaskDirective, NgxMaskService, provideNgxMask } from 'ngx-mask';
import { IdentityApiService, TotpDeliveryChannel, TotpRequest, TotpVerificationRequest, ValidationProblemDetails, } from 'src/app/services/identity-api.service';
import { WorkflowApiService } from 'src/app/services/workflow.service';
import { ProblemDetails } from 'src/app/services/portal-api.service';
import { ToptCursorMoveDirective } from 'src/app/shared/directives/top-cursor-move.directive';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-make-offer-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    TranslocoPipe,
    ReactiveFormsModule,
    RequestsUnauthorizedComponent,
    NgxMaskDirective,
    ToptCursorMoveDirective
  ],
  templateUrl: './make-offer-modal.component.html',
  styleUrl: './make-offer-modal.component.css',
  providers: [provideNgxMask()],
})
export class MakeOfferModalComponent {
  @Input() askingPrice: number | undefined;
  @Input() propertyId: string | undefined;
  @Input() isLoggedIn: boolean = false;

  private stepSubject: Subject<number> = new Subject<number>();
  protected currentStep: number = 0;
  protected totalSteps: number = 4;
  protected offerForm: FormGroup;
  private priceControl: FormControl;
  protected includeLoanControl: FormControl;
  protected loanAmountControl: FormControl;
  protected equityControl: FormControl = new FormControl(0);
  private priceSubject: Subject<number> = new Subject<number>();
  private includeLoanSubject: Subject<boolean> = new Subject<boolean>();
  private equitySubject: Subject<void> = new Subject<void>();
  protected equity: number = 0;
  private onPhoneFormInitSubject: Subject<void> = new Subject<void>();
  private isFirstInitPhoneForm: boolean = true;
  protected totpPhoneDisclaimerUiText: string = '';
  protected hasPhoneVerificationError: boolean = false;
  protected phoneVerificationErrorText?: string;

  public stepVisible: boolean = false;

  private totp$ = this._identityApi
    .send(
      new TotpRequest({
        channel: TotpDeliveryChannel.Sms,
        message:
          'OTP CODE: {0} FOR PHONE NUMBER VERIFICATION. IT WILL BE VALID FOR 2 MINUTES.',
        subject: 'Verify phone number',
      }),
    )
    .pipe(
      catchError((error: any) => {
        this._modal.hide({ success: false });

        return EMPTY;
      }),
    );

  constructor(
    private _modal: Modal,
    private _authService: AuthService,
    private _fb: FormBuilder,
    private _identityApi: IdentityApiService,
    private _translocoService: TranslocoService,
    private _workflowApi: WorkflowApiService,
    private _ngx: NgxMaskService,
    @Inject(ModalOptions)
    private _options: ModalOptions<MakeOfferModalComponent>,
  ) {
    this.askingPrice = this._options?.initialState?.askingPrice as number;
    this.propertyId = this._options?.initialState?.propertyId as string;
    this.isLoggedIn = this._options?.initialState?.isLoggedIn as boolean;

    this.stepSubject
      .pipe(
        tap((newStep: number) => {
          this.currentStep = newStep;
          if (this.currentStep == 4) {
            this.onPhoneFormInitSubject.next();
          }
        }),
      )
      .subscribe();

    this.offerForm = this._fb.group({
      0: this._fb.group({}),
      1: this._fb.group({
        price: [
          this.askingPrice,
          [
            Validators.required,
            Validators.min(this.getMinOfferPrice()),
            Validators.max(this.getMaxOfferPrice()),
          ],
        ],
        tnc: [false, [Validators.required, Validators.requiredTrue]],
      }),
      2: this._fb.group({
        includeLoan: [false],
        loanAmount: [undefined],
      }),
      3: this._fb.group({}),
      4: this._fb.group({
        digit1: ['', Validators.required],
        digit2: ['', Validators.required],
        digit3: ['', Validators.required],
        digit4: ['', Validators.required],
        digit5: ['', Validators.required],
        digit6: ['', Validators.required],
      }),
    });

    this.priceControl = this.offerForm.get('1.price') as FormControl;
    this.includeLoanControl = this.offerForm.get(
      '2.includeLoan',
    ) as FormControl;
    this.loanAmountControl = this.offerForm.get('2.loanAmount') as FormControl;
    this.equityControl = this.offerForm.get('2.includeLoan') as FormControl;

    this.priceSubject
      .pipe(
        tap((value: number) => {
          this.equity = value - (this.loanAmountControl.value ?? (0 as number));
          this.setLoanAmountValueAndValidations();
        }),
      )
      .subscribe();

    this.includeLoanSubject
      .pipe(
        tap((value: boolean) => {
          this.equitySubject.next();
          this.setLoanAmountValueAndValidations();
        }),
      )
      .subscribe();

    this.equitySubject
      .pipe(
        tap(() => {
          this.equity =
            this.priceControl.value -
            (this.loanAmountControl.value ?? (0 as number));
        }),
      )
      .subscribe();

    this.priceControl.valueChanges
      .pipe(
        tap((value: number) => {
          this.priceSubject.next(value);
          this.equitySubject.next();
        }),
      )
      .subscribe();

    this.onPhoneFormInitSubject
      .pipe(
        tap(() => {
          if (this.isFirstInitPhoneForm) {
            this.isFirstInitPhoneForm = false;

            this.totp$.subscribe();
          }
        }),
      )
      .subscribe();

    this.includeLoanControl.valueChanges
      .pipe(
        tap((value: boolean) => {
          this.includeLoanSubject.next(value);
        }),
      )
      .subscribe();

    this.loanAmountControl.valueChanges
      .pipe(
        tap((value: number) => {
          this.equitySubject.next();
        }),
      )
      .subscribe();

    this.totp$ = this._identityApi.send(
      new TotpRequest({
        channel: TotpDeliveryChannel.Sms,
        message:
          'OTP CODE: {0} FOR PLACING AN OFFER. IT WILL BE VALID FOR 2 MINUTES.',
        subject: 'Verify phone number',
      }),
    );

    this._translocoService
      .selectTranslateObject('offer-modal')
      .pipe(
        tap((t) => {
          let transText = t['totp-phone-disclaimer'];
          let maskedPhone = this.maskPhoneNumber(
            this._authService.getCurrentUser()?.profile.phone_number,
          );

          this.totpPhoneDisclaimerUiText = transText.replace(
            '{{PhoneNumber}}'.trim(),
            maskedPhone,
          );
        }),
      )
      .subscribe();
  }

  protected onNextClick(): void {
    if (!this.isSubFormValid()) {
      this.markAllAsTouched(
        this.offerForm.get(`${this.currentStep}`) as FormGroup,
      );
      return;
    }
    if (this.currentStep === 0) {
      this.stepVisible = true;
    }
    this.stepSubject.next(this.currentStep + 1);
  }

  protected onPreviousClick(): void {
    if (this.currentStep === 1) {
      this.stepVisible = false;
    }
    this.stepSubject.next(this.currentStep - 1);
  }

  private isSubFormValid(): boolean {
    const activeSubForm = this.offerForm.get(
      `${this.currentStep}`,
    ) as FormGroup;
    const rs = activeSubForm !== null && activeSubForm.valid;
    return rs;
  }

  protected setLoanAmountValueAndValidations() {
    let loanAmountControlValue: number | undefined =
      this.loanAmountControl.value ?? undefined;
    if (this.includeLoanControl.value) {
      const minLoanAmount = this.getMinLoanAmount();
      const maxLoanAmount = this.getMaxLoanAmount();

      this.loanAmountControl.setValidators([
        Validators.required,
        Validators.min(minLoanAmount),
        Validators.max(maxLoanAmount),
      ]);

      if (
        loanAmountControlValue === undefined ||
        loanAmountControlValue < minLoanAmount ||
        loanAmountControlValue > maxLoanAmount
      ) {
        loanAmountControlValue = Math.floor(
          (minLoanAmount + maxLoanAmount) / 2,
        );
      }
    } else {
      this.loanAmountControl?.clearValidators();
      loanAmountControlValue = undefined;
    }
    this.loanAmountControl.setValue(loanAmountControlValue);
    this.loanAmountControl.updateValueAndValidity();
  }

  login() {
    this._authService.signinRedirect(`/properties/${this.propertyId}`);
  }

  signUp() {
    var authBasePath = environment.appSettings.auth_settings.authority;
    var portalAuthRedirect =
      environment.appSettings.auth_settings.post_logout_redirect_uri;
    window.location.href = `${authBasePath}/on-boarding/get-started?returnurl=${portalAuthRedirect}/properties/${this.propertyId}`;
  }

  protected closeModal(): void {
    this._modal.hide({});
  }

  protected getMinOfferPrice(): number {
    const askingPrice = this.askingPrice ?? 0;
    const rs = Math.floor(askingPrice * 0.6);

    return rs;
  }
  protected getMaxOfferPrice(): number {
    const askingPrice = this.askingPrice ?? 0;
    const rs = Math.ceil(askingPrice * 1.15);

    return rs;
  }

  protected getMinLoanAmount(): number {
    const offerPrice: number = this.offerForm.get('1.price')?.value ?? 0;
    const rs = Math.floor(offerPrice * 0.1);

    return rs;
  }
  protected getMaxLoanAmount(): number {
    const offerPrice: number = this.offerForm.get('1.price')?.value ?? 0;
    const rs = Math.floor(offerPrice * 0.9);

    return rs;
  }

  protected onGoToOffersClick(): void { }
  protected hasError(controlName: string): boolean {
    const control = this.offerForm.get(controlName) as FormControl;
    const result = control.invalid && control.touched;
    return result;
  }

  private markAllAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }
  private maskPhoneNumber(phoneNumber: string | undefined): string {
    if (!phoneNumber) return '';
    const phoneSections = phoneNumber.split(' ');
    const hasCountryCode = phoneSections?.length > 1;
    const countryCode = hasCountryCode ? phoneSections[0] : '';
    const restNumber = hasCountryCode ? phoneSections[1] : phoneSections[0];
    const maskedSection = restNumber.slice(0, 6).replace(/\d/g, 'X');
    const visibleSection = restNumber.slice(6);
    return `${countryCode} ${maskedSection}${visibleSection}`;
  }

  protected resendTotp(): void {
    this.offerForm.get('4')?.reset();
    this.hasPhoneVerificationError = false;
    this.totp$.subscribe();
  }

  protected onPhoneSubmitClick(): void {
    const subForm = this.offerForm.get(`${this.currentStep}`) as FormGroup;
    if (!this.isSubFormValid()) {
      this.markAllAsTouched(subForm);
      return;
    }
    const token = Object.values(subForm.value).join('');
    this._identityApi
      .verify(
        new TotpVerificationRequest({
          code: token,
        }),
      )
      .pipe(
        catchError((error: any) => {
          if (error instanceof ValidationProblemDetails) {
            this.phoneVerificationErrorText =
              error.errors?.['Code']?.at(0) ?? undefined;
            console.log(this.phoneVerificationErrorText);
          }

          this.hasPhoneVerificationError = true;
          this.offerForm.get('4')?.reset();

          return EMPTY;
        }),
        switchMap(() => {
          const price = this.priceControl.value;
          const loanAmount =
            this.includeLoanControl.value === true
              ? this.loanAmountControl.value
              : undefined;

          return this._workflowApi.initiatePropertyOffer({
            propertyId: this.propertyId ?? '',
            offerDetails: {
              price: price,
              loanAmount: loanAmount,
            },
          });
        }),
        catchError((error: any) => {
          this._modal.hide({ success: false });

          return EMPTY;
        }),
        tap(() => {
          const nextStep = this.currentStep + 1;
          this.stepSubject.next(nextStep);
        }),
      )
      .subscribe();
  }

  public handleTitle() {
    switch (this.currentStep) {
      case 0:
        return 'Όλα τα βήματα';
      case 1:
        return 'Κάνε προσφορά για την καταχώριση διαμέρισμα 122 τ.μ. στη Νέα Φιλοθέη';
      case 2:
        return 'Ενδιαφέρεσαι για δάνειο;';
      case 3:
        return 'Στοιχεία πιστωτικής / χρεωστικής κάρτας';
      case 4:
        return 'Μόλις στείλαμε έναν κωδικό επαλήθευσης στο τηλέφωνό σου';
      case 5:
        return 'Συγχαρητήρια!';
      default:
        return '';
    }
  }
}
