import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
    selector: 'app-locked-content-overlay',
    templateUrl: './locked-content-overlay.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule]
})
export class LockedContentOverlayComponent {
    @Input() isVissible: boolean = false;
    constructor() {}
}