import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Modal, ModalOptions } from '@indice/ng-components';
import { AuthService } from 'src/app/core/services/auth.service';
import { PropertyDetails } from 'src/app/services/portal-api.service';
import { RequestsUnauthorizedComponent } from '../requests-unauthorized/requests-unauthorized.component';
import * as environment from '../../../core/appSettings';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { WorkflowApiService } from 'src/app/services/workflow.service';
import { catchError, EMPTY, tap } from 'rxjs';

@Component({
  selector: 'app-request-callback-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RequestsUnauthorizedComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './request-callback-modal.component.html'
})
export class RequestCallbackModalComponent {

  @Input()
  property: PropertyDetails | undefined;

  @Input()
  isLoggedIn: boolean = false;

  form = new UntypedFormGroup({
    message: new UntypedFormControl(''),
  });

  constructor(public modalRef: Modal, @Inject(ModalOptions) private _options: ModalOptions, private _authService: AuthService, private _workflowApi: WorkflowApiService) { }

  ngOnInit(): void {
    this.property = (this._options?.initialState?.['property'] as PropertyDetails);
    this.isLoggedIn = (this._options?.initialState?.['isLoggedIn'] as boolean);
  }

  close = () => this.modalRef.hide({ name: this.form.get('message')?.value });

  sendRequest = () => {
    this._workflowApi.initiateCallback({
      propertyId: this.property?.id ?? '',
      userMessage: this.form.get('message')?.value,
      preferredContactDate: undefined,
    }).pipe(
      catchError((error: any) => {
        this.modalRef.hide({ success: false });

        return EMPTY;
      }),
      tap((t) => {
        this.modalRef.hide({ success: true });
      })
    ).subscribe();
  }

  login() {
    this._authService.signinRedirect(`/properties/${this.property?.id}`);
  }

  signUp() {
    this._authService.signinRedirect(`/home?action=callback&propertyId=${this.property?.id}`, { operation: 'register' });
  }
}
