<ng-container *transloco="let t; prefix: 'financing'">
  <section class="loan-calc-hero min-h-[575px] flex">
    <div class="container flex justify-center">
      <div class="flex flex-col justify-center items-center flex-1">
        <h1 class="text-white text rfs:text-[5rem] leading-tight font-semibold text-center max-w-[750px]">
          {{t('headers.helpFinanceDream')}}</h1>
        <div class="flex flex-col md:flex-row items-center md:items-start justify-between w-full gap-6">
          <div class="w-[176px]"></div>
          <button class="bg-white rounded-full text-secondary-900 py-3 px-8 font-semibold md:mt-8">
            {{t('buttons.calculateMonthlyCost')}}
          </button>
          <div class="rounded-full bg-primary-500 flex items-center justify-center w-[176px] h-[176px]">
            <div class="flex flex-col items-center max-w-[130px] text-center">
              <span class="text-sm text-white font-semibold mb-1">{{t('infoText.alwaysOffering')}}</span>
              <hr class="w-full border-white/25">
              <span class="text-[2.5rem] leading-none text-white font-medium mt-1">{{t('infoText.greatRates')}}</span>
              <hr class="w-full border-white/25">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container py-[72px] grid lg:grid-cols-3 rfs:gap-12">
    <div class="flex flex-col gap-4">
      <h3 class="h3 text-black leading-tight lg:max-w-[300px]">{{t('headers.housingLoans')}}</h3>
      <div class="flex gap-4">
        <div class="min-w-[58px] h-[58px] rounded-full flex justify-center items-center bg-secondary-900">
          <i class="text-white text-3xl icon-investment"></i>
        </div>
        <span>{{t('infoText.housingLoans')}}</span>
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <h3 class="h3 text-black leading-tight lg:max-w-[300px]">{{t('headers.renovationLoans')}}</h3>
      <div class="flex gap-4">
        <div class="min-w-[58px] h-[58px] rounded-full flex justify-center items-center bg-secondary-900">
          <i class="text-white text-3xl icon-paint-roller"></i>
        </div>
        <span>{{t('infoText.renovationLoans')}}</span>
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <h3 class="h3 text-black leading-tight lg:max-w-[300px]">{{t('headers.greenLoan')}}</h3>
      <div class="flex gap-4">
        <div class="min-w-[58px] h-[58px] rounded-full flex justify-center items-center bg-secondary-900">
          <i class="text-white text-4xl icon-investment"></i>
        </div>
        <span>{{t('infoText.greenLoan')}}</span>
      </div>
    </div>

  </section>
  <section class="container flex flex-col items-center gap-6 mb-[72px]">
    <div class="text-center">
      <h2 class="text-black text-[2.5rem] font-bold leading-tight">{{t('headers.whatCanIAfford')}}</h2>
      <span class="text-xl text-gray-900">{{t('infoText.whatCanIAfford')}}</span>
    </div>
    <div class="bg-white rounded-[1rem] py-20 px-12">
      <div class="mx-auto max-w-[1056px] flex flex-col gap-6 w-full">
        <h3 class="h2 text-black">{{'shared.headers.financeThisProperty' | transloco}}</h3>
        <div>
          <form>
            <div class="flex flex-row gap-12">
              <app-loan-calulator></app-loan-calulator>
              <div class="flex items-center justify-center">
                <app-doughnut-chart></app-doughnut-chart>
              </div>
            </div>
          </form>
        </div>
        <div class="flex flex-col md:flex-row gap-6" *ngIf="isLoggedIn">
          <a class="btn-secondary" [routerLink]="['/loan-eligibility']">{{'shared.anchors.loanEligibility' |
            transloco}}</a>
          <a class="btn-primary" [routerLink]="['/loan-application']">{{'shared.anchors.loanApplication' |
            transloco}}</a>
        </div>
      </div>
    </div>
  </section>
  <section class="container flex flex-col items-center mb-[72px]">
    <p class="max-w-[800px] rfs:text-[1.75rem] leading-tight text-center mb-8">
      {{t('infoText.beforeChoosingMortgage')}}
    </p>
    <a href="#"
      class="font-semibold text-secondary-900 flex items-center gap-2 p-4 hover:text-secondary-700 transition-colors">{{t('anchors.compareLoans')}}<i
        class="icon-arrow-right text-xl"></i></a>
  </section>
  <section class="container flex flex-col gap-6 items-center mb-[72px]">
    <h2 class="text-black text-[2.5rem] leading-tight font-semibold">{{t('headers.reasonsToGetLoan')}}</h2>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
      <div class="gap-4">
        <img src="assets/static/benefits-1.png" alt="Benefits 1" class="mb-4 max-h-[178px]">
        <h4 class="h3 text-black">{{t('headers.favorableLoanTerms')}}</h4>
        <p class="max-w-[374px]">{{t('infoText.favorableLoanTerms')}}</p>
      </div>
      <div class="gap-4">
        <img src="assets/static/benefits-2.png" alt="Benefits 1" class="mb-4 max-h-[178px]">
        <h4 class="h3 text-black">{{t('headers.longRepaymentTerm')}}</h4>
        <p class="max-w-[374px]">{{t('infoText.longRepaymentTerm')}}</p>
      </div>
      <div class="gap-4">
        <img src="assets/static/benefits-3.png" alt="Benefits 1" class="mb-4 max-h-[178px]">
        <h4 class="h3 text-black">{{t('headers.easyToApply')}}</h4>
        <p class="max-w-[374px]">{{t('infoText.easyToApply')}}</p>
      </div>
    </div>
  </section>
</ng-container>