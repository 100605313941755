import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.css'
})
export class RegistrationComponent {
  constructor(private authService: AuthService){
  }
  ngOnInit(){
    this.authService.signinRedirect('/', { operation: 'register'});
  }
}
