import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input } from '@angular/core';
import { AutocompleteBaseComponent } from '../autocomplete-base.component';
import { CommonModule } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AddressComponent, AddressComponentModel } from '../../google-maps-autocomplete/helper';
import { PlaceViewModel } from 'src/app/services/property.service';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';

@Component({
  selector: 'app-google-maps-search-autocomplete',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './google-maps-search-autocomplete.component.html',
  styleUrl: './google-maps-search-autocomplete.component.css'
})
export class GoogleMapsSearchAutocompleteComponent extends AutocompleteBaseComponent implements AfterViewInit {

  @Input() types: string[] = ['geocode'];

  private _itemsFilter = (item: google.maps.places.AutocompletePrediction) => {
    const selectedItem = this.selectedItems.find(x => x.PlaceId == item.place_id);
    return selectedItem == null || selectedItem == undefined;
  }

  public override onItemSelected: EventEmitter<PlaceViewModel> = new EventEmitter();
  public override onItemRemoved: EventEmitter<PlaceViewModel> = new EventEmitter();
  public override items: google.maps.places.AutocompletePrediction[] | undefined;
  public override selectedItems: PlaceViewModel[] = [];

  private _autocompleteService: google.maps.places.AutocompleteService | undefined;
  private _placesService: google.maps.places.PlacesService | undefined;
  private autocompleteSessionToken: google.maps.places.AutocompleteSessionToken | undefined;

  public resultsFound: boolean = false;

  constructor(private _addressComponentHelper: AddressComponent, private _cdr: ChangeDetectorRef) {
    super();
    this._autocompleteService = new google.maps.places.AutocompleteService();
    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  }
  public override ngOnInit(): void {
    this.debouncer
      .pipe(
        debounceTime(this.debounceMs),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        console.log(this.autocompleteSessionToken)
        this.busy = true;
        if (value.length >= this.minLength) {
          this.searchTerm = value;
          this._autocompleteService?.getPlacePredictions(
            {
              input: value,
              sessionToken: this.autocompleteSessionToken,
              componentRestrictions: { country: 'GR' },
              types: this.types,
            }, (results, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                this.items = results.filter(this._itemsFilter);;
                this.resultsFound = true;
              } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                this.items = [];
                this.resultsFound = false;
              }
              console.log(this.items)
              this.busy = false;
            }
          )
        }
      });
  }

  public ngAfterViewInit(): void {
    let map = new google.maps.Map(document.createElement('div') as HTMLElement);
    this._placesService = new google.maps.places.PlacesService(map);
  }

  public override onListItemSelected(item: google.maps.places.AutocompletePrediction): void {
    this._placesService?.getDetails(
      {
        fields: ['address_component', 'geometry', 'formatted_address'],
        placeId: item.place_id,
        sessionToken: this.autocompleteSessionToken
      }, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
          let addressComponents: AddressComponentModel[] = result['address_components'] ?? [];
          let geometry: google.maps.places.PlaceGeometry = result['geometry'] ?? {};
          let place: PlaceViewModel = {
            PlaceId: item.place_id,
            FormatedAddress: result['formatted_address'],
            Country: this._addressComponentHelper.findCountry(addressComponents),
            Prefecture: this._addressComponentHelper.findLocality(addressComponents),
            AdministrativeAreas: this._addressComponentHelper.findAdministativeAreas(addressComponents),
            StreetName: this._addressComponentHelper.findRoute(addressComponents),
            StreetNumber: this._addressComponentHelper.findStreetNumber(addressComponents),
            PostalCode: this._addressComponentHelper.findPostalCode(addressComponents),
            Lon: geometry?.location?.lng() ?? null,
            Lat: geometry?.location?.lat() ?? null,
            Bounds: {
              SwLon: geometry?.viewport?.getSouthWest().lng() ?? undefined,
              NeLat: geometry?.viewport?.getNorthEast().lat() ?? undefined,
              SwLat: geometry?.viewport?.getSouthWest().lat() ?? undefined,
              NeLon: geometry?.viewport?.getNorthEast().lng() ?? undefined
            }
          }
          console.log(addressComponents);
          console.log(place);
          this.selectedItems.push(place);
          this.items = this.items?.filter(this._itemsFilter);
          this.showResults = false;
          this._cdr.detectChanges();
          this.onItemSelected.emit(place);
        }
        this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();

      })

  }
  hideResults() {
    this.showResults = false;
  }
  public override onItemRemove(item: any): void {
    const index = this.selectedItems.indexOf(item);
    if (index > - 1) {
      this.selectedItems.splice(index, 1);
      this._cdr.detectChanges();
      this.onItemRemoved.emit(item);
    }
  }

  public clearItems(): void {
    this.showResults = false;
    this.items = [];
  }
}
