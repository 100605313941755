import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    // there are cases where clickOutside doesn't work for generated elements
    // in this particular scenario, to avoid any unwanted behaviour add the following class to the generated element
    // 'clickoutside-off'
    const clickedInside =
      this.elementRef.nativeElement.contains(target) ||
      target.classList.contains('clickoutside-off');
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
