import { HttpHeaders } from "@angular/common/http";
import { Injectable, InjectionToken } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";

export const ECOMMERCE_BASE_URL = new InjectionToken<string>('ECOMMERCE_BASE_URL');
export const ECOMMERCE_MERCHANTID = new InjectionToken<string>('ECOMMERCE_MERCHANTID');
export const ECOMMERCE_API_USERNAME = new InjectionToken<string>('ECOMMERCE_API_USERNAME');
export const ECOMMERCE_API_PASSWORD = new InjectionToken<string>('ECOMMERCE_API_PASSWORD');

export interface INbgEcommerceService {
    //createSession(body: CreateSessionRequest): Observable<CreateSessionResponse>

    loadSessionScript(merchant: string, version: string): Observable<void>;
}

@Injectable({
    providedIn: 'root'
})
export class NbgEcommerceService implements INbgEcommerceService {
    private scriptLoaded = false;
    public baseUrl: string | null;

    public script3ds: HTMLScriptElement | undefined;

    constructor(
    ) {
        this.baseUrl = "https://ibanke-commerce.nbg.gr";
        this.load3dsScript();
    }
    private load3dsScript(): void {
        if (this.script3ds) {
            return;
        }
        this.script3ds = document.createElement("script");
        this.script3ds.src = "https://ibanke-commerce.nbg.gr/static/threeDS/1.3.0/three-ds.min.js";
        document.body.appendChild(this.script3ds);
    }

    loadSessionScript(merchant: string, version: string): Observable<void> {
        if (this.scriptLoaded) {
            return of(void 0);
        }

        return new Observable<void>((observer) => {
            const script = document.createElement('script');
            script.src = `https://ibanke-commerce.nbg.gr/form/version/${version}/merchant/${merchant}/session.js`;
            script.type = 'text/javascript';
            script.async = true;

            script.onload = () => {
                this.scriptLoaded = true;
                observer.next(void 0);
                observer.complete();
            };

            script.onerror = (error: any) => {
                observer.error(error);
            };

            document.body.appendChild(script);
        }).pipe(
            map(() => void 0),
            catchError((error) => {
                console.error('Error loading NBG script', error);
                throw error;
            })
        );
    }

    private createAuthHeaders(username: string, password: string): HttpHeaders {
        const auth = btoa(`${username}:${password}`);
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': `Basic ${auth}`
        });
    }
}

/*
Interface classes
*/
export interface ICreateSessionRequest {
    correlationId?: string | undefined;
    session?: ISession | undefined;
}

export interface ICreateSessionResponse {
    correlationId?: string | undefined;
    lineOfBusiness?: string | undefined;
    merchant?: string | undefined;
    result?: string | undefined;
    session?: ISession | undefined;
}

export interface ISession {
    aes256Key?: string | undefined;
    authenticationLimit?: number | undefined;
    id?: string | undefined;
    updateStatus?: string | undefined;
    version?: string | undefined;
}

export interface IUpdateSessionRequest {
    order?: IOrder | undefined;
    contact?: IContact | undefined;
    shipping?: IShipping | undefined;
    customer?: ICustomer | undefined;
}

export interface IUpdateSessionResponse {
    customer?: ICustomer | undefined;
    lineOfBusiness?: string | undefined;
    merchant?: string | undefined;
    order?: IOrder | undefined;
    session?: ISession | undefined;
    shipping?: IShipping | undefined;
    version?: string | undefined;
}

export interface IOrder {
    id?: string | undefined;
    amount?: string | undefined;
    currency?: string | undefined;
    invoiceNumber?: string | undefined;
    reference?: string | undefined;
    customerOrderDate?: string | undefined;
    description?: string | undefined;
}

export interface IContact {
    email?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    mobilePhone?: string | undefined;
}

export interface IShipping {
    method?: string | undefined;
    contact?: IContact | undefined;
}

export interface ICustomer {
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    mobilePhone: string | undefined;
}

/*
Concrete classes
*/
export class CreateSessionRequest implements ICreateSessionRequest {
    correlationId?: string | undefined;
    session?: ISession | undefined;
}

export class CreateSessionResponse implements ICreateSessionResponse {
    correlationId?: string | undefined;
    lineOfBusiness?: string | undefined;
    merchant?: string | undefined;
    result?: string | undefined;
    session?: ISession | undefined;
}

export class UpdateSessionRequest implements IUpdateSessionRequest {
    order?: IOrder | undefined;
    contact?: IContact | undefined;
    shipping?: IShipping | undefined;
    customer?: ICustomer | undefined;
}

export class UpdateSessionResponse implements IUpdateSessionResponse {
    customer?: ICustomer | undefined;
    lineOfBusiness?: string | undefined;
    merchant?: string | undefined;
    order?: IOrder | undefined;
    session?: ISession | undefined;
    shipping?: IShipping | undefined;
    version?: string | undefined;
}

export class Session implements ISession {
    aes256Key: string | undefined;
    authenticationLimit: number | undefined;
    id: string | undefined;
    updateStatus: string | undefined;
    version: string | undefined;

}
