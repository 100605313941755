<section class="services-hero-2">
    <div class="bg-[#0BC582B2] py-[150px]">
        <div class="container flex flex-col items-center gap-6">
            <h1 class="text-white font-semibold text-center rfs:text-[80px] max-w-[1000px] leading-none">
                We make moving easy
            </h1>
            <span class="text-white rfs:text-[28px] max-w-[1000px]">
            </span>
        </div>
    </div>
</section>
<div class="container lg:flex flex-row pt-10 gap-[84px] h-full items-stretch">
    <div class="relative">
        <div class="max-w-[255px] flex flex-col justify-center">
            <h4 class="h4 text-black">Interested in this service?</h4>
            <span class="block text-center">
                Book your move through Neon and get great prices
            </span>
            <button (click)="requestSelectedService()" class="btn-primary mt-6 mb-8">Request Pre-approval</button>
            <a (click)="backToAllServices()" class="cursor-pointer btn-text flex items-center justify-center gap-2">
                <i class="icon-chevron-left text-[20px]"></i>
                Back to all services
            </a>
        </div>
    </div>
    <div class="flex-1">
        <section class="mb-[72px]">
            <p class="h3">
                <span class="font-normal leading-tight">
                    We can help you perform all types of moves. From packing your
                    cherished belongings, safe transport of the goods, to unpacking from
                    the moving boxes in your new home. We provide all available and
                    relevant insurance for your safety and our delivery.
                </span>
            </p>
        </section>
        <section class="mb-[72px]">
            <h2 class="h2 text-black mb-4">Our offering</h2>
            <div class="flex flex-col gap-6">
                <div class="rounded-lg border border-gray-300 p-6">
                    <div class="flex gap-4">
                        <div
                            class="rounded-full bg-secondary flex justify-center items-center text-white h-[58px] w-full max-w-[58px]">
                            <i class="icon-piano text-3xl"></i>
                        </div>
                        <div class="pb-5">
                            <h3 class="h3 text-black">All types of moving</h3>
                            <p class="text-gray-700">
                                Donec ullamcorper nulla non metus auctor fringilla. Nullam id dolor id
                                nibh ultricies vehicula ut id elit. Etiam porta sem malesuada magna
                                mollis euismod. Maecenas faucibus mollis interdum.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-lg border border-gray-300 p-6">
                    <div class="flex gap-4">
                        <div
                            class="rounded-full bg-secondary flex justify-center items-center text-white h-[58px] w-full max-w-[58px]">
                            <i class="icon-piano text-3xl"></i>
                        </div>
                        <div class="pb-5">
                            <h3 class="h3 text-black">All types of moving</h3>
                            <p class="text-gray-700">
                                Donec ullamcorper nulla non metus auctor fringilla. Nullam id dolor id
                                nibh ultricies vehicula ut id elit. Etiam porta sem malesuada magna
                                mollis euismod. Maecenas faucibus mollis interdum.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-lg border border-gray-300 p-6">
                    <div class="flex gap-4">
                        <div
                            class="rounded-full bg-secondary flex justify-center items-center text-white h-[58px] w-full max-w-[58px]">
                            <i class="icon-piano text-3xl"></i>
                        </div>
                        <div class="pb-5">
                            <h3 class="h3 text-black">All types of moving</h3>
                            <p class="text-gray-700">
                                Donec ullamcorper nulla non metus auctor fringilla. Nullam id dolor id
                                nibh ultricies vehicula ut id elit. Etiam porta sem malesuada magna
                                mollis euismod. Maecenas faucibus mollis interdum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg-gray-100 rounded-md p-6 mb-[72px]">
            <h2 class="h2 text-black mb-6">How it works</h2>
            <div class="flex flex-col gap-2">
                <div class="hidden md:flex rfs:px-20 items-center">
                    <div class="flex items-center justify-center rounded-full bg-white h-[58px] w-[58px]">
                        <i class="icon-euro-money text-[26px] text-secondary-900"></i>
                    </div>
                    <div class="flex-1 h-px bg-secondary-900"></div>
                    <div class="flex items-center justify-center rounded-full bg-white h-[58px] w-[58px]">
                        <i class="icon-euro-money text-[26px] text-secondary-900"></i>
                    </div>
                    <div class="flex-1 h-px bg-secondary-900"></div>
                    <div class="flex items-center justify-center rounded-full bg-white h-[58px] w-[58px]">
                        <i class="icon-euro-money text-[26px] text-secondary-900"></i>
                    </div>
                </div>
                <ul class="grid md:grid-cols-3 gap-5 h4 text-secondary-900">
                    <li class="flex md:justify-start">
                        <div class="flex gap-2 md:max-w-[220px]">
                            <span class="block">1.</span>Tell us the time and place
                        </div>
                    </li>
                    <li class="flex md:justify-center">
                        <div class="flex gap-2 md:max-w-[220px]">
                            <span class="block">2.</span>Get offers from moving companies
                        </div>
                    </li>
                    <li class="flex md:justify-end">
                        <div class="flex text-center md:max-w-[220px]">
                            <span class="block">3.</span>Sit back, and watch as your things move
                            perfectly
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <section class="mb-[72px]">
            <h2 class="h2 text-black mb-4">Related services</h2>
            <div class="grid md:grid-cols-3 gap-4">
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col gap-6">
                        <div>
                            <img class="object-fit h-[128px]"
                                src="../../../assets/img/document-signing.d93c100e640ad0925ac5.webp" alt="">
                        </div>
                        <div class="flex flex-col gap-2">
                            <p class="text-gray-700">Make your place look perfect</p>
                            <h3 class="h3 text-black">Professional photography</h3>
                        </div>
                        <div class="flex">
                            <button class="btn-secondary-sm flex items-center gap-2">
                                Book a photographer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col gap-6">
                        <div>
                            <img class="object-fit h-[128px]" src="../../../assets/img/document-signing.webp" alt="">
                        </div>
                        <div class="flex flex-col gap-2">
                            <p class="text-gray-700">Make your place look perfect</p>
                            <h3 class="h3 text-black">Professional photography</h3>
                        </div>
                        <div class="flex">
                            <button class="btn-secondary-sm flex items-center gap-2">
                                Book a photographer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col gap-6">
                        <div>
                            <img class="object-fit h-[128px]" src="../../../assets/img/document-signing.webp" alt="">
                        </div>
                        <div class="flex flex-col gap-2">
                            <p class="text-gray-700">Make your place look perfect</p>
                            <h3 class="h3 text-black">Professional photography</h3>
                        </div>
                        <div class="flex">
                            <button class="btn-secondary-sm flex items-center gap-2">
                                Book a photographer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>