import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-select-buttons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './select-buttons.component.html',
  styleUrl: './select-buttons.component.css'
})
export class SelectButtonComponent implements OnChanges {

  @Input() IsMultiple: boolean = false;
  @Input() ToggledButtons: any[] = [];
  @Input() Buttons: Button[] = [];
  @Output() SelectedItems: EventEmitter<Button[]> = new EventEmitter();

  SelectedButtons: Button[] = []

  ngOnInit() {
    this.ToggledButtons.forEach(toggled => {
      this.Buttons.forEach(button => {
        if (button.id === toggled) {
          button.isToggled = true;
          if (this.SelectedButtons.indexOf(button) == -1) {
            this.SelectedButtons.push(button);
          }
        }
      });
    })
  }
  ngOnChanges() {
    this.ToggledButtons.forEach(toggled => {
      this.Buttons.forEach(button => {
        if (button.id === toggled) {
          button.isToggled = true;
          if (this.SelectedButtons.indexOf(button) == -1) {
            this.SelectedButtons.push(button);
          }
        }
      });
    })
  }
  toggleSelect(buttonId: string) {
    var button = this.Buttons.find(x => x.id == buttonId);
    if (button == null)
      return;
    if (!this.IsMultiple) {
      let turnedOn = true;
      this.Buttons.forEach(x => {
        if (x.id == buttonId) {
          x.isToggled = !x.isToggled;
          turnedOn = x.isToggled;
        }
        else {
          x.isToggled = false;
        }
      });
      if (turnedOn) {
        this.SelectedButtons = [button];
      }
      else {
        this.SelectedButtons = []
      }
    }
    else {
      button.isToggled = !button.isToggled;
      let index = this.SelectedButtons.indexOf(button);
      if (index > -1) {
        this.SelectedButtons.splice(index, 1);
      }
      else {
        this.SelectedButtons.push(button);
      }
    }

    this.SelectedItems.emit(this.SelectedButtons)
  }
}

export class Button {
  id: string = "";
  title: string = "";
  icon: string = "";
  isToggled: boolean = false;
}

