import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PlaceType } from 'src/app/services/portal-api.service';

export const placeTypeToIconMap: { [key in PlaceType]: string } = {
  [PlaceType.Unknown]: 'location',
  [PlaceType.PrimarySchool]: 'school',
  [PlaceType.School]: 'school',
  [PlaceType.SecondarySchool]: 'school',
  [PlaceType.LightRailStation]: 'metro',
  [PlaceType.TrainStation]: 'metro',
  [PlaceType.BusStation]: 'bus',
  [PlaceType.Park]: 'park',
  [PlaceType.Supermarket]: 'supermarket',
  [PlaceType.GasStation]: 'gas-station',
  [PlaceType.ShoppingMall]: 'shopping',
  [PlaceType.Hospital]: 'hospital',
  [PlaceType.Church]: 'church',
  [PlaceType.Bank]: 'bank',
  [PlaceType.Airport]: 'airport',
  [PlaceType.Police]: 'police',
  [PlaceType.PostOffice]: 'post-office',
  [PlaceType.Stadium]: 'stadium',
  [PlaceType.University]: 'school',
  [PlaceType.CityHall]: 'city-hall'
};

@Component({
  selector: 'app-point-of-interest',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './point-of-interest.component.html',
  styleUrl: './point-of-interest.component.css'
})
export class PointOfInterestComponent {
  @Input() coordinates?: string = "";
  @Input() lat?: number = 0;
  @Input() lon?: number = 0;
  @Input() circleColor?: string = "";
  @Input() icon?: string | PlaceType;
  @Input() distanceFromProperty?: number = 0;
  @Input() isPropertyPin?: boolean = false;
  @Input() placeType?: string = "";
  @Input() subtitle?: string = "";

  ngOnInit(){
    this.icon = this.icon ?? ( this.placeType &&  placeTypeToIconMap[this.placeType as PlaceType] );
    this.distanceFromProperty = this.distanceFromProperty && this.distanceFromProperty * 1000;
  }
}
