<aside
  class="sticky top-[100px]"
  *transloco="let t; prefix: 'property-details.aside'"
>
  <div
    class="bg-white xl:relative fixed bottom-0 right-0 left-0 xl:rounded-b-medium rounded-t-medium p-6 shadow-[0px_3px_24px_0px_#0000000A] xl:max-w-[283px] z-20"
  >
    <h3
      class="text-black font-semibold rfs:text-2xl xl:mb-8 mb-5 flex flex-row items-center justify-between"
    >
      {{ t("aside-title") }}
      <button
        (click)="toggleAside()"
        type="button"
        class="text-primary-600 xl:hidden"
      >
        <i
          [ngClass]="{
            'icon-chevron-down': isOpen,
            'icon-chevron-up': !isOpen,
          }"
        ></i>
      </button>
    </h3>
    <div class="xl:block hidden">
      <button (click)="onCallbackClick()" class="expert-button-wrapper border max-w-[240px] w-full mb-4">
        <div class="button-title">
          <span>{{ t("expert-button.title") }}</span>
          <i class="icon-chevron-right"></i>
        </div>
        <p class="button-content">
          {{ t("expert-button.description") }}
        </p>
        <div class="flex -space-x-2 rtl:space-x-reverse">
          <div
            class="w-6 h-6 rounded-full border-2 border-white overflow-hidden"
          >
            <img src="" alt="expert-1" class="" />
          </div>

          <div
            class="w-6 h-6 rounded-full border-2 border-white overflow-hidden"
          >
            <img src="" alt="expert-2" class="" />
          </div>

          <div
            class="w-6 h-6 rounded-full border-2 border-white overflow-hidden"
          >
            <img src="" alt="expert-3" class="" />
          </div>
        </div>
      </button>
      <button
        (click)="onAskTourClick()"
        class="btn-secondary text-nowrap flex flex-row items-center justify-between w-full mb-4"
      >
        {{ t("ask-for-toor") }} <i class="icon-key"></i>
      </button>
      <button
        (click)="onMakeOfferClick()"
        class="btn-primary flex flex-row items-center justify-between w-full"
      >
        {{ t("make-an-offer") }} <i class="icon-make-an-offer"></i>
      </button>
    </div>
    <div [ngClass]="{ 'h-0': !isOpen, 'h-[auto]': isOpen }" class="xl:hidden block overflow-hidden">
      <button (click)="onAskTourClick()"
        class="btn-secondary text-nowrap flex flex-row items-center justify-between w-full mb-4">
        {{ t("ask-for-toor") }} <i class="icon-key"></i>
      </button>
      <button
        (click)="onMakeOfferClick()"
        class="btn-primary flex flex-row items-center justify-between w-full mb-4"
      >
        {{ t("make-an-offer") }} <i class="icon-make-an-offer"></i>
      </button>
      <button (click)="onCallbackClick()" class="expert-button-wrapper border w-full mb-4">
        <div class="button-title">
          <span>{{ t("expert-button.title") }}</span>
          <i class="icon-chevron-right"></i>
        </div>
        <p class="button-content">
          {{ t("expert-button.description") }}
        </p>
        <div class="flex -space-x-2 rtl:space-x-reverse">
          <div
            class="w-6 h-6 rounded-full border-2 border-white overflow-hidden"
          >
            <img src="" alt="expert-1" class="" />
          </div>

          <div
            class="w-6 h-6 rounded-full border-2 border-white overflow-hidden"
          >
            <img src="" alt="expert-2" class="" />
          </div>

          <div
            class="w-6 h-6 rounded-full border-2 border-white overflow-hidden"
          >
            <img src="" alt="expert-3" class="" />
          </div>
        </div>
      </button>
    </div>
  </div>
</aside>
