import { FormGroup } from "@angular/forms";


export interface ILoanEligibilityModel {
    loan: {
        categoryId: string;
        realEstateValue: string;
        amount: string;
        duration: string;
        interestRate: string;
        fixedInterestRate: string;
    };
    household: {
        yearBorn: string;
        marriageStatus: string;
        children: string;
        householdIncomePerYear: string;
        taxPerYear: string;
        householdDebt: string;
        isNbgCustomer: boolean;
    };
    guarantor: {
        wantGuarantor: boolean;
        yearBornGuarantor?: string;
        childrenGuarantor?: string;
        marriageStatusGuarantor?: string;
        householdIncomeGuarantor?: string;
        taxPerYearGuarantor?: string;
        householdDebtGuarantor?: string;
    };
}
export class LoanEligibilityModel implements ILoanEligibilityModel {
    constructor(form: FormGroup) {
        this.loan = form.get('loan')!.value;
        this.household = form.get('household')!.value;
        this.guarantor = form.get('guarantor')!.value;
    }
    loan: {
        categoryId: string;
        realEstateValue: string;
        amount: string;
        duration: string;
        interestRate: string;
        fixedInterestRate: string;
    };
    household: {
        yearBorn: string;
        marriageStatus: string;
        children: string;
        householdIncomePerYear: string;
        taxPerYear: string;
        householdDebt: string;
        isNbgCustomer: boolean;
    };
    guarantor: {
        wantGuarantor: boolean;
        yearBornGuarantor?: string;
        childrenGuarantor?: string;
        marriageStatusGuarantor?: string;
        householdIncomeGuarantor?: string;
        taxPerYearGuarantor?: string;
        householdDebtGuarantor?: string;
    };
}