import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';

@Component({
  selector: 'app-filter-dropdown',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './filter-dropdown.component.html',
  styleUrl: './filter-dropdown.component.css'
})
export class FilterDropdownComponent {
  @Input() dropdownItems: FilterDropdownItem[] = [];
  @Input() title: string | undefined;
  @Input() selectedKeys: string[] | undefined;
  @Input() allValuesLabel: string | undefined;
  @Input() hasBorder: boolean | undefined = true;
  @Input() hasPlaceholder: boolean | undefined = true;
  @Input() isHomepage: boolean | undefined = false;
  @Input() iconClasses: string | undefined = '';
  @Output() selectedItem: EventEmitter<string[] | undefined> = new EventEmitter<string[] | undefined>();

  selectedLabel: string | undefined;
  showResults: boolean = false;
  MultipleSelectedString: string = "Multiple"
  selectedItems: string[] = [];

  ngOnInit() {
    this.setDefaultValue();
  }
  ngOnChanges() {
    this.setDefaultValue();
  }
  setDefaultValue() {
    if (this.selectedKeys) {
      this.selectedItems = this.selectedKeys;
      this.resolveSelection()
    }
    else {
      this.selectedLabel = this.allValuesLabel;
    }
  }
  ItemSelected(item: FilterDropdownItem) {
    let itemIndex = this.selectedItems.indexOf(item.key)
    if (itemIndex > -1) {
      this.selectedItems.splice(itemIndex, 1);
    }
    else {
      this.selectedItems.push(item.key);
    }

    this.resolveSelection()
    this.selectedItem.emit(this.selectedItems);
  }
  ClearFilter() {
    this.selectedItems = [];
    this.resolveSelection();
    this.selectedItem.emit([]);
    this.closeDropdown();
  }

  toggleDropdown() {
    this.showResults = !this.showResults;
  }

  closeDropdown() {
    this.showResults = false;
  }

  private resolveSelection() {
    this.resolveViewLabel();
    this.resolveChecked();
  }
  private resolveViewLabel() {
    if (this.selectedItems.length > 1) {
      this.selectedLabel = this.MultipleSelectedString;
    }
    else if (this.selectedItems.length == 1) {
      this.selectedLabel = this.dropdownItems.find(x => x.key == this.selectedItems[0])?.label ?? this.allValuesLabel;
    }
    else {
      this.selectedLabel = this.allValuesLabel;
    }
  }
  private resolveChecked() {
    this.dropdownItems.forEach(x => x.checked = this.selectedItems.includes(x.key))
  }
}
export interface FilterDropdownItem {
  key: string,
  label: string,
  icon?: string,
  checked?: boolean
}
