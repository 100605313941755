<section class="services-hero-1">
    <div class="bg-[#0BC582B2] py-[150px]">
        <div class="container flex flex-col items-center gap-6">
            <h1 class="text-white font-semibold text-center rfs:text-[80px] max-w-[1000px] leading-none">
                We simplify the journey
                to a new home
            </h1>
            <span class="text-white rfs:text-[28px] max-w-[1000px]">
                Our approved premium services range from cleaning and photography to arranging the right
                documentation as you prepare your property to be sold
            </span>
        </div>
    </div>
</section>
<div class="container lg:flex flex-row pt-10 gap-[84px] h-full items-stretch">

    <app-indication-side-panel [inputData]="sidePanelData"></app-indication-side-panel>

    <div class="flex-1">
        <section class="mb-[72px]" scroll-anchor="listingEnchancement">
            <h2 class="h2 text-black mb-4">Listing enhancement</h2>
            <div class="grid xl:grid-cols-2 gap-4">
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Professional photography</h3>
                                <p class="text-gray-700">Make your place look perfect</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Book a
                                    photographer
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/static/photography.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Sponsor your listing</h3>
                                <p class="text-gray-700">Get more walkthroughs</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Boost your walkthroughs
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/static/service-1.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Pre-approve your property</h3>
                                <p class="text-gray-700">Sell faster at a better price</p>
                            </div>
                            <div class="flex">
                                <button (click)="goToServicePage(unikoServiceTypeEnum.preapproval)"
                                    class="btn-secondary flex items-center gap-2">
                                    Get pre-approval
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/img/document-signing.webp" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mb-[72px]" scroll-anchor="movingCleaning">
            <h2 class="h2 text-black mb-4">Moving and cleaning</h2>
            <div class="grid xl:grid-cols-2 gap-4">
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Clean your property</h3>
                                <p class="text-gray-700">Leave it in top condition</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Book a cleaner
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/static/cleaning.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Moving between properties</h3>
                                <p class="text-gray-700">make it easy between homes</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Book a
                                    mover
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/static/service-4.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mb-[72px]" scroll-anchor="propertyDocumentation">
            <h2 class="h2 text-black mb-4">Property documentation</h2>
            <div class="grid xl:grid-cols-2 gap-4">
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Technical due diligence</h3>
                                <p class="text-gray-700">Prove everthing is up to code</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Get this document
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/sell-with-us/document-stamped.webp"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Legal due diligence</h3>
                                <p class="text-gray-700">Prove the property is legal</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Get this document
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/sell-with-us/document-stamped.webp"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Property transfer pack</h3>
                                <p class="text-gray-700">Make it easy to transfer</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Get this document
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/sell-with-us/document-stamped.webp"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Legalization support</h3>
                                <p class="text-gray-700">Get the property legalized</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Get legalization support
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/sell-with-us/document-stamped.webp"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Energy certificate</h3>
                                <p class="text-gray-700">Prove your property is efficient</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Get this document
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/sell-with-us/document-stamped.webp"
                                alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mb-[72px]" scroll-anchor="otherServices">
            <h2 class="h2 text-black mb-4">Other services</h2>
            <div class="grid xl:grid-cols-2 gap-4">
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Home security</h3>
                                <p class="text-gray-700">Live worry free</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Get protected
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/on-boarding/bg-8.webp" alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Electricity bill deals</h3>
                                <p class="text-gray-700">Lower your energy bills</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Get a better deal
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/property-wizard/house-karikatur.webp"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded-medium py-6 px-4">
                    <div class="flex flex-col sm:flex-row rfs:gap-10 justify-between">
                        <div class="flex flex-col gap-6 order-2">
                            <div class="flex flex-col gap-2">
                                <h3 class="h3 text-black">Renovation service</h3>
                                <p class="text-gray-700">Improve your home hassle free</p>
                            </div>
                            <div class="flex">
                                <button class="btn-secondary flex items-center gap-2">
                                    Make your house a home
                                </button>
                            </div>
                        </div>
                        <div class="order-1 sm:order-2">
                            <img class="object-fit h-[128px]" src="../../../assets/static/renovating-karikatur.webp"
                                alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mb-[72px]">
            <h2 class="h2 text-black mb-4">What our customers say</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div class="flex flex-col">
                    <div class="flex justify-between flex-wrap">
                        <span class="review-name font-bold text-xl text-black">Effrosyni</span>
                        <div class="text-lg text-secondary">
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3">
                        <span class="review-date text-sm">3 days ago</span>
                        <p class="text-sm text-black">
                            Our dedicated agent made buying a home so easy. I couldn’t be more
                            impressed.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="flex justify-between flex-wrap">
                        <span class="review-name font-bold text-xl text-black">Alexander</span>
                        <div class="text-lg text-secondary">
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3">
                        <span class="review-date text-sm">3 days ago</span>
                        <p class="text-sm text-black">
                            Our dedicated agent made buying a home so easy. I couldn’t be more
                            impressed.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="flex justify-between flex-wrap">
                        <span class="review-name font-bold text-xl text-black">Theo</span>
                        <div class="text-lg text-secondary">
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                            <i class="icon-star-filled"></i>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3">
                        <span class="review-date text-sm">3 days ago</span>
                        <p class="text-sm text-black">
                            Our dedicated agent made buying a home so easy. I couldn’t be more
                            impressed.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>