<div class="flex-1 flex flex-col justify-between pt-10 t-[84px]"
  *transloco="let t; prefix: 'onboarding.onboarding-buyer'">
  <ng-container [ngSwitch]="Step">
    <!-- STEP 1 -->
    <ng-container *ngSwitchCase="'Step1'">
      <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
        <progress-stepper class="progress-stepper-onboarding" [StepNo]=1 [Steps]=Steps></progress-stepper>
        <div class="flex flex-col gap-12 items-center justify-center">
          <div class="flex flex-col gap-4">
            <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepOne')}}</h2>
            <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepOne')}}
            </p>
          </div>
          <app-property-type-buttons (SelectedItems)="buttonSelected($event)" [IsMultiple]="true"
            [ToggledButtons]="PropertyTypes"></app-property-type-buttons>
        </div>
      </div>

      <app-navigation-buttons [HidePrevious]="true" (ExitButtonClicked)="openModalComponent()" [HideSkip]="true"
        (NextButtonClicked)="SaveStep1()" (SkipButtonClicked)="Goto('Step2')"></app-navigation-buttons>
    </ng-container>
    <!-- STEP 2 -->
    <ng-container *ngSwitchCase="'Step2'">
      <div class="container flex flex-1 flex-col rfs:gap-28 items-center">

        <progress-stepper class="progress-stepper-onboarding" [StepNo]=2 [Steps]=Steps></progress-stepper>
        <div class="flex flex-col gap-12 items-center justify-center">
          <div class="flex flex-col gap-4">
            <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepTwo')}}</h2>
            <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepTwo')}}
            </p>
          </div>
          <app-select-buttons (SelectedItems)="searchHistorySelected($event)" [IsMultiple]="false"
            [Buttons]="SearchHistoryButtons" [ToggledButtons]="SearchHistoryButtons"></app-select-buttons>
        </div>
      </div>

      <app-navigation-buttons (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="SaveStep2()"
        [HideSkip]="true" (PreviousButtonClicked)="Goto('Step1')"></app-navigation-buttons>
    </ng-container>

    <!-- STEP 3 -->
    <ng-container *ngSwitchCase="'Step3'">
      <div class="flex flex-1 flex-col h-full">
        <div class="container flex-1 flex flex-col justify-start rfs:gap-28 items-center">
          <progress-stepper [StepNo]=3 [Steps]=Steps></progress-stepper>
          <div class="flex flex-col gap-12 items-center justify-center">
            <div class="flex flex-col gap-4">
              <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepThree')}}</h2>
              <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepThree')}}
              </p>
            </div>
            <form class="flex flex-col gap-4 justify-center">
              <ngx-slider [(value)]="PriceMin" [(highValue)]="PriceMax" [options]="sliderOptions"
                (userChange)="onPriceSliderChange($event)"></ngx-slider>
              <div class="flex flex-row items-center gap-3">
                {{t('labels.from')}}
                <fieldset class="rounded-full max-w-[144px] px-4 py-3 border border-tone-05 bg-tone-00">
                  <input type="number" max="PriceMax" class="bg-transparent max-w-[112px] outline-none no-spinner"
                    placeholder="€10 000" #buyerMinPrice (keyup)="SetMinPrice(buyerMinPrice.value)" [value]="PriceMin">
                </fieldset>
                {{t('labels.to')}}
                <fieldset class="rounded-full max-w-[144px] px-4 py-3 border border-tone-05 bg-tone-00">
                  <input type="number" min="PriceMin" class="bg-transparent max-w-[112px] outline-none no-spinner"
                    placeholder="€100 000" #buyerMaxPrice (keyup)="SetMaxPrice(buyerMaxPrice.value)" [value]="PriceMax">
                </fieldset>
              </div>
            </form>
          </div>
          <div></div>
        </div>
        <app-navigation-buttons (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="SaveStep3()"
          [HideSkip]="true" (PreviousButtonClicked)="Goto('Step2')"></app-navigation-buttons>
      </div>
    </ng-container>

    <!-- STEP 4 -->
    <ng-container *ngSwitchCase="'Step4'">
      <div class="container flex flex-1 flex-col justify-start rfs:gap-28 items-center">
        <progress-stepper [StepNo]=4 [Steps]=Steps></progress-stepper>
        <div class="flex flex-col gap-12 items-center justify-center max-w-[800px] w-full">
          <div class="flex flex-col gap-4">
            <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepFour')}}</h2>
            <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepFour')}}
            </p>
          </div>
          <app-search-property class="w-full" (ItemSelected)="AddPlace($event)"
            [disableInput]="disableSearchSubject"></app-search-property>
        </div>

      </div>
      <app-navigation-buttons (ExitButtonClicked)=" openModalComponent()" (NextButtonClicked)="SaveStep4()"
        [HideSkip]="true" (PreviousButtonClicked)="Goto('Step3')"></app-navigation-buttons>
    </ng-container>

    <!-- STEP 5 -->
    <ng-container *ngSwitchCase="'Step5'">
      <app-onboarding-buyer-finish-page [PropertyTypes]="PropertyTypes" [SelectedHistory]="SelectedHistory"
        [SelectedPlaces]="SelectedPlaces" [PriceMin]="PriceMin"
        [PriceMax]="PriceMax"></app-onboarding-buyer-finish-page>
      <app-navigation-buttons (NextButtonClicked)="SaveAnswers('/properties')" (PreviousButtonClicked)="Goto('Step3')"
        [CustomNextTitle]="t('find-a-property')" [HidePrevious]="true" [CustomSkipTitle]="t('go-to-home')"
        (SkipButtonClicked)="SaveAnswers()" [HideNextArrow]="true"></app-navigation-buttons>
    </ng-container>
  </ng-container>

</div>