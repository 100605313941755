import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Modal, ModalOptions } from '@indice/ng-components';
import { AcceptTnCRequest, IAcceptTnCRequest, InitiateWorkflowRequest, PortalApiService, PropertyDetails } from 'src/app/services/portal-api.service';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { RequestWalkthroughSelectDateComponent, IWalkthroughDate } from './request-walkthrough-select-date/request-walkthrough-select-date.component';
import { RequestWalkthroughVerifyPhoneComponent } from './request-walkthrough-verify-phone/request-walkthrough-verify-phone.component';
import { RequestWalkthroughSuccessComponent } from './request-walkthrough-success/request-walkthrough-success.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestsUnauthorizedComponent } from '../requests-unauthorized/requests-unauthorized.component';
import * as environment from '../../../core/appSettings';
import { WorkflowApiService } from 'src/app/services/workflow.service';
import { catchError, EMPTY, tap } from 'rxjs';
import { ToasterService, ToastType } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-request-walkthrough-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, DatePickerComponent, RequestWalkthroughSelectDateComponent, RequestWalkthroughVerifyPhoneComponent, RequestWalkthroughSuccessComponent, RequestsUnauthorizedComponent],
  templateUrl: './request-walkthrough-modal.component.html'
})
export class RequestWalkthroughModalComponent {

  @Input()
  property: PropertyDetails | undefined;

  @Input()
  isLoggedIn: boolean = false;

  form = new UntypedFormGroup({
    message: new UntypedFormControl(''),
  });


  public RequestWalkthroughSteps = RequestWalkthroughSteps;
  public currentStep: RequestWalkthroughSteps = RequestWalkthroughSteps.SelectDate;
  public selectedDate: IWalkthroughDate | undefined;

  constructor(public modalRef: Modal, @Inject(ModalOptions) private _options: ModalOptions, private _authService: AuthService, private _api: PortalApiService, private _workflowApi: WorkflowApiService,
    @Inject(ToasterService) private _toastService: ToasterService,
  ) { }

  ngOnInit(): void {
    this.property = (this._options?.initialState?.['property'] as PropertyDetails);
    this.isLoggedIn = (this._options?.initialState?.['isLoggedIn'] as boolean);
  }

  close = () => this.modalRef.hide({ name: this.form.get('message')?.value });

  public onDateSelected(date: IWalkthroughDate) {
    this.selectedDate = date;
    this._api.acceptTerms(new AcceptTnCRequest({
      propertyId: this.property?.id,
      content: ''
    } as IAcceptTnCRequest))
      .subscribe(() => {
        this.currentStep = RequestWalkthroughSteps.VerifyPhone;
      });
  }

  public onPhoneVerified() {
    this._workflowApi.initiateWalkthrough({
      propertyId: this.property?.id ?? '',
      walkthroughDetails: {
        proposedTimeSlotFrom: this.getFullDateTime(this.selectedDate?.date, this.selectedDate?.fromTime),
        proposedTimeSlotTo: this.getFullDateTime(this.selectedDate?.date, this.selectedDate?.toTime),
      }
    }).pipe(
      catchError((err: any) => {
        this.modalRef.hide({ success: false });

        return EMPTY;
      }),
      tap(() => {
        this.modalRef.hide({ success: true });
      })
    ).subscribe();
  }

  public closeModal() {
    this.modalRef.hide({ name: this.form.get('message')?.value, success: true });
  }

  login() {
    this._authService.signinRedirect(`/properties/${this.property?.id}`);
  }

  signUp() {
    var authBasePath = environment.appSettings.auth_settings.authority;
    var portalAuthRedirect = environment.appSettings.auth_settings.post_logout_redirect_uri;
    window.location.href = `${authBasePath}/on-boarding/get-started?returnurl=${portalAuthRedirect}/properties/${this.property?.id}`;
    ///home?action=walkthrough&propertyId=${this.property?.id}&date=${this.selectedDate?.date}&from=${this.selectedDate?.fromTime}&to=${this.selectedDate?.toTime}
  }

  private getFullDateTime(baseDate?: Date, time?: string): string {
    if (!baseDate || !time) {
      return new Date("1970-01-01T00:00:00.000Z").toISOString(); // Default date
    }

    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date(baseDate); // clone the date to avoid mutating the original
    date.setHours(hours, minutes, 0, 0); // set hours and minutes

    return date.toISOString();
  }
}

export enum RequestWalkthroughSteps {
  SelectDate = 0,
  VerifyPhone = 1,
  Success = 2
}
