import { Injectable } from "@angular/core";
import { GetWorkflowForPropertyResponse, InitiateWorkflowRequest, PortalApiService, SendForReviewResponse } from "./portal-api.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WorkflowApiService {
    constructor(private _portalApi: PortalApiService) { }


    public initiateEditProperty(data: IEditPropertyData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.editProperty,
            data: data
        });
        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiateWalkthrough(data: IWalkthroughData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.walkthrough,
            data: data
        });
        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiatePhotography(data: IPhotographyData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.photography,
            data: {}
        });

        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiateTechnicalReport(data: IPropertyReportDocsData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.technicalReport,
            data: {}
        });

        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiateLegalReport(data: IPropertyReportDocsData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.legalReport,
            data: {}
        });

        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiateAssetOnboarding(data: IAssetOnboardingData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.assetOnboarding,
            data: {}
        });

        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiatePropertyOffer(data: IPropertyOfferData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.propertyOffer,
            data: data
        });

        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiateCallback(data: ICallbackData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.callback,
            data: data
        });

        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public assetOnboardGetWorkflowForReview(propertyId: string): Observable<GetWorkflowForPropertyResponse> {
        return this._portalApi.assetOnboardGetWorkflowForReview(propertyId);
    }

    public assetOnboardSendForReview(propertyId: string): Observable<SendForReviewResponse> {
        return this._portalApi.assetOnboardSendForReview(propertyId);
    }
}

export enum CaseTypeCodes {
    mortgageLoan = 'mortgage-loan',
    callback = 'callback',
    preapproval = 'pre-approval',
    editProperty = 'edit-property',
    walkthrough = 'walkthrough',
    technicalReport = 'technical-report',
    legalReport = 'legal-report',
    photography = 'photography',
    assetOnboarding = 'asset-onboarding',
    propertyOffer = 'property-offer',
}

export interface IEditPropertyData {
    propertyId: string;
    editDetails: IEditDetails;
}

export interface IEditDetails {
    hasPriceChange: boolean;
    hasShortDescriptionChange: boolean;
    hasFloorChange: boolean;
    hasPhotoChange: boolean;
}

export interface IWalkthroughData {
    propertyId: string;
    walkthroughDetails: IWalkthroughDetails;

}
export interface IWalkthroughDetails {
    proposedTimeSlotFrom: string;   //example: "2011-10-05T14:48:00.000Z"
    proposedTimeSlotTo: string;     //example: "2011-10-05T15:48:00.000Z"
}

export interface IPhotographyData {
    propertyId: string;
}

export interface IPropertyReportDocsData {
    propertyId: string;
}

export interface IAssetOnboardingData {
    propertyId: string;
}

export interface IPropertyOfferData {
    propertyId: string;
    offerDetails: IOfferDetails;
}

export interface IOfferDetails {
    price: number,
    loanAmount?: number,
}

export interface ICallbackData {
    propertyId: string;
    userMessage: string;
    preferredContactDate?: string;
}