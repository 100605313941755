import { FormGroup } from "@angular/forms";


export interface ILoanApplicationModel {
    applicationId: string;
    property: {
        propertyId: string;
        address: string;
        url: string;
    };
    loan: {
        categoryId: string;
        realEstateValue: string;
        amount: string;
        duration: string;
        fixedInterestDuration: string;
        interestRate: string;
    };
    details: {
        yearBorn: string;
        professionalStatus: string;
        marriageStatus: string;
        children: string;
        haveCoborrower: boolean;
        grossSalaryPerYear: string;
        householdDebt: string;
        nationality: string;
        wherePayTax: string;
        taxPerYear: string;
    };
    guarantor: {
        haveGuarantor: boolean;
        yearBornGuarantor: string;
        childrenGuarantor: string;
        marriageStatusGuarantor: string;
        householdIncomeGuarantor: string;
        taxPerYearGuarantor: string;
        householdDebtGuarantor: string;
    };
    branch: {
        branchInfo: string;
    };
    review: {
        consent: boolean;
    };
}

export class LoanApplicationModel implements ILoanApplicationModel {
    constructor(applicationId: string, form: FormGroup) {
        this.applicationId = applicationId;
        this.property = form.get('property')!.value;
        this.loan = form.get('loan')!.value;
        this.details = form.get('details')!.value;
        this.guarantor = form.get('guarantor')!.value;
        this.branch = form.get('branch')!.value;
        this.review = form.get('review')!.value;
    }
    applicationId: string;
    property: {
        propertyId: string;
        address: string;
        url: string;
    };
    loan: {
        categoryId: string;
        realEstateValue: string;
        amount: string;
        duration: string;
        fixedInterestDuration: string;
        interestRate: string;
    };
    details: {
        yearBorn: string;
        professionalStatus: string;
        marriageStatus: string;
        children: string;
        haveCoborrower: boolean;
        grossSalaryPerYear: string;
        householdDebt: string;
        nationality: string;
        wherePayTax: string;
        taxPerYear: string;
    };
    guarantor: {
        haveGuarantor: boolean;
        yearBornGuarantor: string;
        childrenGuarantor: string;
        marriageStatusGuarantor: string;
        householdIncomeGuarantor: string;
        taxPerYearGuarantor: string;
        householdDebtGuarantor: string;
    };
    branch: {
        branchInfo: string;
    };
    review: {
        consent: boolean;
    };
}

export interface ILoanApplicationCases {
    propertyId: string;
    userId: string;
    loanDetails: {
        state?: string;
        currency: string;
        type: string;
        propertyValue: number;
        amount: number;
        durationInYears: number;
        fixedInterestRateDurationInYears: number;
        yearOfBirth: number;
        professionalStatus: string;
        marriageStatus: string;
        children: number;
        taxRegion: string;
        taxPerYear: number;
        householdDebt: number;
        hasCoBorrower: boolean;
        yearlyGrossSalary: number;
        nationality: string;
        hasGuarantor: boolean;
        guarantorChildren?: number;
        guarantorYearOfBirth?: number;
        guarantorMarriageStatus?: string;
        guarantorTaxPerYear?: number;
        guarantorHouseholdDebt?: number;
        guarantorIncome?: number;
    };
}
