
@if (isLoggedIn) {
  <div class="bg-modals-light rounded-medium p-8 shadow-[0_4px_18px_0_rgba(0,0,0,0.17)]">
    <div class="flex justify-end">
        <button class="p-2"><i class="icon-close text-xl" (click)="close()"></i></button>
    </div>
        @if (currentStep === RequestWalkthroughSteps.SelectDate) {
          <app-request-walkthrough-select-date [property]="property" (dateSelected)="onDateSelected($event)"></app-request-walkthrough-select-date>
        }
        @else if (currentStep === RequestWalkthroughSteps.VerifyPhone) {
            <app-request-walkthrough-verify-phone (phoneVerified)="onPhoneVerified()"></app-request-walkthrough-verify-phone>
        }
        @else if (currentStep === RequestWalkthroughSteps.Success) {
            <app-request-walkthrough-success [property]="property" [walkthroughDate]="selectedDate" (succeeded)="closeModal()"></app-request-walkthrough-success>
        }
  </div>
}
@else {
  <app-requests-unauthorized (login)="login()" (signUp)="signUp()" (close)="close()"></app-requests-unauthorized>
}


