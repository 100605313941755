import { Component } from '@angular/core';
import { Modal } from '@indice/ng-components';

@Component({
  selector: 'app-sign-prompt-actions-modal',
  standalone: true,
  imports: [],
  templateUrl: './sign-prompt-actions-modal.component.html',
  styleUrl: './sign-prompt-actions-modal.component.css'
})
export class SignPromptActionsModalComponent {
  constructor(public modalRef: Modal){}

  close = () => this.modalRef.hide();

}
