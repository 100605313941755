import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslocoDirective } from "@jsverse/transloco";
import Swiper from "swiper";

@Component({
    selector: 'app-property-services',
    templateUrl: './property-services.component.html',
    standalone: true,
    imports: [CommonModule, TranslocoDirective]
})
export class PropertyServicesComponent implements OnInit{
    constructor() {}

    ngOnInit() {
        // TODO: Implement Swiper
        // FIX: Swiper initializes before the DOM because of transloco directive
        var swiper = new Swiper(".mySwiper", {});
    }
}