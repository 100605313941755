import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { DoughnutChartComponent } from 'src/app/shared/components/loan-calulator/doughnut-chart/doughnut-chart.component';
import { LoanCalulatorComponent } from 'src/app/shared/components/loan-calulator/loan-calulator.component';

@Component({
  selector: 'app-financing-section',
  standalone: true,
  imports: [LoanCalulatorComponent, DoughnutChartComponent, RouterLink, CommonModule, TranslocoPipe],
  templateUrl: './financing-section.component.html',
  styleUrl: './financing-section.component.css'
})
export class FinancingSectionComponent {
  @Input() propertyValue: number | undefined;
  @Input() isLoggedIn: boolean | null = null;

  protected propertyId = this._route.snapshot.params['propertyId'];

  constructor(private _route: ActivatedRoute) {

  }
}
