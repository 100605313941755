<ng-container *transloco="let t; prefix: 'onboarding.onboarding-buyer'">
    <section class="container mb-10">
        <div class="flex flex-row gap-14">
            <div class="flex-1">
                <h2 class="text-black font-semibold text-[2.5rem] mb-4">{{t('registration-completed.title')}}</h2>
                <p class="text-2xl text-gray-900">{{t('registration-completed.infotext')}}</p>
            </div>
            <img class="max-w-[262px]" src="assets/img/bg-1.fe45e87044513f71afec.webp" alt="On boarding buyer 4">
        </div>
    </section>
    <section class="container mb-10">
        <h4 class="font-semibold text-gray-900 mb-4">{{t('registration-completed.property-preference')}}</h4>
        <div class="p-4 rounded bg-white grid md:grid-cols-2 grid-cols-1 gap-x-8 gap-y-8">
            <div class="flex flex-row items-center justify-between">
                <p class="flex-1 text-gray-800">{{t('registration-completed.property-types')}}</p>
                <p class="flex-1 text-black font-semibold">{{PropertyTypesString}}</p>
            </div>
            <div class="flex flex-row items-center justify-between">
                <p class="flex-1 text-gray-800">{{t('registration-completed.price-range')}}</p>
                <p class="flex-1 text-black font-semibold">{{PriceMin | currency: 'EUR'}} - {{PriceMax | currency:
                    'EUR'}}</p>
            </div>
            <div class="flex flex-row items-center justify-between">
                <p class="flex-1 text-gray-800">{{t('registration-completed.looking-for')}}</p>
                <p class="flex-1 text-black font-semibold">{{SelectedHistory}}</p>
            </div>
        </div>
    </section>
    <section *ngIf="SelectedPlaces" class="container mb-10">
        <h4 class="font-semibold text-gray-900 mb-4">{{t('registration-completed.location-preference')}}</h4>
        <div class="flex flex-col gap-3">
            <div *ngFor="let place of SelectedPlaces"
                class="p-4 border border-gray-200 bg-white rounded-small font-semibold text-black">
                {{place.FormatedAddress}}</div>

        </div>
    </section>
    <section #callback class="container">
        <button class="expert-button-wrapper border flex-row items-center w-full justify-between">
            <div class="flex flex-row items-center gap-8">
                <div class="flex -space-x-2 rtl:space-x-reverse">
                    <div class="w-6 h-6 rounded-full border-2 border-white overflow-hidden">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
                            alt="" class="">
                    </div>
                    <div class="w-6 h-6 rounded-full border-2 border-white overflow-hidden">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
                            alt="" class="">
                    </div>
                    <div class="w-6 h-6 rounded-full border-2 border-white overflow-hidden">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
                            alt="" class="">
                    </div>
                </div>
                <div>
                    <div class="button-title">
                        <span>Speak to Uniko's experts</span>
                    </div>
                    <p class="button-content">
                        For any queries you may have, ask for a callback and we will contact you as soon as possible.
                    </p>
                </div>
            </div>
            <i class="icon-chevron-right text-[#BD7456]"></i>
        </button>
    </section>
</ng-container>