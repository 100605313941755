<div class="md:hidden px-4 py-10 bg-white backdrop-blur-[10px] flex-col justify-start items-start gap-8 inline-flex w-full" *transloco="let t; prefix: 'find-property'">
    <div class="self-stretch text-black text-[28px] font-semibold">{{t('header')}}</div>

    <div class="self-stretch">
        <app-search-property (SearchSelected)="searchWithLocation($event)" [searchPlaceholder]="t('searchProperty')"
                    (ItemSelected)="selectSearchItem($event)"></app-search-property>
    </div>
    <div class="self-stretch flex-col justify-start items-start gap-4 flex">
        <div class="justify-start items-center gap-4 inline-flex">
            <button class="w-14 h-14 rounded-full border border-gray-300 justify-center items-center inline-flex" (click)="toggleAdvancedFilers()">
                <div class="w-9 h-9 relative flex flex-col">
                    <div
                        class="w-full h-full absolute rounded-full flex items-center justify-center text-white font-bold">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="filter">
                                <g id="Union">
                                    <path
                                        d="M2 6.72C2 6.32236 2.32236 6 2.72 6H21.92C22.3176 6 22.64 6.32236 22.64 6.72C22.64 7.11765 22.3176 7.44 21.92 7.44H2.72C2.32236 7.44 2 7.11765 2 6.72Z"
                                        fill="black"></path>
                                    <path
                                        d="M4.4 12.72C4.4 12.3224 4.72235 12 5.12 12H19.52C19.9176 12 20.24 12.3224 20.24 12.72C20.24 13.1176 19.9176 13.44 19.52 13.44H5.12C4.72235 13.44 4.4 13.1176 4.4 12.72Z"
                                        fill="black"></path>
                                    <path
                                        d="M8.72 18C8.32236 18 8 18.3224 8 18.72C8 19.1176 8.32236 19.44 8.72 19.44H15.92C16.3176 19.44 16.64 19.1176 16.64 18.72C16.64 18.3224 16.3176 18 15.92 18H8.72Z"
                                        fill="black"></path>
                                </g>
                            </g>
                        </svg></div>
                    <div
                        class="w-5 h-5 absolute -top-[10px] left-8 bg-green-600 rounded-full flex flex-col justify-center">
                        <span class="text-white text-xs">{{searchFiltersCount}}</span>
                    </div>
                </div>
              </button>
            <div class="text-black text-base font-normal">{{t('searchFilters')}}</div>
        </div>

        @if (searchFiltersCount > 0) {
            <div class="rounded-[999px] justify-center items-center gap-2 flex">
                <button class="text-[#576774] text-base font-normal underline" (click)="removeQueryParams()">{{t('advanced-filters-drawer.filters.buttons.clear')}}</button>
            </div>
        }

    </div>
    <div class="h-16 flex-col justify-start items-start gap-6 inline-flex">
        @if (searchFiltersCount > 0) {
            <button class="rounded-full justify-center items-center gap-2 flex" (click)="SaveSearch()">
                <div class="w-5 h-5 relative">
                    <i class="icon-heart-line text-green-700 text-md font-normal"></i>
                </div>
                <button class="text-green-700 text-base font-normal ">{{t('grid-list-cards.buttons.saveSearch')}}</button>
            </button>
        }
        <div class="rounded-full justify-center items-center gap-2 flex">
            <button [routerLink]="['/account-settings/saved-searches']" routerLinkActive="router-link-active"  class="text-green-700  text-base font-normal underline">{{t('allSavedSearches')}}</button>
        </div>
    </div>
</div>
<div class="hidden md:inline-flex w-full px-[72px] py-8 bg-white backdrop-blur-[10px] flex-col justify-start items-start gap-8 relative z-20" *transloco="let t; prefix: 'find-property'">
    <div class="self-stretch justify-between items-center inline-flex">
        <div class="grow shrink basis-0 text-black text-[32px] font-semibold">{{t('header')}}</div>

        <div class="justify-start items-center gap-6 flex">
            @if (searchFiltersCount > 0) {
                <button class="rounded-full justify-center items-center gap-2 flex" (click)="SaveSearch()">
                    <div class="w-5 h-5 relative">
                        <i class="icon-heart-line text-green-700 text-md font-normal"></i>
                    </div>
                    <div class="text-green-700  text-base font-normal">{{t('grid-list-cards.buttons.saveSearch')}}</div>
                </button>
                <div class="w-[18px] h-[0px] rotate-90 border border-gray-400"></div>
            }
            <button [routerLink]="['/account-settings/saved-searches']"  class="rounded-rounded justify-center items-center gap-2 flex">
                <div class="text-green-700  text-base font-normal underline">{{t('allSavedSearches')}}</div>
            </button>
        </div>
    </div>
    <div class="self-stretch">
        <app-search-property (ItemSelected)="selectSearchItem($event)"></app-search-property>
    </div>
    <div class="xl:self-stretch justify-between items-start md:items-center flex flex-col self-center gap-8 xl:flex-row">
        <div class="h-14 justify-between items-center gap-12 flex w-full">

            <app-filter-dropdown [dropdownItems]="propertyTypes" [title]="t('filterTitles.propertyType')"
                [selectedKeys]="selectedType" [allValuesLabel]="t('filterAllValuesLabels.propertyType')"
                (selectedItem)="searchWithTypeFilter($event)" class="w-full z-10"></app-filter-dropdown>

            <app-range-dropdown [minDropdownItems]="minPriceRanges" [maxDropdownItems]="maxPriceRanges" [title]="t('filterTitles.price')"
                [preselectedRange]="selectedPriceRange" [allValuesLabel]="t('filterAllValuesLabels.price')"
                postfix="€"
                (selectedRangeEvent)="searchWithPriceFilter($event)" class="w-full z-10"></app-range-dropdown>

            <app-range-dropdown [minDropdownItems]="minSizeRanges" [maxDropdownItems]="maxSizeRanges"
                [title]="t('filterTitles.size')"
                [preselectedRange]="selectedSizeRange" [allValuesLabel]="t('filterAllValuesLabels.size')" postfix="τ.μ."
                (selectedRangeEvent)="searchWithSizeFilter($event)" class="w-full z-10"></app-range-dropdown>
        </div>
        <div class="justify-end items-center gap-5 flex w-full xl:w-1/3">
            @if (searchFiltersCount > 0) {
                <div class="rounded-[999px] justify-center items-center gap-2 flex">
                    <button class="text-[#576774] text-base font-normal underline" (click)="removeQueryParams()">{{t('advanced-filters-drawer.filters.buttons.clear')}}</button>
                </div>
            }

            <button class="w-14 h-14 rounded-full border border-gray-300 justify-center items-center inline-flex"
                (click)="toggleAdvancedFilers()">
                <div class="w-9 h-9 relative flex flex-col">
                    <div
                        class="w-full h-full absolute rounded-full flex items-center justify-center text-white font-bold">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="filter">
                                <g id="Union">
                                    <path
                                        d="M2 6.72C2 6.32236 2.32236 6 2.72 6H21.92C22.3176 6 22.64 6.32236 22.64 6.72C22.64 7.11765 22.3176 7.44 21.92 7.44H2.72C2.32236 7.44 2 7.11765 2 6.72Z"
                                        fill="black"></path>
                                    <path
                                        d="M4.4 12.72C4.4 12.3224 4.72235 12 5.12 12H19.52C19.9176 12 20.24 12.3224 20.24 12.72C20.24 13.1176 19.9176 13.44 19.52 13.44H5.12C4.72235 13.44 4.4 13.1176 4.4 12.72Z"
                                        fill="black"></path>
                                    <path
                                        d="M8.72 18C8.32236 18 8 18.3224 8 18.72C8 19.1176 8.32236 19.44 8.72 19.44H15.92C16.3176 19.44 16.64 19.1176 16.64 18.72C16.64 18.3224 16.3176 18 15.92 18H8.72Z"
                                        fill="black"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div
                        class="w-5 h-5 absolute -top-[10px] left-8 bg-green-600 rounded-full flex flex-col justify-center">
                        <span class="text-white text-xs">{{searchFiltersCount}}</span>
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>

<!-- <app-sponsored-properties></app-sponsored-properties>
<app-property-tag-filters></app-property-tag-filters> -->
<app-grid-list-cards></app-grid-list-cards>
<app-advanced-filters-drawer [Toggle$]="toggleAdvancedFilters$" (FilterChanged)="searchWithAdvancedFilters($event)"
    (OnHidden)="advancedSearchHidden($event)" [propertyTypes]="propertyTypes"
    [selectedPriceRange]="selectedPriceRange" [maxPriceRanges]="maxPriceRanges"
     [minPriceRanges]="minPriceRanges" [selectedPriceRange]="selectedPriceRange"
     [selectedType]="selectedType" [minSizeRanges]="minSizeRanges"
     [maxSizeRanges]="maxSizeRanges" [selectedSizeRange]="selectedSizeRange"></app-advanced-filters-drawer>

<button #triggerButton style="display: none;"></button>
