<ng-container *ngIf="model$ | async as properties">
  <ng-container *ngIf="properties.count && properties.count > 0">
    <div class="relative w-full" *transloco="let t; prefix: 'shared.property-swiper'">
      <h4 class="h4 subheading font-light text-xl text-black">{{t('header')}}</h4>
      <div class="gap-x-8 gap-y-6 pt-9 pb-6">
        <!-- flex flex-1 justify-evenly -->
        <section class="container my-5 relative flex justify-center">
          <swiper #propertySwiper class="swiper-container w-full" [config]="config">
            <ng-template swiperSlide *ngFor="let property of properties.items; index as i">
              <app-property-card-item [newWindow]="newWindow" class="block h-full min-w-[565px]" name="property-item"
                [propertySummary]="property" [size]="0"></app-property-card-item>
            </ng-template>
          </swiper>
        </section>

        <div (click)="swipePrev()" class="absolute left-0 top-0 bottom-0 flex items-center -translate-x-1/2">
          <button class="btn-icon-primary flex items-center justify-center">
            <i class="icon-arrow-left text-[20px]"></i>
          </button>
        </div>
        <div (click)="swipeNext()" class="absolute right-0 top-0 bottom-0 flex items-center translate-x-1/2">
          <button class="btn-icon-primary flex items-center justify-center">
            <i class="icon-arrow-right text-[20px]"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>