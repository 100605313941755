import { ApplicationConfig, LOCALE_ID, importProvidersFrom, isDevMode } from '@angular/core';
import {
  provideRouter,
  NoPreloading,
  withPreloading,
  withInMemoryScrolling
} from '@angular/router';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModalService } from '@indice/ng-components';
import { PORTAL_API_BASE_URL } from './services/portal-api.service';
import * as environment from './core/appSettings';
import { IDENTITY_API_BASE_URL } from './services/identity-api.service';
import { LocaleInterceptor } from './core/services/locale.interceptor';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { MESSAGING_API_BASE_URL } from './services/messaging-api.service';
import { CMS_API_BASE_URL } from './services/cms-api.service';
import { CMS_API_KEY } from './services/cms.service';
import { SearchHistoryInterceptor } from './core/services/search-history.interceptor';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NG_PROGRESS_CONFIG } from 'ngx-progressbar';
import { ECOMMERCE_API_PASSWORD, ECOMMERCE_API_USERNAME, ECOMMERCE_BASE_URL, ECOMMERCE_MERCHANTID } from './services/nbg-ecommerce.service';
import { NBG_PORTAL_API_BASE_URL } from './services/loan.service';
import { CASES_API_BASE_URL } from './services/cases-api.service';
import { SERVICES_PAYMENTS_API_BASE_URL } from './core/services/payments-api-service';
import { SERVICES_DMS_API_BASE_URL } from './core/services/dms-api-service';
import { SERVICES_LOANS_API_BASE_URL } from './core/services/loans-api-service';
import { MATERPORT_SKD_KEY } from './services/matterport.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(NoPreloading), withInMemoryScrolling()),
    provideHttpClient(withInterceptorsFromDi()),
    ModalService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: SearchHistoryInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: environment.appSettings.culture },
    { provide: PORTAL_API_BASE_URL, useValue: environment.appSettings.api_url },
    { provide: IDENTITY_API_BASE_URL, useValue: environment.appSettings.auth_settings.authority },
    { provide: CASES_API_BASE_URL, useValue: environment.appSettings.cases_url },
    { provide: MESSAGING_API_BASE_URL, useValue: environment.appSettings.msg_url },
    { provide: CMS_API_BASE_URL, useValue: environment.appSettings.cms_url },
    { provide: CMS_API_KEY, useValue: environment.appSettings.cmsApiKey },
    { provide: SERVICES_PAYMENTS_API_BASE_URL, useValue: environment.appSettings.services_api_url },
    { provide: SERVICES_DMS_API_BASE_URL, useValue: environment.appSettings.services_api_url },
    { provide: SERVICES_LOANS_API_BASE_URL, useValue: environment.appSettings.services_api_url },
    { provide: MATERPORT_SKD_KEY, useValue: environment.appSettings.matterport_key },
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'el'],
        defaultLang: 'el',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
    {
      provide: NG_PROGRESS_CONFIG,
      useValue: {
        thick: true,
        color: '#50575f'
      }
    },
    importProvidersFrom(NgProgressHttpModule),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: NBG_PORTAL_API_BASE_URL, useValue: environment.appSettings.nbg_portal_api_url }
  ]
};
