<section
  class="pt-6 pb-12"
  id="servicesSection"
  *transloco="let t; prefix: 'property-details.property-services'"
>
  <h2 class="text-[1.75rem] font-semibold text-black mb-2">
    {{ t("header") }}
  </h2>
  <p class="mb-8">{{ t("description") }}</p>

  <div
    class="flex flex-row rfs:gap-8 flex-wrap lg:items-start items-center lg:justify-between"
  >
    <div
      class="max-w-[268px] w-full px-4 pt-4 pb-6 bg-white rounded-medium flex flex-col gap-4 min-h-[362px]"
    >
      <div class="rounded-medium overflow-hidden">
        <img
          src="../../../../../assets/img/property-service-1.png"
          alt="Property Services"
          class="w-full h-auto object-cover"
        />
      </div>
      <div class="flex flex-col justify-between flex-1">
        <div class="">
          <h4 class="mb-1 text-black text-2xl font-semibold">
            {{ t("service-1.title") }}
          </h4>
          <p class="text-gray-800">{{ t("service-1.description") }}</p>
        </div>
        <a class="btn-secondary max-w-fit">{{ t("service-1.button") }}</a>
      </div>
    </div>
    <div
      class="max-w-[268px] w-full px-4 pt-4 pb-6 bg-white rounded-medium flex flex-col gap-4 min-h-[362px]"
    >
      <div class="rounded-medium overflow-hidden">
        <img
          src="../../../../../assets/img/property-service-2.png"
          alt="Property Services"
          class="w-full h-auto object-cover"
        />
      </div>
      <div class="flex flex-col justify-between flex-1">
        <div class="">
          <h4 class="mb-1 text-black text-2xl font-semibold">
            {{ t("service-2.title") }}
          </h4>
          <p class="text-gray-800">Επαγγε{{ t("service-2.description") }}</p>
        </div>
        <a class="btn-secondary max-w-fit">{{ t("service-2.button") }}</a>
      </div>
    </div>
    <div
      class="max-w-[268px] w-full px-4 pt-4 pb-6 bg-white rounded-medium flex flex-col gap-4 min-h-[362px]"
    >
      <div class="rounded-medium overflow-hidden">
        <img
          src="../../../../../assets/img/property-service-3.png"
          alt="Property Services"
          class="w-full h-auto object-cover"
        />
      </div>
      <div class="flex flex-col justify-between flex-1">
        <div class="">
          <h4 class="mb-1 text-black text-2xl font-semibold">
            {{ t("service-3.title") }}
          </h4>
          <p class="text-gray-800">{{ t("service-3.description") }}</p>
        </div>
        <a class="btn-secondary max-w-fit">{{ t("service-3.button") }}</a>
      </div>
    </div>
  </div>
</section>
