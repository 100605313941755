import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { Modal, ModalOptions } from '@indice/ng-components';
import { MatterportService } from 'src/app/services/matterport.service';

@Component({
  selector: 'app-threed-viewer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './threed-viewer.component.html',
  styleUrl: './threed-viewer.component.css'
})
export class ThreedViewerComponent implements AfterViewInit {
  @Input() SpaceId: string | undefined
  mpSdk: any;
  @ViewChild("matterport") matterport!: ElementRef;

  constructor(private matterportService: MatterportService, private modal: Modal,
    @Inject(ModalOptions) private options: ModalOptions<ThreedViewerComponent>
  ) {
    this.SpaceId = options.initialState?.SpaceId;
  }
  ngAfterViewInit() {
    this.matterportService.setupMatterportSdk(this.matterport.nativeElement, this.SpaceId ?? "")

  }
  closeModal() {
    this.modal.hide();
  }
}


