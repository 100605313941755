<div class="relative w-full">
  <button *transloco="let t;" class="w-full space-y-2" id="dropdownDefaultButton" (clickOutside)="closeDropdown()"
      (click)="toggleDropdown()" type="button">
      <div class="h-9 px-4 py-5 bg-white border rounded-full border-slate-300 flex flex-col items-center justify-center">
          <div class="w-full flex items-center justify-between">
              <div class="pr-6 flex gap-x-2 items-center justify-start">
                  <p class="text-sm leading-none text-slate-500"><span [class]="'fi fi-'+Flag"></span> {{t(Value ?? '')}}</p>
              </div>
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_909_8289)">
                      <path
                          d="M4.74503 6.07474L1.64419 2.90714C1.398 2.65527 0.982868 2.64277 0.718993 2.87714L4.74503 6.07474ZM4.74503 6.07474L4.73758 6.08235L5.09488 6.43212M4.74503 6.07474L5.09488 6.43212M5.09488 6.43212L5.45218 6.08235L5.44473 6.07474M5.09488 6.43212L5.44473 6.07474M5.44473 6.07474L8.54556 2.90714L5.44473 6.07474ZM4.53303 6.97439L4.5329 6.97425L1.04575 3.41135C1.04572 3.41133 1.0457 3.41131 1.04568 3.41128C1.01932 3.38429 1.0109 3.35548 1.01178 3.33008C1.01266 3.30476 1.02304 3.27619 1.05152 3.25054C1.11839 3.19166 1.22941 3.1981 1.28663 3.25664L1.2869 3.25691L4.73758 6.78189L5.09488 7.14688L5.45218 6.78189L8.90286 3.25691L8.90355 3.2562C8.96105 3.19723 9.07215 3.19154 9.13777 3.25013C9.16683 3.27608 9.17741 3.30508 9.17832 3.33059C9.17923 3.35598 9.17086 3.38456 9.14466 3.41135L5.65785 6.9739C5.65781 6.97395 5.65776 6.974 5.65772 6.97404C5.51262 7.12192 5.30631 7.20898 5.09488 7.20898C4.88325 7.20898 4.6772 7.1218 4.53303 6.97439Z"
                          fill="#0E4749" stroke="#0E4749"></path>
                  </g>
                  <defs>
                      <clipPath id="clip0_909_8289">
                          <rect width="10" height="10" fill="white" transform="translate(0.0957031 0.208984)">
                          </rect>
                      </clipPath>
                  </defs>
              </svg>
          </div>
      </div>
  </button>

  <!-- Dropdown menu -->
  <div *transloco="let t;" class="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow max-h-[400px] overflow-y-scroll w-full"
      [ngClass]="hideDropdown? 'hidden': 'block'">
      <ul class="py-2 text-sm text-gray-700 " aria-labelledby="dropdownDefaultButton">
          <li *ngFor="let item of Content"
              class="hover:bg-gray-100 hover:cursor-pointer"
              (click)="dropDownSelected(item)">
              <button class="block px-4 py-2">{{t(item.label)}}</button>
          </li>
      </ul>
  </div>
</div>
