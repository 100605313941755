<div class="flex items-center" [ngClass]=" { relative: !inline }" (clickOutside)="closeCalendar()">

  <button type="button" *ngIf="!inline" class="datepicker-button" [disabled]="disabled" (click)="openCalendar()"
    [ngClass]="{ 'cursor-not-allowed': disabled }">
    <i class="icon-calendar text-gray-700 text-xl"></i>
  </button>
  <div *ngIf="showCalendar || inline" [ngClass]="{ 'absolute': !inline, '!right-full !left-auto': displayRight }" class="datepicker-background z-10"
    style="height: 18rem; min-width: 17rem" name="calendar">
    <div class="datepicker-btn-wrapper">
      <div>
        <button type="button" (click)="openYears()">
          <span class="datepicker-selected-month" *ngIf="!yearsOnly">
            {{ monthNames[month] }}
          </span>
          <span class="datepicker-selected-year"> {{ year }}</span>
        </button>
      </div>
      <div *ngIf="!showYears; else showYearsPickerTmpl">
        <button type="button" class="datepicker-nav-btn" (click)="previousMonth()">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <button type="button" class="datepicker-nav-btn" (click)="nextMonth()">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
      <ng-template #showYearsPickerTmpl>
        <div>
          <button type="button" class="datepicker-nav-btn" (click)="previousYear()">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button type="button" class="datepicker-nav-btn" (click)="nextYear()">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </ng-template>
    </div>

    <div class="datepicker-wrapper" *ngIf="!showYears; else showCalYearsTmpl">
      <div *ngFor="let day of dayNames" class="datepicker-day-name" style="width: 14.28%">
        {{ day }}
      </div>
    </div>

    <ng-template #showCalYearsTmpl>
      <div class="datepicker-content-wrapper">
        <div *ngFor="let year of showCalendarYears" style="width: 18.28%" class="px-1 mb-1">
          <div (click)="selectYearValue(year)" class="datepicker-year"
            [class]="year.selected ? 'selected' : 'not-selected'">
            {{ year.year }}
          </div>
        </div>
      </div>
    </ng-template>

    <div class="datepicker-content-wrapper" *ngIf="!showYears">
      <div *ngFor="let blankday of blankDays" style="width: 14.28%" class="datepicker-blank-days">
        {{ blankday }}
      </div>
      <div *ngFor="let date of calendarDates" style="width: 14.28%" class="px-1 mb-1">
        <div (click)="selectDateValue(date)" class="datepicker-days" [ngClass]="{
            'out-of-range': outOfRangeDate(date),
            selected: date.selected,
            'not-selected': !date.selected
          }">
          {{ date.day }}
        </div>
      </div>
    </div>
  </div>
</div>
