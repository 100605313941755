<div
  class="shadow-lg w-full bg-white right-0 flex flex-col transition-all h-screen"
  [ngClass]="toggleProfile ? '' : 'translate-x-[100%]'"
>
  <div class="flex justify-between text-2xl font-semibold p-10">
    <p>Το προφίλ μου</p>
    <button (click)="closeProfile()">
      <i class="icon-x text-primary hover:opacity-50 transition"></i>
    </button>
  </div>
  <div
    class="flex flex-col flex-1 bg-gray-100 p-10 gap-7 sm:gap-[56px] overflow-y-auto scrollbar-container"
    *ngIf="updatedUserProfile"
  >
    <div class="flex items-center gap-4">
      <div
        *ngIf="updatedUserProfile.given_name && updatedUserProfile.family_name"
        class="h-[72px] w-[72px] flex justify-center items-center text-white text-2xl font-medium bg-contrast rounded-full"
      >
        {{ updatedUserProfile.given_name[0] | uppercase
        }}{{ updatedUserProfile.family_name[0] | uppercase }}
      </div>
      <div class="flex">
        <span
          class="p-3 rounded focus-visible:outline-none transition-all text-2xl font-medium"
          *ngIf="!editName"
        >
          {{
            updatedUserProfile.given_name + " " + updatedUserProfile.family_name
          }}
        </span>
        <input
          type="text"
          oninput="this.size = this.value.length"
          class="p-3 rounded focus-visible:outline-none transition-all text-2xl font-medium mr-2"
          [(ngModel)]="updatedUserProfile.given_name"
          *ngIf="editName"
        />
        <input
          type="text"
          oninput="this.size = this.value.length"
          class="p-3 rounded focus-visible:outline-none transition-all text-2xl font-medium"
          [(ngModel)]="updatedUserProfile.family_name"
          *ngIf="editName"
        />
        <button (click)="toggleEditName()" class="hover:opacity-50 transition">
          <i class="icon-pencil text-gray-500"></i>
        </button>
      </div>
    </div>
    <div>
      <p
        class="text-xl font-semibold pb-3 border-b-[1px] border-black border-opacity-20"
      >
        Στοιχεία επικοινωνίας
      </p>
      <div
        class="flex justify-between py-3 border-b-[1px] border-black border-opacity-20 transition-all"
      >
        <p>Email</p>
        <div class="flex gap-2">
          <span class="underline text-primary mr-5">{{
            updatedUserProfile.email
          }}</span>
        </div>
      </div>
      <div
        class="flex justify-between py-3 border-b-[1px] border-black border-opacity-20 transition-all"
        [class]="editPhone ? 'bg-white px-3' : ''"
      >
        <p>Τηλέφωνο</p>
        <div class="flex gap-2">
          <input
            [disabled]="!editPhone"
            class="focus-visible:outline-none disabled:bg-transparent bg-transparent w-28"
            [(ngModel)]="updatedUserProfile.phone_number"
          />
          <button
            (click)="toggleEditPhone()"
            class="hover:opacity-50 transition"
          >
            <i class="icon-pencil text-gray-500"></i>
          </button>
        </div>
      </div>
    </div>
    <form #form="ngForm">
      <div class="flex flex-col gap-4">
        <p
          class="text-xl font-semibold pb-3 border-b-[1px] border-black border-opacity-20"
        >
          Αλλαγή κωδικού
        </p>
        <div class="flex">
          <input
            id="oldPassword"
            class="flex-1 p-3 rounded focus-visible:outline-none"
            placeholder="Παλιός Κωδικός"
            type="password"
            name="oldPassword"
            required
            [(ngModel)]="changePasswordRequest.oldPassword"
          />
        </div>
        <div class="flex">
          <input
            id="newPassword"
            class="flex-1 p-3 rounded focus-visible:outline-none"
            placeholder="Νεος Κωδικός"
            type="password"
            name="newPassword"
            required
            [(ngModel)]="changePasswordRequest.newPassword"
            #newPassword="ngModel"
            (ngModelChange)="validatePasswordText$.next($event)"
          />
        </div>
        <div
          *ngIf="passwordRules && passwordRules.length > 0"
          class="password-rules flex justify-center"
        >
          <ul>
            <li
              class="password-rule"
              [ngClass]="passwordRule.isValid ? 'rule-valid' : ''"
              *ngFor="let passwordRule of passwordRules"
            >
              {{ passwordRule.requirement }}
            </li>
          </ul>
        </div>
        <div class="flex">
          <input
            id="newPasswordConfirmation"
            type="password"
            name="newPasswordConfirmation"
            class="flex-1 p-3 rounded focus-visible:outline-none"
            placeholder="Επιβεβαιώση Νέου Κωδικού"
            required
            [(ngModel)]="changePasswordRequest.newPasswordConfirmation"
            #newPasswordConfirmation="ngModel"
          />
        </div>
        <small
          *ngIf="
            newPasswordConfirmation.value &&
            newPassword.value &&
            newPassword.value !== newPasswordConfirmation.value
          "
          class="text-red-600"
          >Αναντιστοιχία κωδικού πρόσβασης</small
        >

        <button
          class="px-4 py-2 bg-gray-500 max-w-[147px] text-white text-sm font-bold rounded transition hover:bg-opacity-50"
          (click)="onPasswordChange()"
          [disabled]="
            form.invalid ||
            newPasswordInvalid ||
            newPassword.value !== newPasswordConfirmation.value
          "
        >
          Αλλαγή κωδικού
        </button>
      </div>
    </form>
  </div>
  <div class="p-10 flex flex-row justify-between bg-white">
    <button
      class="text-secondary font-bold underline hover:opacity-50 transition"
      (click)="closeProfile()"
    >
      Κλείσιμο
    </button>
  </div>
</div>
