import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class OnboardingGuardService {

    constructor(private authService: AuthService, private onboardingService: OnboardingService, private _router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.authService.isLoggedIn().subscribe((isLoggedIn: boolean) => {
            if (!isLoggedIn) {
                return true;
            } else {
                var userInfo = this.authService.getCurrentUser();//.subscribe( x=> userInfo = x);
                if (!userInfo || userInfo.profile[this.authService.ACCOUNT_OWNER_ID_CLAIM] != null) {
                    return true;
                }
                //Create user
                this.onboardingService.createAccountOwner(userInfo.profile, undefined).subscribe();
                var intent = userInfo.profile[this.authService.INTENT_CLAIM];
                if (intent === this.authService.INTENT_SELLER) {
                    this._router.navigate(['welcome/seller'], {queryParamsHandling: 'preserve'})
                    return false;
                }
                if (intent === this.authService.INTENT_BUYER) {
                    this._router.navigate(['welcome/buyer'], {queryParamsHandling: 'preserve'})
                    return false;
                }
                else {
                    this._router.navigate(['welcome/default'], {queryParamsHandling: 'preserve'})
                    return true;
                }
            }
        });
        return of(true);
    }
}


