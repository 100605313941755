import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    private whiteListUrl: string[] = []; //urls in this list will not be intercepted on authorization header

    constructor(
        private _authService: AuthService,
        private _logger: LoggerService,
    ) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let copiedRequest: HttpRequest<any>;
        const whitelistEntry = this.whiteListUrl.find(item => item.startsWith(request.url));

        if (!whitelistEntry) {
            copiedRequest = request.clone({
                headers: request.headers.set('Authorization', this._authService.getAuthorizationHeaderValue())
            });
        } else {
            copiedRequest = request.clone();
        }

        return next.handle(copiedRequest).pipe(
            tap((httpEvent: HttpEvent<any>) => {
                if (httpEvent instanceof HttpResponse) { }
            }),
            catchError((error: any) => {
                if (error.status === 401) {
                    this._authService.signoutRedirect();
                }
                this._logger.log(error);
                throw error;
            })
        );
    }
}
