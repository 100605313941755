<div class="flex flex-col"
    *transloco="let t; prefix: 'shared.request-walkthrough-modal.request-walkthrough-select-date'">
    <div class="flex flex-col gap-y-8 divide-y-2" [formGroup]="form">
        <div class="flex flex-col">
            <span class="h3 text-black font-normal">{{t('headers.requestWalkthroughFor')}} </span>
            <span class="h2 text-black">{{t('headers.propertyInfo', { propertyArea: property?.propertyArea,
                propertyType: 'shared.enum.propertyType.' + (property?.type ?? 'Unknown') | transloco, prefecture:
                property?.address?.prefecture})}}</span>
        </div>
        <div class="flex flex-col gap-4 pt-8 px-4">
            <h3>{{t('headers.dayAndTimeslot')}}</h3>
            <date-picker [yearsOnly]="false" [inline]="true" [minDate]="now"
                (valueChange)="selectDate($event)"></date-picker>
            <div class="flex flex-col gap-4 items-center">
                <div>{{t('headers.selectTimeslot')}}:</div>
                <div class="flex flex-row gap-2">
                    <label>{{t('labels.from')}}</label>
                    <input type="time" class="timepicker" id="from-time" name="fromTime" formControlName="from">
                    <label>{{t('labels.to')}}</label>
                    <input type="time" class="timepicker" id="to-time" name="toTime" formControlName="to">
                </div>
            </div>
        </div>
        <div class="pt-4 flex gap-4">
            <label for="checkbox-selector-" class="checkbox-selector">
                <input type="checkbox" class="hidden" id="checkbox-selector-"
                    [checked]="form.controls.acceptedTerms.value == true"
                    (click)="updateTerms()">
                <div class="ml-2 checkbox-selector-content">
                    <span class="checkbox-selector-text">{{t('consent-terms')}}</span>
                </div>
            </label>
        </div>
        <div class="flex flex-col px-4 pt-8">
            <button class="btn-primary" [disabled]="form.invalid" (click)="next()">{{t('buttons.next')}}</button>
        </div>
    </div>
</div>

<style scoped>
    .timepicker {
        border-radius: 20px;
        border: 1px solid;
        padding: 0 .5rem;
    }
</style>