import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ConstructionFormGroup, PropertyListingTab } from '../property-listing.component';
import { Advisor, ConstructionMaterial, EnergyClass, FloorMaterial, HeatingControl, HeatingType, PropertyCondition } from 'src/app/services/portal-api.service';
import { Subject } from 'rxjs';
import { AdvisorCallbackComponent } from 'src/app/shared/components/advisor-callback/advisor-callback.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-construction',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AdvisorCallbackComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './construction.component.html',
  styleUrl: './construction.component.css'
})
export class ConstructionComponent {
  @Input() constructionFormGroup: FormGroup<ConstructionFormGroup> = new FormGroup(new ConstructionFormGroup());
  @Input() advisor: Advisor | undefined;

  @Output() onNextTab: Subject<PropertyListingTab> = new Subject();

  public heatingType = HeatingType;
  public heatingControl = HeatingControl;
  public energyClass = EnergyClass;
  public constructionMaterial = ConstructionMaterial;
  public floorMaterial = FloorMaterial;
  public propertyCondition = PropertyCondition;
  public propertyListingTab = PropertyListingTab;
  public currentYear = new Date().getUTCFullYear();

  public onConstructionMaterialClick(constructionMaterial: ConstructionMaterial): void {
    var index = this.constructionFormGroup.controls.constructionMaterial?.value?.indexOf(constructionMaterial.toString());
    let constructionMaterialVal = this.constructionFormGroup?.controls?.constructionMaterial?.value;

    if (index === -1) {
      constructionMaterialVal?.push(constructionMaterial.toString());
    } else if (index !== -1) {
      constructionMaterialVal?.splice(index, 1);
    }
    this.constructionFormGroup.controls.constructionMaterial.patchValue(constructionMaterialVal);
  }

  public onFloorMaterialClick(floorMaterial: FloorMaterial): void {
    var index = this.constructionFormGroup.controls.floorMaterial?.value?.indexOf(floorMaterial.toString());
    let floorMaterialVal = this.constructionFormGroup?.controls?.floorMaterial?.value;

    if (index === -1) {
      floorMaterialVal?.push(floorMaterial.toString());
    } else if (index !== -1) {
      floorMaterialVal?.splice(index, 1);
    }
    this.constructionFormGroup.controls.floorMaterial.patchValue(floorMaterialVal);
  }

  public navigateToTab(tab: PropertyListingTab) {
    this.onNextTab.next(tab);
  }

}
