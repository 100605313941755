<ng-container *transloco="let t; prefix: 'shared.checkout.step-review'">

    <div class="flex flex-col gap-4">
        <h3 class="font-semibold">{{t('headers.orderDetails')}}</h3>
        <app-property-card-item *ngIf="propertySummary$ | async; let propertySummary"
            [propertySummary]="propertySummary" [newWindow]="true"></app-property-card-item>
        <h3 class="font-semibold">{{t('headers.orderSummary')}}</h3>

        <ng-container *ngFor="let orderItem of orderData?.orderItems; let i = index">
            <button (click)="true" class="bg-white rounded-t-md p-4 flex flex-wrap justify-between w-full">
                <div class="flex gap-4 items-center">
                    <div class="rounded-full h-[9px] w-[9px] bg-info"></div>
                    <h4 class="h4 text-black">{{ orderItem.sku }} - {{ orderItem.title }}</h4>
                </div>
                <div class="flex items-center gap-4">
                    <span class="text-xl font-bold text-secondary-900">{{ orderItem.total | currency: 'EUR' }}</span>
                </div>
            </button>
        </ng-container>

        <div class="bg-gray-100 rounded-md p-4 flex justify-end w-full">
            <div class="flex items-center gap-4">
                <span class="text-black font-semibold">{{t('labels.totalPrice')}}:</span>
                <span class="text-xl font-bold text-secondary-900">{{ orderData?.total | currency: 'EUR' }}</span>
            </div>
        </div>

        <h3 class="font-semibold">{{t('headers.billingInformation')}}</h3>
        <div class="bg-white rounded-md p-4">
            <h4 class="h4 text-black mb-5">{{t('subheaders.receipt')}}</h4>
            <div class="ng">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-3">
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.fullName')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ orderData?.billingAddress?.fullName }}</p>
                    </div>
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.email')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ orderData?.billingAddress?.email }}</p>
                    </div>
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.address')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ orderData?.billingAddress?.street }}</p>
                    </div>
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.zipCode')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ orderData?.billingAddress?.postalCode }}</p>
                    </div>
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.city')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ orderData?.billingAddress?.city }}</p>
                    </div>
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.mobilePhone')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ orderData?.billingAddress?.phoneNumber1 }}</p>
                    </div>
                    <div class="flex justify-between border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.landLine')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ orderData?.billingAddress?.phoneNumber2 }}</p>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="font-semibold">{{t('headers.paymentDetails')}}</h3>
        <div class="bg-white rounded-md p-4">
            <h4 class="h4 text-black mb-5">{{t('subheaders.creditDebitCard')}}</h4>
            <div class="transition-all duration-500 overflow-hidden dropdown-active">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-3">
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.cardNumber')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ cardData?.number }}</p>
                    </div>
                    <div class="flex justify-between divider-line1 border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.cardholderName')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ cardData?.nameOnCard }}</p>
                    </div>
                    <div class="flex justify-between border-b-gray-300 pb-[10px]">
                        <p class="flex-1">{{t('labels.expirationDate')}}</p>
                        <p class="flex-1 text-black font-semibold">{{ cardData?.expiryDate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>