import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-favorite-anonymous-modal',
  standalone: true,
  imports: [RouterModule, TranslocoPipe, TranslocoDirective],
  templateUrl: './favorite-anonymous-modal.component.html',
  styleUrl: './favorite-anonymous-modal.component.css'
})
export class FavoriteAnonymousModalComponent {
  currentPath: string
  constructor(private router: Router) {
    this.currentPath = this.router.url;
  }
}
