import { CommonModule } from '@angular/common';
import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-google-maps-autocomplete',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './google-maps-autocomplete.component.html',
  styleUrl: './google-maps-autocomplete.component.css'
})

export class GoogleMapsAutocompleteComponent implements OnInit, AfterViewInit {
  @Input() adressType: string = 'geocode'; // 'establishment' / 'address' / 'geocode'
  @Input() searchBoxPlaceholder: string = "Search Area or address";
  @Input() clearSearch: Observable<void> = new Observable();
  @Input() disableSearch: Observable<void> = new Observable();
  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  @ViewChild('addresstext') addresstext: any;
  private eventsSubscription: Subscription = new Subscription;
  disabled: boolean = false;
  selectedResults: object[] = [];

  constructor() {
  }

  ngOnInit() {
    this.eventsSubscription = this.clearSearch.subscribe(() => this.addresstext.nativeElement.value = '');
    this.eventsSubscription = this.disableSearch.subscribe(() => this.disabled = !this.disabled);
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'GR' },
        types: [this.adressType]
      });
    autocomplete.setFields(['address_component', 'geometry', 'formatted_address']);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}

