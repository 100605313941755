import { Field, Message } from "protobufjs/light"

export class GeoSearchBoundsMessage extends Message<GeoSearchBoundsMessage> {
    @Field.d(1, "float")
    public swLng: number | undefined
    @Field.d(2, "float")
    public neLat: number | undefined
    @Field.d(3, "float")
    public swLat: number | undefined
    @Field.d(4, "float")
    public neLng: number | undefined
}

export class GeoSearchAddressMessage extends Message<GeoSearchAddressMessage> {
    @Field.d(1, "float")
    public lng: number | undefined
    @Field.d(2, "float")
    public lat: number | undefined
}
export class GeoSearchMessage extends Message<GeoSearchMessage> {
    @Field.d(1, GeoSearchBoundsMessage)
    public Bounds: GeoSearchBoundsMessage | undefined
    @Field.d(2, GeoSearchAddressMessage)
    public Address: GeoSearchAddressMessage | undefined
}