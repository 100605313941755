<div class="bg-white rounded-t-2xl w-full p-6 flex-col justify-start items-end gap-y-28 inline-flex"
    *transloco="let t; prefix: 'find-property.grid-list-cards'">
    <div class="self-stretch flex-col justify-start items-start gap-6 flex">
        <div class="self-stretch justify-end items-start gap-6 inline-flex">
            <div class="grow shrink basis-0 text-black text-2xl font-normal">{{t('propertyDetails')}}</div>
            <div class="w-5 h-5 relative">
                <i class="icon-close text-xl hover:cursor-pointer" (click)="close()"></i>
            </div>
        </div>

        <div class="divider-line1 border-b-tone-05"></div>

        <div class="self-stretch flex-col justify-start items-start flex">
            <app-property-card-item 
                id="{{property?.id}}" 
                [propertySummary]="property"
                [isClickable]="false"
                [withBorder]="false">
            </app-property-card-item>
        </div>
    </div>

    <button class="btn-primary self-stretch w-full h-12 py-4 rounded-full justify-center items-center gap-2 inline-flex" (click)="openPropertyDetails()">{{t('viewMore')}}</button>
</div>

