export enum ProofOfPayment {
    Receipt = "receipt",
    Invoice = "invoice"
}


export class BuyerInfoModel {
    proofOfPayment?: ProofOfPayment;
    fullName?: string;
    email?: string;
    address?: string;
    zipCode?: string;
    city?: string;
    mobilePhone?: string;
    landLine?: string | null;
    invoiceData?: InvoiceBuyerInfo | undefined;
    termsConditionsAccepted?: boolean;
}

export class InvoiceBuyerInfo {
    taxNumber?: string;
    companyName?: string;
    taxOffice?: string;
    profession?: string;
}

export class PaymentDetalsModel {
    invoiceType?: string;
    city?: string;
    mobilePhone?: string;
    landLine?: string | null;
    invoiceData?: InvoiceBuyerInfo | undefined;
}
export class CardInfoModel {
    sessionId?: string;
    merchant?: string;
    version?: string;
    expiryDate?: string;
    nameOnCard?: string;
    number?: string;
    transactionId?: string;
    orderReference?: string;
}

export interface IPreapprovalCases {
    propertyId: string,
    userId: string,
}