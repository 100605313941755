import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Modal, ModalOptions } from '@indice/ng-components';
import { TranslocoDirective } from '@jsverse/transloco';
import { PropertySummary } from 'src/app/services/portal-api.service';
import { PropertyCardItemComponent } from 'src/app/shared/components/property-card-item/property-card-item.component';

@Component({
  selector: 'app-property-modal', 
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RouterLink, TranslocoDirective, PropertyCardItemComponent],
  templateUrl: './property-modal.component.html',
  styleUrl: './property-modal.component.css'
})
export class PropertyModalComponent {

  @Input() property: PropertySummary | undefined;

  constructor(public modalRef: Modal, @Inject(ModalOptions) private _options: ModalOptions, private router: Router) { }

  ngOnInit(): void {
    this.property = (this._options?.initialState?.['property'] as PropertySummary);
  }

  openPropertyDetails() {
    this.router.navigate(['/properties/' + this.property?.id]);
    this.close();
  };

  close = () => this.modalRef.hide();
}
