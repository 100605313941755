<section
  class="pt-6"
  id="floorPlan"
  *transloco="let t; prefix: 'property-details.floor-plan-section'"
>
  <div class="flex flex-row items-center justify-between mb-12">
    <h2 class="text-[1.75rem] font-semibold text-black">
      {{ t("header") }}
    </h2>
    <button
      *ngIf="vrCode"
      (click)="openVrModalComponent()"
      type="button"
      class="rounded-full px-4 py-2 flex flex-row gap-2 items-center bg-[#D0FBE4E5] border border-primary-500 backdrop-blur text-black font-semibold"
    >
      Εικονική περιήγηση
      <i class="icon-marker-pin"></i>
    </button>
  </div>
  <div class="flex flex-col items-center gap-12">
    @for (floorPlan of floorPlans; track $index) {
      @if ($index == selectedIndex) {
        <div>
          <!-- <img
            class="w-full h-[380px] object-contain"
            src="assets/static/floor-plan.webp"
            alt="floor plan of a house"
          /> -->
          <img class="w-full h-[380px] object-contain"
              src="{{
                floorPlan && floorPlan.uri
                  ? floorPlan.uri
                  : '../../../../../assets/img/uniko-gray-logo.svg'
              }}"
              alt="{{
                floorPlan
                ? floorPlan.uri
                  : ''
              }}"
            />
        </div>
      }
    }
    <div class="flex flex-row items-center justify-center gap-6">
      <!-- <span class="text-black text-xl font-semibold">Όροφος 1</span> -->
      <div class="flex flex-row items-center gap-3">
        <button class="btn-icon-secondary-sm" type="button" (click)="previous()" [disabled]="selectedIndex == 0">
          <i class="icon-chevron-up"></i>
        </button>
        <button class="btn-icon-secondary-sm" type="button" (click)="next()" [disabled]="selectedIndex == floorPlans.length - 1">
          <i class="icon-chevron-down"></i>
        </button>
      </div>
    </div>
  </div>
</section>
