<div class="flex flex-grow container py-8">
    <div class="flex-grow flex justify-center">
        <div class="w-full max-w-96 pt-[5vw]">
            <div class="flex flex-col rfs:gap-6">
                <ng-container *transloco="let t; prefix: 'save-search-modal.anonymous'">
                    <h2 class="text-secondary h2">{{t('title')}}</h2>
                    <p class="text-tone-07">{{t('message')}}</p>
                </ng-container>
                <div *transloco="let t; prefix: 'shared.navigation'">
                    <form class="flex flex-col gap-6">
                        <a [routerLink]="['/login']" [queryParams]="{location: currentPath}" role="button" type="submit"
                            class="btn-primary w-full flex flex-row items-center justify-center">{{t('anchors.logIn')}}
                        </a>
                    </form>

                    <a class="text-primary font-semibold text-center flex flex-row items-center w-full justify-center mt-7"
                        [routerLink]="['/register']">{{t('anchors.signUp')}} <i class="icon-chevron-right"></i></a>
                </div>
            </div>
        </div>
        <!-- <div class="hidden lg:block flex-1 on-boarding-bg-4"></div> -->
    </div>
</div>