<ng-container *transloco="let t; prefix: 'shared.advisor-callback'">
    <div *ngIf="advisor?.email" class="bg-gray-100 rounded-small p-4">
        <div class="flex flex-col md:flex-row justify-between items-center">
            <div class="flex flex-col sm:flex-row gap-4">
                <div>
                    <img class="rounded-full w-16 h-16"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
                        alt="checkered">
                </div>
                <div *ngIf="advisor?.fullName" class="flex flex-col justify-center">
                    <p class="uppercase font-semibold text-[0.625rem] text-gray-700">{{t('text.speakToAdvisor')}}</p>
                    <h4 class="font-semibold text-xl text-black">{{advisor?.fullName}}</h4>
                </div>
            </div>
            <div>
                <button class="btn-secondary flex items-center">
                    <i class="icon-clock text-xl"></i> {{t('buttons.callMeBack')}}
                </button>
            </div>
        </div>
    </div>
</ng-container>