import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PortalShellComponent } from './components/portal-shell/portal-shell.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [PortalShellComponent],
  imports: [CommonModule, RouterModule, FooterComponent, NavigationComponent],
  exports: [PortalShellComponent, FooterComponent, NavigationComponent]
})
export class LayoutModule {}
