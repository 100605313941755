<ng-container *ngIf="order">
    <div class="flex flex-col flex-1" *transloco="let t; prefix: 'shared.checkout'">
        <ng-container *ngIf="Step=='Step1'">
            <!-- STEP 1 -->
            <main class="flex-1 flex flex-col mt-[84px]">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]="1" [Steps]="Steps"></progress-stepper>

                <section class="flex-1 flex justify-center items-center container pb-[108px]">
                    <div class="flex flex-col gap-12">
                        <div class="text-center max-w-[800px]">
                            <h1 class="h1 text-black">{{t('headers.stepOne')}}</h1>
                            <p class="text-xl text-gray-900">{{t('infoText.stepOne')}}</p>
                        </div>
                        <app-step-buyer-info [oldState]="buyerInfoData"
                            (formDataEvent)="handleFormData($event)"></app-step-buyer-info>

                    </div>
                </section>
            </main>

            <app-navigation-buttons [HideSkip]="true" [HidePrevious]="true" (ExitButtonClicked)="OpenModalComponent()"
                (NextButtonClicked)="submitStep1()"></app-navigation-buttons>

        </ng-container>
        <div *ngIf="Step=='Step2' || step2Loaded" [style.display]="Step!='Step2' ? 'none': null">
            <!-- STEP 2 -->
            <main class="flex-1 flex flex-col mt-[84px]">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]="2" [Steps]="Steps"></progress-stepper>

                <section class="flex-1 flex justify-center items-center container pb-[108px]">
                    <div class="flex flex-col gap-12">
                        <div class="text-center max-w-[800px]">
                            <h1 class="h1 text-black">{{t('headers.stepTwo')}}</h1>
                            <p class="text-xl text-gray-900">{{t('infoText.stepTwo')}}</p>
                        </div>

                        <app-step-payment-details [orderNumber]="orderNumber"
                            (cardDataUpdatedEvent)="handleCardData($event)"></app-step-payment-details>
                    </div>
                </section>

            </main>
            <app-navigation-buttons [HideSkip]="true" (ExitButtonClicked)="OpenModalComponent()"
                (PreviousButtonClicked)="Goto('Step1')" (NextButtonClicked)="submitStep2()"></app-navigation-buttons>
        </div>
        <ng-container *ngIf="Step=='Step3'">
            <!-- STEP 3 -->
            <main class="flex-1 flex flex-col mt-[84px]">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]="3" [Steps]="Steps"></progress-stepper>
                <section class="flex-1 flex justify-center items-center container pb-[108px]">
                    <div class="flex flex-col gap-12">
                        <div class="text-center max-w-[800px]">
                            <h1 class="h1 text-black">{{t('headers.stepThree')}}</h1>
                            <p class="text-xl text-gray-900">{{t('infoText.stepThree')}}</p>
                        </div>
                        <app-step-review [cardData]="cardData" [orderData]="order"></app-step-review>
                    </div>
                </section>

            </main>
            <app-navigation-buttons [HideSkip]="true" (ExitButtonClicked)="OpenModalComponent()"
                (PreviousButtonClicked)="Goto('Step2')" (NextButtonClicked)="submitStep3()"
                [CustomNextTitle]="t('titles.orderConfirmation')" [HideNextArrow]="true"></app-navigation-buttons>
        </ng-container>
        <ng-container *ngIf="Step=='Step4'">
            <!-- STEP 4 -->
            <main class="flex-1 flex flex-col mt-[5px]">
                <section class="flex-1 flex justify-center items-center container pb-[108px] pt-[72px]">
                    <div class="flex flex-col gap-12 flex-1 max-w-[1024px]">
                        <div class="text-start">
                            <h1 class="h1 font-sm text-black leading-snug" [innerHTML]="t('headers.stepFour')"></h1>
                        </div>
                        <div class="bg-gray-100 p-6 flex flex-col gap-6 rounded-lg">
                            <div class="flex justify-between items-center">
                                <div class="text-black font-semibold leading-tight">
                                    <span class="rfs:text-xl">{{t('labels.orderId')}}</span>
                                    <span class="block rfs:text-[2rem]">{{ order.number }}</span>
                                </div>
                                <div class="flex rounded-full h-8 w-8 bg-primary-500 justify-center items-center">
                                    <i class="icon-check text-white text"></i>
                                </div>
                            </div>
                            <hr>
                            <p class="max-w-[380px]" [innerHTML]="t('infoText.stepFour')"></p>
                        </div>
                        <app-step-review [cardData]="cardData" [orderData]="order"></app-step-review>
                    </div>
                </section>

            </main>
            <app-navigation-buttons [HideSkip]="true" [HidePrevious]="true" (ExitButtonClicked)="OpenModalComponent()"
                (PreviousButtonClicked)="Goto('Step3')" (NextButtonClicked)="submitStep4()"
                [CustomNextTitle]="t('titles.myRequests')" [HideNextArrow]="true"></app-navigation-buttons>
        </ng-container>
    </div>
</ng-container>
<lib-toaster-container></lib-toaster-container>