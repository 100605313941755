import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { AddressComponent, AddressComponentModel } from '../google-maps-autocomplete/helper';
import { PlaceViewModel } from 'src/app/services/property.service';
import { AutocompleteBaseComponent } from '../autocomplete/autocomplete-base.component';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'app-google-maps-search-places',
  standalone: true,
  imports: [CommonModule,ClickOutsideDirective],
  templateUrl: './google-maps-search-places.component.html',
  styleUrl: './google-maps-search-places.component.css'
})
export class PlaceAutocompleteComponent extends AutocompleteBaseComponent {
  @Input() addressType: string[] = ['geocode']; // 'establishment' / 'address' / 'geocode'
  @Input() showSearchIcon: boolean = true;
  @Output() placeSelected: EventEmitter<PlaceViewModel> = new EventEmitter<PlaceViewModel>();
  @ViewChild('searchInput') searchTextbox: any;
  // @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
  placeLatLng: google.maps.LatLngLiteral | undefined;
  private _autocompleteService: google.maps.places.AutocompleteService;
  private autocompleteSessionToken: google.maps.places.AutocompleteSessionToken | undefined;
  private _placesService: google.maps.places.PlacesService | undefined;
  public resultsFound: boolean = false;

  constructor(
    private _addressComponentHelper: AddressComponent,
    private _cdr: ChangeDetectorRef,
  ) {
    super();
    this._autocompleteService = new google.maps.places.AutocompleteService();
    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();

  }

  override ngOnInit(): void {
    this.debouncer
      .pipe(
        debounceTime(this.debounceMs),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        this.busy = true;
        if (value.length >= this.minLength) {
          this.searchTerm = value;
          this._autocompleteService?.getPlacePredictions(
            {
              input: value,
              sessionToken: this.autocompleteSessionToken,
              componentRestrictions: { country: 'GR' },
              types: this.addressType
            }, (results, status) => {
              console.log('inside prediction callback')
              if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                this.items = results.filter(this._itemsFilter).sort((one, two) => (one.description < two.description ? -1 : 1));
                this.resultsFound = true;
                console.log('pred call items : ', this.items)
              } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                console.log('zero results')
                this.items = undefined;
                this.resultsFound = false;
              }
              this.busy = false;
            }
          )
        }
        if (value == null || value == "") {
          this.items = undefined;
        }
      });
  }

  public ngAfterViewInit(): void {
    let map = new google.maps.Map(document.createElement('div') as HTMLElement);
    this._placesService = new google.maps.places.PlacesService(map);
  }

  private _itemsFilter = (item: google.maps.places.AutocompletePrediction) => {
    const selectedItem = this.selectedItems.find(x => x.PlaceId == item.place_id);
    return selectedItem == null || selectedItem == undefined;
  }

  showDropDown() {
    this.showResults = true
  }
  hideDropdown() {
    this.showResults = false
  }

  selectPlaceById(item: google.maps.places.AutocompletePrediction, emmit: boolean) {
    console.log('called selectPlace',item.place_id)
    this._placesService?.getDetails(
      {
        fields: ['address_component', 'geometry', 'formatted_address'],
        placeId: item.place_id,
        sessionToken: this.autocompleteSessionToken
      }, (result, status) => {
        console.log('inside selectPlace callback')
        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
          console.log('inside selectPlace callback',result)
          let addressComponents: AddressComponentModel[] = result['address_components'] ?? [];
          let geometry: google.maps.places.PlaceGeometry = result['geometry'] ?? {};
          let place: PlaceViewModel = {
            PlaceId: item.place_id,
            FormatedAddress: item.description, //+ ' ' + result['formatted_address'],
            Country: this._addressComponentHelper.findCountry(addressComponents),
            Prefecture: this._addressComponentHelper.findLocality(addressComponents),
            AdministrativeAreas: this._addressComponentHelper.findAdministativeAreas(addressComponents),
            StreetName: this._addressComponentHelper.findRoute(addressComponents),
            StreetNumber: this._addressComponentHelper.findStreetNumber(addressComponents),
            PostalCode: this._addressComponentHelper.findPostalCode(addressComponents),
            Lon: geometry?.location?.lng() ?? null,
            Lat: geometry?.location?.lat() ?? null,
            Bounds: {
              SwLon: geometry?.viewport?.getSouthWest().lng() ?? undefined,
              NeLat: geometry?.viewport?.getNorthEast().lat() ?? undefined,
              SwLat: geometry?.viewport?.getSouthWest().lat() ?? undefined,
              NeLon: geometry?.viewport?.getNorthEast().lng() ?? undefined
            }
          }
          this._cdr.detectChanges();
          if (emmit) {
            this.placeSelected.emit(place);
          }
          this.searchTextbox.nativeElement.value = null;
          this.debouncer.next('')
        }

        this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
      })
  }
}
