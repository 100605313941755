import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, Input, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { placeTypeToIconMap, PointOfInterestComponent } from './point-of-interest/point-of-interest.component';
import { GoogleMapsAutocompleteComponent } from "../../../shared/components/google-maps-autocomplete/google-maps-autocomplete.component";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, scan, tap } from 'rxjs/operators';
import { TranslocoDirective } from '@jsverse/transloco';
import { PlaceAutocompleteComponent } from 'src/app/shared/components/google-maps-search-places/google-maps-search-places.component';
import { calculatedTravels, MapsDistanceService } from 'src/app/services/maps-distance.service.ts.service';
import { PlaceViewModel } from 'src/app/services/property.service';
import { PlaceType, PortalApiService, PropertyPointOfInterest } from 'src/app/services/portal-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-map-area-section',
  standalone: true,
  templateUrl: './map-area-section.component.html',
  styleUrl: './map-area-section.component.css',
  imports: [
    CommonModule,
    GoogleMapsModule,
    PointOfInterestComponent,
    GoogleMapsAutocompleteComponent,
    TranslocoDirective,
    PlaceAutocompleteComponent,
  ],
})
export class MapAreaSectionComponent implements AfterViewInit {
  @Input() lat: number | any;
  @Input() lon: number | any;
  @Input() propertyID!: string;
  @ViewChild('googleMap') map: GoogleMap | undefined;
  @ViewChild('placesHistoryListContainer', { read: ViewContainerRef })
  private vcr!: ViewContainerRef;
  private ref!: ComponentRef<PointOfInterestComponent>;
  private bounds = new google.maps.LatLngBounds();
  public targetCords: { lat: number; lng: number } = { lat: 0, lng: 0 };
  public locationRadius = 200;
  public placeLatLng: { lat: number; lng: number } | undefined;
  public travelDetails$: BehaviorSubject<calculatedTravels[] | undefined> = new BehaviorSubject<calculatedTravels[] | undefined >(undefined);
  public userSearchInput?: string;

  public pointsOfInterest: PropertyPointOfInterest[] = [];
  protected markerIcon: HTMLElement;

  markerAddedSubject: BehaviorSubject<DtoMarker[]> = new BehaviorSubject<
    DtoMarker[]
  >([]);
  poiMarkersAddedSubject: BehaviorSubject<DtoMarker[]> = new BehaviorSubject<
    DtoMarker[]
  >([]);
  markers$: Observable<DtoMarker[]> = this.markerAddedSubject.pipe(
    scan((all: DtoMarker[], current: DtoMarker[]) => {
      all.push(...current);
      // this.bounds.extend(new google.maps.LatLng(this.lat, this.lon));
      return all;
    }),
    tap((t) => {
      this.map?.googleMap?.setCenter(new google.maps.LatLng(this.lat, this.lon));

    })
  );

  POImarkers$: Observable<DtoMarker[]> = of(this.POIsToMarkers(this.pointsOfInterest));
  //HTML GoogleMap this is to be used ngAfterViewInit
  protected mapOptions: google.maps.MapOptions = {
    maxZoom: 20,
    disableDefaultUI: true, //removes default control icons. this is closer to the designed look.
    streetViewControl: true,
    center: new google.maps.LatLng(38.1169981, 21.6855135), //default map center Athens (when no properties available),
    zoom: 15,
  };

  protected mapLocationRadiusOptions: google.maps.CircleOptions = {
    strokeColor: '#41c580',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#5dcd93',
    fillOpacity: 0.35,
  };

  /**
   *
   */

  constructor(
    private _distanceService: MapsDistanceService,
    private _cdr: ChangeDetectorRef,
    private _portalApi: PortalApiService,
    private _activatedRoute: ActivatedRoute,
    private _renderer: Renderer2
  ) {

    this.markerIcon = this._renderer.createElement('div');
    this.markerIcon.innerHTML = `
      <div style="text-align: center; transform: translate(0, 20px);">
        <img src="../../../assets/propertyMapPoint.png" width="42 height="42">
      </div>`;
  }

  ngOnInit() {
    this.POImarkers$ = this._portalApi.getPointsOfInterest(this.propertyID).pipe(
      tap(res=>{
        this.pointsOfInterest = res;
      })
      ,map(res=>{
       return this.POIsToMarkers(res);
      })
    )
  }

  ngAfterViewInit() {
    let propertyMarker = new DtoMarker();
    propertyMarker.icon = {
      anchor: new google.maps.Point(21, 21),
      url: '../../../assets/propertyMapPoint.png',
    };

    propertyMarker.position = new google.maps.LatLng(this.lat, this.lon);
    // var advancedMarker = new MapAdvancedMarker(map, new NgZone({
    // }));
    this.markerAddedSubject.next([propertyMarker]);
    this.targetCords = { lat: this?.lat, lng: this?.lon };
  }


  changeMapCenter(lat?:number,lon?:number, coordinates?: string, isProp?: boolean ) {
    if(coordinates) {
      lat = Number(coordinates.split(',')[0])
      lon = Number(coordinates.split(',')[1])
    }

    (lat && lon) && this.map?.googleMap?.setCenter(new google.maps.LatLng(lat, lon));
    !isProp ? this.map?.googleMap?.setZoom(18) : this.map?.googleMap?.setZoom(15);
  }

  // addPlace($event: any) {
  //   let newPlaceMarker = new DtoMarker();
  //   newPlaceMarker.icon = $event.icon;
  //   newPlaceMarker.position = new google.maps.LatLng(
  //     $event.geometry.location.lat(),
  //     $event.geometry.location.lng()
  //   );
  //   this.markerAddedSubject.next([newPlaceMarker]);

  //   this.addChild(
  //     ($event.types as Array<any>).at(0).replaceAll('_', ' '),
  //     $event.name,
  //     $event.icon
  //   );
  // }

  // addChild(hypertitle: string, subtitle: string, icon: string) {
  //   this.ref = this.vcr.createComponent(PointOfInterestComponent);
  //   this.ref.instance.hypertitle = hypertitle;
  //   this.ref.instance.subtitle = subtitle;
  //   this.ref.instance.icon = 'apartment';
  //   this.ref.instance.circleColor = 'secondary';
  // }

  // removeChild() {
  //   const index = this.vcr.indexOf(this.ref.hostView);
  //   if (index != -1) this.vcr.remove(index);
  // }

  onPlaceSelected(place: PlaceViewModel) {
    this.placeLatLng = { lat: place.Lat, lng: place.Lon } as google.maps.LatLngLiteral;
    this.userSearchInput = place.FormatedAddress;
    this._distanceService
      .calculateAllDistances(this.targetCords, this.placeLatLng)
      .then((distances: calculatedTravels[]) => {
        this.travelDetails$.next(distances);
        this._cdr.detectChanges();
      });
  }

  POIsToMarkers(pois : PropertyPointOfInterest[]): DtoMarker[]  {
    if (pois !== undefined && pois.length < 1) {
      return [];
    }else{
      return pois
          .filter(poi => poi.coordinates)
          .map( (poi,index) => {
            let lat = Number(poi.coordinates!.split(',')[0]);
            let lon = Number(poi.coordinates!.split(',')[1]);
            let position = new google.maps.LatLng(lat, lon);
            let m: DtoMarker = new DtoMarker();
            let poiMarkIcon = this._renderer.createElement('div');
            this._renderer.setAttribute(poiMarkIcon,'class','bg-primary-200 flex h-[42px] items-center justify-center w-[42px] rounded-full text-tone-01 w-full')
            let icon =  placeTypeToIconMap[poi.placeType as PlaceType];

            poiMarkIcon.innerHTML = `<div style="text-align: center;">
                                    <i class="text-2xl icon-${icon}"></i>
                                    </div>`
              m.id = index;
              console.log(poiMarkIcon)
              m.icon = poiMarkIcon;
              m.position = position;
            // this.bounds.extend(position);
            return m;
          })
    }
  }
}

export class DtoMarker {
  icon: string | google.maps.Icon | any;
  advancedIcon: string | any;
  id?: string | any;
  position: google.maps.LatLng | any;
}
