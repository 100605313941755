import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { tap } from 'rxjs';
import { PortalApiService } from 'src/app/services/portal-api.service';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class PropertyOwnershipGuardService {

    constructor(private _portalApi: PortalApiService, private _router: Router, private _authService: AuthService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        var propertyCode = route.paramMap.get('propertyCode') ?? undefined;
        var propertyId = route.paramMap.get('propertyId') ?? undefined;

        return new Promise((resolve) => {
            if (!propertyCode && !propertyId) {
                this._router.navigate(['home']);
                resolve(false);
            }
            this._authService.isLoggedIn().pipe(
                tap((isLoggedIn) => {
                    if (!isLoggedIn) {
                        this._router.navigate(['home']);
                        resolve(false);
                    }
                    this._portalApi.resolveOwnership(propertyCode, propertyId).pipe(
                        tap(
                            (res) => {
                                if (!res.isMine) {
                                    this._router.navigate(['home']);
                                    resolve(false);
                                } else {
                                    resolve(true);
                                }

                            },
                            (err) => {
                                this._router.navigate(['home']);
                                resolve(false);
                            })
                    ).subscribe();
                })).subscribe();
        });
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        var propertyCode = route.parent?.paramMap.get('propertyCode') ?? undefined;
        var propertyId = route.parent?.paramMap.get('propertyId') ?? undefined;

        return new Promise((resolve) => {
            if (!propertyCode && !propertyId) {
                this._router.navigate(['home']);
                resolve(false);
            }

            this._portalApi.resolveOwnership(propertyCode, propertyId).pipe(
                tap(
                    (res) => {
                        if (!res.isMine) {
                            this._router.navigate(['home']);
                            resolve(false);
                        } else {
                            resolve(true);
                        }

                    },
                    (err) => {
                        this._router.navigate(['home']);
                        resolve(false);
                    })
            ).subscribe();
        });
    }
}