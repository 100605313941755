import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslocoDirective } from "@jsverse/transloco";
import { AuthService } from "src/app/core/services/auth.service";
import { EstimatedFees, EstimatedFeeService } from "src/app/services/estimated-fee.service";
import { LockedContentOverlayComponent } from "../../../shared/components/locked-content-overlay/locked-content-overlay.component";

@Component({
    selector: 'app-costs',
    standalone: true,
    templateUrl: './costs.component.html',
    imports: [CommonModule, TranslocoDirective, LockedContentOverlayComponent],
})
export class CostsComponent implements OnInit {
    @Input() propertyValue: number = 0;

    public fees: EstimatedFees = {};
    public isLoggedIn: boolean = false;

    constructor(
        private estimatedFee: EstimatedFeeService,
        private authService: AuthService
    ) {
        this.authService.isLoggedIn().subscribe((isLoggedIn) => {
            this.isLoggedIn = isLoggedIn;
        });
    }

    public accordionItems = [
        {index: 0, active: false},
        {index: 1, active: false},
        {index: 2, active: false},
        {index: 3, active: false},
        {index: 4, active: false},
        {index: 5, active: false},
        {index: 6, active: false},
        {index: 7, active: false},
        {index: 8, active: false},
    ]

    ngOnInit(): void {
        if (this.propertyValue !== 0) {
            this.fees = this.estimatedFee.calculateEstimatedFees(this.propertyValue)
            console.log(this.fees)
        }
    }

    toggleAccordion(index: number): void {
        this.accordionItems[index].active = !this.accordionItems[index].active;
      }
    
}