import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PropertySummaryResultSet } from 'src/app/services/portal-api.service';
import { PropertyCardItemComponent } from "../property-card-item/property-card-item.component";
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-similar-properties',
  standalone: true,
  imports: [CommonModule, PropertyCardItemComponent, TranslocoDirective],
  templateUrl: './similar-properties.component.html',
  styleUrl: './similar-properties.component.css'
})
export class SimilarPropertiesComponent {
  @Input() similarProperties: PropertySummaryResultSet | undefined;
}
