import { Inject, Injectable, InjectionToken } from "@angular/core";
import setupSdk from "@matterport/sdk";

export const MATERPORT_SKD_KEY = new InjectionToken<string>('MATERPORT_SKD_KEY');

@Injectable({
    providedIn: 'root'
})
export class MatterportService {
    SDK_KEY!: string
    constructor(@Inject(MATERPORT_SKD_KEY) sdkKey?: string) {
        this.SDK_KEY = sdkKey ?? "";
    }

    public async setupMatterportSdk(nativeElement: HTMLElement, spaceId: string) {
        await setupSdk(this.SDK_KEY, {
            container: nativeElement,
            space: spaceId
        })
            .then(sdk => {
                console.log("setup sdk OK")
                return sdk;
            })
            .catch(x => console.log("SDK FAILED : ", x));

    }
}