<section
  class="pt-6 pb-12"
  id="mapArea"
  *transloco="let t; prefix: 'property-details.map-area-section'"
>
  <h2 class="text-[1.75rem] font-semibold text-black pb-6">
    {{ t("header") }}
  </h2>
  <div class="aspect-[800/400]" #gMapContainer>
    <google-map
      [mapId]="'f5552e3916e0e5d7'"
      #googleMap
      [options]="mapOptions"
      #propertyMap
      [width]="gMapContainer.clientWidth"
    >
      <map-advanced-marker
        *ngFor="let POImarker of POImarkers$ | async"
        [position]="POImarker.position"
        [content]="POImarker.icon"
        class="text-white"
      >
      </map-advanced-marker>
      <map-advanced-marker
        *ngFor="let marker of markers$ | async"
        [position]="marker.position"
        [content]="markerIcon"
        class="text-white"
      ></map-advanced-marker>
      <map-circle
        *ngFor="let marker of markers$ | async"
        [center]="marker.position"
        [radius]="locationRadius"
        [options]="mapLocationRadiusOptions"
      />
    </google-map>
  </div>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-2 pt-8 2xl:pt-0">
      <p class="font-semibold">{{ t("distanceInfoText") }}</p>
      <app-google-maps-search-places
        [addressType]="['geocode', 'establishment']"
        (placeSelected)="onPlaceSelected($event)"
      ></app-google-maps-search-places>
    </div>

    <div
      *ngIf="travelDetails$ | async as travelDetails; else noData"
      class="border border-gray-300 rounded-small flex flex-col w-full px-4 py-3 gap-4"
    >
      <div class="flex w-full">
        <span class="my-auto font-semibold w-full text-center">{{
          userSearchInput
        }}</span>
      </div>
      <div class="flex w-full">
        <div
          *ngFor="let detail of travelDetails"
          class="flex flex-1 gap-2 items-center justify-center"
        >
          <div
            class="bg-primary flex h-[42px] items-center justify-center max-w-[42px] rounded-full text-tone-01 w-full"
          >
            <i
              class="text-2xl"
              [ngClass]="{
                'icon-car': detail.travelMode == 'DRIVING',
                'icon-bus': detail.travelMode == 'TRANSIT',
                'icon-multiple-users': detail.travelMode == 'WALKING',
              }"
            ></i>
          </div>
          <div class="flex flex-col">
            <span class="text-sm"
              >{{ t("place-distance") }} : {{ detail.distance }}</span
            >
            <span class="text-sm"
              >{{ t("place-duration") }} : {{ detail.duration }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <ng-template #noData>
      <!-- Content when travelDetails is null or undefined -->
    </ng-template>
    <div
      class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 hover:cursor-pointer"
    >
      <app-point-of-interest
        [lat]="lat"
        [lon]="lon"
        [circleColor]="'primary'"
        [icon]="'apartment'"
        [distanceFromProperty]="0"
        [isPropertyPin]="true"
        (click)="changeMapCenter(lat, lon, undefined, true)"
      ></app-point-of-interest>
      <ng-container #placesHistoryListContainer></ng-container>
      <ng-container *ngFor="let item of pointsOfInterest">
        <app-point-of-interest
          [coordinates]="item.coordinates"
          [circleColor]="'primary-200'"
          [distanceFromProperty]="item.distance"
          [placeType]="item.placeType"
          [subtitle]="item.name"
          (click)="changeMapCenter(undefined, undefined, item.coordinates)"
        ></app-point-of-interest>
      </ng-container>
    </div>
  </div>
</section>
