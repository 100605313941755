import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ImageItemComponent } from './image-item/image-item.component';
import { Modal, ModalOptions } from '@indice/ng-components';
import { TranslocoDirective } from '@jsverse/transloco';
import { Photo, PhotoResultSet } from 'src/app/services/portal-api.service';

@Component({
  selector: 'app-modal-image-gallery',
  standalone: true,
  templateUrl: './modal-image-gallery.component.html',
  imports: [CommonModule, ImageItemComponent, TranslocoDirective]
})
export class ModalImageGalleryComponent {
  public title: string = "";
  public countPhotos: number = 0;
  public photos: PhotoResultSet | undefined
  protected selfHidden: boolean = false;

  constructor(
    public modal: Modal,
    @Inject(ModalOptions) private options: ModalOptions<ModalImageGalleryComponent>
  ) {
    this.title = options?.initialState?.title as string;
    this.countPhotos = options?.initialState?.countPhotos as number;
    this.photos = options?.initialState?.photos as PhotoResultSet;
  }

  closeModal() {
    this.modal.hide({});
  }

  /*When a child modal is created, this function hides this modal*/
  selfHideSwitch(event: boolean) {
    this.selfHidden = event;
  }
}
