import { FeaturesFormGroup, PropertyListingTab } from './../property-listing.component';
import { CommonModule } from '@angular/common';
import { Component, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AdvisorCallbackComponent } from 'src/app/shared/components/advisor-callback/advisor-callback.component';
import { Advisor, ParkingType } from 'src/app/services/portal-api.service';
import { Subject } from 'rxjs';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-features',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, AdvisorCallbackComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './features.component.html',
  styleUrl: './features.component.css'
})
export class FeaturesComponent {
  @Input() featuresFormGroup: FormGroup<FeaturesFormGroup> = new FormGroup(new FeaturesFormGroup());
  @Input() advisor: Advisor | undefined;

  @Output() onNextTab: Subject<PropertyListingTab> = new Subject();

  public parkingType = ParkingType;
  public propertyListingTab = PropertyListingTab;

  public navigateToTab(tab: PropertyListingTab) {
    this.onNextTab.next(tab);
  }
}
