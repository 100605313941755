import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AdvisorCallbackComponent } from 'src/app/shared/components/advisor-callback/advisor-callback.component';
import { OwnershipFormGroup, PropertyListingTab } from '../property-listing.component';
import { Subject } from 'rxjs';
import { Advisor, OwnershipType, PropertyAvailability, PropertyUsageCategory } from 'src/app/services/portal-api.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-ownership',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AdvisorCallbackComponent, NgxMaskDirective, TranslocoDirective, TranslocoPipe],
  providers: [provideNgxMask()],
  templateUrl: './ownership.component.html',
  styleUrl: './ownership.component.css'
})
export class OwnershipComponent implements AfterViewInit {
  @ViewChild('maskedKaekInput') _maskedKaekInput: ElementRef | undefined;
  @Input() ownershipFormGroup: FormGroup<OwnershipFormGroup> = new FormGroup(new OwnershipFormGroup());
  @Input() advisor: Advisor | undefined;

  @Output() onNextTab: Subject<PropertyListingTab> = new Subject();

  public propertyUsageCategory = PropertyUsageCategory;
  public ownershipType = OwnershipType;
  public propertyAvailability = PropertyAvailability;
  public propertyListingTab = PropertyListingTab;
  public maskedKaekFormControl: FormControl = new FormControl<string>(this.ownershipFormGroup.controls.kaek, [Validators.minLength(12), Validators.maxLength(12), Validators.pattern('^[0-9]{12}$')]);
  
  ngAfterViewInit(): void {
    this.maskedKaekFormControl.setValue(this.ownershipFormGroup.controls.kaek.value?.toString());

    this.maskedKaekFormControl.valueChanges.subscribe((val) => {
      if(this.maskedKaekFormControl.valid) {
        this.ownershipFormGroup.controls.kaek.setValue(!val ? null : val);
      }
    })
  }

  public navigateToTab(tab: PropertyListingTab) {
    this.onNextTab.next(tab);
  }

}
