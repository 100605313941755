import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DetailTableRowComponent } from './detail-table-row/detail-table-row.component';
import { PropertyDetails } from 'src/app/services/portal-api.service';


@Component({
  selector: 'app-detail-section',
  standalone: true,
  imports: [CommonModule, DetailTableRowComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './detail-section.component.html',
  styleUrl: './detail-section.component.css'
})
export class DetailSectionComponent {
  @Input() model = new PropertyDetails();

  protected isCollapsed: boolean = true;

  toggleCollapse(event: MouseEvent) {
    this.isCollapsed = !this.isCollapsed;
  }
}
