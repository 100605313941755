<section
  class="relative"
  *transloco="let t; prefix: 'property-details.hero-section'"
>
  <div class="container">
    <button
      (click)="GoBack()"
      class="text-black py-[6px] my-6 font-semibold flex gap-2 justify-center items-center"
    >
      <i class="icon-arrow-left"></i>{{ t("buttons.back") }}
    </button>
    <div
      class="grid grid-cols-5 grid-rows-2 gap-3 rounded-medium overflow-hidden h-[490px]"
    >
      <div class="row-span-2 col-span-5 lg:col-span-3">
        <div class="relative px-4 py-5 h-full">
          <div class="relative z-10 flex flex-col justify-between h-full">
            <div class="flex flex-row justify-between">
              <div class="flex flex-row flex-wrap gap-2 text-white">
                <div *ngIf="model.isHot" class="property-label red max-h-fit">
                  {{ t("tags.hot") }}
                </div>
                <div
                  *ngIf="model.preApproved"
                  class="property-label blue max-h-fit"
                >
                  {{ t("tags.preApproved") }}
                </div>
                <div
                  *ngIf="model?.auctionRound"
                  class="property-label green max-h-fit"
                >
                  {{ t("tags.auction") }}
                </div>
                <div
                  *ngIf="model?.isSponsored"
                  class="property-label orange max-h-fit"
                >
                  {{ t("tags.sponsored") }}
                </div>
              </div>
              <div
                *ngIf="model?.preApproved === 'Approved'"
                class="bg-[#037079CC] py-2 px-4 backdrop-blur rounded-small flex flex-row items-center gap-1"
              >
                <span class="text-white font-bold text-[0.546875rem]">{{
                  t("default-image.nbg-aproved")
                }}</span>
                <img src="assets/static/nbg-white.png" />
              </div>
            </div>
            <div class="flex flex-row items-center justify-between">
              <div class="flex flex-row items-center gap-2">
                <button
                  type="button"
                  (click)="openModalComponent()"
                  class="btn-secondary-sm bg-white"
                >
                  {{ photos?.count }} {{ t("default-image.photos") }}
                </button>
                @if (floorPlanExists) {
                  <button class="btn-secondary-sm bg-white" 
                    type="button"
                    (click)="openFloorPlanComponent()">
                    {{ t("default-image.floor-plan") }}
                  </button>
                }
              </div>
              <div *ngIf="vrCode">
                <button
                  (click)="openVrModalComponent()"
                  type="button"
                  class="bg-[#D0FBE4E5] backdrop-blur-sm border text-black text-sm font-semibold border-primary-500 px-4 rounded-full py-2"
                >
                  {{ t("default-image.vr-toor") }}
                  <i class="icon-marker-pin"></i>
                </button>
              </div>
            </div>
          </div>
          <img
            src="{{ model.defaultPhoto?.uri ?? model.defaultImagePath }}"
            class="w-full h-full object-cover object-center img-disable-context absolute top-0 left-0 bottom-0 right-0 -z-1"
          />
        </div>
      </div>
      <div class="bg-gray-100 hidden lg:flex items-center justify-center">
        <img
          [ngClass]="{
            'object-cover w-full h-full img-disable-context':
              photos && photos.items && photos.items.length > 0
          }"
          src="{{
            photos && photos.items && photos.items.length > 0
              ? photos.items[0].uri
              : '../../../../../assets/img/uniko-gray-logo.svg'
          }}"
          alt="{{
            photos && photos.items && photos.items.length > 0
              ? photos.items[0].altText
              : ''
          }}"
        />
      </div>
      <div class="bg-gray-100 hidden lg:flex items-center justify-center">
        <img
          [ngClass]="{
            'object-cover w-full h-full img-disable-context':
              photos && photos.items && photos.items.length > 1
          }"
          src="{{
            photos && photos.items && photos.items.length > 1
              ? photos.items[1].uri
              : '../../../../../assets/img/uniko-gray-logo.svg'
          }}"
          alt="{{
            photos && photos.items && photos.items.length > 1
              ? photos.items[1].altText
              : ''
          }}"
        />
      </div>
      <div class="bg-gray-100 hidden lg:flex items-center justify-center">
        <img
          [ngClass]="{
            'object-cover w-full h-full img-disable-context':
              photos && photos.items && photos.items.length > 2
          }"
          src="{{
            photos && photos.items && photos.items.length > 2
              ? photos.items[2].uri
              : '../../../../../assets/img/uniko-gray-logo.svg'
          }}"
          alt="{{
            photos && photos.items && photos.items.length > 2
              ? photos.items[2].altText
              : ''
          }}"
        />
      </div>
      <div class="bg-gray-100 hidden lg:flex items-center justify-center">
        <img
          [ngClass]="{
            'object-cover w-full h-full img-disable-context':
              photos && photos.items && photos.items.length > 3
          }"
          src="{{
            photos && photos.items && photos.items.length > 3
              ? photos.items[3].uri
              : '../../../../../assets/img/uniko-gray-logo.svg'
          }}"
          alt="{{
            photos && photos.items && photos.items.length > 3
              ? photos.items[3].altText
              : ''
          }}"
        />
      </div>
    </div>
  </div>
</section>
