import { Injectable } from "@angular/core";
import { PortalApiService, Search } from "./portal-api.service";
import { AsyncSubject } from "rxjs";
import { ParamMap, convertToParamMap } from "@angular/router";
import { PropertyListingService } from "./property.service";
import { HttpParams } from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class SearchHistoryService {
    private cachedData: any = {};
    private expiresAtMs: number = 60 * 1000; //1hour
    private cacheKeyMask = "userHistory/{URL}"

    constructor(private api: PortalApiService, private propertyListingService: PropertyListingService) {
    }
    public saveHistory(params: ParamMap) {

        if (!this.existsInCasheOrStore(JSON.stringify(params))) {
            let filter = this.propertyListingService.getSearchFiltersFromUrl(params);
            if (filter.type || filter.searchTerm) {
                let type = filter.type ? (filter.type[0]) : undefined;
                let propertyLocation: string | undefined = undefined;
                if (filter.searchTerm && filter.searchTerm.split('!').length > 0) {
                    let locationTerm = filter.searchTerm.split("!")[0]

                    if (locationTerm) {
                        let propertyLocations = locationTerm.split("~");
                        propertyLocation = propertyLocations.length > 1 ? propertyLocations[1] : undefined;
                    }

                }
                let searchBody = new Search({
                    placeName: propertyLocation,
                    query: filter,
                    propertyType: type,

                })
                this.api.upsertSearchHistory(searchBody).subscribe();
            }
        }
    }

    private getCacheKey = (url: string) => this.cacheKeyMask.replace("{URL}", url);
    private existsInCasheOrStore(url: string): boolean {
        let key = this.getCacheKey(url);
        if (!this.cachedData[key]) {
            this.cachedData[key] = new AsyncSubject<string>();

            this.cachedData[key] = url;
            setTimeout(() => this.removeDataFromCache(key), this.expiresAtMs);
            return false;
        }
        return true;
    }

    private removeDataFromCache(key: string): void {
        if (this.cachedData[key]) {
            delete this.cachedData[key];
        }
    }
}
