import { Directive, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[tooltip]',
    standalone: true
})
export class TooltipDirective {
    @Input('tooltip') tooltip: string = '';
    tooltipElement: HTMLElement | null = null;
    offset = 30;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.tooltipElement) { this.show(); }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.tooltipElement) { this.hide(); }
    }

    private show() {
        this.tooltipElement = this.renderer.createElement('span');
        if (this.tooltipElement) {
            this.tooltipElement.innerHTML = this.tooltip;
            this.renderer.appendChild(this.el.nativeElement, this.tooltipElement);

            this.tooltipElement.className = 'tooltip absolute bg-secondary text-white text-xs rounded py-1 px-2 transition-opacity duration-300 opacity-0 font-light';

            const top = - this.offset;
            const left = this.offset;

            this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
            this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
            this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
        }
    }

    private hide() {
        this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
        setTimeout(() => {
            if (this.tooltipElement) {
                this.renderer.removeChild(this.el.nativeElement, this.tooltipElement);
                this.tooltipElement = null;
            }
        }, 300); // Match the transition duration
    }
}
