import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[euroFormatInput]',
  standalone: true
})
export class EuroFormatInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.el.nativeElement.value = this.formatToEuro(value);
  }

   // Remove Euro formatting to get a plain number string
   public removeEuroFormat(value: string): string {
    return value.replace(/\./g, '').trim();
  }

  private formatToEuro(value: string): string {
	if (!value) return '';
    // Remove any non-numeric characters except for the decimal separator
    const numericValue = value.replace(/[^0-9]/g, '');

	const formattedToEuro = parseFloat(numericValue).toLocaleString('el-GR', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	  });

    // Format with dots as thousands separators
    return formattedToEuro
  }
}
