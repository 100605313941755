import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Modal, ModalService } from '@indice/ng-components';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, scan, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { PropertyListFilter, PropertySummary, PropertySummaryResultSet } from 'src/app/services/portal-api.service';
import { PlaceViewModel, PropertyListingService } from 'src/app/services/property.service';
import { Toast, ToasterService, ToastType } from 'src/app/services/toaster.service';
import { PropertyCardItemComponent, } from 'src/app/shared/components/property-card-item/property-card-item.component';
import { ToasterContainerComponent } from 'src/app/shared/components/toast/toast-container.component';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { DropdownMenuComponent } from "../../../shared/components/dropdown-menu/dropdown-menu.component";
import { MapComponent } from "../../../shared/components/map/map.component";
import { SortDropdownComponent } from "../../../shared/components/sort-dropdown/sort-dropdown.component";
import { PropertyModalComponent } from '../../property-modal/property-modal.component';
import { SaveSearchAnonymousModalComponent } from '../../save-search-modal/save-search-anonymous-modal/save-search-anonymous-modal.component';
import { SaveSearchModalComponent } from '../../save-search-modal/save-search-modal.component';
import { FilterDropdownItem } from '../filter-dropdown/filter-dropdown.component';

@Component({
  selector: 'app-grid-list-cards',
  standalone: true,
  imports: [
    CommonModule,
    PropertyCardItemComponent,
    DropdownMenuComponent,
    MapComponent,
    RouterModule,
    ToasterContainerComponent,
    TranslocoDirective,
    SortDropdownComponent
  ],
  templateUrl: './grid-list-cards.component.html',
  styleUrl: './grid-list-cards.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListCardsComponent {
  private searchFilter: PropertyListFilter | undefined
  private page: number = 1;
  private readonly size: number = 8;
  private readonly acceptLanguage = "";
  protected displayMap: boolean = false;
  protected loading: boolean = false;
  private modelCount: number = 0;
  private searchTerm: string | undefined;
  public sortString: string | undefined;
  protected pageByLoadMoreSubject = new BehaviorSubject(1);
  protected googleMapItems = new BehaviorSubject<PropertySummary[]>([]);
  toast$: Observable<Toast> = new Observable();
  public criteriaAdjusted = false;
  public hasQueryParams = false;
  public selectedItem: FilterDropdownItem | undefined;

  public get canLoadMoreItems() {
    return this.page * this.size < this.modelCount;
  }

  private debouncedMapItemsSubject = new Subject<PropertySummary[]>();
  private debouncedMapItems$ = this.debouncedMapItemsSubject.asObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(300), // Adjust the time as necessary
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)) // Emit only if different
  );

  orderByItems: FilterDropdownItem[] = [
    {
      key: 'suggested',
      label: 'Suggested',
    },
    {
      key: 'mostRecent',
      label: 'Most recent'
    },
    {
      key: 'ascPrice',
      label: 'Ascending Price'
    },
    {
      key: 'descPrice',
      label: 'Descending Price'
    },
    {
      key: 'ascSize',
      label: 'Ascending Size'
    },
    {
      key: 'descSize',
      label: 'Descending Size'
    }
  ];

  private mergePropertyResults = (all: PropertySummaryResultSet, current: PropertySummaryResultSet): PropertySummaryResultSet => {
    const currentItems = current.items || [];
    const allItems = all.items || [];

    const newItems = this.page === 1
      ? [...currentItems]
      : [...allItems, ...currentItems];

    const mergedResult = new PropertySummaryResultSet({
        ...all,
        items: newItems,
        count: current.count
    });

    // Emit to the debounced subject
    this.debouncedMapItemsSubject.next(mergedResult.items!); // Emit to debounced subject
    return mergedResult;
};
  
  mapSearch: boolean = true;

  model$: Observable<PropertySummaryResultSet> = this.pageByLoadMoreSubject.pipe(
    takeUntilDestroyed(this.destroyRef),
    tap(() => {
      this.loading = true;
      this.cdr.detectChanges(); // Trigger change detection
    }),
    mergeMap((page: number) => {
      return this.propertyListingService.getProperties(
          this.acceptLanguage,
          page,
          this.size,
          this.sortString,
          this.searchTerm,
          this.searchFilter
      )
    }),
    switchMap((res) => {
      this.criteriaAdjusted = res.criteriaAdjusted;
      this.cdr.detectChanges(); // Force change detection after updating criteria
      return of(res.properties);
    }),
    scan(this.mergePropertyResults, new PropertySummaryResultSet({ count: 0, items: [] })),
    tap((res) => {
      this.loading = false;
      this.modelCount = res.count ?? 0;
      this.cdr.detectChanges(); // Ensure view updates when loading is complete
    })
  );
  isLoggedIn: boolean = false;
  isMobile = false;

  focusedCardId: string | undefined = undefined;

  constructor(private propertyListingService: PropertyListingService,
    private readonly activatedRoute: ActivatedRoute,
    private _modalService: ModalService,
    @Inject(ToasterService) private _toastService: ToasterService,
    private geocodeService: GeocodeService,
    private router: Router,
    private transloco: TranslocoService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private responsiveService: ResponsiveService) {
    this.transloco.selectTranslateObject('find-property.grid-list').pipe(
      tap(t => {
        this.applyTranslations(t);
      })
    ).subscribe();
  }
  ngOnInit() {
    this.responsiveService.isMobile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.isLoggedIn().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(x => this.isLoggedIn = x)
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.hasQueryParams = params.keys.length > 0;
      if (!this.hasQueryParams) this.getSortKeyMapping('suggested');
      this.searchFilter = this.propertyListingService.getSearchFiltersFromUrl(params);
      this.page = 1;
      this.pageByLoadMoreSubject.next(this.page);
      this.cdr.detectChanges(); // Mark the component for check
    });

    // Subscribe to the debounced observable where needed
    this.debouncedMapItems$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(items => {
      this.googleMapItems.next(items); // Emit updated items after debouncing
      this.cdr.detectChanges(); // Mark the component for check
    });
  }
  protected loadMoreGridItems() {
    if (this.loading) return;
    if (!this.canLoadMoreItems) return;
    this.page++;
    this.pageByLoadMoreSubject.next(this.page);
  }

  private applyTranslations(t: any) {
    this.orderByItems.forEach((item, index) => {
      if (t && t[item.key]) {
        item.label = t[item.key]
      }
    });
  }
  protected toggleListMap(event?: MouseEvent) {
    let element = event?.target as HTMLElement;
    let isActive = element.classList.contains("active");
    if (!isActive) {
      this.displayMap = !this.displayMap
    }
  }
  public propertyClickedInMap(property?: PropertySummary) {
    if (this.isMobile) {
      let propertyModal: Modal<PropertyModalComponent> =
        new Modal<PropertyModalComponent>();
  
      propertyModal = this._modalService.show(PropertyModalComponent, {
        keyboard: true,
  
        initialState: { property }
      });
    }
    else {
      this.focusedCardId = property?.id;
    }
  }

  public SaveSearch() {
    let saveSearchModal: Modal<SaveSearchModalComponent> = new Modal<SaveSearchModalComponent>;
    saveSearchModal.onHidden?.subscribe((r: any) => {
      if (r?.result?.success == true) {
        this._toastService.show(ToastType.Success, 'Search saved!', 'You can find it in your "saved searches" or in the search bar!', 3000);
      }
    });

    if (!this.isLoggedIn) {
      this._modalService.show(SaveSearchAnonymousModalComponent, {
        keyboard: true,
      })
    }

    else {
      saveSearchModal = this._modalService.show(SaveSearchModalComponent, {
        keyboard: true,

        initialState: {
          SearchFilters: this.searchFilter
        }
      })

    }
  }

  getSortKeyMapping(sortByKey: string | undefined) {
    this.selectedItem = this.orderByItems.find(item => item.key === sortByKey);

    // Lookup object to map keys to sort strings
    const sortKeyMapping: { [key: string]: string | undefined } = {
      mostRecent: 'createdOn-',
      ascPrice: 'price+',
      descPrice: 'price-',
      ascSize: 'propertyArea+',
      descSize: 'propertyArea-',
      suggested: 'price+',
    };

    if (this.selectedItem) {
      this.sortString = sortKeyMapping[this.selectedItem.key] || ''; // Default to empty if key not found
    }
  }

  orderByChanged(sortByKey: string | undefined) {
    this.getSortKeyMapping(sortByKey);

    this.router.navigate(['/properties'], {
      queryParams: { sort: sortByKey && this.sortString ? this.sortString : undefined },
      queryParamsHandling: 'merge'
    });
  }
  public mapViewChanged(bounds: google.maps.LatLngBounds) {

    if (this.mapSearch) {
      let place: PlaceViewModel = {
        Bounds: {
          SwLon: bounds.getSouthWest().lng() ?? undefined,
          NeLat: bounds.getNorthEast().lat() ?? undefined,
          SwLat: bounds.getSouthWest().lat() ?? undefined,
          NeLon: bounds.getNorthEast().lng() ?? undefined
        }
      }
      let encodedMessage = this.geocodeService.protoEncodeGeoSearch(place);
      let urlencodedMessage = btoa(String.fromCharCode(...encodedMessage));
      let filter: PropertyListFilter = new PropertyListFilter({
        area: urlencodedMessage
      })
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { ...filter },
          queryParamsHandling: 'merge'
        }
      );
    }
  }

  trackByPropertyId(index: number, property: PropertySummary): string  {
    return property.id!; // or whatever unique identifier your properties have
  }

}

