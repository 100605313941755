<ng-container *transloco="let t; prefix: 'shared.request-callback-modal'">
  @if (isLoggedIn) {
  <div class="bg-modals-light rounded-medium p-8 shadow-[0_4px_18px_0_rgba(0,0,0,0.17)]">
    <div class="flex justify-end">
      <button class="p-2"><i class="icon-close text-xl" (click)="close()"></i></button>
    </div>
    <div class="flex flex-col gap-y-8 divide-y-2" [formGroup]="form">
      <div class="flex flex-col">
        <span class="h3 text-black font-normal">{{t('headers.callMeBackAbout')}} </span>
        <span class="h2 text-black">{{t('headers.propertyInfo', { propertyArea: property?.propertyArea, propertyType:
          'shared.enum.propertyType.' + (property?.type ?? 'Unknown') | transloco, prefecture: property?.address?.prefecture})}}</span>
      </div>
      <div class="flex flex-col gap-4 pt-8 px-4">
        <h3>{{t('headers.messageAdvisor')}}</h3>
        <textarea class="rounded-3xl p-4 border border-gray-700" formControlName="message" [placeholder]="t('placeholders.message')"
          rows="5"></textarea>
      </div>
      <div class="flex flex-col px-4 pt-8">
        <button class="btn-primary" (click)="sendRequest()">{{t('buttons.sendRequest')}}</button>
      </div>
    </div>
  </div>
  }
  @else {
  <app-requests-unauthorized (login)="login()" (signUp)="signUp()" (close)="close()"></app-requests-unauthorized>
  }
</ng-container>