<nav
  class="property-top-navigation-wrapper"
  [ngClass]="{ active: shouldFixNav$ | async }"
  #menu
>
  <ul>
    @for (item of inputData; track item; let index = $index) {
    <li>
      <div
        #tabElement
        class="tab-variant"
        [ngClass]="{
            active: activeAnchor === index,
          }"
        [attr.scroll-to]="item.scrollTo"
        (click)="scrollToTarget($event, index)"
      >
        {{ item.label }}
      </div>
    </li>
    }
  </ul>
</nav>
