import { LookingForPropertyOfType } from "src/app/services/portal-api.service";
import { PlaceViewModel } from "src/app/services/property.service";

export class Step1Button {
  id: LookingForPropertyOfType = LookingForPropertyOfType.Unspecified;
  title: string = "";
  icon: string = "";
  isToggled: boolean = false;
}

export class OnboardingData {
  propertyTypes: LookingForPropertyOfType[] = [];
  priceMin: number | undefined;
  priceMax: number | undefined;
  locations: PlaceViewModel[] = [];
}