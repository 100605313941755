import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ReactiveComponentBase } from 'src/app/core/components/ReactiveComponentBase';
import { PortalApiService, PropertyReportPartial } from 'src/app/services/portal-api.service';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { LockedContentOverlayComponent } from "../locked-content-overlay/locked-content-overlay.component";

@Component({
  selector: 'app-property-insights-component',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, DoughnutChartComponent, LockedContentOverlayComponent],
  templateUrl: './property-insights-component.component.html',
  styleUrl: './property-insights-component.component.css'
})
export class PropertyInsightsComponentComponent extends ReactiveComponentBase<PropertyReportPartial>{
  @Input() PropertyId: string | undefined;

  public isLoggedIn: boolean = false;

  constructor(private _portalApiService: PortalApiService, private authService: AuthService) {
    super();
    this.authService.isLoggedIn().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  getModel() {
    return this._portalApiService.getPartialReport(this.PropertyId ?? '');
  }
}
