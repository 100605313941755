export const environment = {
  api_url: 'https://www-api-dev.uniko.gr',
  cms_url: 'https://s3-dev.uniko.gr',
  msg_url: 'https://msg-dev.uniko.gr/api',
  cases_url: 'https://cases-dev.uniko.gr/api',
  matterport_key: "20zg721iw6d60s1sug75tr7cc",
  auth_settings: {
    accessTokenExpiringNotificationTime: 120,
    authority: 'https://id-dev.uniko.gr',
    automaticSilentRenew: true,
    client_id: 'neon-portal-ui',
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    post_logout_redirect_uri: 'https://www-dev.uniko.gr',
    redirect_uri: 'https://www-dev.uniko.gr/auth-callback',
    response_type: 'code',
    revokeAccessTokenOnSignout: true,
    scope: 'openid profile email phone role offline_access identity identity:users neon-portal neon-messaging neon-services:payments neon-services:documents neon-workflow',
    silent_redirect_uri: 'https://www-dev.uniko.gr/auth-renew',
  },
  culture: 'el-GR',
  isTemplate: false,
  production: false,
  recaptchaPublicKey: '6LcNMp4lAAAAAM7_yfC1P24EXWg_N5dbaAIxkY0I',
  cmsApiKey: "my-key",
  services_api_url: 'https://s2-dev.uniko.gr',
  nbg_portal_api_url: 'https://www.nbg.gr'
};
