import { AbstractControl, ValidatorFn } from '@angular/forms';

export function compareValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);
    if (!control || !matchingControl) {
      return null;
    }

    if (control.value >= matchingControl.value) {
      return { compareValues: true };
    }

    return null;
  };
}