<div class="w-full h-full object-center object-cover" #gMapContainer>
    <google-map [mapId]="'f5552e3916e0e5d7'" [options]="mapOptions" #propertyMap [width]="gMapContainer.clientWidth"
        [height]="gMapContainer.clientHeight" (mapInitialized)="mapInitialized($event)">
        <map-marker-clusterer #cluster
            [imagePath]="'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'">
            <map-marker *ngFor="let marker of markers$ | async" [position]="marker.position" [label]="marker.title"
                class="text-white" [icon]="marker.icon" (mapClick)="markerClicked(marker.id)">
            </map-marker>
        </map-marker-clusterer>
    </google-map>
</div>