import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatePickerComponent } from '../../date-picker/date-picker.component';
import { PropertyDetails } from 'src/app/services/portal-api.service';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { compareValidator } from 'src/app/shared/validators/compare.validator';

@Component({
  selector: 'app-request-walkthrough-select-date',
  standalone: true,
  imports: [ReactiveFormsModule, DatePickerComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './request-walkthrough-select-date.component.html'
})
export class RequestWalkthroughSelectDateComponent {
  @Input() 
  property: PropertyDetails | undefined;
  @Output()
  dateSelected: EventEmitter<IWalkthroughDate> = new EventEmitter<IWalkthroughDate>();

  form = new FormGroup({
    date: new FormControl<Date | undefined>(undefined, Validators.required),
    acceptedTerms: new FormControl<boolean |undefined>(undefined, Validators.requiredTrue),
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required),
  }, {
    validators: [compareValidator('from', 'to')]
  });
  public now = new Date();

  public next() {
    this.dateSelected.emit({
      date: this.form.get('date')?.value as Date,
      fromTime: this.form.get('from')?.value as string,
      toTime: this.form.get('to')?.value as string
    });
  }

  public selectDate(input: Date) {
    this.form.get('date')?.setValue(input);
  }

  public updateTerms() {
    const currentValue = !!this.form.get('acceptedTerms')?.value;
    this.form.controls.acceptedTerms.setValue(!currentValue);
  }
}

export interface IWalkthroughDate {
  date: Date,
  fromTime: string,
  toTime: string
}