<div class="flex-1" [formGroup]="surroundingsFormGroup" *transloco="let t; prefix: 'property-listing.surroundings'">
    <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
            <h2 class="h2 text-black">{{'property-listing.tabs.settingAndSurroundings' | transloco}}</h2>
        </div>

        <div class="flex flex-col">
            <h3 class="h3 text-black mb-6">{{t('subheaders.settings')}}</h3>

            <form id="property-wizard" class="mb-6">
                <fieldset class="flex flex-col gap-3 group/input">
                    <label class="font-semibold flex gap-2 items-center text-gray-950 group-disabled:text-gray-300">
                        {{t('labels.plotArea')}}
                        <i class="icon-info-circle text-xl text-gray-900"></i>
                    </label>
                    <div class="flex flex-col lg:flex-row gap-4 items-center justify-center">
                        <div class="min-w-[300px] flex-1">
                            <label for="plotArea"></label>
                            <input #plotAreaRangeInput type="range" id="plotArea" min="20" max="800" step="1"
                                [value]="surroundingsFormGroup.controls.plotArea.value ?? 1"
                                (input)="surroundingsFormGroup.controls.plotArea.setValue(plotAreaRangeInput.value)">
                        </div>
                        <input #plotAreaInput type="number" min="20" max="800" placeholder="122"
                            formControlName="plotArea"
                            onchange="if(this.value < 20) { this.value = null; } else if (this.value > 800) { this.value = 800; }"
                            (input)="surroundingsFormGroup.controls.plotArea.setValue(plotAreaInput.value ? plotAreaInput.value : null)"
                            class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                    </div>
                </fieldset>
            </form>

            <fieldset class="flex flex-col gap-2 group/input max-w-[400px] mb-6">
                <label class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300">
                    {{t('labels.floor')}}
                    <i class="icon-info-circle text-gray-700 text-xl"></i>
                </label>
                <span class="text-gray-900 mb-4">
                    {{t('infoText.floor')}}
                </span>
                <input type="number" formControlName="floor" placeholder="7"
                    class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                <!-- <span class="text-sm text-gray-700 disabled:text-gray-300">
                    Helper text
                </span> -->
            </fieldset>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.view')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('helperText.checkBoxes')}}</span>
            <div class="flex flex-col gap-2 mb-6">
                <label *ngFor="let viewEnum of view | keyvalue: unsorted" [attr.for]="'view'+viewEnum.key"
                    class="checkbox-selector">
                    <input type="checkbox" class="hidden" [attr.id]="'view'+viewEnum.key"
                        [attr.name]="'checkbox-selector-view'+viewEnum.key" [value]="viewEnum.value"
                        [checked]="surroundingsFormGroup.controls.view.value.indexOf(viewEnum.key) > -1"
                        (click)="onMultipleCheckboxClick(viewEnum.key, surroundingsFormGroup.controls.view)">
                    <div class="checkbox-selector-content">
                        <span class="chexkbox-selector-text">{{'shared.enum.view.' + viewEnum.key | transloco}}</span>
                    </div>
                </label>
            </div>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.orientation')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">{{t('helperText.checkBoxes')}}</span>
            <div class="flex flex-col gap-2 mb-16">
                <label *ngFor="let orientationEnum of orientation | keyvalue: unsorted" role="button"
                    [attr.for]="'radio-selector-orientation'+orientationEnum.key" class="radio-selector">
                    <input type="radio" class="hidden" [attr.id]="'radio-selector-orientation'+orientationEnum.key"
                        name="orientation"
                        [checked]="surroundingsFormGroup.controls.orientation.value === orientationEnum.value"
                        (click)="surroundingsFormGroup.controls.orientation.setValue(surroundingsFormGroup.controls.orientation.value === orientationEnum.key ? null : orientationEnum.key)">
                    <div class="radio-selector-content">
                        <span class="radio-selector-text font-semibold">{{'shared.enum.orientation.' + orientationEnum.key | transloco}}</span>
                    </div>
                </label>
            </div>

            <h3 class="h3 text-black mb-6">{{t('subheaders.surroundings')}}</h3>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('helperText.surroundings')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <span class="text-gray-900 mb-4">
                {{t('infoText.surroundings')}}
            </span>
            <div class="flex flex-col gap-2 mb-6">
                <label *ngFor="let surroundingEnum of surrounding | keyvalue: unsorted"
                    [attr.for]="'surrounding'+surroundingEnum.key" class="checkbox-selector">
                    <input type="checkbox" class="hidden" [attr.id]="'surrounding'+surroundingEnum.key"
                        [attr.name]="'checkbox-selector-surrounding'+surroundingEnum.key"
                        [value]="surroundingEnum.value"
                        [checked]="surroundingsFormGroup.controls.surrounding.value.indexOf(surroundingEnum.key) > -1"
                        (click)="onMultipleCheckboxClick(surroundingEnum.key, surroundingsFormGroup.controls.surrounding)">
                    <div class="checkbox-selector-content">
                        <span class="checkbox-selector-text">{{'shared.enum.surrounding.' + surroundingEnum.key | transloco}}</span>
                    </div>
                </label>
            </div>

            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.nearbyHighwaysEl')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col gap-1">
                <input type="text" [placeholder]="t('placeholders.nearbyHighways')" class="bg-white border rounded-md py-[5px] px-2 w-full"
                    [formControl]="highwayEl">
                <div *ngFor="let highwayEl of nearbyHighwaysEl.value"
                    class="bg-white hover:bg-white/40 rounded-full py-[10px] px-6 text-black flex justify-between">
                    {{highwayEl}}
                    <i class="icon-trash cursor-pointer" (click)="onHighwayRemove(highwayEl, nearbyHighwaysEl)"></i>
                </div>
            </div>
            <div class="font-semibold flex items-center gap-2 text-gray-950 group-disabled:text-gray-300 mb-2">
                {{t('labels.nearbyHighwaysEn')}}
                <i class="icon-info-circle text-gray-700 text-xl"></i>
            </div>
            <div class="flex flex-col gap-1">
                <input type="text" [placeholder]="t('placeholders.nearbyHighways')" class="bg-white border rounded-md py-[5px] px-2 w-full"
                    [formControl]="highwayEn">
                <div *ngFor="let highwayEn of nearbyHighwaysEn.value"
                    class="bg-white hover:bg-white/40 rounded-full py-[10px] px-6 text-black flex justify-between">
                    {{highwayEn}}
                    <i class="icon-trash cursor-pointer" (click)="onHighwayRemove(highwayEn, nearbyHighwaysEn)"></i>
                </div>
            </div>
        </div>

        <app-advisor-callback [advisor]="advisor"></app-advisor-callback>

        <div class="border-t-2 pt-7 flex justify-between">
            <button (click)="navigateToTab(propertyListingTab.Construction)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                <i class="icon-arrow-left text-lg"></i>
                {{'property-listing.tabs.heatingAndConstruction' | transloco}}
            </button>
            <button (click)="navigateToTab(propertyListingTab.Ownership)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                {{'property-listing.tabs.ownership' | transloco}}
                <i class="icon-arrow-right text-lg"></i>
            </button>
        </div>
    </div>
</div>