import { OnDestroy, OnInit, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ValidationProblemDetails } from 'src/app/services/identity-api.service';

@Injectable()
export abstract class ReactiveComponentBase<T> implements OnInit, OnDestroy {
  /** use this for the entire component destruction */
  protected componentDestroy$ = new ReplaySubject<void>(1);
  /** use this for model destruction */
  protected modelDestroy$ = new ReplaySubject<void>(1);
  /** not to be confused with loading local variable in reactive template! */
  public loading: boolean = false;
  public error: ValidationProblemDetails | undefined;
  public model$!: Observable<T>;
  constructor() { }

  ngOnInit() {
    this.initModel();
  }

  ngOnDestroy() {
    this.clearModelSubscriptions();
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  public refreshModel() {
    this.clearModelSubscriptions();
    this.clearErrorState();
    this.initModel();
  }

  private clearModelSubscriptions() {
    this.modelDestroy$.next();
    this.modelDestroy$.complete();
    this.modelDestroy$ = new ReplaySubject<void>(1);  // start a new replay subject because someone may make a new subscription to getModel() after it was completed
  }

  private initModel() {
    this.loading = true;
    this.model$ = this.getModel()        // some child class will implement this to fetch the data
      .pipe(
        takeUntil(this.modelDestroy$),       // when ngOnDestroy is called, initial observable will complete. Takes care of subscriptions
        catchError((error: ValidationProblemDetails) => {           // if you want some basic handling of errors, we got you
          this.error = error;
          this.loading = false;
          return throwError(this.onError(error));
        }),
        tap((model: T) => {
          this.loading = false;
          this.onGetModel(model);
        }),
        finalize(() => {
          this.onFinalize();            // You can do more by overriding this
        })
      );
  }

  /**
   * makes a network call, returns an observable.
   * Override on your component to receive state
   * handling goodness.
   * when this returns, local property "model"
   * will have a value that should be directly bound
   * to the UI
   */
  abstract getModel(): Observable<T>;

  /**
   * When the model is fetched,
   * override to create side-effects
   * @param theModel the model
   */
  protected onGetModel(theModel: T) { }

  protected onFinalize() { }

  protected onError(error: ValidationProblemDetails): ValidationProblemDetails {
    return error;
  }

  /**
   * things to be done when error happens go here
   */
  private clearErrorState() {
    this.error = undefined;
  }
}
