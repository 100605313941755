<div class="bg-gray-50 rounded-medium p-8 shadow-[0_4px_18px_0_rgba(0,0,0,0.17)] w-[1000px] h-[812px]" *transloco="let t; prefix: 'property-listing.photos.photo-tag-modal'">
    <div class="flex flex-col">
        <div class="flex flex-col gap-2">
            <div class="flex justify-end">
                <button class="p-2" (click)="onCancel()"><i class="icon-close text-xl"></i></button>
            </div>
            <div class="flex flex-col gap-4">
                <h2 class="h2 text-black">{{t('header')}}</h2>
                <h4 class="h4 subheading text-gray-900">{{t('subheader')}}</h4>
            </div>
        </div>
        <div class="divider-line1 border-b-gray-300 rfs:pt-8"></div>
        <div class="flex flex-col lg:flex-row gap-6 rfs:pt-14 min-h-[510px]">
            <div class="max-w-[384px] max-h-[256px]">
                <div class="flex flex-wrap gap-2 mb-6">
                    <div *ngFor="let mediaTag of mediaTag | keyvalue: unsorted" (click)="onTagClick(media?.tag == mediaTag.key ? undefined : mediaTag.key)" class="border rounded-full px-5 py-2 cursor-pointer"
                        [ngClass]="media?.tag === mediaTag.key ? 'bg-secondary text-white' : 'bg-white hover:bg-white/20'">
                        {{ 'shared.enum.mediaTag.' + mediaTag.key | transloco}}
                    </div>
                </div>
            </div>
            <div class="w-[466px] w-full h-[291px]">
                <picture>
                    <source [srcset]="media?.previewSrc" type="image/webp">
                    <source [srcset]="media?.fallbackSrc" type="image/jpeg">
                    <img class="rounded-medium w-full h-full object-center pointer-events-none img-disable-context"
                        [src]="media?.fallbackSrc" alt="photo">
                </picture>
            </div>
        </div>
        <div class="flex flex-col md:flex-row justify-between items-center">
            <div>
                <button class="btn-text flex items-center gap-2 pt-6" (click)="onDeletePhoto()">{{t('buttons.deletePhoto')}}<i
                        class="icon-trash text-xl"></i></button>
            </div>
            <div class="flex gap-4">
                <div class="flex pt-6"><button class="btn-secondary" (click)="onCancel()">{{t('buttons.cancel')}}</button></div>
                <div class="flex pt-6"><button class="btn-primary" (click)="onSaveTags()">{{t('buttons.saveTags')}}</button></div>
            </div>
        </div>
    </div>
</div>