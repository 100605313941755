import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './../../../core/services/auth.service';
import {
  PortalApiService,
} from '../../../services/portal-api.service';
import { UserProfile } from 'oidc-client-ts';
import { Modal } from '@indice/ng-components';
import { 
  ChangePasswordRequest,
  CredentialsValidationInfo,
  IdentityApiService,
  PasswordRuleInfo,
  UpdateUserPhoneNumberRequest,
  ValidatePasswordRequest,
  ValidationProblemDetails
} from '../../../services/identity-api.service';
import {
  Subject,
  Subscription,
  forkJoin,
  of,
} from 'rxjs';

import { 
  catchError,
  debounceTime,
  finalize,
  map,
  tap,
} from 'rxjs/operators';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-profile',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private _authService: AuthService,
    private _identityApi: IdentityApiService,
    private _portalService: PortalApiService,
    public modalRef: Modal
  ) {}
  ngOnInit(): void {
    this.getUserProfile();
    this._passwordValidateTextSubscription = this.validatePasswordText$
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.validatePassword(
          this.changePasswordRequest.newPassword
        ).subscribe();
      });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.toggleProfile = true;
    }, 200);
  }
  ngOnDestroy(): void {
    this._passwordValidateTextSubscription?.unsubscribe();
  }

  userProfile: UserProfile | undefined;
  updatedUserProfile: UserProfile | undefined;

  changePasswordRequest: ChangePasswordRequest = new ChangePasswordRequest();
  passwordRules: PasswordRuleInfo[] = [];
  newPasswordInvalid = false;
  showErrors = false;
  validatePasswordText$: Subject<string> = new Subject<string>();
  private _passwordValidateTextSubscription!: Subscription;

  toggleProfile: boolean = false;
  editName = false;
  editPhone = false;

  private getUserProfile(): void {
    this._authService.removeUser(); // force user profile reload
    this._authService
      .signinSilent()
      .pipe(
        tap(() => {
          const currentUser = this._authService.getCurrentUser();
          if (currentUser) {
            this.userProfile = currentUser.profile;
            this.updatedUserProfile = cloneDeep(this.userProfile);
          }
        })
      )
      .subscribe();
  }

  closeProfile(): void {
    this.toggleProfile = false;
    setTimeout(() => {
      this.modalRef.hide();
    }, 200);
  }
  toggleEditName(): void {
    this.editName = !this.editName;
  }
  toggleEditPhone(): void {
    this.editPhone = !this.editPhone;
  }


  onPasswordChange() {
    this._identityApi.updatePassword(this.changePasswordRequest).subscribe(
      () => {
        alert(
          'Password was changed successfully. You need to logout and sign in again.'
        );
        this._authService.signoutRedirect();
      },
      (error: ValidationProblemDetails) => {
        if (error.errors) {
          Object.values(error.errors).forEach((value) => {
            alert('Error: ' + value.join(','));
          });
        }
      }
    );
  }

  validatePassword(term: string) {
    return this._identityApi
      .validatePassword({
        password: term,
      } as ValidatePasswordRequest)
      .pipe(
        map((response: CredentialsValidationInfo) => {
          this.passwordRules = response.passwordRules ?? [];
          this.newPasswordInvalid =
            this.passwordRules.find((x) => !x.isValid) !== undefined;
        })
      );
  }
}
