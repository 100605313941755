<div class="chart-container flex flex-col justify-center" *transloco="let t; prefix: 'shared.insights'">
  <div class="flex justify-center">
    <div class="relative">
      <canvas [attr.id]="title ?? ''" class="!w-[168px]">{{ chart }}</canvas>
      <div class="absolute inset-0 flex justify-center items-center ">
        <div class="text-end">
          <div class="flex items-center justify-center">
            <span class=" font-bold block text-5xl" [ngClass]="type == 0 ? 'text-secondary-500':'text-primary-500'">{{
              value
              | number:'1.0':'el'}} </span>
            <span class="text-gray-700 block"> / 5</span>
          </div>

          <span class="text-gray-700 block">{{title}}</span>
        </div>
      </div>
    </div>
  </div>
</div>