import { Component, OnInit } from '@angular/core';

@Component({
    selector: '.app-portal-shell',
    templateUrl: './portal-shell.component.html'
})
export class PortalShellComponent implements OnInit {
    constructor() { }

    public ngOnInit(): void { }
}
