import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopNavigationService {
  private readonly shoulfFixNavSub = new BehaviorSubject<boolean>(false);
  readonly shouldFixNav$ = this.shoulfFixNavSub.asObservable();

  addClass(value: boolean): void {
    this.shoulfFixNavSub.next(value);
  }
}
