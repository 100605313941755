<div (clickOutside)="closeDropdown()" *transloco="let t; prefix: 'find-property.range-dropdown'">
    <div class="min-w-[276px] w-full px-1 py-1.5 flex-col justify-start items-start gap-1.5 inline-flex" [ngClass]="{'border-b border-gray-300': hasBorder}">
@if(isHomepage) {
  <span
  class="font-semibold text-black text-base">
    <i [ngClass]="iconClasses"></i>
    {{title}}
  </span>
  <button
      euroFormatInput
      (click)="toggleDropdown()"
      class="w-full justify-between items-center gap-2 flex text-base font-normal ml-5" [ngClass]="selectedLabel ? 'text-black': 'text-gray-700'">
      @if (!selectedRange?.from && !selectedRange?.to) {
          <div class="flex flex-row justify-between text-gray-500">
              {{selectedLabel}}
          </div>
      } @else {
          <div class="flex flex-row justify-between">
              {{selectedRange?.from && !selectedRange?.to ? t('from') : ''}}
              {{selectedRange?.from | numberMask}}
              {{selectedRange?.from ? postfix : ''}}
              {{selectedRange?.from && selectedRange?.to ? '-' : ''}}
              {{selectedRange?.to && !selectedRange?.from ? t('to') : ''}} {{selectedRange?.to | numberMask}}
              {{selectedRange?.to ? postfix : ''}}
          </div>
      }
  </button>
} @else {
  @if(!hasPlaceholder){
    <div
    class="text-black text-lg font-semibold flex justify-between w-full" (click)="toggleDropdown()">
      <span>{{title}}</span>
      <i class="w-5 h-5 text-md font-normal"
      [ngClass]="showResults? 'icon-chevron-down text-primary-700' : 'icon-chevron-up'">
      </i>
    </div>
  } @else {
    <span
    class="text-gray-700 text-xs font-semibold uppercase">{{title}}</span>
    <button
        euroFormatInput
        (click)="toggleDropdown()"
        class="w-full justify-between items-center gap-2 flex text-base font-normal" [ngClass]="selectedLabel ? 'text-black': 'text-gray-700'">
        @if (!selectedRange?.from && !selectedRange?.to) {
            <div class="flex flex-row justify-between text-gray-500">
                {{selectedLabel}}
            </div>
        } @else {
            <div class="flex flex-row justify-between">
                {{selectedRange?.from && !selectedRange?.to ? t('from') : ''}}
                {{selectedRange?.from | numberMask}}
                {{selectedRange?.from ? postfix : ''}}
                {{selectedRange?.from && selectedRange?.to ? '-' : ''}}
                {{selectedRange?.to && !selectedRange?.from ? t('to') : ''}} {{selectedRange?.to | numberMask}}
                {{selectedRange?.to ? postfix : ''}}
            </div>
        }
        <i
            class="w-5 h-5 text-md font-normal"
            [ngClass]="showResults? 'icon-chevron-down text-primary-700' : 'icon-chevron-up'">
        </i>
    </button>
  }
}


    </div>

    <div *ngIf="showResults"
        class="md:absolute md:top-[100%]  md:left-0 md:rounded-xl px-4 md:shadow-2xl md:bg-white"
        [ngClass]="isHomepage ? 'bg-white md:translate-y-8' : 'bg-gray-50 md:translate-y-4 w-full' "
        >

        <section class="flex flex-col py-4 gap-2">
            <fieldset class="flex flex-col gap-2 group/input">
                <div class="flex rounded-full bg-white border border-gray-300 py-2 px-4">
                    <p *ngIf="prefix || prefixIcon" class="flex justify-center items-center gap-1 pr-4">
                        <i class="text-gray-700 text-xl" [ngClass]="prefixIcon">{{prefix}}</i>
                    </p>
                    <input
                        numericOnly
                        euroFormatInput
                        [placeholder]="t('from')"
                        class="bg-white outline-none ml-2 flex-1"
                        [ngClass]="minValue ? 'text-black' : 'text-gray-500'"
                        [value]="(minValue ?? null) | numberMask"
                        (change)="minValueChanged($event)">
                    <div *ngIf="postfix || postfixIcon" class="flex justify-center items-center gap-1 " [ngClass]="isHomepage ? '' : 'pl-4'">
                        <div *ngIf="postfix || postfixIcon" class="text-black text-base font-normal">
                            @if (postfixIcon) {
                                <i [ngClass]="postfixIcon">{{postfixIcon}}</i>
                            } @else {
                                <span >{{postfix}}</span>
                            }
                        </div>
                    </div>
                </div>
            </fieldset>

            <label *ngFor="let item of minDropdownItems; let i = index" role="button" [attr.for]="'menu-item-radio-group1-'+i"
                class="menu-item-radio small py-2">
                <input
                    type="radio" class="hidden" [attr.id]="'menu-item-radio-group1-'+i" name="menu-item-radio-group1"
                    (change)="itemSelected(item)" [checked]="selectedLabel==item.label">
                <div class="menu-item-radio-content">
                    <div class="flex flex-1 justify-start items-center">
                        <span class="menu-item-radio-text">{{item.label}}</span>
                    </div>
                </div>
            </label>
        </section>

        <div class="divider-line1 border-gray-300"></div>

        <section class="flex flex-col py-4 gap-2">
            <fieldset class="flex flex-col gap-2 group/input">
                <div class="flex rounded-full bg-white border border-gray-300 py-2 px-4">
                    <p *ngIf="prefix || prefixIcon" class="flex justify-center items-center gap-1 pr-4">
                        <i class="text-gray-700 text-xl" [ngClass]="prefixIcon">{{prefix}}</i>
                    </p>
                    <input
                        numericOnly
                        euroFormatInput
                        [placeholder]="t('to')"
                        class="bg-white outline-none ml-2 flex-1"
                        [ngClass]="maxValue ? 'text-black' : 'text-gray-500'"
                        [value]="(maxValue ?? null) | numberMask"
                        (change)="maxValueChanged($event)">
                    <div *ngIf="postfix || postfixIcon" class="text-black text-base font-normal">
                        @if (postfixIcon) {
                            <i [ngClass]="postfixIcon">{{postfixIcon}}</i>
                        } @else {
                            <span >{{postfix}}</span>
                        }
                    </div>
                </div>
            </fieldset>

            <label *ngFor="let item of maxDropdownItems; let i = index" role="button" [attr.for]="'menu-item-radio-group2-'+i"
                class="menu-item-radio small py-2">
                <input
                    type="radio" class="hidden" [attr.id]="'menu-item-radio-group2-'+i" name="menu-item-radio-group2"
                    (change)="itemSelected(item)" [checked]="selectedLabel==item.label">
                <div class="menu-item-radio-content">
                    <div class="flex flex-1 justify-start items-center">
                        <span class="menu-item-radio-text">{{item.label}}</span>
                    </div>
                </div>
            </label>
        </section>
    </div>
</div>
