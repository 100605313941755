<div class="w-full container">
    <div class="container flex py-7">
        <h1 class="h1 text-black">Επικοινώνησε μαζί μας</h1>
    </div>
    <div class="flex flex-col md:flex-row container justify-between border border-gray-200 bg-gray-50 rounded-lg p-8 md:gap-14 gap-8 ">
      <div class="flex flex-col justify-between border-b md:border-r md:border-b-0 border-gray-200 flex-1 gap-3 pb-6 md:pb-0">
        <span class="text-xl text-black">Πού θα μας βρεις</span>
        <span class="text-base text-primary-700 underline">Ελευθερίου Βενιζέλου 132, Καλλιθέα</span>
      </div>
      <div class="flex flex-col justify-between border-b md:border-r md:border-b-0 border-gray-200 flex-1 gap-3 pb-6 md:pb-0">
        <span class="text-xl text-black">Στείλε μας email</span>
        <span class="text-base text-primary-700 underline">info&#64;qualco.eu</span>
      </div>
      <div class="flex flex-col justify-between flex-1 gap-3">
        <span class="text-xl text-black">Κάλεσέ μας</span>
        <span class="text-base text-gray-900">Αθήνα: +30 210 8028319</span>
      </div>
    </div>
    <section class="container pt-8 pb-12 px-0">
      <div class="mx-auto border rounded-lg bg-white p-4 md:p-8">
      <h1 class="h1 text-black mb-6">Στείλε μας μήνυμα </h1>
      <h2 class="mb-8 text-gray-900">Συμπλήρωσε την παρακάτω φόρμα και η ομάδα μας θα επικοινωνήσει μαζί σου σύντομα.</h2>
      <form class="flex flex-col gap-4 md:gap-8" [formGroup]="form">
          <div class="flex md:flex-row flex-col gap-4 md:gap-14">
              <fieldset class="flex flex-col gap-2 group/input flex-1">
                  <label class="font-semibold flex items-center text-gray-950 group-disabled:text-gray-300 gap-4" ><span>Your name
                          <span class="text-error">*</span></span></label>
                  <input placeholder="Your full name" formControlName="fullName" mask="A* S*"
                      class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
              </fieldset>
              <fieldset class="flex flex-col gap-2 group/input flex-1">
                  <label class="font-semibold flex items-center text-gray-950 group-disabled:text-gray-300 gap-4" ><span>Your email
                          <span class="text-error">*</span></span></label>
                  <input placeholder="Your contact email" formControlName="email"
                      class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
              </fieldset>
          </div>
          <fieldset class="flex flex-col gap-2 group/input">
              <label class="font-semibold flex items-center gap-4 text-gray-950 group-disabled:text-gray-300" ><span>Message or question <span class="text-error">*</span></span></label>
              <textarea
                  rows="5" formControlName="message"
                  class="bg-white text-gray-500 rounded-[1.5rem] border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none"
                  placeholder="What would you like to chat about?"></textarea>
          </fieldset>
          <div class="flex flex-col md:flex-row justify-between gap-5 md:gap-0">
            <div class="flex flex-col gap-3 max-w-[460px]">
              <div class="flex-1 border border-gray-300 rounded-lg p-4 w-full">
                <label role="button" for="menu-item-checkmark-1" class="menu-item-checkmark border-0 hover:!bg-white">
                  <input type="checkbox" class="hidden" id="menu-item-checkmark-1" name="menu-item-checkmark"
                  formControlName="acceptTerms"
                    />
                  <div>
                    <span class="font-semibold text-sm">Συμφωνώ με τους Όρους και Προϋποθέσεις</span>
                  </div>
                </label>
              </div>
              <div class="flex-1 border border-gray-300 rounded-lg p-4 w-full">
                <label role="button" for="menu-item-checkmark-2" class="menu-item-checkmark border-0 hover:!bg-white">
                  <input type="checkbox" class="hidden" id="menu-item-checkmark-2" name="menu-item-checkmark"
                     />
                  <div>
                    <span class="font-semibold text-sm">Δεν ειμαι ρομποτ</span>
                  </div>
                </label>
              </div>
            </div>
            <div class="content-end">
              <button [disabled]="!(acceptedTerms && isLoggedIn)" class="btn-primary flex-1c flex-nowrap">Send us your message</button>
            </div>
          </div>
      </form>
      </div>
  </section>
</div>

