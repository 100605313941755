import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ResolveEnd, Router, RouterModule } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject } from 'rxjs';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslocoDirective, ClickOutsideDirective],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  showDropdown = false;
  openDropdowns: { [key: string]: boolean } = {};
  showFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  isOpen = false;
  selectedLanguage: string | undefined = this.translocoService.getActiveLang() === 'en' ? 'English' : 'Greek';

  constructor(private router: Router, private translocoService: TranslocoService) { }
  ngOnInit(): void {
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        if (routerData.url.includes("welcome") || routerData.url.includes("on-boarding") || routerData.url.includes("property-upload-wizard")) {
          this.showFooter.next(false);
        }
        else {
          this.showFooter.next(true)
        }
      }
    });
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  handleShowDropdown(id: string): void {
    this.showDropdown = !this.showDropdown;
    this.openDropdowns[id] = !this.openDropdowns[id];
  }

  setSelectedValue(event: Event) {
    const language = (event.target as HTMLButtonElement).value;
    this.openDropdowns['languages'] = false;
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectLanguage() {
    let langToSet = this.translocoService.getActiveLang() === 'en' ? 'el' : 'en';
    this.translocoService.setActiveLang(langToSet)
    // Set the selected language label based on the language code
    this.selectedLanguage = langToSet === 'en' ? 'English' : 'Greek';
    this.isOpen = false;
  }

}
