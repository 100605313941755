<div (clickOutside)="closeDropdown()" class="transition-all">
    <div class="flex flex-col gap-1 min-w-[115px]">
        <button
            (click)="toggleDropdown()"
            class="flex items-center justify-between text-sm py-2 px-3"
            [ngClass]="btnStyleText ? 'btn-text text-primary-700' : 'btn-secondary text-black' ">
                {{selectedLabel}}
            <i
                class="ml-3 text-xl active:text-primary-700"
                [ngClass]="showResults? 'icon-chevron-down':'icon-chevron-up'">
            </i>
        </button>
    </div>

    <div *ngIf="showResults"
        class="absolute top-[100%] translate-y-4 left-0 rounded-xl px-4 py-4 shadow-2xl bg-gray-50 z-50 max-h-[300px] overflow-y-auto overflow-x-hidden w-[300px]">

        <label *ngFor="let item of dropdownItems; let i = index" role="button" [attr.for]="'menu-item-radio-'+i"
            class="menu-item-checkbox small pb-2">
            <input type="checkbox" class="hidden" [attr.id]="'menu-item-radio-'+i" name="menu-item-radio-group1"
                (change)="ItemSelected(item)" [checked]="item.checked">
            <div class="menu-item-radio-content p-2" [ngClass]="{'bg-gray-100 rounded':item.checked}">
                <div class="flex flex-row justify-between">
                    <!-- <i class="group-disabled/menu-item:text-gray-50 mr-4" [ngClass]="item.icon"></i> -->
                    <span class="menu-item-radio-text" [ngClass]="{'text-primary-700':item.checked}">{{item.label}}</span>
                    <i *ngIf="item.checked===true" class="icon-check text-primary-700 my-auto"></i>
                </div>
            </div>
        </label>
    </div>
</div>
