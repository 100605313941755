import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NavigationButtonsComponent } from 'src/app/shared/components/navigation-buttons/navigation-buttons.component';
import { ModalService, ToasterService } from '@indice/ng-components';
import { ToasterContainerComponent } from 'src/app/shared/components/toast/toast-container.component';
import { OnboardingExitModalComponent } from '../../onboarding/onboarding-exit-modal/onboarding-exit-modal.component';
import { TranslocoDirective, TranslocoPipe, } from '@jsverse/transloco';
import { ILoanEligibilityModel } from '../model';
import { DoughnutChartComponent } from "../../../shared/components/loan-calulator/doughnut-chart/doughnut-chart.component";
import { LoanService } from 'src/app/services/loan.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-loan-eligibility-result',
  standalone: true,
  imports: [CommonModule, NavigationButtonsComponent, ToasterContainerComponent, TranslocoDirective, TranslocoPipe, DoughnutChartComponent],
  templateUrl: './loan-eligibility-result.component.html',
  styleUrl: './loan-eligibility-result.component.css'
})
export class LoanEligibilityResultComponent {
  @Input() resultData!: ILoanEligibilityResultModel;
  @Input() formData!: ILoanEligibilityModel

  protected tableIsCollapsed = true;
  protected propertyMortgageCategoryId = '9EEC3373-4411-44C1-82F9-8B12A3C30566'; //this is the category id as provided by nbg
  protected propertyRenovationCategoryId = '300951CE-9CC4-4904-8921-540AD2B6CC20'; //this is the category id as provided by nbg

  constructor(
    private _modalService: ModalService,
    private _loanService: LoanService,
    private _route: ActivatedRoute,
    private router: Router,
  ) {

  }

  protected onNextClick(): void {
    const propertyId = this._route.snapshot.queryParams["property"];
    let path = '/loan-application'
    this.router.navigate(
      [path],
      {
        queryParams: { property: propertyId },
        queryParamsHandling: 'merge'
      }
    );
  }

  protected onPrevClick(): void {
    const propertyId = this._route.snapshot.queryParams["property"];
    let path = '/properties'
    if (propertyId) {
      path += `/${propertyId}`
    }

    this.router.navigate([path]);
  }

  toggleCollapse(event: MouseEvent) {
    this.tableIsCollapsed = !this.tableIsCollapsed;
  }
  protected openModalComponent(): void {
    const exitModal = this._modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
}

export interface ILoanEligibilityResultModel {
  isEligible: boolean,

}