import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { AuthService } from 'src/app/core/services/auth.service';
import { IdentityApiService, TotpDeliveryChannel, TotpRequest, TotpVerificationRequest } from 'src/app/services/identity-api.service';
import { ToptCursorMoveDirective } from 'src/app/shared/directives/top-cursor-move.directive';

@Component({
  selector: 'app-request-walkthrough-verify-phone',
  standalone: true,
  imports: [ReactiveFormsModule, ToptCursorMoveDirective, TranslocoDirective],
  templateUrl: './request-walkthrough-verify-phone.component.html'
})
export class RequestWalkthroughVerifyPhoneComponent implements OnInit {
  @Output()
  phoneVerified: EventEmitter<void> = new EventEmitter<void>();

  public form = new FormGroup({
    digit1: new FormControl('', Validators.required),
    digit2: new FormControl('', Validators.required),
    digit3: new FormControl('', Validators.required),
    digit4: new FormControl('', Validators.required),
    digit5: new FormControl('', Validators.required),
    digit6: new FormControl('', Validators.required),
  });
  public error: string = '';
  public phoneNumber: string | undefined = '';

  constructor(private _identityApi: IdentityApiService, private _authService: AuthService) {
  }

  ngOnInit(): void {
    this.form.valueChanges
      .subscribe(x => this.error = '')
    this.sendTotp();

    this.phoneNumber = this._maskPhoneNumber(this._authService.getCurrentUser()?.profile.phone_number);
  }

  public sendTotp() {
    this.form.reset();
    this.error = '';
    this._identityApi.send(new TotpRequest({
      channel: TotpDeliveryChannel.Sms,
      message: 'OTP CODE: {0} FOR PHONE NUMBER VERIFICATION. IT WILL BE VALID FOR 2 MINUTES.',
      subject: 'Verify phone number'
    }))
    .subscribe();
  }

  public verifyTotp() {
    const token = Object.values(this.form.value).join('');
    this._identityApi.verify({
        code: token
      } as TotpVerificationRequest)
      .subscribe(
        res => {
          this.phoneVerified.emit()
        },
        err => {
          this.error = 'The code you have inserted is not valid. Please try again.'
        })
  }

  public resetForm() {
    this.form.reset();
  }

  private _maskPhoneNumber(phoneNumber: string | undefined): string {
    if (!phoneNumber) return '';
    const phoneSections = phoneNumber.split(' ');
    const hasCountryCode = phoneSections?.length > 1;
    const countryCode = hasCountryCode ? phoneSections[0] : '';
    const restNumber = hasCountryCode ? phoneSections[1] : phoneSections[0];
    const maskedSection = restNumber.slice(0, 6).replace(/\d/g, 'X');
    const visibleSection = restNumber.slice(6);
    return `${countryCode} ${maskedSection}${visibleSection}`;
  }
}

