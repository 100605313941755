import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { User } from 'oidc-client-ts';
import { Observable, filter, map, switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { PropertyDetails } from 'src/app/services/portal-api.service';
import { PropertyListingService } from 'src/app/services/property.service';
import { PropertyCardItemComponent } from 'src/app/shared/components/property-card-item/property-card-item.component';

@Component({
  selector: 'app-complete-request-callback',
  standalone: true,
  imports: [PropertyCardItemComponent, RouterModule, CommonModule, TranslocoDirective],
  templateUrl: './complete-request-callback.component.html',
})
export class CompleteRequestCallbackComponent {
  @Input() requestData: IRequestData | undefined;
  modelItem$: Observable<PropertyDetails> | undefined;

  @Output() continue = new EventEmitter();
  @Output() skip = new EventEmitter();

  public currentUser: User | null = null;

  constructor(private modelService: PropertyListingService, private _authService: AuthService) {}

  ngOnInit(){
    if(this.requestData?.propertyId)
      this.modelItem$ = this.modelService.getPropertyById(this.requestData?.propertyId);

    this.currentUser = this._authService.getCurrentUser();
  }

  onContinue = () => this.continue.emit();
  onSkip = () => this.skip.emit();

}

export interface IRequestData {
  action: 'callback' | 'walkthrough';
  propertyId:string;
  date: string;
  from: string;
  to: string;
}
