<form [formGroup]="paymentForm" class="flex flex-col gap-8"
    *transloco="let t; prefix: 'shared.checkout.step-payment-details'">
    <div class="flex flex-col sm:flex-row gap-5">
        <label [htmlFor]="paymentMethodEnum.CreditCard" class="radio-selector">
            <input [formControlName]="'paymentMethod'" type="radio" [id]="paymentMethodEnum.CreditCard"
                (change)="this.paymentMethod = paymentMethodEnum.CreditCard" [value]="paymentMethodEnum.CreditCard"
                [checked]="paymentMethod === paymentMethodEnum.CreditCard ? 'checked' : null" class="hidden" />
            <div class="radio-selector-content">
                <span class="radio-selector-text font-semibold">{{'shared.enum.wayOfPayment.' +
                    paymentMethodEnum.CreditCard | transloco }}</span>
            </div>
        </label>

        <label [htmlFor]="paymentMethodEnum.BankDeposit" class="radio-selector">
            <input [formControlName]="'paymentMethod'" type="radio" [id]="paymentMethodEnum.BankDeposit"
                (change)="this.paymentMethod = paymentMethodEnum.BankDeposit" [value]="paymentMethodEnum.BankDeposit"
                [checked]="paymentMethod === paymentMethodEnum.BankDeposit ? 'checked' : null" class="hidden"
                [attr.disabled]="true" />
            <div class="radio-selector-content">
                <span class="radio-selector-text font-semibold">{{'shared.enum.wayOfPayment.' +
                    paymentMethodEnum.BankDeposit| transloco }}</span>
            </div>
        </label>
    </div>

    <div class="flex gap-8" *ngIf="paymentMethod === paymentMethodEnum.CreditCard">
        <ng-container [formGroupName]="'cardForm'">
            <div class="flex-1 flex flex-col gap-6">
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': formCardError.cardHolder }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.cardholderName')}}
                        <span class="text-error">*</span></label>
                    <input [formControlName]="'cardholderName'" id="cardholder_name" readonly
                        class="bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">

                </fieldset>
                <fieldset class="flex flex-col gap-2 group/input">
                    <label [ngClass]="{ 'text-red': formCardError.cardNumber }"
                        class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.cardNumber')}}
                        <span class="text-error">*</span></label>
                    <div
                        class="flex text-gray-500 rounded-full bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">

                        <button class="flex justify-center items-center gap-1 pr-4 border-r border-gray-300 mr-4">
                            <i class="icon-info-circle text-gray-700 text-xl"></i>
                        </button>
                        <input [formControlName]="'cardNumber'" id="card_number" readonly
                            class="bg-white outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300"
                            placeholder="XXXX XXXX XXXX XXXX">
                    </div>
                </fieldset>
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="flex flex-col gap-2 md:mb-0 mb-4">
                        <label [ngClass]="{ 'text-red': formCardError.expiry}"
                            class="font-semibold flex gap-1 items-center text-gray-950 group-disabled:text-gray-300">{{t('labels.expirationDate')}}
                            <span class="text-red">*</span></label>
                        <div class="flex gap-2">
                            <div class="group/input">
                                <div
                                    class="flex bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <input [formControlName]="'expiryMonth'" id="expiry_month"
                                        class="bg-white max-w-[60px] outline-none flex-1 group-hover/input:bg-white transition-colors duration-300"
                                        placeholder="MM" readonly>
                                </div>
                            </div>
                            <div class="group/input">
                                <div
                                    class="flex bg-white text-gray-500 rounded-full border border-gray-300 py-3 px-4 group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <input [formControlName]="'expiryYear'" id="expiry_year"
                                        class="bg-white max-w-[60px] outline-none flex-1 group-hover/input:bg-white transition-colors duration-300"
                                        placeholder="YY" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                    <fieldset class="flex flex-col gap-2 group/input">
                        <label [ngClass]="{ 'text-red': formCardError.securityCode}"
                            class="font-semibold text-gray-950 group-disabled:text-gray-300">{{t('labels.securityCode')}}</label>
                        <div
                            class="flex text-gray-500 rounded-full bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                            <input [formControlName]="'securityCode'" id="security_code" readonly
                                class="bg-white outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300 md:max-w-[50px]"
                                placeholder="XXX">
                            <button class="flex justify-center items-center">
                                <i class="icon-eye text-gray-700 text-xl"></i>
                            </button>
                        </div>
                    </fieldset>
                </div>
            </div>
        </ng-container>
        <div class="flex-1 flex flex-col gap-6">
        </div>
    </div>
    <div *ngIf="paymentMethod === paymentMethodEnum.BankDeposit">bank deposit form</div>

</form>
<lib-toaster-container></lib-toaster-container>