import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toast, ToastType } from 'src/app/services/toaster.service';


@Component({
  selector: 'lib-toaster',
  templateUrl: './toast.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class ToastComponent implements OnInit {

  @Input() toast: Toast | undefined;
  @Input() i = 0;
  @Output() remove = new EventEmitter<number>();
  public isMobile = false;
  public closed = false;
  public toastType = ToastType;

  constructor() { }

  ngOnInit(): void {
    window.screen.width > 640 ? this.isMobile = false : this.isMobile = true;
  }

  public closeToast(index: number): void {
    this.closed = true;
    setTimeout(() => this.remove.emit(index), 100);
  }
}